import React from "react";
import { CssBaseline } from "@mui/material";
import { ThemeProvider, createTheme } from "@mui/material/styles";

const theme = createTheme();

theme.palette.background.default = "#f4f5f7";
theme.palette.background.secondary = "#e2eae3bd";
theme.palette.primary.main = "#15abe6";
theme.palette.primary.dark = "#15abe6";
theme.palette.primary.light = "#15abe6";
theme.palette.secondary.main = "#ffffff";
theme.palette.secondary.dark = "#ffffff";
theme.palette.secondary.light = "#ffffff";
theme.palette.secondary.disabled = "#dddddd";
theme.shadows[1] = "rgb(0 0 0 / 12%) 0px 1px 2px, rgb(0 0 0 / 5%) 0px 0px 0px 1px";

const Theme = ({ children }) => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {children}
    </ThemeProvider>
  );
};

export default Theme;

import { useRecoilState, useResetRecoilState } from "recoil";
import useAlert from "hooks/useAlert";
import useCommon from "hooks/useCommon";
import { clientAtom } from "store/client";
import { experimentEditorAtom } from "store/experiment";
import { apiUpdateExperiment } from "store/api";
import moment from "moment";

const useExperimentsEditor = () => {
  const hCommon = useCommon();
  const hAlert = useAlert();

  const [clientState] = useRecoilState(clientAtom);
  const [editorState, setEditorState] = useRecoilState(experimentEditorAtom);

  const resetEditorState = useResetRecoilState(experimentEditorAtom);

  const init = (experiment) => {
    setEditorState({
      ...editorState,
      type: experiment.type ?? "funnel",
      values: {
        id: experiment.id,
        name: experiment.name,
        variations: (experiment.variations[0] && experiment.variations[0].variation_slug) || "",
        traffic_volume: experiment.traffic_volume,
        objective: experiment.objective,
        start_datetime: experiment.start_datetime,
        end_datetime: experiment.end_datetime,
        end_visitors_amount: experiment.end_visitors_amount,
        result: experiment.result,
      },
      bannerInitialized: true,
      bannerValues: experiment.banner,
    });
  };

  const close = () => {
    resetEditorState();
  };

  const setType = async (value) => {
    await setEditorState({
      ...editorState,
      type: value,
      touched: true,
    });
  };

  const setBannerValue = async (key, value) => {
    await setEditorState({
      ...editorState,
      touched: true,
      bannerInitialized: true,
      bannerValues: {
        ...editorState.bannerValues,
        [key]: value,
      },
    });
  };

  const setBannerValues = async (values) => {
    await setEditorState({
      ...editorState,
      touched: true,
      bannerInitialized: true,
      bannerValues: {
        ...editorState.bannerValues,
        ...values,
      },
    });
  };

  const setValue = async (key, value, error = null) => {
    setEditorState({
      ...editorState,
      touched: true,
      values: {
        ...editorState.values,
        [key]: value,
      },
      errors: {
        ...editorState.errors,
        [key]: error,
      },
    });
  };

  const setValues = async (values, errors) => {
    setEditorState({
      ...editorState,
      touched: true,
      values: {
        ...editorState.values,
        ...values,
      },
      errors: {
        ...editorState.errors,
        ...errors,
      },
    });
  };

  const back = () => {
    setEditorState({
      ...editorState,
      form: editorState.form - 1,
      showError: false,
      errors: {},
    });
  };

  const next = () => {
    if (Object.values(editorState.errors).filter((d) => d !== null).length === 0) {
      setEditorState({
        ...editorState,
        form: editorState.form + 1,
        showError: false,
      });
    } else {
      setEditorState({
        ...editorState,
        showError: true,
      });
    }
  };

  const save = async () => {
    if (Object.values(editorState.errors).filter((d) => d !== null).length === 0) {
      hCommon.loading("Saving Experiment ...");

      const res = await apiUpdateExperiment(editorState.values.id)(
        {
          solar_company: clientState.currentId,
        },
        {
          name: editorState.values.name,
          type: editorState.type,
          variations: [editorState.values.variations],
          traffic_volume: editorState.values.traffic_volume,
          objective: editorState.values.objective,
          start_datetime: moment(editorState.values.start_datetime).format("YYYY-MM-DDTHH:mm:ssZ"),
          end_datetime: moment(editorState.values.end_datetime).format("YYYY-MM-DDTHH:mm:ssZ"),
          end_visitors_amount: editorState.values.end_visitors_amount,
          result: editorState.values.result,
          solar_company: clientState.currentId,
          banner: editorState.bannerValues,
        }
      );

      if (res.status === 200) {
        hAlert.show("Experiment saved successfully!");
        setEditorState({
          ...editorState,
          touched: false,
        });
      } else {
        hAlert.show(Object.values(res.data).join("\n"), false);
      }

      hCommon.loading("");
    } else {
      setEditorState({
        ...editorState,
        showError: true,
      });
    }
  };

  return {
    ...editorState,
    setType,
    setBannerValue,
    setBannerValues,
    setValue,
    setValues,
    back,
    next,
    save,
    init,
    close,
  };
};

export default useExperimentsEditor;

import { useRecoilState } from "recoil";
import { clientAtom } from "store/client";
import { experimentAtom } from "store/experiment";
import { apiGetClient, apiGetVariations } from "store/api";

const useExperiments = () => {
  const [clientState] = useRecoilState(clientAtom);
  const [expState, setExpState] = useRecoilState(experimentAtom);

  const init = async () => {
    const resVariation = await apiGetVariations({
      solar_company: clientState.currentId,
    });

    const resClient = await apiGetClient(clientState.currentId);

    if (resVariation.status === 200 && resClient.status === 200) {
      setExpState({
        ...expState,
        initialized: true,
        variations: resVariation.data,
        client: resClient.data,
      });
    }
  };

  return {
    ...expState,
    init,
  };
};

export default useExperiments;

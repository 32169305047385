import {
  apiGet,
  apiGetParams,
  apiPost,
  apiPostParams,
  apiPostMulti,
  apiPostBlob,
  apiPostHeaders,
  apiPatch,
  apiPatchParams,
  apiPatchMulti,
  apiPut,
  apiDelete,
} from "utils/api";

// auth
export const apiGetToken = apiPost("/auth/token/");
export const apiGetRefreshToken = apiPost("/auth/token/refresh/");
export const apiSendInvite = apiPost(`/auth/send-invite/`);
export const apiCheckInviteKey = (key) => apiGet(`/auth/accept-invite/${key}/`)();
export const apiAcceptInvite = apiPost(`/auth/registration/`);
export const apiResetPasswordRequest = apiPost(`/auth/password/reset/`);
export const apiResetPasswordConfirm = apiPost(`/auth/password/reset/confirm/`);

export const apiGetPrismaticToken = apiPost("/auth/prismatic-token/");

// user
export const apiGetUsers = apiGetParams(`/users/`);
export const apiGetUser = (id) => apiGet(`/users/${id}/`)();
export const apiCreateUser = apiPost(`/users/`);
export const apiUpdateUser = (id) => apiPatchParams(`/users/${id}/`);
export const apiDeleteUser = (id) => apiDelete(`/users/${id}/`)();
export const apiDeleteUsers = apiPostParams(`/users/bulk-delete/`);
export const apiGetProfile = apiGet(`/users/me/`);
export const apiUpdateProfile = apiPatchMulti(`/users/me/`);
export const apiChangePassword = apiPatch(`/users/me/change-password/`);
export const apiResetUserPassword = (id) => apiPatch(`/users/${id}/reset-password/`);

// calendar apps
export const apiUpdateCalendlySettings = (id, settings) => apiPost(`auth/solarcompanies/${id}/calendly/`)(settings);
export const apiDisconnectCalendly = (id) => apiPost(`auth/solarcompanies/${id}/disconnect/`)();
export const apiUpdateCronofySettings = (id, settings) => apiPost(`auth/solarcompanies/${id}/cronofy/`)(settings);
export const apiDisconnectCronofy = (id) => apiPost(`auth/solarcompanies/${id}/cronofy-disconnect/`)();

// client
export const apiGetClientHubspotVisitorIdentificationToken = (id) =>
  apiGet(`/solarcompanies/${id}/get-hubspot-visitor-identification-token/`)();
export const apiGetClients = apiGet(`/solarcompanies/`);
export const apiGetClient = (id) => apiGet(`/solarcompanies/${id}/`)();
export const apiCreateClient = apiPost(`/solarcompanies/`);
export const apiUpdateClient = (id) => apiPatchMulti(`/solarcompanies/${id}/`);
export const apiDeleteClient = (id) => apiDelete(`/solarcompanies/${id}/`)();
export const apiGetClientZipcodes = (id) => apiGet(`/solarcompanies/${id}/zipcodes/`)();
export const apiCreateClientZipcodes = (id, formData) => apiPostMulti(`/solarcompanies/${id}/zipcodes/`)(formData);
export const apiDeleteClientZipcodes = (id) => apiDelete(`/solarcompanies/${id}/zipcodes/`)();
export const apiGetClientDns = (id) => apiGet(`/solarcompanies/${id}/dns-records/`);
export const apiValidateClientDns = (id) => apiPost(`/solarcompanies/${id}/dns-records/validate/`);
export const apiValidateClientCustomDomain = (id) => apiPost(`/solarcompanies/${id}/custom-domain/validate/`);
export const apiSendEmailToCoworker = (id) => apiPost(`/solarcompanies/${id}/dns-records/send-setup-instructions/`);
export const apiActiveCalendar = (id, ccid) => apiPost(`solarcompanies/${id}/active/calendar/${ccid}/`)();
export const apiGetIncentives = (id) => apiGet(`/solarcompanies/${id}/incentives/`)();
export const apiCreateIncentives = (id, formData) => apiPostMulti(`/solarcompanies/${id}/incentives/`)(formData);
export const apiDeleteIncentives = (id) => apiDelete(`/solarcompanies/${id}/incentives/`)();
export const apiGetEscalations = (id) => apiGet(`/solarcompanies/${id}/escalations/`)();
export const apiCreateEscalations = (id, formData) => apiPostMulti(`/solarcompanies/${id}/escalations/`)(formData);
export const apiDeleteEscalations = (id) => apiDelete(`/solarcompanies/${id}/escalations/`)();
export const apiLoadDefaultQuestions = (lang) => apiGet(`/${lang}/template-questions/`)();
export const apiGetClientQuestions = (id, lang) => apiGet(`/solarcompanies/${id}/${lang}/chosen-questions/`)();
export const apiCreateClientQuestion = (id, lang) => apiPost(`/solarcompanies/${id}/${lang}/chosen-questions/`);
export const apiUpdateClientQuestion = (cid, qid) => apiPut(`/solarcompanies/${cid}/chosen-questions/${qid}/`);
export const apiDeleteClientQuestion = (cid, qid) => apiDelete(`/solarcompanies/${cid}/chosen-questions/${qid}/`)();
export const apiChooseClientQuestion = (id) => apiPost(`/solarcompanies/${id}/chosen-questions/choose/`);
export const apiMassUpdateQuestion = apiPut(`/chosen-questions/mass-update/`);
export const apiGetClientWattPriceList = (id) => apiGet(`/solarcompanies/${id}/price-per-watts/`)();
export const apiPutClientWattPriceList = (id, formData) =>
  apiPostMulti(`/solarcompanies/${id}/price-per-watts/`)(formData);
export const apiDeleteClientWattPriceList = (id) => apiDelete(`/solarcompanies/${id}/price-per-watts/`)();
export const apiGetClientGridRateList = (id) => apiGet(`/solarcompanies/${id}/grid-rates/`)();
export const apiPutClientGridRateList = (id, formData) => apiPostMulti(`/solarcompanies/${id}/grid-rates/`)(formData);
export const apiDeleteClientGridRateList = (id) => apiDelete(`/solarcompanies/${id}/grid-rates/`)();
export const apiSaveEmailNotifications = (id) => apiPost(`solarcompanies/${id}/email-notification-settings/`);

// equipment
export const apiGetBatteries = apiGetParams(`/equipments/battery/`);
export const apiCreateBattery = apiPostParams(`/equipments/battery/`);
export const apiUpdateBattery = (id) => apiPatchParams(`/equipments/battery/${id}/`);
export const apiDeleteBattery = (id) => apiDelete(`/equipments/battery/${id}/`)();
export const apiGetChargers = apiGetParams(`/equipments/charger/`);
export const apiCreateCharger = apiPostParams(`/equipments/charger/`);
export const apiUpdateCharger = (id) => apiPatchParams(`/equipments/charger/${id}/`);
export const apiDeleteCharger = (id) => apiDelete(`/equipments/charger/${id}/`)();

// experiment
export const apiGetExperiments = apiGetParams(`/experiments/`);
export const apiCreateExperiments = apiPostParams(`/experiments/`);
export const apiUpdateExperiment = (id) => apiPatchParams(`/experiments/${id}/`);
export const apiStartExperiment = (id) => apiPostParams(`/experiments/${id}/start/`);
export const apiStopExperiment = (id) => apiPostParams(`/experiments/${id}/stop/`);
export const apiGetExperimentReport = (id) => apiGetParams(`experiment/${id}/lead-status-report/`);
export const apiGetConversionRateReport = (id) => apiGetParams(`experiment/${id}/conversion-rate-report/`);

// report
export const apiGetExportLeads = apiGetParams(`/reports/export-leads/`);
export const apiGetLeadStatusByPeriod = apiGetParams(`/reports/lead-status-by-period/`);
export const apiGetLeadStatusTraffic = apiGetParams(`/reports/heat-map/`);
export const apiGetStatesForReport = apiGetParams(`/reports/states/`);
export const apiGetInstantEstimateApiReport = apiGetParams(`/reports/instant-estimate-api-request-logs/`);

// progress
export const apiGetFields = apiGet(`/progresses/fields/`);
export const apiGetLeadStatusReport = apiGetParams(`/progresses/lead-status-report/`);
export const apiGetLeadStatusBillReport = apiGet(`/progresses/lead-status-bill-report/`);
export const apiGetActivityReport = apiGetParams(`/progresses/activity-report/`);
export const apiUpdateLeadStatus = (id) => apiPatchParams(`/progresses/${id}/`);
export const apiGetEstimate = (req, headers) => apiPostHeaders(`/progresses/estimate/`)(req, headers);

// stripe
export const apiGetDefaultPaymentMethod = apiPost(`/stripe/get-default-payment-method/`);
export const apiGetCustomerDetails = apiPost(`/stripe/get-customer-details/`);
export const apiGetBalanceTransactions = apiPost(`/stripe/get-balance-transactions/`);
export const apiGetPaymentMethod = apiPost(`/stripe/get-payment-method/`);
export const apiSetPaymentMethod = apiPost(`/stripe/set-default-payment-method/`);
export const apiDetachPaymentMethod = apiPost(`/stripe/detach-payment/`);
export const apiGetInvoices = apiPost(`/stripe/get-invoice/`);
export const apiAttachPaymentMethod = apiPost(`/stripe/attach-payment/`);
export const apiEarlyPay = (startDate, endDate) =>
  apiPost(`stripe/early-pay/?start_at=${startDate}&end_at=${endDate}`)();
export const apiGetMergePDF = apiPostBlob(`stripe/get-merged-pdf/`);
export const apiGetBalance = apiGetParams(`/stripe/get-balance/`);
export const apiSetBalanceTransactions = apiPost(`/stripe/customer-balance-transactions/`);

// custom mapping
export const apiGetCustomMaps = apiGet(`/custom-mapping/`);
export const apiCreateCustomMap = apiPost(`/custom-mapping/`);
export const apiUpdateCustomMap = (id) => apiPut(`/custom-mapping/${id}/`);
export const apiDeleteCustomMap = (id) => apiDelete(`/custom-mapping/${id}/`)();
export const apiReOrderCustomMap = (id, destination) => apiPost(`/custom-mapping/${id}/reorder/${destination}/`)();

export const apiGetVariations = apiGetParams(`/variations/`);
export const apiSgEmailCheck = apiPost(`/mail/sg-email-check/`);
export const apiGetEmailNotifications = () => ({
  data: {
    emails: ["a@b.com", "c@d.com"],
    statuses: ["lead", "appointment"],
  },
});
export const apiLookupPhoneNumber = apiPost(`/lookup-phone-number/`);

// financial
export const apiValidateSunlight = () => apiPost(`financial/sunlight/validate`);

import React from "react";
import { Stack } from "@mui/material";
import useConfigurator from "hooks/useConfigurator";
import EmbedScript from "../../shared/EmbedScript";
import { generateEmbedAddressWidgetScript, generateEmbedZipcodeWidgetScript } from "utils/generators";

const EmbedWidget = () => {
  const hConfigurator = useConfigurator();
  const { custom_domain, company_slug } = hConfigurator.client;

  const addressWidgetScript = generateEmbedAddressWidgetScript(
    hConfigurator.customDomainValidated && custom_domain,
    company_slug
  );
  const zipcodeWidgetScript = generateEmbedZipcodeWidgetScript(
    hConfigurator.customDomainValidated && custom_domain,
    company_slug
  );

  return (
    <Stack p={4} spacing={4}>
      <EmbedScript
        title="Embed Zip Code Entry Widget"
        description="Place this code in the location you want the widget to appear"
        script={zipcodeWidgetScript}
      />
      <EmbedScript
        title="Embed Address Entry Widget"
        description="Place this code in the location you want the widget to appear"
        script={addressWidgetScript}
      />
    </Stack>
  );
};

export default EmbedWidget;

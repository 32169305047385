import { Box } from "@mui/material";
import { format } from "date-fns";
import { useNavigate } from "react-router-dom";
import { LoadingButton } from "@mui/lab";
import useCommon from "hooks/useCommon";
import useClientCreateWizard from "hooks/useClientCreateWizard";
import { apiCreateClient } from "store/api";
import { clientAtom } from "store/client";
import { useRecoilState } from "recoil";

const SubmitForm = () => {
  const navigate = useNavigate();
  const hCommon = useCommon();
  const wizard = useClientCreateWizard();

  const [clientState, setClientState] = useRecoilState(clientAtom);

  const handleSubmit = async () => {
    hCommon.loading("Creating Client ...");

    const res = await apiCreateClient({
      ...wizard.data,
      energy_type: "rooftop",
      date_contract_signed: format(wizard.data.date_contract_signed, "yyyy-MM-dd"),
    });

    if (res.status === 201) {
      setClientState({
        ...clientState,
        all: [...clientState.all, res.data],
        customDomainValidated: false,
      });

      navigate("/settings/clients");
    } else {
      let errors = {};
      for (const key in res.data) {
        errors[key] = res.data[key][0];
      }
      wizard.submitFailed(errors);
    }

    hCommon.loading("");
  };

  return (
    <Box mt={4}>
      <LoadingButton fullWidth variant="contained" onClick={handleSubmit}>
        Create Client
      </LoadingButton>
    </Box>
  );
};

export default SubmitForm;

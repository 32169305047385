import { Box, Radio, RadioGroup, FormControlLabel } from "@mui/material";

const RadioInput = ({ options, value, onChange, disabled = false }) => {
  return (
    <Box>
      <RadioGroup value={value} onChange={onChange} row>
        {options.map((option) => (
          <FormControlLabel
            key={option.value}
            disabled={disabled}
            value={option.value}
            control={<Radio />}
            label={option.label}
          />
        ))}
      </RadioGroup>
    </Box>
  );
};

export default RadioInput;

import React from "react";
import { Box, Dialog, IconButton, Stack, TextField } from "@mui/material";
import { useRecoilState } from "recoil";
import { authAtom } from "store/auth";
import { isSuper } from "utils/authHelper";
import { OutlineVpnKey } from "utils/icons";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import FormText from "shared/FormText";
import { LoadingButton } from "@mui/lab";
import { getDisplayName } from "utils/generators";
import { apiResetUserPassword } from "store/api";
import useAlert from "hooks/useAlert";

const validationSchema = Yup.object().shape({
  new_password: Yup.string().required("Password is required").min(8, "Password must be at least 8 characters"),
  confirm_password: Yup.string()
    .required("Confirm Password is required")
    .oneOf([Yup.ref("new_password")], "Passwords must match"),
});

const ResetPassword = ({ user }) => {
  const hAlert = useAlert();

  const [authState] = useRecoilState(authAtom);
  const canResetPassword = isSuper(authState.user);

  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const { control, handleSubmit } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      new_password: "",
      confirm_password: "",
    },
  });

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onSubmit = async (data) => {
    setLoading(true);

    const { new_password } = data;
    const res = await apiResetUserPassword(user.id)({ new_password });

    if (res.status === 200) {
      setOpen(false);
      hAlert.show(res.data.msg);
    } else {
      const messages = Object.values(res.data);
      hAlert.show(messages[0][0], false);
    }

    setLoading(false);
  };

  if (!canResetPassword) return null;

  return (
    <Box onClick={(e) => e.stopPropagation()}>
      <IconButton onClick={handleClickOpen}>
        <OutlineVpnKey />
      </IconButton>

      <Dialog open={open} onClose={handleClose} PaperProps={{ sx: { width: "100%" } }}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack p={4} spacing={2}>
            <Box sx={{ mb: 2, fontSize: 20, color: "primary.main" }}>Reset Password</Box>

            <TextField disabled label="Name" value={getDisplayName(user)} />
            <TextField disabled label="Email" value={user.email} />

            <FormText
              autoFocus
              required
              disabled={loading}
              control={control}
              type="password"
              name="new_password"
              label="New Password *"
            />
            <FormText
              required
              disabled={loading}
              control={control}
              type="password"
              name="confirm_password"
              label="Confirm Password *"
            />

            <Box
              sx={{
                pt: 2,
                display: "flex",
                justifyContent: "flex-end",
                gap: 2,
              }}
            >
              <LoadingButton loading={loading} variant="outlined" onClick={handleClose}>
                Cancel
              </LoadingButton>

              <LoadingButton loading={loading} type="submit" variant="contained">
                Save
              </LoadingButton>
            </Box>
          </Stack>
        </form>
      </Dialog>
    </Box>
  );
};

export default ResetPassword;

import React from "react";
import { TableRow, TableCell, MenuItem, ListSubheader, TextField, IconButton } from "@mui/material";
import { MdClose } from "react-icons/md";
import { debounce } from "lodash";

const TARGET_TYPE = {
  FIELD: "FD",
  QUESTION: "QZ",
};

const CustomQuestionItem = ({ item, questionChoices, fieldChoices, onUpdate, onDelete, index }) => {
  const [targetKey, setTargetKey] = React.useState("");
  const [title, setTitle] = React.useState("");

  React.useEffect(() => {
    setTargetKey(item.target_key || "");
    setTitle(item.title || "");
  }, [item]);

  const handleSelectTarget = (targetType, targetValue) => {
    onUpdate({
      ...item,
      target_type: targetType,
      target_key: targetValue,
    });
  };

  const handleUpdateTitle = (e) => {
    onUpdate({
      ...item,
      title: e.target.value,
    });
  };

  const handleUpdateTitleDebounce = React.useCallback(debounce(handleUpdateTitle, 300), [onUpdate]);

  const handleChangeTitle = (e) => {
    setTitle(e.target.value);
    handleUpdateTitleDebounce(e);
  };

  const handleDelete = () => {
    onDelete(item);
  };

  return (
    <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
      <TableCell>{index}</TableCell>
      <TableCell>
        <TextField select fullWidth size="small" sx={{ maxWidth: 300 }} value={targetKey}>
          <MenuItem value="">None</MenuItem>
          <ListSubheader color="primary">Questions</ListSubheader>
          {questionChoices.map((d) => (
            <MenuItem key={d.id} value={d.id} onClick={() => handleSelectTarget(TARGET_TYPE.QUESTION, d.id)}>
              {d.question_desc}
            </MenuItem>
          ))}
          <ListSubheader color="primary">Fields</ListSubheader>
          {fieldChoices.map((d) => (
            <MenuItem key={d.value} value={d.value} onClick={() => handleSelectTarget(TARGET_TYPE.FIELD, d.value)}>
              {d.key}
            </MenuItem>
          ))}
        </TextField>
      </TableCell>
      <TableCell>
        <IconButton size="small" color="inherit" onClick={handleDelete}>
          <MdClose />
        </IconButton>
      </TableCell>
    </TableRow>
  );
};

export default CustomQuestionItem;

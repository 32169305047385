import apiClient from "./apiClient";

// stella config
export const apiGetFields = () => apiClient().get(`/progresses/fields/`);

// appointment
export const apiApptCalendlyAll = () => apiClient().get(`/p/appointment/calendly/`);
export const apiApptCalendlyTokenConnect = (body) => apiClient().post(`/p/appointment/calendly/token/`, body);
export const apiApptCalendlyTokenReconnect = (id) => apiClient().post(`/p/appointment/calendly/token/${id}/reconnect/`);
export const apiApptCalendlyTokenDisconnect = (id) =>
  apiClient().post(`/p/appointment/calendly/token/${id}/disconnect/`);
export const apiApptCalendlyEventShow = (id, body) =>
  apiClient().post(`/p/appointment/calendly/event/${id}/show/`, body);
export const apiApptCalendlyEventShowAll = (body) => apiClient().post(`/p/appointment/calendly/event/show_all/`, body);

export const apiApptCronofyAll = () => apiClient().get(`/p/appointment/cronofy/`);
export const apiApptCronofyTokenConnect = (body) => apiClient().post(`/p/appointment/cronofy/token/`, body);
export const apiApptCronofyTokenReconnect = (id) => apiClient().post(`/p/appointment/cronofy/token/${id}/reconnect/`);
export const apiApptCronofyTokenDisconnect = (id) => apiClient().post(`/p/appointment/cronofy/token/${id}/disconnect/`);
export const apiApptCronofyCalendarSelect = (id) => apiClient().post(`/p/appointment/cronofy/calendar/${id}/select/`);

// question
export const apiQuestionDefaultList = (id, lang) => apiClient().get(`/solarcompanies/${id}/${lang}/chosen-questions/`);
export const apiQuestionDefaultCreate = (id, lang, body) =>
  apiClient().post(`/solarcompanies/${id}/${lang}/chosen-questions/`, body);
export const apiQuestionDefaultUpdate = (cid, qid, body) =>
  apiClient().put(`/solarcompanies/${cid}/chosen-questions/${qid}/`, body);
export const apiQuestionDefaultDelete = (cid, qid) =>
  apiClient().delete(`/solarcompanies/${cid}/chosen-questions/${qid}/`);
export const apiQuestionDefaultChoose = (id, body) =>
  apiClient().post(`/solarcompanies/${id}/chosen-questions/choose/`, body);
export const apiQuestionDefaultUpdateMass = (body) => apiClient().put(`/chosen-questions/mass-update/`, body);

export const apiQuestionCustomList = () => apiClient().get(`/p/question/calendly/`);
export const apiQuestionCustomCreate = (body) => apiClient().post(`/p/question/calendly/`, body);
export const apiQuestionCustomUpdate = (pk, body) => apiClient().patch(`/p/question/calendly/${pk}/`, body);
export const apiQuestionCustomDelete = (pk) => apiClient().delete(`/p/question/calendly/${pk}/`);

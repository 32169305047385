import React from "react";
import { Box, TextField } from "@mui/material";
import useQuickSetupWizard from "hooks/useQuickSetupWizard";

const ChangeAiName = () => {
  const setupWizard = useQuickSetupWizard();
  const [aiName, setAiName] = React.useState("");
  const [error, setError] = React.useState("");

  React.useEffect(() => {
    setAiName(setupWizard.client.ai_name || "");
    // eslint-disable-next-line
  }, []);

  React.useEffect(() => {
    setError(setupWizard.errors.ai_name);
    // eslint-disable-next-line
  }, [setupWizard.errors]);

  const handleChange = (e) => {
    if (e.target.value === "") {
      setError("required");
    } else {
      setError("");
    }
    setAiName(e.target.value);
    setupWizard.update("ai_name", e.target.value);
  };

  return (
    <Box>
      <Box mb={2} sx={{ fontSize: 12 }}>
        We recommend something short....
      </Box>
      {error && (
        <Box sx={{ color: "error.main" }}>
          {error === "required" && "Required"}
          {error !== "required" && error}
        </Box>
      )}
      <TextField value={aiName} onChange={handleChange} />
    </Box>
  );
};

export default ChangeAiName;

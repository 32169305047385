import LinkEditForm from "shared/wizard/LinkEditForm";
import { Box, Typography } from "@mui/material";

const ChangeWebsiteLink = () => (
  <>
    <Box mb={2}>
      <Typography variant="body2">This should be your home page.</Typography>
    </Box>
    <LinkEditForm field="website_url" label="Website URL" />
  </>
);

export default ChangeWebsiteLink;

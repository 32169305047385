import React from "react";
import { Box, Stack, TextField } from "@mui/material";
import { LocalizationProvider, DatePicker } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import useReporting from "hooks/useReporting";
import DateTemplateMenu from "../../shared/DateTemplateMenu";

const Filter = () => {
  const hReporting = useReporting();

  const handleStartDateUpdate = (date) => {
    hReporting.conversion.setFilter({
      startDate: date,
    });
  };

  const handleEndDateUpdate = (date) => {
    hReporting.conversion.setFilter({
      endDate: date,
    });
  };

  const handleTemplateSelect = (start, end) => {
    hReporting.conversion.setFilter({
      startDate: start,
      endDate: end,
    });
  };

  return (
    <Box p={4}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <Stack direction="row" spacing={2} sx={{ alignItems: "center" }}>
          <DateTemplateMenu onSelect={handleTemplateSelect} />
          <DatePicker
            value={hReporting.conversion.filter.startDate}
            maxDate={hReporting.conversion.filter.endDate}
            onChange={handleStartDateUpdate}
            renderInput={(params) => <TextField {...params} />}
          />
          <Box sx={{ width: 10, height: "1px", backgroundColor: "#000" }} />
          <DatePicker
            value={hReporting.conversion.filter.endDate}
            minDate={hReporting.conversion.filter.startDate}
            onChange={handleEndDateUpdate}
            renderInput={(params) => <TextField {...params} />}
          />
        </Stack>
      </LocalizationProvider>
    </Box>
  );
};

export default Filter;

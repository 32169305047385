import React from "react";
import { Box, CircularProgress } from "@mui/material";
import useReporting from "hooks/useReporting";
import LeadStatusChart from "./LeadStatusChart";
import LeadStatusTable from "./LeadStatusTable";
import { leadStatusLabels } from "../../shared/const";

const LeadStatus = () => {
  const hReporting = useReporting();
  const [loading, setLoading] = React.useState(false);
  const [tableData, setTableData] = React.useState({
    open: false,
    status: null,
  });

  React.useEffect(() => {
    if (hReporting.main.filter.filterType === "date") {
      refreshData();
    }
    // eslint-disable-next-line
  }, [hReporting.main.filter.startDate, hReporting.main.filter.endDate]);

  React.useEffect(() => {
    if (hReporting.main.filter.filterType === "location") {
      refreshData();
    }
    // eslint-disable-next-line
  }, [hReporting.main.filter.currentLocation]);

  React.useEffect(() => {
    refreshData();
    // eslint-disable-next-line
  }, [hReporting.main.filter.stella2only]);

  const refreshData = async () => {
    setLoading(true);
    await hReporting.getLeadMainData(leadStatusLabels);
    setLoading(false);
  };

  const openTable = (status) => {
    setTableData({
      ...tableData,
      open: true,
      status,
    });
  };

  const closeTable = () => {
    setTableData({
      ...tableData,
      open: false,
    });
  };

  if (loading || !hReporting.main.data.chart) {
    return (
      <Box sx={{ height: 420, display: "flex", alignItems: "center", justifyContent: "center" }}>
        <CircularProgress size={20} />
      </Box>
    );
  }

  return (
    <Box>
      <LeadStatusChart chartData={hReporting.main.data.chart} openTable={openTable} />
      <LeadStatusTable tableData={tableData} closeTable={closeTable} />
    </Box>
  );
};

export default LeadStatus;

import React from "react";
import { Box, TextField } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { LoadingButton } from "@mui/lab";
import useClientCreateWizard from "hooks/useClientCreateWizard";
import { apiSgEmailCheck } from "store/api";
import clsx from "clsx";

const useStyles = (state) =>
  makeStyles((theme) => ({
    root: {
      "& .Mui-error": {
        color: getStateColor(state, theme),
      },

      "& .MuiFormHelperText-root": {
        color: getStateColor(state, theme),
      },

      "& .MuiOutlinedInput-root": {
        "& .MuiOutlinedInput-notchedOutline": {
          borderColor: getStateColor(state, theme),
        },
      },
    },
  }));

const getStateColor = (state, theme) => {
  switch (state) {
    case "warning":
      return theme.palette.warning.main;
    case "error":
      return theme.palette.error.main;
    default:
      return "none";
  }
};

const WizardEmail = ({ name, label, className, ...props }) => {
  const wizard = useClientCreateWizard();
  const mounted = React.useRef(true);
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    return () => {
      mounted.current = false;
    };
    // eslint-disable-next-line
  }, []);

  const handleChange = (e) => {
    wizard.update(name, e.target.value);
  };

  const handleVerify = async () => {
    setLoading(true);
    const res = await apiSgEmailCheck({
      email: wizard.data[name],
    });
    if (res.status === 200) {
      wizard.setVerified(name, res.data?.message, res.data?.status);
    } else {
      wizard.setVerifyFailed(name, res.data?.message);
    }
    if (mounted.current) {
      setLoading(false);
    }
  };

  let verifiedState = "";
  if (wizard.errors[name]) {
    verifiedState = "error";
  } else if (wizard.warnings[name]) {
    verifiedState = "warning";
  } else {
    verifiedState = "success";
  }

  const classes = useStyles(verifiedState)();

  return (
    <Box sx={{ display: "flex", alignItems: "flex-start" }}>
      <TextField
        disabled={loading}
        label={label}
        value={wizard.data[name] || ""}
        onChange={handleChange}
        helperText={wizard.warnings[name] || wizard.errors[name] || ""}
        error={!!wizard.warnings[name] || !!wizard.errors[name]}
        className={clsx(classes.root, className)}
        {...props}
      />
      <LoadingButton
        loading={loading}
        disabled={wizard.verified[name]}
        variant="contained"
        onClick={handleVerify}
        sx={{ marginLeft: 1 }}
      >
        {wizard.verified[name] ? "Verified" : "Verify"}
      </LoadingButton>
    </Box>
  );
};

export default WizardEmail;

import React from "react";
import { Stack, Box, TextField, InputAdornment, Button } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import useConfigurator from "hooks/useConfigurator";
import DialogVerify from "./DialogSaveVerify";
import DialogRemoveAsk from "./DialogRemoveAsk";
import DialogRemoveVerify from "./DialogRemoveVerify";

const SetupDomainName = ({ loading, setLoading, onUpdate }) => {
  const mounted = React.useRef(true);
  const hConfigurator = useConfigurator();
  const [customDomain, setCustomDomain] = React.useState("");
  const [form, setForm] = React.useState(false);
  const [verifyForm, setVerifyForm] = React.useState(false);
  const [removeAskForm, setRemoveAskForm] = React.useState(false);
  const [removeVerifyForm, setRemoveVerifyForm] = React.useState(false);

  React.useEffect(() => {
    if (hConfigurator.client.custom_domain) {
      setCustomDomain(hConfigurator.client.custom_domain);
    }
    return () => {
      mounted.current = false;
    };
    // eslint-disable-next-line
  }, []);

  React.useEffect(() => {
    if (!hConfigurator.client.custom_domain) {
      setForm(true);
    }
    // eslint-disable-next-line
  }, [hConfigurator.client.custom_domain]);

  const handleToggleUpdate = () => {
    setForm(!form);
  };

  const handleUpdate = async () => {
    if (customDomain) {
      setVerifyForm(true);
    } else {
      setRemoveAskForm(true);
    }
  };

  const handleVerified = async () => {
    setLoading("saving");
    await hConfigurator.saveSingle("custom_domain", customDomain);
    if (mounted.current) {
      setLoading("");
      setForm(false);
      onUpdate();
    }
  };

  const handleRemoveConfirm = () => {
    setRemoveAskForm(false);
    setRemoveVerifyForm(true);
  };

  const handleRemoveVerified = async () => {
    setLoading("saving");
    await hConfigurator.saveSingle("custom_domain", "");
    if (mounted.current) {
      setLoading("");
      setForm(false);
    }
  };

  return (
    <Box sx={{ display: "flex" }}>
      <Box
        sx={{
          width: 40,
          height: 40,
          background: "#666",
          color: "#fff",
          borderRadius: "50%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        1
      </Box>
      <Box ml={2} sx={{ flex: 1 }}>
        <Box mt={0.5} sx={{ fontSize: 20 }}>
          Setup your own domain
        </Box>
        <Stack mt={1} spacing={2}>
          <Box
            sx={{
              fontSize: 12,
              color: "#888",
            }}
          >
            Configure this so that your customers see this as your tool.
          </Box>
          {hConfigurator.client.custom_domain && (
            <Box
              sx={{
                display: "flex",
                alignItems: "start",
              }}
            >
              <Box sx={{ fontSize: 20 }}>https://{hConfigurator.client.custom_domain}</Box>
              <Button
                sx={{
                  color: "#888",
                  marginLeft: 4,
                }}
                variant="outlined"
                size="small"
                color="inherit"
                disabled={loading !== ""}
                onClick={handleToggleUpdate}
              >
                {form ? "Cancel" : "Update"}
              </Button>
            </Box>
          )}
          {(!hConfigurator.client.custom_domain || form) && (
            <Box>
              <Box
                sx={{
                  display: "flex",
                  gap: 1,
                  maxWidth: 490,
                }}
              >
                <TextField
                  fullWidth
                  size="small"
                  InputProps={{
                    startAdornment: <InputAdornment position="start">https://</InputAdornment>,
                  }}
                  disabled={loading !== ""}
                  value={customDomain}
                  onChange={(e) => setCustomDomain(e.target.value)}
                />
                <LoadingButton
                  sx={{ width: 100 }}
                  variant="contained"
                  size="small"
                  onClick={handleUpdate}
                  loading={loading === "saving"}
                  disabled={loading !== ""}
                >
                  Save
                </LoadingButton>
              </Box>
              <Box
                sx={{
                  fontSize: 12,
                  color: "#888",
                  mt: 1,
                }}
              >
                Example: estimate.mywebsite.com
              </Box>
            </Box>
          )}
        </Stack>
      </Box>
      <DialogVerify onVerified={handleVerified} open={verifyForm} closeDialog={() => setVerifyForm(false)} />
      <DialogRemoveAsk
        onConfirm={handleRemoveConfirm}
        open={removeAskForm}
        closeDialog={() => setRemoveAskForm(false)}
      />
      <DialogRemoveVerify
        onRemoveVerified={handleRemoveVerified}
        open={removeVerifyForm}
        closeDialog={() => setRemoveVerifyForm(false)}
      />
    </Box>
  );
};

export default SetupDomainName;

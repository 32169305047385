import React from "react";
import { Box, TextField } from "@mui/material";
import useExperimentsEditor from "hooks/useExperimentsEditor";

const NameForm = () => {
  const hEditor = useExperimentsEditor();

  const handleChange = (e) => {
    if (e.target.value === "") {
      hEditor.setValue("name", "", "Required");
    } else {
      hEditor.setValue("name", e.target.value);
    }
  };

  return (
    <Box>
      <Box mb={2}>Give your experiment a descriptive name.</Box>

      <TextField fullWidth sx={{ maxWidth: 400 }} value={hEditor.values.name || ""} onChange={handleChange} />

      {hEditor.errors.name && hEditor.showError && <Box sx={{ color: "error.main" }}>{hEditor.errors.name}</Box>}
    </Box>
  );
};

export default NameForm;

import React from "react";
import { Scrollbars } from "react-custom-scrollbars";
import { Link } from "react-router-dom";
import { Button, Box, Paper, MobileStepper } from "@mui/material";
import useClientCreateWizard from "hooks/useClientCreateWizard";
import { ArrowLeftIcon } from "utils/icons";
import WizardForm from "./shared/WizardForm";
import InfoForm from "./components/InfoForm";
import CreditForm from "./components/CreditForm";
import FeeForm from "./components/FeeForm";
import SubscriptionForm from "./components/SubscriptionForm";
import SubmitForm from "./components/SubmitForm";

const wizardLength = 5;

const ClientAddPage = () => {
  const [loading, setLoading] = React.useState(true);
  const wizard = useClientCreateWizard();

  React.useEffect(() => {
    wizard.reset();
    setTimeout(() => {
      setLoading(false);
    }, 1000);
    // eslint-disable-next-line
  }, []);

  if (loading) {
    return <Box />;
  }

  return (
    <Box px={4} sx={{ height: 1 }}>
      <Box sx={{ height: 136, display: "flex", flexDirection: "column", justifyContent: "center" }}>
        <Box mb={2}>
          <Button component={Link} to="/settings/clients" size="small" startIcon={<ArrowLeftIcon />}>
            Clients
          </Button>
        </Box>
        <Box sx={{ fontSize: 24, color: "primary.main" }}>New Client</Box>
      </Box>
      <Paper
        sx={{
          height: "calc(100% - 136px)",
          borderTopLeftRadius: 16,
          borderTopRightRadius: 16,
          borderBottomLeftRadius: 0,
          borderBottomRightRadius: 0,
        }}
      >
        <Box sx={{ height: 1 }}>
          <Scrollbars>
            <Box p={4}>
              <MobileStepper
                variant="progress"
                steps={wizardLength}
                position="static"
                activeStep={wizard.form - 1}
                backButton={
                  <Button size="small" variant="outlined" onClick={wizard.back} disabled={wizard.form === 1}>
                    Back
                  </Button>
                }
                nextButton={
                  <Button
                    size="small"
                    variant="contained"
                    disableElevation
                    onClick={wizard.next}
                    disabled={wizard.form === wizardLength}
                  >
                    Next
                  </Button>
                }
              />
              {wizard.form === 1 && (
                <WizardForm
                  label="INFO"
                  required={["company_name", "company_slug", "primary_first_name", "primary_last_name", "phone"]}
                  needVerified={["email"]}
                >
                  <InfoForm />
                </WizardForm>
              )}
              {wizard.form === 2 && (
                <WizardForm label="CREDITS">
                  <CreditForm />
                </WizardForm>
              )}
              {wizard.form === 3 && (
                <WizardForm
                  label="FEES"
                  required={[
                    "minimum_monthly_fee",
                    "cost_per_lead",
                    "cost_per_appointment",
                    "cost_per_appointment_with_bill",
                    "cost_per_estimate_viewer",
                  ]}
                >
                  <FeeForm />
                </WizardForm>
              )}
              {wizard.form === 4 && (
                <WizardForm label="SUBSCRIPTION">
                  <SubscriptionForm />
                </WizardForm>
              )}
              {wizard.form === 5 && (
                <WizardForm label="">
                  <SubmitForm />
                </WizardForm>
              )}
            </Box>
          </Scrollbars>
        </Box>
      </Paper>
    </Box>
  );
};

export default ClientAddPage;

import React from "react";
import { Stack, Box, TextField } from "@mui/material";
import useConfigurator from "hooks/useConfigurator";

const SelectPricePerWatt = () => {
  const hConfigurator = useConfigurator();
  const [values, setValues] = React.useState({
    dollar_per_watt: hConfigurator.client.dollar_per_watt,
  });

  React.useEffect(() => {
    // eslint-disable-next-line
  }, []);

  const handleWattChange = (field) => (e) => {
    setValues({
      ...values,
      [field]: e.target.value,
    });
    hConfigurator.updateSingle(field, e.target.value);
  };

  return (
    <Stack spacing={2}>
      <Box>
        <Box sx={{ fontSize: 12 }}>Dollar per Watt</Box>
        <Box mt={1}>
          <TextField
            size="small"
            placeholder="$/watt"
            type="number"
            value={values.dollar_per_watt}
            onChange={handleWattChange("dollar_per_watt")}
          />
        </Box>
      </Box>
    </Stack>
  );
};

export default SelectPricePerWatt;

import { atom } from "recoil";

export const configuratorAtom = atom({
  key: "configuratorAtom",
  default: {
    saving: false,
    touched: false,
    client: null,
    updates: {},
  },
});

export const quickSetupAtom = atom({
  key: "quickSetupAtom",
  default: {
    form: 1,
    updates: [],
    errors: {},
  },
});

export const spanishSetupAtom = atom({
  key: "spanishSetupAtom",
  default: {
    form: 1,
    updates: [],
    errors: {},
  },
});

import React from "react";
import { Box, Button, IconButton, Table, TableBody, TableCell, TableHead, TableRow, Checkbox } from "@mui/material";
import useAlert from "hooks/useAlert";
import useQuickSetupWizard from "hooks/useQuickSetupWizard";
import { MdClose, MdRefresh } from "react-icons/md";
import {
  apiApptCalendlyAll,
  apiApptCalendlyTokenReconnect,
  apiApptCalendlyTokenDisconnect,
  apiApptCalendlyEventShowAll,
} from "utils/api_v2";
import ConnectCalendlyEvent from "./ConnectCalendlyEvent";

const sortEvent = (a, b) => {
  if (a.pooling_type < b.pooling_type) return -1;
  if (a.pooling_type > b.pooling_type) return 1;
  if (a.name < b.name) return -1;
  if (a.name > b.name) return 1;
  return 0;
};

const ConnectCalendly = () => {
  const setupWizard = useQuickSetupWizard();
  const hAlert = useAlert();
  const [showAll, setShowAll] = React.useState(false);
  const [calendlyList, setCalendlyList] = React.useState([]);

  React.useEffect(() => {
    const load = async () => {
      const res = await apiApptCalendlyAll();
      if (res.status === 200) {
        setShowAll(res.data.appointment_calendly_show_all);
        setCalendlyList(res.data.calendly_token);
      } else {
        hAlert.show("Loading calendly failed!", false);
      }
    };

    load();

    // eslint-disable-next-line
  }, []);

  const handleConnectClick = () => {
    localStorage.setItem("appointment_connect_method", "calendly");
    localStorage.setItem("appointment_connect_company", setupWizard.client.id);
    window.location.assign(
      `${process.env.REACT_APP_CALENDLY_OAUTH_URL}?client_id=${process.env.REACT_APP_CALENDLY_CLIENT_ID}&response_type=code&redirect_uri=${process.env.REACT_APP_CALENDLY_AUTH_REDIRECT_ENDPOINT}`
    );
  };

  const handleRefresh = async (calendlyToken) => {
    const { id } = calendlyToken;
    const res = await apiApptCalendlyTokenReconnect(id);
    if (res.status === 200) {
      setCalendlyList(calendlyList.map((d) => (d.id === id ? res.data : d)));
      hAlert.show("Refresh success!");
    } else {
      hAlert.show("Refresh failed!", false);
    }
  };

  const handleDisconnect = async (calendlyToken) => {
    const { id } = calendlyToken;
    const res = await apiApptCalendlyTokenDisconnect(id);
    if (res.status === 200) {
      setCalendlyList(calendlyList.filter((d) => d.id !== id));
      hAlert.show("Disconnect success!");
    } else {
      hAlert.show("Disconnect failed!", false);
    }
  };

  const handleToggleEventAll = async () => {
    setShowAll(!showAll);
    const res = await apiApptCalendlyEventShowAll({ show_all: !showAll });
    if (res.status === 200) {
      hAlert.show("Calendly selection updated!");
    } else {
      setShowAll(showAll);
      hAlert.show("Event selection update failed!", false);
    }
  };

  return (
    <Box>
      <Button
        variant="contained"
        component="a"
        // href={`${process.env.REACT_APP_CALENDLY_OAUTH_URL}?client_id=${process.env.REACT_APP_CALENDLY_CLIENT_ID}&response_type=code&redirect_uri=${process.env.REACT_APP_CALENDLY_AUTH_REDIRECT_ENDPOINT}`}
        onClick={handleConnectClick}
      >
        Connect Calendly Account
      </Button>
      <Box sx={{ mt: 4, border: "1px solid #ccc", borderRadius: 2 }}>
        <Box sx={{ p: 2, color: "#888", fontSize: 12 }}>Connected accounts</Box>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>User</TableCell>
              <TableCell>Calendly</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {calendlyList.map((d) => (
              <TableRow key={d.id} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                <TableCell>{d.user ? d.user.first_name + " " + d.user.last_name : ""}</TableCell>
                <TableCell>{d.user_email}</TableCell>
                <TableCell width={30}>
                  <Box sx={{ display: "flex" }}>
                    <IconButton size="small" onClick={() => handleRefresh(d)}>
                      <MdRefresh />
                    </IconButton>
                    <IconButton size="small" onClick={() => handleDisconnect(d)}>
                      <MdClose />
                    </IconButton>
                  </Box>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <Box sx={{ p: 2, borderTop: "1px solid #ddd", color: "#888", fontSize: 12 }}>Available events</Box>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Calendly</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Type</TableCell>
              <TableCell>URL</TableCell>
              <TableCell width={20}>
                <Checkbox checked={showAll} onChange={handleToggleEventAll} />
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {calendlyList.map((d) => (
              <React.Fragment key={d.id}>
                {d.calendly_event.sort(sortEvent).map((c) => (
                  <ConnectCalendlyEvent key={c.id} event={c} token={d} showAll={showAll} />
                ))}
              </React.Fragment>
            ))}
          </TableBody>
        </Table>
      </Box>
    </Box>
  );
};

export default ConnectCalendly;

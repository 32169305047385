import { Box, Stack } from "@mui/material";
import SingleTextArea from "../shared/SingleTextArea";

const CustomHtml = () => {
  return (
    <Box p={4}>
      <Box mb={4} sx={{ display: "flex", alignItems: "center" }}>
        <Box mr={1} sx={{ fontSize: 20, fontWeight: "bold" }}>
          Custom HTML Tags
        </Box>
      </Box>

      <Stack spacing={4} sx={{ "& textarea": { fontFamily: "monospace" } }}>
        <SingleTextArea field="custom_tag" label="HTML code" />
      </Stack>
    </Box>
  );
};

export default CustomHtml;

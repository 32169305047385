import { Box } from "@mui/material";
import WizardText from "../shared/WizardText";

const CreditForm = () => {
  return (
    <Box>
      <WizardText fullWidth type="number" name="starting_credit_balance" label="Starting Credit Balance" />
    </Box>
  );
};

export default CreditForm;

import React from "react";
import { useForm } from "react-hook-form";
import { Box, Stack } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import useAlert from "hooks/useAlert";
import { apiUpdateClient } from "store/api";
import FormCheck from "shared/FormCheck";
import { clientAtom } from "store/client";
import { useRecoilState } from "recoil";

const SubscriptionTab = ({ client, updateClient }) => {
  const hAlert = useAlert();

  const [clientState, setClientState] = useRecoilState(clientAtom);

  const mounted = React.useRef(true);
  const loaded = React.useRef(false);
  const [loading, setLoading] = React.useState(false);

  const { control, setValue, watch, handleSubmit } = useForm({
    defaultValues: {
      status: client.status,
      enable_chat: client.enable_chat,
      enable_stepper: client.enable_stepper,
      is_free_trial: client.is_free_trial,
      is_sunroof_enabled: client.is_sunroof_enabled,
      is_phone_verification_required: client.is_phone_verification_required,
      enable_automated_text_follow_up: client.enable_automated_text_follow_up,
      enable_email_follow_up: client.enable_email_follow_up,
      is_instant_estimate_api_enabled: client.is_instant_estimate_api_enabled,
      is_best_company_enabled: client.is_best_company_enabled,
      enable_sonic: client.enable_sonic,
      is_prismatic_enabled: client.is_prismatic_enabled,
      is_finance_integrations_enabled: client.is_finance_integrations_enabled,
      is_solar_ai_engine_enabled: client.is_solar_ai_engine_enabled,
      is_strict_phone_number_validation_enabled: client.is_strict_phone_number_validation_enabled,
      is_strict_email_validation_enabled: client.is_strict_email_validation_enabled,
      enable_hero_prompt: client.enable_hero_prompt ?? false,
      enable_opt_in: client.enable_opt_in ?? false,
      enable_delinquent_email: client.enable_delinquent_email ?? true,
    },
  });

  React.useEffect(() => {
    return () => {
      mounted.current = false;
    };
    // eslint-disable-next-line
  }, []);

  React.useEffect(() => {
    if (loaded.current) {
      setValue("is_free_trial", !watch("status"));
    }
    loaded.current = true;
  }, [watch("status")]);

  const onSubmit = async (d) => {
    setLoading(true);
    const {
      status,
      enable_chat,
      enable_stepper,
      is_free_trial,
      is_sunroof_enabled,
      is_phone_verification_required,
      enable_automated_text_follow_up,
      enable_email_follow_up,
      is_instant_estimate_api_enabled,
      is_best_company_enabled,
      enable_sonic,
      is_prismatic_enabled,
      is_finance_integrations_enabled,
      is_solar_ai_engine_enabled,
      is_strict_phone_number_validation_enabled,
      is_strict_email_validation_enabled,
      enable_hero_prompt,
      enable_opt_in,
      enable_delinquent_email,
    } = d;

    const formData = new FormData();
    formData.append("status", status);
    formData.append("enable_chat", enable_chat);
    formData.append("enable_stepper", enable_stepper);
    formData.append("is_free_trial", is_free_trial);
    formData.append("is_sunroof_enabled", is_sunroof_enabled);
    formData.append("is_phone_verification_required", is_phone_verification_required);
    formData.append("enable_automated_text_follow_up", enable_automated_text_follow_up);
    formData.append("enable_email_follow_up", enable_email_follow_up);
    formData.append("is_instant_estimate_api_enabled", is_instant_estimate_api_enabled);
    formData.append("is_best_company_enabled", is_best_company_enabled);
    formData.append("enable_sonic", enable_sonic);
    formData.append("is_prismatic_enabled", is_prismatic_enabled);
    formData.append("is_finance_integrations_enabled", is_finance_integrations_enabled);
    formData.append("is_solar_ai_engine_enabled", is_solar_ai_engine_enabled);
    formData.append("is_strict_phone_number_validation_enabled", is_strict_phone_number_validation_enabled);
    formData.append("is_strict_email_validation_enabled", is_strict_email_validation_enabled);
    formData.append("enable_hero_prompt", enable_hero_prompt);
    formData.append("enable_opt_in", enable_opt_in);
    formData.append("enable_delinquent_email", enable_delinquent_email);

    const res = await apiUpdateClient(client.id)(formData);
    if (res.status === 200) {
      setClientState({
        ...clientState,
        all: [...clientState.all.filter((d) => d.id !== client.id), res.data],
        current: clientState.currentId === client.id ? res.data : clientState.current,
      });

      updateClient(res.data);
      hAlert.show("Client saved successfully!");
    }
    if (mounted.current) {
      setLoading(false);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack p={4} spacing={4}>
        <Box></Box>

        <FormCheck disabled={loading} control={control} name="status" label="Active" />
        <FormCheck disabled={loading} control={control} name="enable_chat" label="Enable Stella Embedded" />
        <FormCheck disabled={loading} control={control} name="enable_stepper" label="Enable Stella Embedded Stepper" />
        <FormCheck
          disabled={loading || !watch("status")}
          control={control}
          name="is_free_trial"
          label="Temporarily Pause Billing"
        />
        <FormCheck disabled={loading} control={control} name="is_sunroof_enabled" label="Enable Sunroof" />
        <FormCheck
          disabled={loading}
          control={control}
          name="is_phone_verification_required"
          label="Require 2FA Phone Number Verification"
        />
        <FormCheck
          disabled={loading}
          control={control}
          name="enable_automated_text_follow_up"
          label="Enable Automated Text Follow-Ups"
        />
        <FormCheck disabled={loading} control={control} name="enable_email_follow_up" label="Enable Email Follow-ups" />
        <FormCheck
          disabled={loading}
          control={control}
          name="is_instant_estimate_api_enabled"
          label="Enable Instant Estimate API &amp; Reporting"
        />
        <FormCheck
          disabled={loading}
          control={control}
          name="is_best_company_enabled"
          label="Best Company - Out of Service Area"
        />
        <FormCheck
          disabled={loading}
          control={control}
          name="is_prismatic_enabled"
          label="Enable Prismatic Integration"
        />

        <FormCheck
          disabled={loading}
          control={control}
          name="is_finance_integrations_enabled"
          label="Enable Finance Integrations"
        />
        <FormCheck disabled={loading} control={control} name="is_solar_ai_engine_enabled" label="Solar AI Engine" />
        <FormCheck
          disabled={loading}
          control={control}
          name="is_strict_phone_number_validation_enabled"
          label="Enable Strict Phone Number Validation"
        />
        <FormCheck
          disabled={loading}
          control={control}
          name="is_strict_email_validation_enabled"
          label="Enable Strict Email Validation"
        />
        <FormCheck disabled={loading} control={control} name="enable_hero_prompt" label="Enable Hero" />
        <FormCheck disabled={loading} control={control} name="enable_opt_in" label="Enable Opt In Messaging" />
        <FormCheck
          disabled={loading}
          control={control}
          name="enable_delinquent_email"
          label="Enable Delinquent Payment Email"
        />

        {/* <FormCheck disabled={loading} control={control} name="enable_sonic" label="Enable Sonic" /> */}

        <LoadingButton loading={loading} variant="contained" type="submit">
          SAVE CLIENT
        </LoadingButton>
      </Stack>
    </form>
  );
};

export default SubscriptionTab;

import { Box, Radio, RadioGroup, FormControlLabel } from "@mui/material";

const RadioInput = ({ legend, options, value, onChange, disabled = false, row = false }) => {
  return (
    <Box>
      {legend && <Box sx={{ fontSize: 12, color: "#333" }}>{legend}</Box>}
      <RadioGroup row={row} value={value} onChange={onChange}>
        {options.map((option) => (
          <>
            <FormControlLabel
              key={option.value}
              disabled={disabled || option.disabled}
              value={option.value}
              control={<Radio />}
              label={option.label}
            />
            {option.hint || null}
          </>
        ))}
      </RadioGroup>
    </Box>
  );
};

export default RadioInput;

import React from "react";
import { Box, Button } from "@mui/material";
import useExperimentsCreator from "hooks/useExperimentsCreator";

const SubmitForm = ({ openTab }) => {
  const hCreator = useExperimentsCreator();

  return (
    <Box mt={8} sx={{ display: "flex", justifyContent: "center" }}>
      <Button variant="contained" size="large" onClick={() => hCreator.submit(openTab)}>
        CREATE EXPERIMENT
      </Button>
    </Box>
  );
};

export default SubmitForm;

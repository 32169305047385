import { atom } from "recoil";

export const experimentAtom = atom({
  key: "experimentAtom",
  default: {
    initialized: false,
    variations: [],
  },
});

export const experimentCreatorAtom = atom({
  key: "experimentCreatorAtom",
  default: {
    form: 1,
    type: "funnel",
    values: {
      traffic_volume: 50,
      start_datetime: new Date(),
      end_datetime: new Date(),
    },
    bannerInitialized: false,
    bannerValues: {},
    errors: {},
    showError: false,
  },
});

export const experimentEditorAtom = atom({
  key: "experimentEditorAtom",
  default: {
    form: 1,
    type: "funnel",
    values: {},
    bannerInitialized: false,
    bannerValues: {},
    errors: {},
    showError: false,
    touched: false,
  },
});

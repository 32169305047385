import { Box, Typography } from "@mui/material";
import LinkEditForm from "shared/wizard/LinkEditForm";

const ChangeTestimonialsLink = () => (
  <>
    <Box mb={2}>
      <Typography variant="body2">This should be a page containing customer reviews of your business.</Typography>
    </Box>
    <LinkEditForm field="testimonials_url" label="Testimonials URL" />
  </>
);

export default ChangeTestimonialsLink;

import React from "react";
import { useRecoilState } from "recoil";
import { TextField, MenuItem } from "@mui/material";
import { apiUpdateLeadStatus } from "store/api";
import { clientAtom } from "store/client";
import { leadStatusLabels, leadStatusLevels, leadStatusList } from "../../shared/const";

const LeadStatusSelect = ({ data, onStatusUpdate }) => {
  const [clientState] = useRecoilState(clientAtom);
  const [loading, setLoading] = React.useState(false);

  const handleStatusChange = async (e) => {
    setLoading(true);
    const res = await apiUpdateLeadStatus(data.id)(
      {
        solar_company: clientState.currentId,
      },
      {
        lead_status: e.target.value,
      }
    );
    if (res.status === 200) {
      onStatusUpdate(res.data);
    }
    setLoading(false);
  };

  return (
    <TextField
      select
      size="small"
      disabled={loading}
      sx={{ width: 200 }}
      value={data.lead_status}
      onChange={handleStatusChange}
    >
      {leadStatusList
        .filter((d) => leadStatusLevels[d] <= leadStatusLevels[data.lead_status])
        .map((d) => (
          <MenuItem key={d} value={d}>
            {leadStatusLabels[d]}
          </MenuItem>
        ))}
    </TextField>
  );
};

export default LeadStatusSelect;

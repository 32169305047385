import React from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { Box, Stack } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import useAlert from "hooks/useAlert";
import { apiChangePassword } from "store/api";
import FormText from "shared/FormText";

const validationSchema = Yup.object().shape({
  old_password: Yup.string().required("Password is required").min(8, "Password must be at least 8 characters"),
  new_password: Yup.string().required("Password is required").min(8, "Password must be at least 8 characters"),
  confirm_password: Yup.string()
    .required("Confirm Password is required")
    .oneOf([Yup.ref("new_password")], "Passwords must match"),
});

const Password = () => {
  const hAlert = useAlert();
  const mounted = React.useRef(true);
  const [loading, setLoading] = React.useState(false);
  const { control, handleSubmit, reset } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      old_password: "",
      new_password: "",
      confirm_password: "",
    },
  });

  React.useEffect(() => {
    return () => {
      mounted.current = false;
    };
    // eslint-disable-next-line
  }, []);

  const onSubmit = async (d) => {
    setLoading(true);
    const { old_password, new_password } = d;
    const res = await apiChangePassword({ old_password, new_password });

    if (res.status === 200) {
      reset();
      hAlert.show(res.data.detail);
    } else {
      const messages = Object.values(res.data);
      hAlert.show(messages[0][0], false);
    }

    if (mounted.current) {
      setLoading(false);
    }
  };

  return (
    <Box p={4}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={4}>
          <FormText
            required
            disabled={loading}
            control={control}
            type="password"
            name="old_password"
            label="Old Password *"
          />
          <FormText
            required
            disabled={loading}
            control={control}
            type="password"
            name="new_password"
            label="New Password *"
          />
          <FormText
            required
            disabled={loading}
            control={control}
            type="password"
            name="confirm_password"
            label="Confirm Password *"
          />
        </Stack>
        <Box mt={4}>
          <LoadingButton loading={loading} type="submit" variant="contained">
            Save
          </LoadingButton>
        </Box>
      </form>
    </Box>
  );
};

export default Password;

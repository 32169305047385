import React from "react";
import { Button, Dialog, Stack, Box, TextField, FormControlLabel, Checkbox } from "@mui/material";
import useAlert from "hooks/useAlert";
import useCommon from "hooks/useCommon";
import useReporting from "hooks/useReporting";
import { apiSaveEmailNotifications } from "store/api";
import { SettingIcon } from "utils/icons";
import { emailNotificationStatus } from "../../shared/const";

const EmailNotification = () => {
  const hAlert = useAlert();
  const hCommon = useCommon();
  const hReporting = useReporting();
  const [open, setOpen] = React.useState(false);
  const [data, setData] = React.useState(null);

  React.useEffect(() => {
    const { recipients, lead_statuses } = hReporting.client.instant_estimate_email_notification_setting;
    setData({
      recipients: recipients.join(","),
      statuses: lead_statuses,
    });
    // eslint-disable-next-line
  }, [hReporting.client, open]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleRecipientsChange = (e) => {
    setData({
      ...data,
      recipients: e.target.value,
    });
  };

  const handleStatusChange = (e, status) => {
    let newStatuses;
    if (e.target.checked) {
      newStatuses = [...data.statuses, status];
    } else {
      newStatuses = data.statuses.filter((d) => d !== status);
    }
    setData({
      ...data,
      statuses: newStatuses,
    });
  };

  const handleSave = async () => {
    // check empty email
    if (!data.recipients) {
      hAlert.show("Please enter recipients email addresses", false);
      return;
    }

    // validate email
    const _recipients = data.recipients.split(",").map((d) => d.trim());
    const _filtered = _recipients.filter((d) => /[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,15}/g.test(d));
    if (_recipients.length !== _filtered.length) {
      hAlert.show("Please enter correct email addresses", false);
      return;
    }

    // check empty status
    if (data.statuses.length === 0) {
      hAlert.show("Please select at least one status", false);
      return;
    }

    // call api
    hCommon.loading("Saving ...");

    const res = await apiSaveEmailNotifications(hReporting.client.id)({
      is_instant_estimate: true,
      lead_statuses: data.statuses,
      recipients: _recipients,
      solar_company: hReporting.client.id,
    });

    if (res.status === 200) {
      hReporting.setClient("instant_estimate_email_notification_setting", res.data);
      hAlert.show("Email notifications saved successfully.");
      setOpen(false);
    } else {
      hAlert.show("Saving email notifications failed. Please try again.", false);
    }

    hCommon.loading("");
  };

  return (
    <>
      <Button variant="outlined" startIcon={<SettingIcon />} onClick={handleClickOpen}>
        Email Notifications
      </Button>
      {data && (
        <Dialog open={open} onClose={handleClose} PaperProps={{ sx: { width: "100%" } }}>
          <Stack p={4} spacing={2}>
            <Box sx={{ fontSize: 20, color: "primary.main" }}>Email Notifications</Box>
            <Box sx={{ fontSize: 14, color: "#888" }}>Where would you like notifications sent?</Box>
            <TextField fullWidth value={data.recipients} onChange={handleRecipientsChange} />
            <Box sx={{ fontSize: 14, color: "#888" }}>Select the statuses you’d like to be notified about.</Box>
            <Box>
              <Box sx={{ display: "flex", flexWrap: "wrap" }}>
                {emailNotificationStatus.map((d) => (
                  <Box sx={{ width: 250 }} key={d.value}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={data.statuses.includes(d.value)}
                          onChange={(e) => handleStatusChange(e, d.value)}
                        />
                      }
                      label={d.label}
                    />
                  </Box>
                ))}
              </Box>
            </Box>
            <Stack direction="row" spacing={1} sx={{ justifyContent: "flex-end" }}>
              <Button variant="outlined" onClick={handleClose}>
                Cancel
              </Button>
              <Button disableElevation variant="contained" onClick={handleSave}>
                Save
              </Button>
            </Stack>
          </Stack>
        </Dialog>
      )}
    </>
  );
};

export default EmailNotification;

import { FormControlLabel, Checkbox } from "@mui/material";
import useClientCreateWizard from "hooks/useClientCreateWizard";

const WizardCheck = ({ name, label, disabled = false, ...props }) => {
  const wizard = useClientCreateWizard();

  const handleChange = (e) => {
    wizard.update(name, e.target.checked);
  };

  return (
    <FormControlLabel
      control={<Checkbox disabled={disabled} checked={wizard.data[name] || false} onChange={handleChange} {...props} />}
      label={label}
    />
  );
};

export default WizardCheck;

import React from "react";
import { Button } from "@mui/material";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";

const defaultStyles = makeStyles({
  root: {
    color: "#fff",
    boxShadow: "none",
    border: "1px solid #fff",
    borderRadius: "50px",
    padding: "16px 45px",
    fontWeight: "900",
    fontSize: "16px",
    lineHeight: "22px",
    letterSpacing: "0.1em",
    textAlign: "center",

    "&:hover": {
      boxShadow: "none",
      textDecoration: "none !important",
    },
  },

  disabled: {
    border: "none",
  },
});

const secondaryStyles = makeStyles({
  root: {
    boxShadow: "none",
    borderRadius: "50px",
    padding: "0 45px",
    lineHeight: "22px",
    letterSpacing: "0.1em",
    textAlign: "center",

    "&:hover": {
      boxShadow: "none",
      textDecoration: "none !important",
    },
  },
});

const RoundedButton = ({ kind, className, ...props }) => {
  const defaultClasses = defaultStyles();
  const secondaryClasses = secondaryStyles();
  let classes;

  switch (kind) {
    case "secondary":
      classes = clsx(className, secondaryClasses.root);
      break;
    default:
      classes = clsx(className, defaultClasses.root);
  }

  return <Button variant={props.variant ?? "contained"} className={classes} {...props} />;
};

export default RoundedButton;

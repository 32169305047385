import React from "react";
import { useParams, useNavigate, Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Box, CircularProgress, Stack, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/styles";
import LoginLogo from "shared/LoginLogo";
import FormText from "shared/FormText";
import RoundedButton from "shared/RoundedButton";
import useAlert from "hooks/useAlert";
import { apiResetPasswordConfirm } from "store/api";

const Main = ({ showPromotion, onOpenPromotion }) => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

  const { uid, token } = useParams();
  const navigate = useNavigate();
  const hAlert = useAlert();
  const [loading, setLoading] = React.useState(false);
  const { control, handleSubmit } = useForm();

  const onSubmit = async (d) => {
    setLoading(true);

    const res = await apiResetPasswordConfirm({
      ...d,
      uid,
      token,
    });

    if (res.status === 200) {
      hAlert.show("Password has been reset with the new password.");
      navigate("/login");
    } else {
      for (let p in res.data) {
        if (["uid", "token"].includes(p)) {
          hAlert.show("Password reset link is incorrect.", false);
        } else {
          hAlert.show(res.data[p][0], false);
        }
      }
      setLoading(false);
    }
  };

  return (
    <Stack className="section-main">
      <Box p={isDesktop ? 4 : 2} sx={{ display: "flex", justifyContent: "center" }}>
        <Box sx={{ width: isDesktop ? "100%" : "600px" }}>
          <LoginLogo />
        </Box>
      </Box>

      <Stack p={isDesktop ? 4 : 2} sx={{ flexGrow: 1, justifyContent: "center", alignItems: "center" }}>
        <Stack mt={-10} sx={{ maxWidth: "600px", width: 1, height: 1 }}>
          <Box my={3} sx={{ fontSize: 18, fontWeight: 900 }}>
            Reset Password
          </Box>

          <Box>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Stack spacing={3}>
                <FormText
                  disabled={loading}
                  control={control}
                  type="password"
                  name="new_password1"
                  label="New password *"
                  required
                />

                <FormText
                  disabled={loading}
                  control={control}
                  type="password"
                  name="new_password2"
                  label="Confirm Password *"
                  required
                />

                <Box sx={{ display: "flex", justifyContent: "center" }}>
                  <RoundedButton
                    type="submit"
                    variant="contained"
                    fullWidth={!isDesktop}
                    sx={{ minWidth: 150 }}
                    disabled={loading}
                  >
                    {loading ? <CircularProgress color="secondary" size={22} /> : "Reset"}
                  </RoundedButton>
                </Box>
              </Stack>
            </form>
          </Box>

          <Box mt={3} sx={{ textAlign: "center" }}>
            <Box
              component={Link}
              to="/login"
              sx={{
                color: "primary.main",
                fontSize: 14,
                fontWeight: 900,
                textDecoration: "none",
                "&:hover": { textDecoration: "underline" },
              }}
            >
              Back to login page
            </Box>
          </Box>

          <Box mt={3} sx={{ opacity: showPromotion ? 1 : 0, transition: "opacity .1s ease-in-out" }}>
            <Box sx={{ fontSize: 14, fontWeight: 900, textAlign: "center" }}>
              <Box component="span" mr={1}>
                <img src="images/promotion.svg" width="14px" alt="" /> Check out our
              </Box>

              <Box
                component="span"
                sx={{
                  color: "primary.main",
                  fontSize: 14,
                  cursor: "pointer",
                }}
                onClick={onOpenPromotion}
              >
                Promotional Offer
              </Box>
            </Box>
          </Box>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default Main;

import React from "react";
import { Box } from "@mui/material";
import useExperimentsCreator from "hooks/useExperimentsCreator";

const CreatorForm = ({ label, required = [], children }) => {
  const hCreator = useExperimentsCreator();

  React.useEffect(() => {
    let errors = {};
    for (const key of required) {
      if (!hCreator.values[key]) {
        errors = {
          ...errors,
          [key]: "Required",
        };
      }
    }
    if (Object.keys(errors).length > 0) {
      hCreator.setValues({}, errors);
    }
    // eslint-disable-next-line
  }, []);

  return (
    <Box>
      {label && (
        <Box mt={2} sx={{ color: "#0008", textTransform: "uppercase" }}>
          {label}
        </Box>
      )}
      {children}
    </Box>
  );
};

export default CreatorForm;

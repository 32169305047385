import React from "react";
import { Box, TextField, MenuItem } from "@mui/material";
import useExperiments from "hooks/useExperiments";
import useExperimentsCreator from "hooks/useExperimentsCreator";
import PhonePreview from "shared/Preview/PhonePreview";

const VariationForm = () => {
  const hExperiments = useExperiments();
  const hCreator = useExperimentsCreator();

  const handleChange = (e) => {
    hCreator.setValue("variations", e.target.value);
  };

  return (
    <Box sx={{ display: "flex", flexDirection: { xs: "column", lg: "row" }, gap: 2 }}>
      <Box sx={{ flexGrow: 1 }}>
        <Box mb={2}>Choose from the variations in the dropdown below</Box>

        <TextField
          fullWidth
          select
          value={hCreator.values.variations || ""}
          onChange={handleChange}
          SelectProps={{
            SelectDisplayProps: {
              sx: { whiteSpace: "pre-wrap !important" },
            },
          }}
        >
          {hExperiments.variations.map((d) => (
            <MenuItem key={d.id} value={d.variation_slug} sx={{ textOverflow: "ellipsis" }}>
              {d.name}
            </MenuItem>
          ))}
        </TextField>
        {hCreator.errors.variations && hCreator.showError && (
          <Box sx={{ color: "error.main" }}>{hCreator.errors.variations}</Box>
        )}
      </Box>

      <Box sx={{ width: 434, alignSelf: "center" }}>
        <PhonePreview isExperiment variation={hCreator.values.variations} />
      </Box>
    </Box>
  );
};

export default VariationForm;

import React from "react";
import { Stack, FormControlLabel, Checkbox } from "@mui/material";
import { leadStatusList, leadStatusLabels } from "../../shared/const";

const LeadStatusTableCheck = ({ defaultChecked, onChange }) => {
  const [checked, setChecked] = React.useState([]);

  React.useEffect(() => {
    setChecked([defaultChecked]);
    // eslint-disable-next-line
  }, [defaultChecked]);

  const handleChange = (e, status) => {
    let _checked = [];
    if (e.target.checked) {
      _checked = [...checked, status];
    } else {
      _checked = checked.filter((d) => d !== status);
    }
    setChecked(_checked);
    onChange(_checked);
  };

  return (
    <Stack direction="row" spacing={2} p={2} sx={{ alignItems: "center", justifyContent: "center" }}>
      {leadStatusList.map((status) => (
        <FormControlLabel
          key={status}
          control={<Checkbox checked={checked.includes(status)} onChange={(e) => handleChange(e, status)} />}
          label={leadStatusLabels[status]}
        />
      ))}
    </Stack>
  );
};

export default LeadStatusTableCheck;

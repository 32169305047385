import React from "react";
import { format } from "date-fns";
import { Box, CircularProgress } from "@mui/material";
import { CreditCardIcon, DateRangeIcon } from "utils/icons";

const Balance = ({ balance, invoice }) => {
  return (
    <Box p={4} sx={{ border: "1px solid #eee" }}>
      <Box sx={{ height: 40, display: "flex", alignItems: "center" }}>
        <Box sx={{ flexGrow: 1, fontSize: 24, fontWeight: 500 }}>Your Balance</Box>
        {balance ? <Box sx={{ fontSize: 24 }}>${balance.balance}</Box> : <CircularProgress size={20} />}
      </Box>
      <Box mt={8} sx={{ height: 40, display: "flex", alignItems: "center" }}>
        <Box mr={2} sx={{ display: "flex", alignItems: "center", fontSize: 14 }}>
          <CreditCardIcon size={20} />
          <Box ml={1}>Automatic payments</Box>
        </Box>
        {invoice && (
          <Box sx={{ display: "flex", alignItems: "center", fontSize: 14 }}>
            <DateRangeIcon size={20} />
            <Box ml={1}>
              Your last payment was on <b>{format(new Date(invoice.end_at * 1000), "MMM dd")}</b> for{" "}
              <b>${parseInt(invoice.amount / 100)}</b>
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default Balance;

import { useRecoilState } from "recoil";
import { alertAtom } from "store/common";

const useAlert = () => {
  const [alertState, setAlertState] = useRecoilState(alertAtom);

  const show = (message, success = true) => {
    setAlertState({
      open: true,
      success,
      message,
    });
  };

  const close = () => {
    setAlertState({
      ...alertState,
      open: false,
    });
  };

  return {
    ...alertState,
    show,
    close,
  };
};

export default useAlert;

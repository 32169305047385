import React from "react";
import { Box } from "@mui/material";

const FormStep = ({ index, label, children }) => {
  return (
    <Box>
      <Box
        sx={{
          my: 4,
          fontSize: 20,
        }}
      >
        <Box
          component="span"
          sx={{
            mr: 2,
            fontWeight: 900,
            textTransform: "uppercase",
          }}
        >
          step {index}:
        </Box>
        {label}
      </Box>
      {children}
    </Box>
  );
};

export default FormStep;

import React from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import { Box, Menu, MenuItem, ButtonBase, ListItemIcon, ListItemText } from "@mui/material";
import { authAtom } from "store/auth";
import { clientAtom } from "store/client";
import { ArrowDownIcon, LogoutIcon, ProfileIcon } from "utils/icons";
import { getDisplayName } from "utils/generators";

const UserMenu = () => {
  const navigate = useNavigate();
  const [authState, setAuthState] = useRecoilState(authAtom);
  const [clientState, setClientState] = useRecoilState(clientAtom);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const navigateProfile = () => {
    navigate("/settings/profile");
    handleClose();
  };

  const handleLogout = () => {
    window.localStorage.removeItem("jwt_access_token");
    window.localStorage.removeItem("jwt_refresh_token");
    window.localStorage.removeItem("jwt_nolt");

    setAuthState({
      ...authState,
      user: null,
    });

    setClientState({
      ...clientState,
      all: null,
      current: null,
      currentId: null,
      customDomainValidated: false,
    });
  };

  return (
    <>
      <ButtonBase
        component="a"
        onClick={handleClick}
        sx={{ height: 1, display: "flex", alignItems: "center", paddingLeft: 1, paddingRight: 1 }}
      >
        <Box
          sx={{
            width: 40,
            height: 40,
            backgroundColor: "#bdbdbd",
            color: "#F7F7F7",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: "50%",
            fontWeight: 700,
            fontSize: 20,
            textTransform: "uppercase",
            overflow: "hidden",
          }}
        >
          {authState.user.avatar ? (
            <Box component="img" src={authState.user.avatar} sx={{ width: 1, height: 1, objectFit: "cover" }} />
          ) : (
            getDisplayName(authState.user)[0]
          )}
        </Box>

        <Box mx={1} sx={{ fontSize: 14 }}>
          <Box sx={{ fontWeight: 700 }}>{getDisplayName(authState.user)}</Box>
          <Box sx={{ fontSize: 12, textTransform: "capitalize" }}>{authState.user.role}</Box>
        </Box>

        <ArrowDownIcon />
      </ButtonBase>

      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{ disablePadding: true }}
        disableScrollLock
      >
        <Box sx={{ width: 165 }}>
          <MenuItem onClick={navigateProfile}>
            <ListItemIcon>
              <ProfileIcon />
            </ListItemIcon>
            <ListItemText>My Profile</ListItemText>
          </MenuItem>

          <MenuItem onClick={handleLogout}>
            <ListItemIcon>
              <LogoutIcon />
            </ListItemIcon>
            <ListItemText>Logout</ListItemText>
          </MenuItem>
        </Box>
      </Menu>
    </>
  );
};

export default UserMenu;

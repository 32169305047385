import React from "react";
import { Box, TableCell, TableRow } from "@mui/material";
import { CheckCircleIcon, InfoCircleIcon } from "utils/icons";

const DnsRecord = ({ record }) => (
  <>
    <TableRow
      sx={{
        "& td": {
          border: !record.valid && "none",
          backgroundColor: record.valid ? "#fff" : "#fdfae8",
        },
      }}
    >
      <TableCell width={30}>
        <Box
          sx={{
            display: "flex",
            color: record.valid ? "success.main" : "error.main",
            fontSize: 20,
          }}
        >
          {record.valid ? <CheckCircleIcon /> : <InfoCircleIcon />}
        </Box>
      </TableCell>
      <TableCell>
        <Box
          sx={{
            color: record.valid ? "success.main" : "error.main",
          }}
        >
          {record.valid ? "Verified" : "Not Verified"}
        </Box>
      </TableCell>
      <TableCell>{record.type?.toUpperCase()}</TableCell>
      <TableCell>{record.name}</TableCell>
      <TableCell>{record.data}</TableCell>
      <TableCell>600 seconds</TableCell>
    </TableRow>
    {!record.valid && (
      <TableRow>
        <TableCell
          colSpan={6}
          sx={{
            backgroundColor: "#fdfae8",
            fontSize: 12,
            color: "#666",
            paddingTop: 0,
            paddingLeft: "70px",
          }}
        >
          {record.reason}
        </TableCell>
      </TableRow>
    )}
  </>
);

export default DnsRecord;

import React from "react";
import { Scrollbars } from "react-custom-scrollbars";
import { Box, IconButton } from "@mui/material";
import { apiCreateEscalations, apiDeleteEscalations, apiGetEscalations } from "store/api";
import useQuickSetupWizard from "hooks/useQuickSetupWizard";
import TextUpload from "shared/wizard/TextUpload";
import useAlert from "hooks/useAlert";
import { DeleteIcon } from "utils/icons";

const EscalationView = ({ label, data, color }) => (
  <Box>
    <Box mt={2} sx={{ border: "1px solid", borderColor: color, maxWidth: 400 }}>
      <Box p={2} sx={{ backgroundColor: "#eee" }}>
        <Box sx={{ color }}>{label}</Box>
        <Box sx={{ color: "#666", fontSize: 12 }}>{data.file}</Box>
        <Box sx={{ color: "#666", fontSize: 12 }}>{data.list.length} items</Box>
      </Box>
      <Box sx={{ height: 300 }}>
        <Scrollbars>
          <Box p={2}>
            {data.list.map((d, key) => (
              <Box mt={1} key={key}>
                {d}
              </Box>
            ))}
          </Box>
        </Scrollbars>
      </Box>
    </Box>
  </Box>
);

const UploadEscalation = () => {
  const setupWizard = useQuickSetupWizard();
  const hAlert = useAlert();

  const [loading, setLoading] = React.useState(true);
  const [oldData, setOldData] = React.useState({
    file: null,
    list: null,
  });
  const [newData, setNewData] = React.useState({
    file: null,
    list: null,
  });

  React.useEffect(() => {
    let mounted = true;
    const init = async () => {
      const res = await apiGetEscalations(setupWizard.client.id);
      if (mounted) {
        if (res.status === 200) {
          setOldData({
            file: res.data.filename,
            list: res.data.escalations.map((d) => d.join(", ")),
          });
        }
        setLoading(false);
      }
    };

    init();

    return () => {
      mounted = false;
    };
    // eslint-disable-next-line
  }, []);

  const handleFileSelect = async (file, text) => {
    const data = text.split(/\r\n/g).slice(1, -1);
    setNewData({
      file: file.name,
      list: data,
    });

    const formData = new FormData();
    formData.append("escalations_csv", file);
    const res = await apiCreateEscalations(setupWizard.client.id, formData);

    if (res.status === 201) {
      hAlert.show("Uploading customized escalations succeeded.");
    } else if (res.status === 500) {
      hAlert.show("There's something wrong in uploading escalations.", false);
    } else if (res.status === 400) {
      hAlert.show("Escalation csv file is not valid.", false);
    }
  };

  const handleRemove = async () => {
    const res = await apiDeleteEscalations(setupWizard.client.id);

    if (res.status === 204) {
      hAlert.show("Removing customized escalations succeeded.");

      setOldData({
        file: null,
        list: null,
      });

      setNewData({
        file: null,
        list: null,
      });
    } else if (res.status === 500) {
      hAlert.show("There's something wrong in removing escalations.", false);
    }
  };

  if (loading) {
    return <Box>loading ...</Box>;
  }

  return (
    <Box>
      <Box my={2}>Please upload CSV to customize utility rate escalation by state.</Box>
      <Box my={2}>
        <Box
          component="a"
          href="/csv/custom_escalation_by_state.csv"
          target="_blank"
          download="custom_escalation_by_state.csv"
          sx={{ color: "#15ABE6" }}
        >
          Expected File Format
        </Box>
      </Box>

      <Box display="flex" justifyContent="space-between" sx={{ maxWidth: 400 }}>
        <TextUpload label="Upload Custom Escalation" onChange={handleFileSelect} />

        {(oldData.file || newData.file) && (
          <IconButton color="error" onClick={handleRemove}>
            <DeleteIcon />
          </IconButton>
        )}
      </Box>

      {newData.file && <EscalationView label="New Escalation Rates" data={newData} color="#ff5722" />}
      {oldData.file && <EscalationView label="Uploaded Escalation Rates" data={oldData} color="#666" />}
    </Box>
  );
};

export default UploadEscalation;

import React from "react";
import { Scrollbars } from "react-custom-scrollbars";
import { Link, useParams } from "react-router-dom";
import { Box, Paper, Tabs, Tab, Button } from "@mui/material";
import { apiGetClient } from "store/api";
import PageLoading from "shared/PageLoading";
import { ArrowLeftIcon } from "utils/icons";
import InfoTab from "./components/InfoTab";
import CreditTab from "./components/credit-tab/CreditTab";
import FeeTab from "./components/FeeTab";
import SubscriptionTab from "./components/SubscriptionTab";
import InvoiceTab from "./components/InvoiceTab";

const ClientEditPage = () => {
  const params = useParams();
  const mounted = React.useRef(true);
  const [loading, setLoading] = React.useState(true);
  const [client, setClient] = React.useState(null);
  const [tab, setTab] = React.useState(0);

  React.useEffect(() => {
    const load = async () => {
      const res = await apiGetClient(params.id);
      if (res.status === 200 && mounted) {
        setClient(res.data);
        setLoading(false);
      }
    };
    load();
    return () => {
      mounted.current = false;
    };
    // eslint-disable-next-line
  }, []);

  const handleChange = (e, v) => {
    setTab(v);
  };

  const updateClient = (d) => {
    if (mounted.current) {
      setClient(d);
    }
  };

  if (!loading && client) {
    return (
      <Box px={4} sx={{ height: 1 }}>
        <Box sx={{ height: 136, display: "flex", flexDirection: "column", justifyContent: "center" }}>
          <Box mb={2}>
            <Button component={Link} to="/settings/clients" size="small" startIcon={<ArrowLeftIcon />}>
              Clients
            </Button>
          </Box>
          <Box sx={{ fontSize: 24, color: "primary.main" }}>{client && client.company_name}</Box>
        </Box>
        <Paper
          sx={{
            height: "calc(100% - 136px)",
            borderTopLeftRadius: 16,
            borderTopRightRadius: 16,
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0,
          }}
        >
          <Box px={2} sx={{ borderBottom: 1, borderColor: "divider", height: 50 }}>
            <Tabs value={tab} onChange={handleChange} sx={{ height: 50 }}>
              <Tab label="Info" />
              <Tab label="Credits" />
              <Tab label="Fees" />
              <Tab label="Subscription" />
              <Tab label="Invoices" />
            </Tabs>
          </Box>
          <Box sx={{ height: "calc(100% - 50px)" }}>
            <Scrollbars>
              {tab === 0 && <InfoTab client={client} updateClient={updateClient} />}
              {tab === 1 && <CreditTab client={client} />}
              {tab === 2 && <FeeTab client={client} updateClient={updateClient} />}
              {tab === 3 && <SubscriptionTab client={client} updateClient={updateClient} />}
              {tab === 4 && <InvoiceTab client={client} />}
            </Scrollbars>
          </Box>
        </Paper>
      </Box>
    );
  }

  return <PageLoading />;
};

export default ClientEditPage;

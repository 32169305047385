import React from "react";
import { Stack, Box, ButtonBase, Collapse, IconButton } from "@mui/material";
import { ArrowDownIcon, ArrowUpIcon, EditIcon, DeleteIcon } from "utils/icons";
import DragHandle from "./shared/DragHandle";

const SingleChoiceBox = ({ value: question, deleteQuestion, openEdit }) => {
  const [collapsed, setCollapsed] = React.useState(true);

  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };

  const handleDeleteQuestion = () => {
    let confirmed = window.confirm("Are you sure to delete question?");
    if (confirmed) {
      deleteQuestion(question);
    }
  };

  return (
    <Box
      my={2}
      sx={{
        backgroundColor: "#fff",
        border: "1px solid #2196f3",
        color: "#2196f3",
        borderRadius: 4,
        overflow: "hidden",
      }}
    >
      <Box sx={{ display: "flex" }}>
        <DragHandle />
        <ButtonBase sx={{ padding: 2, width: 1, fontSize: 16 }} onClick={toggleCollapsed}>
          <Box sx={{ flexGrow: 1, textAlign: "left" }}>{question.question_desc}</Box>
          {collapsed ? <ArrowDownIcon /> : <ArrowUpIcon />}
        </ButtonBase>
      </Box>
      <Collapse in={!collapsed}>
        <Stack spacing={1} sx={{ borderTop: "1px solid #ccc", padding: 4 }}>
          {question.choices
            .sort((a, b) => a.position - b.position)
            .map((choice) => (
              <Box key={choice.id} sx={{ display: "flex" }}>
                <Box
                  p={1}
                  sx={{
                    color: choice.is_disqualifier ? "#fff" : "#ccc",
                    backgroundColor: choice.is_disqualifier ? "#333" : "#eee",
                  }}
                >
                  DQ
                </Box>
                <Box ml={2} px={2} py={1} sx={{ flexGrow: 1, border: "1px solid #ccc", color: "#666" }}>
                  {choice.option}
                </Box>
              </Box>
            ))}
          <Stack direction="row">
            <Box sx={{ flexGrow: 1 }}></Box>
            <IconButton onClick={openEdit}>
              <EditIcon />
            </IconButton>
            <IconButton onClick={handleDeleteQuestion}>
              <DeleteIcon />
            </IconButton>
          </Stack>
        </Stack>
      </Collapse>
    </Box>
  );
};

export default SingleChoiceBox;

export const isSuper = (user) => user && user.role === "superuser";

export const isAdmin = (user) => user && user.role === "admin";

export const isUser = (user) => user && user.role === "user";

export const isRep = (user) => user && user.role === "rep";

export const isAnalyst = (user) => user && user.role === "analyst";

export const isAdminOrSuper = (user) => user && (user.role === "admin" || user.role === "superuser");

export const isUserOrAdminOrSuper = (user) =>
  user && (user.role === "user" || user.role === "admin" || user.role === "superuser");

export const isRepOrUserOrAdminOrSuper = (user) =>
  user && (user.role === "rep" || user.role === "user" || user.role === "admin" || user.role === "superuser");

export const isAnalystOrUserOrAdminOrSuper = (user) =>
  user && (user.role === "analyst" || user.role === "user" || user.role === "admin" || user.role === "superuser");

export const isAnyUser = (user) =>
  user &&
  (user.role === "analyst" ||
    user.role === "rep" ||
    user.role === "user" ||
    user.role === "admin" ||
    user.role === "superuser");

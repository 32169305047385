import React from "react";
import { Box, TextField, MenuItem } from "@mui/material";
import useExperimentsCreator from "hooks/useExperimentsCreator";

const objectives = [
  {
    value: "lead",
    label: "Leads",
  },
  {
    value: "qualified_lead",
    label: "Qualified Leads",
  },
  {
    value: "appointment",
    label: "Appointments",
  },
  {
    value: "appointment_with_bill",
    label: "Appointments with Bills",
  },
];

const ObjectiveForm = () => {
  const hCreator = useExperimentsCreator();

  const handleChange = (e) => {
    hCreator.setValue("objective", e.target.value);
  };

  return (
    <Box>
      <Box mb={2}>Pick from the conversion objectives below</Box>
      <Box sx={{ display: "flex" }}>
        <Box sx={{ flexGrow: 1 }}>
          <TextField fullWidth select value={hCreator.values.objective || ""} onChange={handleChange}>
            {objectives.map((d) => (
              <MenuItem key={d.value} value={d.value}>
                {d.label}
              </MenuItem>
            ))}
          </TextField>
          {hCreator.errors.objective && hCreator.showError && (
            <Box sx={{ color: "error.main" }}>{hCreator.errors.objective}</Box>
          )}
        </Box>
        <Box sx={{ width: 400 }}></Box>
      </Box>
    </Box>
  );
};

export default ObjectiveForm;

import React from "react";
import { Box, FormControlLabel, Checkbox } from "@mui/material";
import useQuickSetupWizard from "hooks/useQuickSetupWizard";

const SelectStateIncentive = () => {
  const setupWizard = useQuickSetupWizard();
  const [stateIncentive, setStateIncentive] = React.useState(false);

  React.useEffect(() => {
    setStateIncentive(setupWizard.client.is_state_incentive);
    // eslint-disable-next-line
  }, []);

  const handleChange = (e) => {
    setStateIncentive(e.target.checked);
    setupWizard.update("is_state_incentive", e.target.checked);
  };

  return (
    <Box sx={{ display: "flex" }}>
      <FormControlLabel
        control={<Checkbox checked={stateIncentive} onChange={handleChange} />}
        label="State Incentives"
      />
    </Box>
  );
};

export default SelectStateIncentive;

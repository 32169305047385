import React from "react";
import { Box } from "@mui/material";
import useSpanishSetupWizard from "hooks/useSpanishSetupWizard";
import Welcome from "./welcome/Welcome";
import Wizard from "./wizard/Wizard";

const SpanishSetup = () => {
  const hWizard = useSpanishSetupWizard();
  const [wizard, setWizard] = React.useState(false);

  React.useEffect(() => {
    hWizard.reset();
    // eslint-disable-next-line
  }, []);

  const openWizard = () => {
    setWizard(true);
  };

  const closeWizard = () => {
    setWizard(false);
  };

  return <Box>{wizard ? <Wizard closeWizard={closeWizard} /> : <Welcome openWizard={openWizard} />}</Box>;
};

export default SpanishSetup;

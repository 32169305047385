import React from "react";
import { Box } from "@mui/material";
import useQuickSetupWizard from "hooks/useQuickSetupWizard";
import FileUpload from "shared/wizard/FileUpload";

const UploadFavicon = () => {
  const setupWizard = useQuickSetupWizard();
  const [favicon, setFavicon] = React.useState(null);

  React.useEffect(() => {
    setFavicon(setupWizard.client.favicon);
    // eslint-disable-next-line
  }, []);

  const handleFaviconChange = (file, url) => {
    setFavicon(url);
    setupWizard.update("favicon", file);
  };

  return (
    <Box>
      <FileUpload label="Upload Favicon" onChange={handleFaviconChange} />
      {favicon && (
        <Box mt={4}>
          <img style={{ width: 64 }} src={favicon} alt="favicon" />
        </Box>
      )}
    </Box>
  );
};

export default UploadFavicon;

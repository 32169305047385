import React from "react";
import { Box, IconButton } from "@mui/material";
import { DeleteIcon, LockIcon } from "utils/icons";

const QuestionBoxReferral = ({ referralOff }) => {
  return (
    <Box
      my={2}
      sx={{
        backgroundColor: "#fff",
        border: "1px solid #ccc",
        color: "#888",
        borderRadius: 4,
        overflow: "hidden",
      }}
    >
      <Box sx={{ display: "flex" }}>
        <Box
          sx={{
            width: 50,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexShrink: 0,
            borderRight: "1px solid #ddd",
          }}
        >
          <LockIcon />
        </Box>
        <Box sx={{ padding: 2, width: 1, fontSize: 16 }}>Is there anything else you'd like us to know?</Box>
        <Box px={2} sx={{ display: "flex", alignItems: "center" }}>
          <IconButton onClick={referralOff}>
            <DeleteIcon />
          </IconButton>
        </Box>
      </Box>
    </Box>
  );
};

export default QuestionBoxReferral;

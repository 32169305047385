import React from "react";
import { Box, TextField } from "@mui/material";
import useSpanishSetupWizard from "hooks/useSpanishSetupWizard";

const ChangeAiName = () => {
  const spanishWizard = useSpanishSetupWizard();
  const [value, setValue] = React.useState("");
  const [error, setError] = React.useState("");

  React.useEffect(() => {
    setValue(spanishWizard.client.spanish_ai_name || "");
    // eslint-disable-next-line
  }, []);

  React.useEffect(() => {
    setError(spanishWizard.errors.spanish_ai_name);
    // eslint-disable-next-line
  }, [spanishWizard.errors]);

  const handleChange = (e) => {
    setValue(e.target.value);
    if (e.target.value === "") {
      setError("required");
      spanishWizard.update("spanish_ai_name", "required");
    } else {
      setError("");
      spanishWizard.update("spanish_ai_name", e.target.value);
    }
  };

  return (
    <Box>
      {error && <Box sx={{ color: "error.main", textTransform: "capitalize" }}>{error}</Box>}
      <Box mt={2}>
        <TextField value={value} onChange={handleChange} label="Ai Name" />
      </Box>
    </Box>
  );
};

export default ChangeAiName;

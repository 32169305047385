import React from "react";
import { Box } from "@mui/material";
import { Bar } from "react-chartjs-2";
import { barChartOptions } from "../../shared/const";

const LeadStatusChart = ({ chartData, openTable }) => {
  return (
    <Box>
      <Box sx={{ height: 300, position: "relative" }}>
        <Bar
          data={{
            labels: chartData.labels,
            datasets: [
              {
                label: "Reporting",
                data: chartData.values,
                backgroundColor: "#2196f3",
                unit: "%",
                strokeColor: "#2196f3",
              },
            ],
          }}
          options={barChartOptions}
        />
        <Box px={4} sx={{ position: "absolute", top: 0, left: 0, width: 1, height: 1, display: "flex" }}>
          {chartData.labels.map((label, i) => (
            <Box key={i} sx={{ flex: "1 1 0px" }}>
              <Box
                sx={{
                  width: "72%",
                  height: 1,
                  margin: "0 auto",
                  transition: "all .2s",
                  cursor: "pointer",
                  "&:hover": { backgroundColor: "#f002" },
                }}
                onClick={() => openTable(chartData.statuses[i])}
              />
            </Box>
          ))}
        </Box>
      </Box>
      <Box px={4} py={2} sx={{ display: "flex" }}>
        {chartData.labels.map((label, i) => (
          <Box key={i} sx={{ flex: "1 1 0px", textAlign: "center" }}>
            <Box sx={{ color: "primary.main" }}>{label}</Box>
            <Box mt={2} sx={{ fontSize: 14, display: "flex", justifyContent: "center" }}>
              <Box sx={{ fontWeight: 700 }}>{chartData.comparisons[i][0]}</Box>
              <Box>/</Box>
              <Box sx={{ color: "#888" }}>{chartData.comparisons[i][1]}</Box>
            </Box>
            <Box mt={1} sx={{ textAlign: "center", fontSize: 12, color: "#888" }}>
              all time
            </Box>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default LeadStatusChart;

import React from "react";
import { useRecoilState } from "recoil";
import { useForm, Controller } from "react-hook-form";
import InputMask from "react-input-mask";
import { Box, CircularProgress, Grid, InputAdornment, Stack } from "@mui/material";
import { apiSgEmailCheck, apiLookupPhoneNumber } from "store/api";
import { clientAtom } from "store/client";
import RoundedButton from "../../shared/StellaRoundedButton";
import PlacesAutoComplete from "../../shared/PlacesAutoComplete";
import FormText from "../../shared/StellaFormText";
import FormCheck from "../../shared/StellaFormCheck";
import TextField from "../../shared/StellaTextField";
import Logo from "../../shared/Logo";
import ImageDialog from "./ImageDialog";
import { HelpIcon, MoneyIcon, QuestionIcon } from "utils/icons";
import { FAQs } from "../../shared/const";

const CustomInput = ({ inputRef, ...props }) => (
  <InputMask {...props}>{() => <TextField inputRef={inputRef} {...props} />}</InputMask>
);

const validate = async (data) => {
  const { placeInfo, first_name, last_name, email, phone, zip_code } = data;
  const errors = {};

  if (!placeInfo) errors["input_place"] = "Address is required";
  else if (!placeInfo.zipCode && !zip_code) errors["zip_code"] = "Postal code is required";
  if (!first_name) errors["first_name"] = "First name is required";
  if (!last_name) errors["last_name"] = "Last name is required";
  if (!email) {
    errors["email"] = "Email is required";
  } else {
    const res = await apiSgEmailCheck({ email });
    if (res.status !== 200) errors["email"] = "That email does not seem to be a valid email";
  }
  if (!phone || !phone.trim()) {
    errors["phone"] = "Phone number is required";
  } else if (!phone.match(/\(\d\d\d\) \d\d\d-\d\d\d\d/i)) {
    errors["phone"] = "That number does not seem to be a valid mobile phone number";
  } else {
    const res = await apiLookupPhoneNumber({ phone });
    if (res.status !== 200) errors["phone"] = "That number does not seem to be a valid mobile phone number";
  }
  return errors;
};

const Start = ({
  defaultValues: { formData: defaultFormData, placeValue: defaultPlaceValue, placeInfo: defaultPlaceInfo },
  onSend,
}) => {
  const [clientState] = useRecoilState(clientAtom);
  const { color_scheme_font, color_scheme_button, color_scheme_button_text } = clientState.current;
  const { control, handleSubmit, setError, resetField } = useForm({
    defaultValues: {
      input_place: defaultFormData?.input_place || "",
      zip_code: defaultFormData?.zip_code || "",
      avg_bill: defaultFormData?.avg_bill || "",
      first_name: defaultFormData?.first_name || "",
      last_name: defaultFormData?.last_name || "",
      phone: defaultFormData?.phone || "",
      email: defaultFormData?.email || "",
      auto_text: defaultFormData?.auto_text || false,
      auto_email: defaultFormData?.auto_email || false,
    },
    mode: "onSubmit",
  });
  const [placeValue, setPlaceValue] = React.useState(defaultPlaceValue);
  const [placeInfo, setPlaceInfo] = React.useState(defaultPlaceInfo);
  const [openSample, setOpenSample] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const handleSelectPlace = (value, info) => {
    resetField("zip_code");
    setPlaceValue(value);
    setPlaceInfo(info);
  };

  const onSubmit = async (data) => {
    setLoading(true);
    const fields = ["input_place", "zip_code", "first_name", "last_name", "phone", "email"];
    const errors = await validate({ placeInfo, ...data });
    setLoading(false);
    fields.reduce((total, field) => {
      if (errors[field]) {
        setError(field, { type: "focus", message: errors[field] }, { shouldFocus: total });
        return false;
      }
      return total;
    }, true);
    if (Object.keys(errors).length) return;
    onSend({ formData: data, placeValue, placeInfo });
    setLoading(true);
  };

  const handleShowSample = () => {
    setOpenSample(true);
  };

  React.useEffect(() => {
    new Image().src = "/images/instant-estimate-sample.jpg";
  }, []);

  const showZipcode = placeInfo && !placeInfo.zipCode;

  return (
    <Box sx={{ color: color_scheme_font }}>
      <Stack
        sx={{
          width: 1,
          minHeight: "calc(100vh - 64px)",
          backgroundColor: "white",
          px: { xs: 2, sm: 4 },
          pt: { xs: 2 },
          pb: { xs: 4 },
        }}
      >
        <Logo />
        <Stack
          sx={{
            width: 1,
            flex: 1,
            alignItems: "center",
          }}
        >
          <Box sx={{ maxWidth: 600, fontSize: { xs: 18, sm: 24, md: 28 }, pt: { xs: 2, sm: 4 }, pb: { xs: 4, sm: 4 } }}>
            Generate a ballpark solar estimate from {clientState.current.company_name} using Google Earth satellite
            analysis!
          </Box>
          <Stack sx={{ maxWidth: 600, flex: 1 }}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Grid container spacing={3} mb={4}>
                <Grid item xs={showZipcode ? 8 : 12}>
                  <PlacesAutoComplete
                    defaultValue={placeValue}
                    onSelectPlace={handleSelectPlace}
                    disabled={loading}
                    renderInput={(params) => {
                      return (
                        <FormText
                          {...params}
                          control={control}
                          inputProps={{
                            ...params.inputProps,
                            autoComplete: "off",
                          }}
                          name="input_place"
                          label="Address *"
                          disabled={loading}
                        />
                      );
                    }}
                  />
                </Grid>
                {showZipcode && (
                  <Grid item xs={4}>
                    <FormText control={control} name="zip_code" label="Postal Code *" disabled={loading} />
                  </Grid>
                )}
                <Grid item xs={12}>
                  <FormText
                    control={control}
                    name="avg_bill"
                    type="number"
                    label="Average Monthly Electric Bill Payment"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <MoneyIcon style={{ fontSize: 20 }} />
                        </InputAdornment>
                      ),
                    }}
                    disabled={loading}
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormText control={control} name="first_name" label="First Name *" disabled={loading} required />
                </Grid>
                <Grid item xs={6}>
                  <FormText control={control} name="last_name" label="Last Name *" disabled={loading} required />
                </Grid>
                <Grid item xs={6}>
                  <Controller
                    control={control}
                    name="phone"
                    render={({ field: { onChange, onBlur, value, ref }, fieldState: { invalid, error } }) => (
                      <CustomInput
                        mask="(999) 999-9999"
                        value={value}
                        inputRef={ref}
                        onChange={onChange}
                        onBlur={onBlur}
                        disabled={loading}
                        type="tel"
                        label="Mobile Phone *"
                        style={{ width: "100%" }}
                        disabled={loading}
                        error={invalid}
                        helperText={error?.message || ""}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormText control={control} name="email" label="Email *" disabled={loading} required />
                </Grid>
                <Grid item xs={12}>
                  <FormCheck
                    control={control}
                    name="auto_text"
                    label={
                      <p>
                        Automatically <b>text</b> the estimate on submission
                      </p>
                    }
                    disabled={loading}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormCheck
                    control={control}
                    name="auto_email"
                    label={
                      <p>
                        Automatically <b>email</b> the estimate on submission
                      </p>
                    }
                    disabled={loading}
                  />
                </Grid>
              </Grid>

              <Box sx={{ textAlign: "center" }}>
                <RoundedButton type="submit" variant="contained" fullWidth sx={{ maxWidth: 400 }} disabled={loading}>
                  {loading && <CircularProgress size={24} sx={{ color: color_scheme_button_text }} />}
                  {!loading && "Generate Estimate"}
                </RoundedButton>
              </Box>
            </form>
          </Stack>
        </Stack>
      </Stack>
      <Box sx={{ px: { xs: 2, sm: 4, md: 6 }, py: { xs: 4, sm: 6 } }}>
        <Stack sx={{ margin: "auto", maxWidth: 600 }} spacing={4}>
          <Box sx={{ fontSize: { xs: 20, md: 24 }, textAlign: "center" }}>
            <QuestionIcon style={{ fontSize: 48 }} />
            <Box sx={{ fontSize: { xs: 14, sm: 16 }, fontWeight: 500 }} mt={2}>
              FAQS FOR INSTANT SOLAR ESTIMATES
            </Box>
          </Box>

          <Stack spacing={6}>
            {FAQs.map((faq, index) => (
              <Stack key={index}>
                <Stack
                  direction="row"
                  spacing={1}
                  sx={{ textTransform: "uppercase", fontWeight: 500, color: color_scheme_button, alignItems: "center" }}
                >
                  <HelpIcon style={{ minWidth: "20px", fontSize: 20 }} />
                  <Box sx={{ fontSize: { xs: 12, sm: 14 } }}>{faq.question}</Box>
                </Stack>
                <Box mt={1} sx={{ fontSize: { xs: 12, sm: 14 } }}>
                  {faq.answer}
                </Box>
                {index === 0 && (
                  <RoundedButton
                    variant="outlined"
                    kind="secondary"
                    size="small"
                    sx={{ maxWidth: 200, mt: 2.5 }}
                    onClick={handleShowSample}
                  >
                    See Sample
                  </RoundedButton>
                )}
              </Stack>
            ))}
          </Stack>
        </Stack>
      </Box>
      <ImageDialog open={openSample} setOpen={setOpenSample} />
    </Box>
  );
};

export default Start;

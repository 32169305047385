import { TextField } from "@mui/material";
import { LocalizationProvider, DatePicker } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import useClientCreateWizard from "hooks/useClientCreateWizard";

const WizardDate = ({ name, label, ...props }) => {
  const wizard = useClientCreateWizard();

  const handleChange = (date) => {
    wizard.update(name, date);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DatePicker
        label={label}
        value={wizard.data[name]}
        onChange={handleChange}
        renderInput={(params) => <TextField fullWidth {...params} />}
        {...props}
      />
    </LocalizationProvider>
  );
};

export default WizardDate;

import React from "react";
import { Box, TextField } from "@mui/material";
import useExperimentsCreator from "hooks/useExperimentsCreator";

const NameForm = () => {
  const hCreator = useExperimentsCreator();

  const handleChange = (e) => {
    if (e.target.value === "") {
      hCreator.setValue("name", "", "Required");
    } else {
      hCreator.setValue("name", e.target.value);
    }
  };

  return (
    <Box>
      <Box mb={2}>Give your experiment a descriptive name.</Box>
      <TextField fullWidth sx={{ maxWidth: 400 }} value={hCreator.values.name || ""} onChange={handleChange} />
      {hCreator.errors.name && hCreator.showError && <Box sx={{ color: "error.main" }}>{hCreator.errors.name}</Box>}
    </Box>
  );
};

export default NameForm;

import React from "react";
import { Dialog, Stack, Box, TextField } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { apiGetToken } from "store/api";

const DialogSaveVerify = ({ open, closeDialog, onVerified }) => {
  const [loading, setLoading] = React.useState(false);
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");

  const handleClose = () => {
    if (!loading) {
      closeDialog();
    }
  };

  const handleVerify = async () => {
    if (email && password) {
      setLoading(true);
      const res = await apiGetToken({ email, password });
      setLoading(false);
      if (res.status === 200) {
        closeDialog();
        onVerified();
      }
    }
  };

  return (
    <Box>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          sx: { width: "100%" },
        }}
      >
        <Stack p={4} spacing={4}>
          <Box sx={{ fontSize: 24 }}>Login to Continue Update</Box>
          <TextField
            fullWidth
            label="Email"
            autoComplete="new-password"
            disabled={loading}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <TextField
            fullWidth
            label="Password"
            type="password"
            autoComplete="new-password"
            disabled={loading}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <Box sx={{ display: "flex" }}>
            <LoadingButton variant="contained" loading={loading} onClick={handleVerify}>
              Login
            </LoadingButton>
          </Box>
        </Stack>
      </Dialog>
    </Box>
  );
};

export default DialogSaveVerify;

import React from "react";
import { IconButton, Menu, MenuItem } from "@mui/material";
import { subDays, subMonths, startOfDay, endOfDay, startOfMonth, endOfMonth } from "date-fns";
import { DotIcon } from "utils/icons";
import useReporting from "hooks/useReporting";

const dateTemplates = [
  { label: "Today", value: 0, unit: "day" },
  { label: "Yesterday", value: 1, unit: "day" },
  { label: "Last 7 Days", value: 7 - 1, unit: "day" },
  { label: "Last 14 Days", value: 14 - 1, unit: "day" },
  { label: "Last 30 Days", value: 30 - 1, unit: "day" },
  { label: "Last 60 Days", value: 60 - 1, unit: "day" },
  { label: "Last 90 Days", value: 90 - 1, unit: "day" },
  { label: "Last Month", value: 1, unit: "month" },
  { label: "This Month", value: 0, unit: "month" },
  { label: "All Time", value: "all", unit: "all" },
];

const DateTemplateMenu = ({ onSelect, disabled }) => {
  const hReporting = useReporting();
  const [menuAnchorEl, setMenuAnchorEl] = React.useState(null);
  const openMenu = Boolean(menuAnchorEl);

  const handleMenuClick = (event) => {
    setMenuAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setMenuAnchorEl(null);
  };

  const handleSelect = (template) => {
    let start, end;
    if (template.unit === "all") {
      start = hReporting.main.data.minDate;
      end = hReporting.main.data.maxDate;
    }
    if (template.unit === "month") {
      let dateOfMonth = startOfMonth(new Date());
      dateOfMonth = subMonths(dateOfMonth, template.value);
      start = startOfMonth(dateOfMonth);
      end = endOfMonth(dateOfMonth);
    }
    if (template.unit === "day") {
      start = subDays(new Date(), template.value);
      end = new Date();
      if (template.value === 1) {
        end = start;
      }
    }
    onSelect(startOfDay(start), endOfDay(end));
    handleMenuClose();
  };

  return (
    <>
      <IconButton disabled={disabled} sx={{ border: "1px solid #ccc" }} onClick={handleMenuClick}>
        <DotIcon />
      </IconButton>
      <Menu anchorEl={menuAnchorEl} open={openMenu} onClose={handleMenuClose}>
        {dateTemplates.map((d) => (
          <MenuItem dense key={d.label} onClick={() => handleSelect(d)}>
            {d.label}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default DateTemplateMenu;

export const parseStoken = (stoken) => {
  const result = {};
  const fields = atob(stoken).split("\n");
  for (const field of fields) {
    const [key, value] = field.split("=");
    result[key] = value;
  }
  return result;
};

export const validateURL = (url) => {
  try {
    new URL(url);
    return true;
  } catch {
    return false;
  }
};

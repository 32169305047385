import React from "react";
import { Box } from "@mui/material";
import useQuickSetupWizard from "hooks/useQuickSetupWizard";
import FileUpload from "shared/wizard/FileUpload";

const applyCustomFont = (url) => {
  const elem = document.getElementById("stella_preview");
  if (elem) {
    elem.parentNode.removeChild(elem);
  }

  const css = "@font-face{" + "font-family: StellaPreview;" + "src: url(" + url + ");" + "}";
  const head = document.head || document.getElementsByTagName("head")[0];
  const style = document.createElement("style");

  head.appendChild(style);

  // style.type = 'text/css';
  style.id = "stella_preview";
  if (style.styleSheet) {
    style.styleSheet.cssText = css;
  } else {
    style.appendChild(document.createTextNode(css));
  }
};

const UploadFont = () => {
  const setupWizard = useQuickSetupWizard();
  const [font, setFont] = React.useState(null);

  React.useEffect(() => {
    setFont(setupWizard.client.font);
    // eslint-disable-next-line
  }, []);

  React.useEffect(() => {
    if (font) {
      applyCustomFont(font);
    }
    // eslint-disable-next-line
  }, [font]);

  const handleFontChange = (file, url) => {
    setFont(url);
    setupWizard.update("font", file);
  };

  return (
    <Box>
      <FileUpload label="Upload Font" onChange={handleFontChange} />
      {font && (
        <Box
          mt={4}
          sx={{
            fontSize: 48,
            fontFamily: "StellaPreview, Arial",
            maxWidth: 800,
            wordBreak: "break-all",
          }}
        >
          ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz`1234567890-=[]\;',./~!@#$%^*()_+:"?
        </Box>
      )}
    </Box>
  );
};

export default UploadFont;

import React from "react";
import { Scrollbars } from "react-custom-scrollbars";
import { v4 as uuidv4 } from "uuid";
import { Box, Button, TextField } from "@mui/material";
import Switch from "shared/Switch";
import Battery from "./Battery";
import BatteryForm from "./BatteryForm";
import useQuickSetupWizard from "hooks/useQuickSetupWizard";
import { apiGetBatteries, apiCreateBattery, apiUpdateBattery, apiDeleteBattery } from "store/api";

const ADD = "add";
const EDIT = "edit";

const Batteries = ({ off }) => {
  const setupWizard = useQuickSetupWizard();

  const [isAutoBattery, setIsAutoBattery] = React.useState(false);
  const [description, setDescription] = React.useState("");
  const [batteries, setBatteries] = React.useState([]);
  const [form, setForm] = React.useState(null);
  const [action, setAction] = React.useState("");

  React.useEffect(() => {
    const init = async () => {
      setIsAutoBattery(setupWizard.client.is_auto_assign_battery_enabled ?? false);
      setDescription(setupWizard.client.battery_section_description ?? "");

      const res = await apiGetBatteries({ solar_company: setupWizard.client.id });
      if (res.status === 200) {
        if (res.data.length) setBatteries(res.data);
        else handleAdd();
      }
    };

    init();
  }, []);

  const handleToggleAutoBattery = (e) => {
    setIsAutoBattery(e.target.checked);
    setupWizard.update("is_auto_assign_battery_enabled", e.target.checked);
  };

  const handleChangeDescription = (e) => {
    if (e.target.value.length <= 255) {
      setDescription(e.target.value);
      setupWizard.update("battery_section_description", e.target.value);
    }
  };

  const handleAdd = () => {
    setForm({ id: uuidv4() });
    setAction(ADD);
  };

  const handleEdit = (battery) => {
    setForm(battery);
    setAction(EDIT);
  };

  const handleDelete = async (id) => {
    const res = await apiDeleteBattery(id);
    if (res.status === 204) {
      setBatteries(batteries.filter((battery) => battery.id !== id));
    }
  };

  const handleSave = async (data) => {
    if (action === ADD) {
      const res = await apiCreateBattery(
        { solar_company: setupWizard.client.id },
        {
          ...data,
          id: undefined,
          solar_company: setupWizard.client.id,
        }
      );

      if (res.status === 201) {
        setBatteries([...batteries, res.data]);
        setForm(null);
      }
    } else if (action === EDIT) {
      const res = await apiUpdateBattery(data.id)(
        { solar_company: setupWizard.client.id },
        {
          ...data,
          solar_company: setupWizard.client.id,
        }
      );

      if (res.status === 200) {
        setBatteries(batteries.map((battery) => (battery.id === res.data.id ? res.data : battery)));
        setForm(null);
      }
    }
  };

  const handleCancel = () => {
    setForm(null);
    if (batteries.length === 0) off();
  };

  return (
    <Box>
      <Box sx={{ pt: 2, pl: 3 }}>
        <Switch label="Automatically Assign Battery" checked={isAutoBattery} onChange={handleToggleAutoBattery} />

        {isAutoBattery && (
          <TextField
            fullWidth
            size="small"
            label={`Battery Section Description (${description.length}/255)`}
            value={description}
            onChange={handleChangeDescription}
            sx={{ mt: 3 }}
          />
        )}
      </Box>

      <Box sx={{ px: 1 }}>
        <Scrollbars autoHeight autoHeightMin="100%" autoHeightMax="100%">
          <Box sx={{ display: "flex", height: "100%" }}>
            {batteries.map((battery, index) => (
              <Battery
                key={index}
                {...battery}
                isDefault={index === 0}
                showDelete={index > 0}
                onEdit={() => handleEdit(battery)}
                onDelete={() => handleDelete(battery.id)}
              />
            ))}
            {batteries.length < 10 && (
              <Box
                sx={{
                  minWidth: "15rem",
                  minHeight: "18.125rem",
                  border: "1px dotted #dcdcdc",
                  borderRadius: "10px",
                  p: 2,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  margin: "1.875rem 1rem",
                }}
              >
                <Button variant="outlined" onClick={() => handleAdd()}>
                  Add
                </Button>
              </Box>
            )}
          </Box>
        </Scrollbars>
      </Box>

      {form && (
        <BatteryForm
          key={form.id}
          isAutoBattery={isAutoBattery}
          form={form}
          header={`${action} Battery`}
          onSave={handleSave}
          onCancel={handleCancel}
        />
      )}
    </Box>
  );
};

export default Batteries;

import React from "react";
import { Box, Button, Stack } from "@mui/material";
import FileUpload from "shared/wizard/FileUpload";
import { DeleteIcon } from "utils/icons";

const UploadThanksIcon = ({ icon, width, height, onChange, onRemove }) => {
  const handleChange = (file, url) => {
    onChange(file, url);
  };

  const handleRemove = () => {
    onRemove(new File([], ""));
  };

  return (
    <Stack sx={{ width: 240 }}>
      <Box sx={{ fontSize: 12, color: "#333", mb: 1 }}>Icon</Box>

      <Box sx={{ width: 1, mb: 2, display: "flex", justifyContent: "space-between" }}>
        <FileUpload label="Upload" onChange={handleChange} />

        {icon && (
          <Button color="error" component="label" variant="outlined" endIcon={<DeleteIcon />} onClick={handleRemove}>
            Remove
          </Button>
        )}
      </Box>

      <Box
        component="img"
        alt="Icon for thankyou page"
        src={!icon ? "/images/default-icon-thankyou.svg" : icon}
        sx={{
          width: `${width}px`,
          height: `${height}px`,
          border: "1px solid #ccc",
          borderRadius: "5px",
        }}
      />
    </Stack>
  );
};

export default UploadThanksIcon;

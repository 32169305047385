import { Box } from "@mui/material";

const CodeBox = ({ label }) => {
  return (
    <Box
      component="span"
      sx={{
        background: "#eee",
        color: "#000",
        padding: "2px 4px",
        borderRadius: "2px",
        fontWeight: 700,
      }}
    >
      {label}
    </Box>
  );
};

export default CodeBox;

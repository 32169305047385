import React from "react";
import { useRecoilState } from "recoil";
import { TextField } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { clientAtom } from "store/client";

const textLabelStyle = (color) =>
  makeStyles({
    root: {
      color,

      "&$focused:not(.Mui-error)": {
        color,
        paddingLeft: 2,
        paddingRight: 2,
      },
    },

    focused: {},
  });

const textInputStyle = (color) =>
  makeStyles({
    root: {
      color,
      "&:not(.Mui-error) $notchedOutline": {
        borderColor: color,
      },
    },

    input: {
      "&::-webkit-inner-spin-button, &::-webkit-outer-spin-button": {
        color,
      },
      "-moz-appearance": "textfield",
    },

    focused: {},

    notchedOutline: {},
  });

const StellaTextField = ({ InputProps, ...props }) => {
  const [clientState] = useRecoilState(clientAtom);
  const { color_scheme_font } = clientState.current;
  const textLabelClasses = textLabelStyle(color_scheme_font)();
  const textInputClasses = textInputStyle(color_scheme_font)();

  return (
    <TextField
      {...props}
      InputLabelProps={{
        classes: textLabelClasses,
      }}
      InputProps={{
        classes: textInputClasses,
        ...InputProps,
      }}
    />
  );
};

export default StellaTextField;

import { useRecoilState, useResetRecoilState } from "recoil";
import useAlert from "hooks/useAlert";
import useCommon from "hooks/useCommon";
import { clientAtom } from "store/client";
import { experimentCreatorAtom } from "store/experiment";
import { apiCreateExperiments } from "store/api";
import moment from "moment";

const useExperimentsCreator = () => {
  const hCommon = useCommon();
  const hAlert = useAlert();
  const [clientState] = useRecoilState(clientAtom);
  const [creatorState, setCreatorState] = useRecoilState(experimentCreatorAtom);
  const resetCreatorState = useResetRecoilState(experimentCreatorAtom);

  const setType = async (value) => {
    await setCreatorState({
      ...creatorState,
      type: value,
    });
  };

  const setBannerValue = async (key, value) => {
    await setCreatorState({
      ...creatorState,
      bannerInitialized: true,
      bannerValues: {
        ...creatorState.bannerValues,
        [key]: value,
      },
    });
  };

  const setBannerValues = async (values) => {
    await setCreatorState({
      ...creatorState,
      bannerInitialized: true,
      bannerValues: {
        ...creatorState.bannerValues,
        ...values,
      },
    });
  };

  const setValue = async (key, value, error = null) => {
    setCreatorState({
      ...creatorState,
      values: {
        ...creatorState.values,
        [key]: value,
      },
      errors: {
        ...creatorState.errors,
        [key]: error,
      },
    });
  };

  const setValues = async (values, errors) => {
    setCreatorState({
      ...creatorState,
      values: {
        ...creatorState.values,
        ...values,
      },
      errors: {
        ...creatorState.errors,
        ...errors,
      },
    });
  };

  const back = () => {
    setCreatorState({
      ...creatorState,
      form: creatorState.form - 1,
      showError: false,
      errors: {},
    });
  };

  const next = () => {
    if (Object.values(creatorState.errors).filter((d) => d !== null).length === 0) {
      setCreatorState({
        ...creatorState,
        form: creatorState.form + 1,
        showError: false,
      });
    } else {
      setCreatorState({
        ...creatorState,
        showError: true,
      });
    }
  };

  const submit = async (openTab) => {
    hCommon.loading("Creating Experiment ...");

    const res = await apiCreateExperiments(
      {
        solar_company: clientState.currentId,
      },
      {
        name: creatorState.values.name,
        variations: [creatorState.values.variations],
        type: creatorState.type,
        traffic_volume: creatorState.values.traffic_volume,
        objective: creatorState.values.objective,
        start_datetime: moment(creatorState.values.start_datetime).format("YYYY-MM-DDTHH:mm:ssZ"),
        end_datetime: moment(creatorState.values.end_datetime).format("YYYY-MM-DDTHH:mm:ssZ"),
        end_visitors_amount: creatorState.values.end_visitors_amount,
        result: creatorState.values.result,
        solar_company: clientState.currentId,
        banner: creatorState.bannerValues,
      }
    );

    if (res.status === 201) {
      hAlert.show("New Experiment saved successfully!");
      resetCreatorState();
      openTab(1);
    } else {
      hAlert.show(Object.values(res.data).join("\n"), false);
    }

    hCommon.loading("");
  };

  return {
    ...creatorState,
    reset: resetCreatorState,
    setType,
    setBannerValue,
    setBannerValues,
    setValue,
    setValues,
    back,
    next,
    submit,
  };
};

export default useExperimentsCreator;

import React from "react";
import { Box, IconButton, Stack, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/styles";
import RoundedButton from "shared/RoundedButton";
import { CloseIcon } from "utils/icons";
import "./sidebar.scss";

const SideBar = ({ onClose }) => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <Box sx={{ background: "black" }}>
      <Box
        className="section-left"
        sx={{ backgroundImage: `url("/images/sidebar-banner.png")`, backgroundSize: "cover" }}
      >
        {isDesktop ? (
          <Box>
            <Box className="phone-desktop">
              <img src="/images/phone-desktop.svg" />
            </Box>
            <Box className="news">
              <img src="/images/sidebar-news.svg" />
            </Box>
          </Box>
        ) : (
          <Box>
            <img className="phone-mobile1" src="/images/phone-mobile1.png" />
            <img className="phone-mobile2" src="/images/phone-mobile2.png" />
          </Box>
        )}
        <Box className="section-left-content">
          <Stack className="promotion">
            {isDesktop ? (
              <Stack spacing={2}>
                <img src="/images/sidebar-hero.svg" style={{ width: "113px", height: "40px" }} />
                <Box sx={{ width: "70%", fontSize: "1.8rem", fontWeight: "bold", lineHeight: 1.2 }}>
                  Get instant solar reports on the go.
                </Box>
                <Box sx={{ fontSize: ".85rem", lineHeight: 1.5, opacity: 0.7 }}>
                  Hero is the ultimate sales sidekick that helps outside sales teams increase appointment rates and
                  streamline data collection.
                </Box>
                <Box component="a" href="https://www.demand-iq.com/hero" target="_blank">
                  <img src="/images/sidebar-btn.svg" />
                </Box>
              </Stack>
            ) : (
              <Stack spacing={1} sx={{ alignItems: "center", display: "block" }}>
                <img src="/images/sidebar-hero.svg" style={{ width: "45px" }} />
                <Box sx={{ fontWeight: 600, fontSize: "20px", lineHeight: "21px", width: "220px" }}>
                  Get instant solar reports on the go.
                </Box>
                <Box component="a" href="https://www.demand-iq.com/hero" target="_blank">
                  <img src="/images/sidebar-btn-mobile.png" style={{ marginTop: "14px" }} />
                </Box>
              </Stack>
            )}
          </Stack>
        </Box>
        {isDesktop && (
          <Box sx={{ position: "absolute", left: "24px", bottom: ".8rem", display: "flex" }}>
            <Box component="a" href="https://apps.apple.com/us/app/hero-from-demand-iq/id6443422119" target="_blank">
              <img src="/images/app-store.svg" />
            </Box>
            <Box
              component="a"
              href="https://play.google.com/store/apps/details?id=com.demandiq.hero&pli=1"
              target="_blank"
              sx={{ marginLeft: "4px" }}
            >
              <img src="/images/play.svg" />
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default SideBar;

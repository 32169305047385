import React from "react";
import { Box } from "@mui/material";
import useSpanishSetupWizard from "hooks/useSpanishSetupWizard";

const WizardForm = ({ label, required = [], children }) => {
  const spanishWizard = useSpanishSetupWizard();

  React.useEffect(() => {
    const values = [];
    for (let i = 0; i < required.length; i++) {
      if (!spanishWizard.client[required[i]]) {
        values.push({
          key: required[i],
          value: "required",
        });
      }
    }
    spanishWizard.updateMany(values);
    // eslint-disable-next-line
  }, []);

  return (
    <Box>
      {label && (
        <Box mt={2} sx={{ color: "#0008", textTransform: "uppercase" }}>
          {label}
        </Box>
      )}
      {children}
    </Box>
  );
};

export default WizardForm;

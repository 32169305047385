import React from "react";
import { Stack } from "@mui/material";
import WizardCheck from "../shared/WizardCheck";
import useClientCreateWizard from "hooks/useClientCreateWizard";

const SubscriptionForm = () => {
  const wizard = useClientCreateWizard();

  React.useEffect(() => {
    wizard.update("is_free_trial", !wizard.data["status"]);
  }, [wizard.data["status"]]);

  return (
    <Stack>
      <WizardCheck name="status" label="Active" />
      <WizardCheck name="enable_chat" label="Enable Stella Embedded" />
      <WizardCheck name="is_free_trial" label="Temporarily Pause Billing" disabled={!wizard.data["status"]} />
      <WizardCheck name="is_sunroof_enabled" label="Enable Sunroof" />
      <WizardCheck name="is_phone_verification_required" label="Require 2FA Phone Number Verification" />
      <WizardCheck name="enable_automated_text_follow_up" label="Enable Automated Text Follow-Ups" />
      <WizardCheck name="enable_email_follow_up" label="Enable Email Follow-ups" />
      <WizardCheck name="is_instant_estimate_api_enabled" label="Enable Instant Estimate API &amp; Reporting" />
      <WizardCheck name="is_best_company_enabled" label="Best Company - Out of Service Area" />
      <WizardCheck name="is_finance_integrations_enabled" label="Enable Finance Integrations" />
      <WizardCheck name="is_solar_ai_engine_enabled" label="Solar AI Engine" />
      <WizardCheck name="is_strict_phone_number_validation_enabled" label="Enable Strict Phone Number Validation" />
      <WizardCheck name="is_strict_email_validation_enabled" label="Enable Strict Email Validation" />
    </Stack>
  );
};

export default SubscriptionForm;

import React from "react";
import { UNSAFE_NavigationContext } from "react-router";

const useBlocker = (blocker, when) => {
  const navigator = React.useContext(UNSAFE_NavigationContext).navigator;

  React.useEffect(() => {
    if (!when) return;

    const push = navigator.push;

    navigator.push = (...args) => {
      const result = blocker({ location: args[0] });
      if (result !== false) {
        push(...args);
      }
    };

    return () => {
      navigator.push = push;
    };
  }, [navigator, blocker, when]);
};

export default useBlocker;

import { useRecoilState } from "recoil";
import { format, startOfDay, endOfDay, parseISO } from "date-fns";
import { clientAtom } from "store/client";
import { apiGetStatesForReport, apiGetLeadStatusReport } from "store/api";
import {
  reportingClientAtom,
  leadMainFilterAtom,
  leadMainDataAtom,
  leadConversionFilterAtom,
  leadConversionDataAtom,
  leadSonicFilterAtom,
  leadSonicDataAtom,
  leadStella2FilterAtom,
  leadStella2DataAtom,
} from "store/reporting";
import { getDummyValue } from "utils/dummy";

const defaultLocations = [{ value: "all", label: "All States" }];
const DUMMY_START_DATE = "2021-01-01";

const useReporting = () => {
  const [clientState] = useRecoilState(clientAtom);
  const [reportingClientState, setReportingClientState] = useRecoilState(reportingClientAtom);

  const [mainFilter, setMainFilter] = useRecoilState(leadMainFilterAtom);
  const [mainData, setMainData] = useRecoilState(leadMainDataAtom);

  const [conversionFilter, setConversionFilter] = useRecoilState(leadConversionFilterAtom);
  const [conversionData, setConversionData] = useRecoilState(leadConversionDataAtom);

  const [sonicFilter, setSonicFilter] = useRecoilState(leadSonicFilterAtom);
  const [sonicData, setSonicData] = useRecoilState(leadSonicDataAtom);

  const [stella2Filter, setStella2Filter] = useRecoilState(leadStella2FilterAtom);
  const [stella2Data, setStella2Data] = useRecoilState(leadStella2DataAtom);

  const init = async () => {
    setReportingClientState({
      ...reportingClientState,
      client: clientState.current,
    });
  };

  const setClient = async (key, value) => {
    setReportingClientState({
      ...reportingClientState,
      client: {
        ...reportingClientState.client,
        [key]: value,
      },
    });
  };

  const formatStartDate = (date) => {
    return format(startOfDay(date), "yyyy-MM-dd'T'HH:mm:ssxxxxx");
  };

  const formatEndDate = (date) => {
    return format(endOfDay(date), "yyyy-MM-dd'T'HH:mm:ssxxxxx");
  };

  const getLocations = async () => {
    if (clientState.current.company_name === "Demand IQ") {
      setMainFilter({
        ...mainFilter,
        locations: [...defaultLocations],
        currentLocation: "all",
      });
      return;
    }

    const res = await apiGetStatesForReport({
      start_at: formatStartDate(mainFilter.startDate),
      end_at: formatEndDate(mainFilter.endDate),
      solar_company: clientState.currentId,
      product: "stella1",
      stella2_only: mainFilter.stella2only,
    });
    if (res.status === 200) {
      const locations = Object.values(res.data.states).map((d) => ({
        value: d.code,
        label: d.name,
      }));
      setMainFilter({
        ...mainFilter,
        locations: [...defaultLocations, ...locations],
        currentLocation: "all",
      });
    }
  };

  const getLeadMainData = async (leadStatusLabels) => {
    // confirm API parameter
    const params = {};
    params.start_at = formatStartDate(mainFilter.startDate);
    params.end_at = formatEndDate(mainFilter.endDate);
    params.solar_company = clientState.currentId;
    params.is_instant_estimate = false;
    params.stella2_only = mainFilter.stella2only;

    if (mainFilter.currentLocation !== "all") {
      params.state = mainFilter.currentLocation;
    }

    const res = await apiGetLeadStatusReport(params);

    if (res.status === 200) {
      const { data, date_range, total_data } = res.data;

      // extract chart data

      let visitor = 0;
      let labels = [];
      let values = [];
      let comparisons = [];
      let statuses = [];

      const isDemandIQ = clientState.current.company_name === "Demand IQ" && !mainFilter.stella2only;

      const dummy_data = isDemandIQ && getDummyValue({ startAt: params.start_at, endAt: params.end_at }),
        dummy_total_data =
          isDemandIQ &&
          getDummyValue({
            startAt: date_range.start_at,
            endAt: date_range.end_at,
            isTotal: true,
          });

      for (let i = 0; i < data.length; i++) {
        const lead_status = data[i].lead_status;
        const countId = data[i].count_id + (isDemandIQ ? dummy_data[lead_status] : 0),
          totCountId = total_data[i].count_id + (isDemandIQ ? dummy_total_data[lead_status] : 0);

        if (data[i].lead_status === "visitor") {
          visitor = countId;
        }
        if (data[i].lead_status === "contact" || data[i].lead_status === "disqualified_lead") {
          continue;
        }
        labels.push(leadStatusLabels[data[i].lead_status]);
        values.push(visitor === 0 ? 0 : Math.round((countId / visitor) * 1000) / 10);
        comparisons.push([countId, totCountId]);
        statuses.push(data[i].lead_status);
      }

      // calculate disqualified rate

      const disqualified = data.find((d) => d.lead_status === "disqualified_lead");
      const qualified = data.find((d) => d.lead_status === "qualified_lead");
      const dummyDisqualified = isDemandIQ ? dummy_data["disqualified_lead"] : 0;
      const dummyQualifed = isDemandIQ ? dummy_data["qualified_lead"] : 0;
      const disqualifiedCount = disqualified.count_id + dummyDisqualified;
      const qualifiedCount = qualified.count_id + dummyQualifed;
      const totalCount = disqualifiedCount + qualifiedCount;
      const disqualifiedRate = totalCount > 0 ? parseFloat(((disqualifiedCount / totalCount) * 100).toFixed(2)) : 0;

      setMainData({
        ...mainData,
        chart: {
          labels,
          values,
          comparisons,
          statuses,
        },
        disqualifiedRate,
        minDate: parseISO(isDemandIQ ? DUMMY_START_DATE : date_range.start_at),
        maxDate: parseISO(date_range.end_at),
      });
    }
  };

  const getLeadConversionData = async (leadStatusLabels) => {
    // confirm API parameter
    const params = {};
    params.start_at = formatStartDate(conversionFilter.startDate);
    params.end_at = formatEndDate(conversionFilter.endDate);
    params.solar_company = clientState.currentId;
    params.is_instant_estimate = true;

    const res = await apiGetLeadStatusReport(params);

    if (res.status === 200) {
      const { data, date_range, total_data } = res.data;

      // extract chart data
      let lead = 0;
      let visitor = 0;
      let labels = [];
      let values = [];
      let comparisons = [];
      let statuses = [];

      const isDemandIQ = clientState.current.company_name === "Demand IQ";

      const dummy_data =
          isDemandIQ && getDummyValue({ startAt: params.start_at, endAt: params.end_at, isInstant: true }),
        dummy_total_data =
          isDemandIQ &&
          getDummyValue({
            startAt: date_range.start_at,
            endAt: date_range.end_at,
            isInstant: true,
            isTotal: true,
          });

      for (let i = 0; i < data.length; i++) {
        const lead_status = data[i].lead_status;
        const countId = data[i].count_id + (isDemandIQ ? dummy_data[lead_status] : 0),
          totCountId = total_data[i].count_id + (isDemandIQ ? dummy_total_data[lead_status] : 0);

        if (data[i].lead_status === "visitor") {
          visitor = countId;
        }
        if (data[i].lead_status === "lead") {
          lead = countId;
        }
        if (["contact", "disqualified_lead", "visitor"].includes(data[i].lead_status)) {
          continue;
        }
        labels.push(leadStatusLabels[data[i].lead_status]);
        values.push(visitor === 0 ? 0 : Math.round((countId / visitor) * 1000) / 10);
        comparisons.push([countId, totCountId]);
        statuses.push(data[i].lead_status);
      }

      setConversionData({
        ...conversionData,
        chart: {
          labels,
          values,
          comparisons,
          statuses,
        },
        leadViewEstimate: {
          lead,
          visitor,
        },
        minDate: parseISO(isDemandIQ ? DUMMY_START_DATE : date_range.start_at),
        maxDate: parseISO(date_range.end_at),
      });
    }
  };

  const getSonicLocations = async () => {
    // if (clientState.current.company_name === "Demand IQ") {
    //   setSonicFilter({
    //     ...sonicFilter,
    //     locations: [...defaultLocations],
    //     currentLocation: "all",
    //   });

    //   return;
    // }

    const res = await apiGetStatesForReport({
      start_at: formatStartDate(sonicFilter.startDate),
      end_at: formatEndDate(sonicFilter.endDate),
      solar_company: clientState.currentId,
      product: "sonic",
    });
    if (res.status === 200) {
      const locations = Object.values(res.data.states).map((d) => ({
        value: d.code,
        label: d.name,
      }));
      setSonicFilter({
        ...sonicFilter,
        locations: [...defaultLocations, ...locations],
        currentLocation: "all",
      });
    }
  };

  const getLeadSonicData = async (leadStatusLabels) => {
    // confirm API parameter
    const params = {};
    params.start_at = formatStartDate(sonicFilter.startDate);
    params.end_at = formatEndDate(sonicFilter.endDate);
    params.solar_company = clientState.currentId;
    params.is_instant_estimate = false;
    params.product = "sonic";
    if (sonicFilter.currentLocation !== "all") {
      params.state = sonicFilter.currentLocation;
    }

    const res = await apiGetLeadStatusReport(params);

    if (res.status === 200) {
      const { data, date_range, total_data } = res.data;

      // extract chart data

      let visitor = 0;
      let labels = [];
      let values = [];
      let comparisons = [];
      let statuses = [];

      // const isDemandIQ = clientState.current.company_name === "Demand IQ";
      const isDemandIQ = false;

      const dummy_data = isDemandIQ && getDummyValue({ startAt: params.start_at, endAt: params.end_at }),
        dummy_total_data =
          isDemandIQ &&
          getDummyValue({
            startAt: date_range.start_at,
            endAt: date_range.end_at,
            isTotal: true,
          });

      for (let i = 0; i < data.length; i++) {
        const lead_status = data[i].lead_status;

        if (
          lead_status === "contact" ||
          lead_status === "disqualified_lead" ||
          lead_status === "qualified_lead" ||
          lead_status === "appointment_with_bill"
        )
          continue;

        const countId = data[i].count_id + (isDemandIQ ? dummy_data[lead_status] : 0),
          totCountId = total_data[i].count_id + (isDemandIQ ? dummy_total_data[lead_status] : 0);

        if (data[i].lead_status === "visitor") {
          visitor = countId;
        }
        if (data[i].lead_status === "contact" || data[i].lead_status === "disqualified_lead") {
          continue;
        }
        labels.push(leadStatusLabels[data[i].lead_status]);
        values.push(visitor === 0 ? 0 : Math.round((countId / visitor) * 1000) / 10);
        comparisons.push([countId, totCountId]);
        statuses.push(data[i].lead_status);
      }

      // calculate disqualified rate

      const disqualified = data.find((d) => d.lead_status === "disqualified_lead");
      const qualified = data.find((d) => d.lead_status === "qualified_lead");
      const dummyDisqualified = isDemandIQ ? dummy_data["disqualified_lead"] : 0;
      const dummyQualifed = isDemandIQ ? dummy_data["qualified_lead"] : 0;
      const disqualifiedCount = disqualified.count_id + dummyDisqualified;
      const qualifiedCount = qualified.count_id + dummyQualifed;
      const totalCount = disqualifiedCount + qualifiedCount;
      const disqualifiedRate = totalCount > 0 ? parseFloat(((disqualifiedCount / totalCount) * 100).toFixed(2)) : 0;

      setSonicData({
        ...sonicData,
        chart: {
          labels,
          values,
          comparisons,
          statuses,
        },
        disqualifiedRate,
        minDate: parseISO(isDemandIQ ? DUMMY_START_DATE : date_range.start_at),
        maxDate: parseISO(date_range.end_at),
      });
    }
  };

  const getStella2Locations = async () => {
    // if (clientState.current.company_name === "Demand IQ") {
    //   setStella2Filter({
    //     ...stella2Filter,
    //     locations: [...defaultLocations],
    //     currentLocation: "all",
    //   });

    //   return;
    // }

    const res = await apiGetStatesForReport({
      start_at: formatStartDate(stella2Filter.startDate),
      end_at: formatEndDate(stella2Filter.endDate),
      solar_company: clientState.currentId,
      product: "stella2",
    });

    if (res.status === 200) {
      const locations = Object.values(res.data.states).map((d) => ({
        value: d.code,
        label: d.name,
      }));

      setStella2Filter({
        ...stella2Filter,
        locations: [...defaultLocations, ...locations],
        currentLocation: "all",
      });
    }
  };

  const getLeadStella2Data = async (leadStatusLabels) => {
    const params = {};
    params.start_at = formatStartDate(stella2Filter.startDate);
    params.end_at = formatEndDate(stella2Filter.endDate);
    params.solar_company = clientState.currentId;
    params.is_instant_estimate = false;
    params.product = "stella2";

    if (stella2Filter.currentLocation !== "all") {
      params.state = stella2Filter.currentLocation;
    }

    const res = await apiGetLeadStatusReport(params);

    if (res.status === 200) {
      const { data, date_range, total_data } = res.data;

      // extract chart data
      let visitor = 0;
      let labels = [];
      let values = [];
      let comparisons = [];
      let statuses = [];

      // const isDemandIQ = clientState.current.company_name === "Demand IQ";
      const isDemandIQ = false;

      const dummy_data = isDemandIQ && getDummyValue({ startAt: params.start_at, endAt: params.end_at }),
        dummy_total_data =
          isDemandIQ &&
          getDummyValue({
            startAt: date_range.start_at,
            endAt: date_range.end_at,
            isTotal: true,
          });

      for (let i = 0; i < data.length; i++) {
        const lead_status = data[i].lead_status;

        const countId = data[i].count_id + (isDemandIQ ? dummy_data[lead_status] : 0),
          totCountId = total_data[i].count_id + (isDemandIQ ? dummy_total_data[lead_status] : 0);

        if (data[i].lead_status === "visitor") {
          visitor = countId;
        }
        if (data[i].lead_status === "contact" || data[i].lead_status === "disqualified_lead") {
          continue;
        }
        labels.push(leadStatusLabels[data[i].lead_status]);
        values.push(visitor === 0 ? 0 : Math.round((countId / visitor) * 1000) / 10);
        comparisons.push([countId, totCountId]);
        statuses.push(data[i].lead_status);
      }

      // calculate disqualified rate
      const disqualified = data.find((d) => d.lead_status === "disqualified_lead");
      const qualified = data.find((d) => d.lead_status === "qualified_lead");
      const dummyDisqualified = isDemandIQ ? dummy_data["disqualified_lead"] : 0;
      const dummyQualifed = isDemandIQ ? dummy_data["qualified_lead"] : 0;
      const disqualifiedCount = disqualified.count_id + dummyDisqualified;
      const qualifiedCount = qualified.count_id + dummyQualifed;
      const totalCount = disqualifiedCount + qualifiedCount;
      const disqualifiedRate = totalCount > 0 ? parseFloat(((disqualifiedCount / totalCount) * 100).toFixed(2)) : 0;

      setStella2Data({
        ...stella2Data,
        chart: {
          labels,
          values,
          comparisons,
          statuses,
        },
        disqualifiedRate,
        minDate: parseISO(isDemandIQ ? DUMMY_START_DATE : date_range.start_at),
        maxDate: parseISO(date_range.end_at),
      });
    }
  };

  return {
    ...reportingClientState,
    main: {
      filter: mainFilter,
      data: mainData,
      setFilter: (d) =>
        setMainFilter({
          ...mainFilter,
          ...d,
        }),
    },
    conversion: {
      filter: conversionFilter,
      data: conversionData,
      setFilter: (d) =>
        setConversionFilter({
          ...conversionFilter,
          ...d,
        }),
    },
    sonic: {
      filter: sonicFilter,
      data: sonicData,
      setFilter: (d) =>
        setSonicFilter({
          ...sonicFilter,
          ...d,
        }),
    },
    stella2: {
      filter: stella2Filter,
      data: stella2Data,
      setFilter: (d) =>
        setStella2Filter({
          ...stella2Filter,
          ...d,
        }),
    },
    init,
    setClient,
    formatStartDate,
    formatEndDate,
    getLocations,
    getLeadMainData,
    getLeadConversionData,
    getSonicLocations,
    getLeadSonicData,
    getStella2Locations,
    getLeadStella2Data,
  };
};

export default useReporting;

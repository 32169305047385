import { apiGetMergePDF } from "store/api";

export const formatInvoicePeriod = (startDate, endDate = null) => {
  const start = new Date(startDate * 1000);
  const end = new Date(endDate * 1000);

  if (startDate && endDate) {
    const start_ye = new Intl.DateTimeFormat("en", {
      year: "numeric",
    }).format(start);

    const end_ye = new Intl.DateTimeFormat("en", {
      year: "numeric",
    }).format(end);

    const start_mo = new Intl.DateTimeFormat("en", { month: "short" }).format(start);
    const end_mo = new Intl.DateTimeFormat("en", { month: "short" }).format(end);
    const start_da = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(start);
    const end_da = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(end);

    return start_mo + " " + start_da + ", " + start_ye + " - " + end_mo + " " + end_da + ", " + end_ye;
  }
  if (startDate && !endDate) {
    const start_mo = new Intl.DateTimeFormat("en", { month: "short" }).format(start);
    const start_da = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(start);
    return start_mo + " " + start_da;
  }
};

export const toUSD = (balance) => {
  if (balance >= 0) {
    return `$${(balance / 100).toFixed(2)}`;
  } else {
    return `-$${(-balance / 100).toFixed(2)}`;
  }
};

export const downloadInvoice = async (url, customer) => {
  let urls = [];
  if (typeof url === "string") {
    urls.push(url);
  } else {
    urls = [];
  }
  const res = await apiGetMergePDF({
    customer,
    urls,
  });
  if (res.status === 200) {
    const blob = new Blob([res.data], { type: "application/pdf" });
    const url = window.URL.createObjectURL(blob);
    let link = document.createElement("a");
    link.href = url;
    link.download = "invoices.pdf";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
};

import React from "react";
import { Box, TextField } from "@mui/material";
import useQuickSetupWizard from "hooks/useQuickSetupWizard";

const ChangeProductTerm = () => {
  const setupWizard = useQuickSetupWizard();
  const [productTerm, setProductTerm] = React.useState("");
  const [error, setError] = React.useState("");

  React.useEffect(() => {
    setProductTerm(setupWizard.client.product_term || "");
    // eslint-disable-next-line
  }, []);

  React.useEffect(() => {
    setError(setupWizard.errors.product_term);
    // eslint-disable-next-line
  }, [setupWizard.errors]);

  const handleTermChange = (e) => {
    if (e.target.value === "") {
      setError("required");
      setProductTerm(e.target.value);
      setupWizard.update("product_term", "required");
    } else {
      if (parseFloat(e.target.value) >= 0) {
        setError("");
        setProductTerm(e.target.value);
        setupWizard.update("product_term", e.target.value);
      }
    }
  };

  return (
    <Box>
      <Box my={2}>Product Term</Box>
      {error && (
        <Box sx={{ color: "error.main" }}>
          {error === "required" && "Required"}
          {error !== "required" && error}
        </Box>
      )}
      <TextField type="number" value={productTerm} onChange={handleTermChange} />
    </Box>
  );
};

export default ChangeProductTerm;

import React from "react";
import { TextField, Box } from "@mui/material";
import { useController } from "react-hook-form";

function FormText({
  control,
  name,
  rules,
  label = "",
  defaultValue = "",
  required = false,
  variant = "outlined",
  fullWidth = true,
  autoComplete = "off",
  ...props
}) {
  const {
    field: { ref, ...inputProps },
    fieldState: { invalid, error },
  } = useController({
    name,
    control,
    rules: {
      ...rules,
      required,
    },
    defaultValue,
  });

  return (
    <TextField
      fullWidth={fullWidth}
      name={name}
      label={<Box sx={{ textTransform: "capitalize" }}>{label ? label : name}</Box>}
      variant={variant}
      inputRef={ref}
      error={invalid}
      helperText={error?.message || ""}
      autoComplete={autoComplete}
      {...inputProps}
      {...props}
    />
  );
}

export default FormText;

import React from "react";
import { Box } from "@mui/material";
import useExperimentsEditor from "hooks/useExperimentsEditor";

const EditorForm = ({ label, required = [], children }) => {
  const hEditor = useExperimentsEditor();

  React.useEffect(() => {
    let errors = {};
    for (const key of required) {
      if (!hEditor.values[key]) {
        errors = {
          ...errors,
          [key]: "Required",
        };
      }
    }
    if (Object.keys(errors).length > 0) {
      hEditor.setValues({}, errors);
    }
    // eslint-disable-next-line
  }, []);

  return (
    <Box>
      {label && (
        <Box mt={2} sx={{ color: "#0008", textTransform: "uppercase" }}>
          {label}
        </Box>
      )}
      {children}
    </Box>
  );
};

export default EditorForm;

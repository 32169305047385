import { TextField } from "@mui/material";
import useClientCreateWizard from "hooks/useClientCreateWizard";

const WizardText = ({ name, label, ...props }) => {
  const wizard = useClientCreateWizard();

  const handleChange = (e) => {
    wizard.update(name, e.target.value);
  };

  return (
    <TextField
      label={label}
      value={wizard.data[name] || ""}
      onChange={handleChange}
      helperText={wizard.errors[name] || ""}
      error={!!wizard.errors[name]}
      {...props}
    />
  );
};

export default WizardText;

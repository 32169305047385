import React from "react";
import { Stack, Box } from "@mui/material";
import { apiGetClientDns, apiValidateClientDns } from "store/api";
import useConfigurator from "hooks/useConfigurator";

import FormDomainName from "./FormDomainName";
import FormEntries from "./FormEntries";
import FormVerify from "./FormVerify";
import DnsRecordTable from "./DnsRecordTable";

const CustomDomain = () => {
  const mounted = React.useRef(true);
  const hConfigurator = useConfigurator();
  const [loading, setLoading] = React.useState("");
  const [emailDomain, setEmailDomain] = React.useState("");
  const [dnsRecords, setDnsRecords] = React.useState({});
  const [dnsValidations, setDnsValidations] = React.useState({});

  React.useEffect(() => {
    if (hConfigurator.client.custom_domain) {
      getDnsInformation();
    }
    return () => {
      mounted.current = false;
    };
    // eslint-disable-next-line
  }, []);

  const getDnsInformation = async () => {
    setLoading("refreshing");
    const res = await Promise.all([
      apiGetClientDns(hConfigurator.client.id)(),
      apiValidateClientDns(hConfigurator.client.id)(),
    ]);
    setLoading("");
    if (mounted.current) {
      if (res[0].status === 200 && res[1].status === 200) {
        setEmailDomain(res[0].data.email_authentication.domain);
        setDnsRecords({
          ...res[0].data.custom_domain.dns,
          ...res[0].data.email_authentication.dns,
        });
        setDnsValidations({
          ...res[1].data.custom_domain.validation_results,
          ...res[1].data.email_authentication.validation_results,
        });
      }
    }
  };

  const handleUpdate = async () => {
    setLoading("refreshing");
    await getDnsInformation();
    setLoading("");
  };

  const handleVerify = async () => {
    setLoading("verifying");
    const res = await apiValidateClientDns(hConfigurator.client.id)();
    if (mounted.current) {
      if (res.status === 200) {
        setDnsValidations({
          ...res.data.custom_domain.validation_results,
          ...res.data.email_authentication.validation_results,
        });
      }
      setLoading("");
    }
  };

  return (
    <Box p={4}>
      <Box mb={4} sx={{ fontSize: 20, fontWeight: "bold" }}>
        Custom Stella URL
      </Box>
      <Stack spacing={4}>
        <FormDomainName loading={loading} setLoading={setLoading} onUpdate={handleUpdate} />
        <FormEntries loading={loading} />
        <FormVerify loading={loading} setLoading={setLoading} onVerifyClick={handleVerify} />
        <DnsRecordTable
          loading={loading}
          emailDomain={emailDomain}
          dnsRecords={dnsRecords}
          dnsValidations={dnsValidations}
        />
      </Stack>
    </Box>
  );
};

export default CustomDomain;

import React from "react";
import { Dialog, Stack, Box, Button } from "@mui/material";

const DialogRemoveAsk = ({ open, closeDialog, onConfirm }) => {
  return (
    <Box>
      <Dialog
        open={open}
        onClose={closeDialog}
        PaperProps={{
          sx: { width: "100%" },
        }}
      >
        <Stack p={4} spacing={4}>
          <Box>Would you like to remove domain?</Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              gap: 2,
            }}
          >
            <Button variant="outlined" onClick={onConfirm}>
              YES
            </Button>
            <Button variant="outlined" onClick={closeDialog}>
              NO
            </Button>
          </Box>
        </Stack>
      </Dialog>
    </Box>
  );
};

export default DialogRemoveAsk;

import React from "react";
import { useRecoilState } from "recoil";
import { useLocation, useSearchParams } from "react-router-dom";
import { authAtom } from "store/auth";
import { clientAtom } from "store/client";

const RouterChangeDetector = (props) => {
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const [authState] = useRecoilState(authAtom);
  const [clientState] = useRecoilState(clientAtom);

  React.useEffect(() => {
    if (authState.user && clientState.current) {
      const user_name = `${authState.user.first_name}+${authState.user.last_name}`;
      const stoken = btoa(
        `company_id=${clientState.currentId}\ncompany_name=${clientState.current.company_name}\nuser_id=${authState.user.id}\nuser_name=${user_name}`
      );
      searchParams.set("stoken", stoken);
      setSearchParams(searchParams);
    }
  }, [location.pathname, authState, clientState]);

  return <>{props.children}</>;
};

export default RouterChangeDetector;

import React from "react";
import { useForm } from "react-hook-form";
import { format, parseISO } from "date-fns";
import { Stack, MenuItem, TextField } from "@mui/material";
import { LoadingButton, LocalizationProvider, DatePicker } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import useAlert from "hooks/useAlert";
import { apiUpdateClient } from "store/api";
import FormText from "shared/FormText";

const FeeTab = ({ client, updateClient }) => {
  const hAlert = useAlert();
  const mounted = React.useRef(true);
  const [loading, setLoading] = React.useState(false);
  const [contractSigned, setContractSigned] = React.useState(parseISO(client.date_contract_signed));
  const { control, handleSubmit } = useForm({
    defaultValues: {
      level: client.level,
      date_contract_signed: client.date_contract_signed,
      minimum_monthly_fee: client.minimum_monthly_fee,
      cost_per_lead: client.cost_per_lead,
      cost_per_appointment: client.cost_per_appointment,
      cost_per_appointment_with_bill: client.cost_per_appointment_with_bill,
      cost_per_estimate_viewer: client.cost_per_estimate_viewer,
    },
  });

  React.useEffect(() => {
    return () => {
      mounted.current = false;
    };
    // eslint-disable-next-line
  }, []);

  const handleContractSignedChange = (d) => {
    setContractSigned(d);
  };

  const onSubmit = async (d) => {
    setLoading(true);
    const {
      level,
      minimum_monthly_fee,
      cost_per_lead,
      cost_per_appointment,
      cost_per_appointment_with_bill,
      cost_per_estimate_viewer,
    } = d;
    const formData = new FormData();
    formData.append("level", level);
    formData.append("date_contract_signed", format(contractSigned, "yyyy-MM-dd"));
    formData.append("minimum_monthly_fee", minimum_monthly_fee);
    formData.append("cost_per_lead", cost_per_lead);
    formData.append("cost_per_appointment", cost_per_appointment);
    formData.append("cost_per_appointment_with_bill", cost_per_appointment_with_bill);
    formData.append("cost_per_estimate_viewer", cost_per_estimate_viewer);
    const res = await apiUpdateClient(client.id)(formData);
    if (res.status === 200) {
      updateClient(res.data);
      hAlert.show("Client saved successfully!");
    }
    if (mounted.current) {
      setLoading(false);
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack p={4} spacing={4}>
          <FormText select required disabled={loading} control={control} name="level" label="Subscription Plan *">
            <MenuItem value="starter">Starter </MenuItem>
            <MenuItem value="premier">Premier</MenuItem>
            <MenuItem value="pro">Pro</MenuItem>
            <MenuItem value="enterprise">Enterprise</MenuItem>
            <MenuItem value="self-service">Self Service</MenuItem>
          </FormText>

          <DatePicker
            label="Date contract signed"
            disabled={loading}
            value={contractSigned}
            onChange={handleContractSignedChange}
            renderInput={(params) => <TextField {...params} />}
          />

          <FormText
            required
            type="number"
            disabled={loading}
            control={control}
            name="minimum_monthly_fee"
            label="Minimum monthly fee *"
          />

          <FormText
            required
            type="number"
            disabled={loading}
            control={control}
            name="cost_per_lead"
            label="Cost per Lead *"
          />

          <FormText
            required
            type="number"
            disabled={loading}
            control={control}
            name="cost_per_appointment"
            label="Cost per Appointment *"
          />

          <FormText
            required
            type="number"
            disabled={loading}
            control={control}
            name="cost_per_appointment_with_bill"
            label="Cost per Utility Bill *"
          />

          <FormText
            type="number"
            disabled={loading}
            control={control}
            name="cost_per_estimate_viewer"
            label="Cost per Estimate Viewer"
          />

          <LoadingButton loading={loading} variant="contained" type="submit">
            SAVE CLIENT
          </LoadingButton>
        </Stack>
      </form>
    </LocalizationProvider>
  );
};

export default FeeTab;

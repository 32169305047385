import React from "react";
import { sortableElement } from "react-sortable-hoc";
import SingleChoiceBox from "./components/SingleChoiceBox";
import OpenEndedBox from "./components/OpenEndedBox";
import { OPEN_ENDED_QN, SINGLE_CHOICE_QN } from "utils/constant";

const Main = sortableElement(({ value: question, deleteQuestion, openEdit }) => {
  let QuestionBox;

  switch (question.question_type) {
    case OPEN_ENDED_QN:
      QuestionBox = OpenEndedBox;
      break;
    case SINGLE_CHOICE_QN:
    default:
      QuestionBox = SingleChoiceBox;
  }

  return <QuestionBox value={question} deleteQuestion={deleteQuestion} openEdit={openEdit} />;
});

export default Main;

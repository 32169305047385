import React from "react";
import { FormControlLabel, Checkbox } from "@mui/material";
import { useController } from "react-hook-form";

function FormCheck({ classes, control, name, label = "", defaultValue = false, required = false, ...props }) {
  const {
    field: { ref, value, ...inputProps },
  } = useController({
    name,
    control,
    rules: {
      required,
    },
    defaultValue,
  });

  return (
    <FormControlLabel
      classes={classes}
      control={<Checkbox checked={value} inputRef={ref} {...inputProps} {...props} />}
      label={label}
    />
  );
}

export default FormCheck;

import React from "react";
import { Scrollbars } from "react-custom-scrollbars";
import { Box, Button, Table, TableHead, TableBody, TableRow, TableCell, CircularProgress, Link } from "@mui/material";
import { apiGetCustomerDetails, apiGetBalanceTransactions } from "store/api";
import AdjustBalance from "./AdjustBalance";

const toUSD = (balance) => {
  if (balance >= 0) {
    return `$${(balance / 100).toFixed(2)}`;
  } else {
    return `-$${(-balance / 100).toFixed(2)}`;
  }
};

const CreditTab = ({ client }) => {
  const mounted = React.useRef(true);
  const [loading, setLoading] = React.useState(true);
  const [balance, setBalance] = React.useState(0);
  const [transactions, setTransactions] = React.useState([]);

  React.useEffect(() => {
    load();
    return () => {
      mounted.current = false;
    };
    // eslint-disable-next-line
  }, []);

  const load = async () => {
    setLoading(true);
    const res = await Promise.all([
      apiGetCustomerDetails({ customer: client.customer_id }),
      apiGetBalanceTransactions({ customer: client.customer_id }),
    ]);
    if (mounted.current) {
      if (res[0].status === 200) {
        setBalance(res[0].data.balance);
      }
      if (res[1].status === 200) {
        setTransactions(res[1].data.data);
      }
      setLoading(false);
    }
  };

  const handleAdjust = () => {
    load();
  };

  if (loading) {
    return (
      <Box p={4}>
        <CircularProgress size={20} />
      </Box>
    );
  }

  return (
    <Box sx={{ height: 1 }}>
      <Box p={2} sx={{ height: 100, display: "flex", alignItems: "center" }}>
        <Box sx={{ fontSize: 32, flexGrow: 1 }}>Balance: {toUSD(balance)}</Box>
        <AdjustBalance client={client} balance={balance} onAdjust={handleAdjust} />
      </Box>
      <Box sx={{ height: "calc(100% - 100px)" }}>
        <Scrollbars>
          <Table stickyHeader sx={{ "& th": { whiteSpace: "nowrap" } }}>
            <TableHead>
              <TableRow>
                <TableCell>Amount</TableCell>
                <TableCell>Created At</TableCell>
                <TableCell>Description</TableCell>
                <TableCell>Ending Balance</TableCell>
                <TableCell>Invoice</TableCell>
                <TableCell>Type</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {transactions.map((d) => (
                <TableRow key={d.id}>
                  <TableCell>{toUSD(d.amount)}</TableCell>
                  <TableCell>{new Date(d.created * 1000).toLocaleString()}</TableCell>
                  <TableCell>{d.description}</TableCell>
                  <TableCell>{toUSD(d.ending_balance)}</TableCell>
                  <TableCell>
                    {d.invoice && (
                      <Button
                        component={Link}
                        href={`https://dashboard.stripe.com${
                          process.env.REACT_APP_TRACKING_ENV === "production" ? "" : "/test"
                        }/invoices/${d.invoice}`}
                        target="_blank"
                        rel="noreferrer"
                        color="primary"
                      >
                        {d.invoice}
                      </Button>
                    )}
                  </TableCell>
                  <TableCell>{d.type}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Scrollbars>
      </Box>
    </Box>
  );
};

export default CreditTab;

import React from "react";
import { Box } from "@mui/material";
import useExperimentsCreator from "hooks/useExperimentsCreator";
import RadioInput from "pages/products/configurator/components/banner-config/shared/RadioInput";

const types = [
  {
    value: "funnel",
    label: "Funnel",
  },
  {
    value: "banner",
    label: "Banner",
  },
];

const TypeForm = () => {
  const hCreator = useExperimentsCreator();

  const handleChange = (_, value) => {
    hCreator.setType(value);
  };

  return (
    <Box>
      <Box mb={2}>Select type of experiment</Box>

      <Box sx={{ display: "flex" }}>
        <Box sx={{ flexGrow: 1 }}>
          <RadioInput legend="" row options={types} value={hCreator.type || "funnel"} onChange={handleChange} />
        </Box>

        <Box sx={{ width: 400 }} />
      </Box>
    </Box>
  );
};

export default TypeForm;

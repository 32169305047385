import React from "react";
import { Box, TextField, MenuItem } from "@mui/material";
import useQuickSetupWizard from "hooks/useQuickSetupWizard";

const AppointmentLeadTime = () => {
  const hWizard = useQuickSetupWizard();
  const [timeBuffer, setTimeBuffer] = React.useState("");
  const [error, setError] = React.useState("");

  React.useEffect(() => {
    setTimeBuffer(hWizard.client.appointment_time_buffer || "");
    // eslint-disable-next-line
  }, []);

  React.useEffect(() => {
    setError(hWizard.errors.appointment_time_buffer);
    // eslint-disable-next-line
  }, [hWizard.errors]);

  const handleTimeBufferChange = (e) => {
    if (e.target.value === "0") {
      setError("required");
      setTimeBuffer("required");
      hWizard.update("appointment_time_buffer", "required");
    } else {
      setError("");
      setTimeBuffer(parseInt(e.target.value, 10));
      hWizard.update("appointment_time_buffer", parseInt(e.target.value, 10));
    }
  };

  return (
    <Box my={2}>
      <Box sx={{ mb: 2, fontSize: 12 }}>
        Please select how much lead time you need for customers to schedule appointments.
      </Box>
      {error && (
        <Box sx={{ color: "error.main" }}>
          {error === "required" && "Required"}
          {error !== "required" && error}
        </Box>
      )}
      <Box>
        <TextField select sx={{ width: 280 }} value={timeBuffer} onChange={handleTimeBufferChange}>
          <MenuItem value={0}>None</MenuItem>
          <MenuItem value={1}>1 hr</MenuItem>
          <MenuItem value={3}>3 hrs</MenuItem>
          <MenuItem value={6}>6 hrs</MenuItem>
          <MenuItem value={12}>12hrs</MenuItem>
          <MenuItem value={24}>24hrs</MenuItem>
          <MenuItem value={36}>36hrs</MenuItem>
          <MenuItem value={48}>48hrs</MenuItem>
          <MenuItem value={60}>60hrs</MenuItem>
          <MenuItem value={72}>72hrs</MenuItem>
          <MenuItem value={120}>5 days</MenuItem>
          <MenuItem value={168}>7 days</MenuItem>
          <MenuItem value={240}>10 days</MenuItem>
        </TextField>
      </Box>
    </Box>
  );
};

export default AppointmentLeadTime;

import React from "react";
import { Box, Button, CircularProgress, Table, TableBody, TableRow, TableCell, IconButton } from "@mui/material";
import useCommon from "hooks/useCommon";
import { apiGetInvoices, apiGetMergePDF } from "store/api";
import { DownloadIcon } from "utils/icons";

const formatPeriod = (startDate, endDate = null) => {
  const start = new Date(startDate * 1000);
  const end = new Date(endDate * 1000);

  if (startDate && endDate) {
    const start_ye = new Intl.DateTimeFormat("en", {
      year: "numeric",
    }).format(start);

    const end_ye = new Intl.DateTimeFormat("en", {
      year: "numeric",
    }).format(end);

    const start_mo = new Intl.DateTimeFormat("en", { month: "short" }).format(start);
    const end_mo = new Intl.DateTimeFormat("en", { month: "short" }).format(end);
    const start_da = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(start);
    const end_da = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(end);

    return start_mo + " " + start_da + ", " + start_ye + " - " + end_mo + " " + end_da + ", " + end_ye;
  }
  if (startDate && !endDate) {
    const start_mo = new Intl.DateTimeFormat("en", { month: "short" }).format(start);
    const start_da = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(start);
    return start_mo + " " + start_da;
  }
};

const InvoiceTab = ({ client }) => {
  const hCommon = useCommon();
  const mounted = React.useRef(true);
  const [loading, setLoading] = React.useState(true);
  const [invoices, setInvoices] = React.useState(true);

  React.useEffect(() => {
    const load = async () => {
      setLoading(true);
      const res = await apiGetInvoices({
        customer: client.customer_id,
      });
      if (mounted) {
        if (res.status === 200 && res.data.invoices) {
          const totalInvoices = res.data.invoices.data.filter((d) => d.paid).sort((a, b) => b.created - a.created);
          setInvoices(totalInvoices.slice(0, 3));
        }
        setLoading(false);
      }
    };
    load();
    return () => {
      mounted.current = false;
    };
    // eslint-disable-next-line
  }, []);

  const downloadAll = async (url) => {
    hCommon.loading("Downloading Invoice ...");
    let urls = [];
    if (typeof url === "string") {
      urls.push(url);
    } else {
      urls = [];
    }
    const res = await apiGetMergePDF({
      customer: client.customer_id,
      urls,
    });
    if (res.status === 200) {
      const blob = new Blob([res.data], { type: "application/pdf" });
      const url = window.URL.createObjectURL(blob);
      let link = document.createElement("a");
      link.href = url;
      link.download = "invoices.pdf";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
    hCommon.loading("");
  };

  if (loading) {
    return (
      <Box p={4}>
        <CircularProgress size={20} />
      </Box>
    );
  }

  return (
    <Box p={4}>
      <Box mb={4} sx={{ display: "flex" }}>
        <Box sx={{ fontSize: 20, flexGrow: 1 }}>Invoices</Box>
        <Button disabled={invoices.length === 0} variant="outlined">
          Download All
        </Button>
      </Box>
      {invoices.length > 0 && (
        <Box sx={{ border: "1px solid #ddd", borderBottom: "none" }}>
          <Box p={2} sx={{ backgroundColor: "#f2f2f2" }}>
            Last Invoices
          </Box>
          <Table>
            <TableBody>
              {invoices.map((d) => (
                <TableRow key={d.id}>
                  <TableCell>{formatPeriod(d.start_at, d.end_at)}</TableCell>
                  <TableCell>${d.amount / 100}</TableCell>
                  <TableCell width={30}>
                    <IconButton onClick={() => downloadAll(d.receipt_url)} size="small">
                      <DownloadIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      )}
    </Box>
  );
};

export default InvoiceTab;

import React, { useState } from "react";
import { Box, Button, CircularProgress, Grid, TextField, IconButton, InputAdornment } from "@mui/material";
import { apiValidateSunlight } from "store/api";
import useAlert from "hooks/useAlert";
import { EyeIcon, EyeInvisibleIcon } from "utils/icons";

function Sunlight({ values, onChange }) {
  const hAlert = useAlert();

  const [valid, setValid] = useState(false);
  const [validating, setValidating] = useState(false);
  const [mounted, setMounted] = useState(false);
  const [showPassword, setShowPassword] = React.useState(false);

  React.useEffect(() => {
    if (mounted || !values) return;
    setMounted(true);
    validate(values);
  }, []);

  const validate = async (values) => {
    setValidating(true);

    try {
      const res = await apiValidateSunlight()(values);
      if (res.status !== 200) {
        throw new Error("Validation Failed");
      }

      setValid(true);
    } catch (error) {
      console.log(error);

      hAlert.show("Validation Failed", false);
      setValid(false);
    }

    setValidating(false);
  };

  const handleChange = (key) => (e) => {
    onChange(key, e.target.value);
    setValid(false);
  };

  const handleValidate = () => {
    validate(values);
  };

  return (
    <>
      <AboutSunlight />
      <Box sx={{ display: "flex", flexDirection: "row", my: 4, gap: "64px" }}>
        <Box sx={{ maxWidth: "500px" }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label={"Username"}
                value={values?.["sunlight_username"] ?? ""}
                onChange={handleChange("sunlight_username")}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label={"Email"}
                type="email"
                value={values?.["sunlight_email"] ?? ""}
                onChange={handleChange("sunlight_email")}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label={"Password"}
                value={values?.["sunlight_password"] ?? ""}
                onChange={handleChange("sunlight_password")}
                type={showPassword ? "text" : "password"}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={() => setShowPassword(!showPassword)}>
                        {showPassword ? (
                          <EyeIcon style={{ fontSize: 24, color: "black" }} />
                        ) : (
                          <EyeInvisibleIcon style={{ fontSize: 24, color: "black" }} />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>
        </Box>
        <Box sx={{ flex: 1, display: "flex", justifyContent: "center", alignItems: "center" }}>
          {valid ? (
            <Box
              sx={{
                fontSize: "48px",
                fontWeight: 600,
                textTransform: "uppercase",
                letterSpacing: "2px",
                color: "#2f8a12",
                mb: "36px",
                fontFamily: "system-ui",
              }}
            >
              Validated
            </Box>
          ) : (
            <Box
              sx={{
                color: "#666",
                fontSize: "21px",
                letterSpacing: "0.2px",
                lineHeight: 1.5,
                m: "0px auto 12px",
                maxWidth: "600px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                fontFamily: "system-ui",
              }}
            >
              Pre-qualification will not be shown to user until authentication keys are validated successfully
              <br />
              <Button variant="contained" sx={{ mt: "24px" }} disabled={validating} onClick={() => handleValidate()}>
                Validate
                {validating && <CircularProgress size={16} sx={{ ml: "8px" }} />}
              </Button>
            </Box>
          )}
        </Box>
      </Box>
    </>
  );
}

function AboutSunlight() {
  return (
    <Box sx={{ display: "flex", alignItems: "center", flexDirection: "column" }}>
      <Box height="120px" sx={{ display: "flex", alignItems: "center", mt: { xs: 4, lg: 2, xl: -2 } }}>
        <img src="/images/sunlight.png" alt="sunlight" />
      </Box>
      <Box sx={{ py: 2, fontSize: 16, lineHeight: 1.8 }}>
        Sunlight Financial enables homeowners to pre-qualify for frictionless financing while getting their instant
        solar estimate within Stella’s user interface. Pre-qualifying for financing will ensure homeowners have a
        clearer understanding of whether they qualify for a solar loan, so that they can shop with confidence. With over
        235,000 solar systems funded, Sunlight Financial is a financing partner that solar providers, and homeowners
        alike, can trust.
        <br />
        <br />
        <Box component="a" href="https://sunlightfinancial.com/demand-iq/" target="_blank" sx={{ color: "#15ABE6" }}>
          Reach out
        </Box>{" "}
        to Sunlight Financial to enable pre-qualification by becoming a certified installer.
        <br />
        <br />
        Already a Sunlight Financial partner? Contact your Sunlight Financial account manager for your API key to enable
        pre-qualification access through Demand IQ. Enter your key below along with the email address associated with
        your Sunlight Financial account.
      </Box>
    </Box>
  );
}

export default Sunlight;

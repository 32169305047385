import React from "react";
import { useRecoilState } from "recoil";
import { Box, Paper, Grid, Button, Dialog } from "@mui/material";

const EarnRewardsPage = () => {
  const [reviewLink, setReviewLink] = React.useState("");

  const handleGoogleReview = () => {
    setReviewLink("https://g.page/Demand-IQ/review?rc");
  };

  const handleFacebookReview = () => {
    setReviewLink("https://www.facebook.com/demand.iq2/");
  };

  const handleTrustpilotReview = () => {
    setReviewLink("https://www.trustpilot.com/evaluate/demand-iq.com");
  };

  return (
    <Box p={4}>
      <Box sx={{ fontSize: 24, color: "primary.main" }}>Earn Credits</Box>

      <Box my={4} sx={{ textAlign: "center" }}>
        <Box component="img" src="/images/icon-like.png" alt="Heart" sx={{ width: 120 }} />
      </Box>

      <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
        <Box ml={2} sx={{ fontSize: 28, fontWeight: 500 }}>
          Earn Free Credits
        </Box>
      </Box>

      <Box my={4} sx={{ display: "flex", justifyContent: "center" }}>
        <Box sx={{ textAlign: "center", maxWidth: 420, fontSize: 18 }}>
          Earn as much as you want. Unused bonus credits roll over month to month and never expire.
        </Box>
      </Box>

      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Paper>
            <Box p={4}>
              <Box sx={{ fontSize: 24 }}>Refer your friends</Box>
              <Box sx={{ fontSize: 20, color: "success.light" }}>+$200 credit value</Box>
              <Box mt={4}>Refer your friends and get credits plus a YETI cooler</Box>
              <Box mt={4} sx={{ textAlign: "right" }}>
                <Button component="a" href="https://www.demand-iq.com/referral" target="_blank" variant="contained">
                  REFER NOW
                </Button>
              </Box>
            </Box>
          </Paper>
        </Grid>

        <Grid item xs={12} md={4}>
          <Paper>
            <Box p={4}>
              <Box sx={{ fontSize: 24 }}>Google Review</Box>
              <Box sx={{ fontSize: 20, color: "success.light" }}>+$100 credit value</Box>
              <Box mt={4}>
                Get $100 worth of credits by writing a review of Demand IQ on Google. Once it is verified by a team
                member we will add the credits to your account.
              </Box>
              <Box mt={2} sx={{ fontSize: 14 }}>
                * While we appreciate multiple reviews, credits are limited to 1 review, per network per account
              </Box>
              <Box mt={4}>
                <Button variant="contained" onClick={handleGoogleReview}>
                  CLAIM REWARD
                </Button>
              </Box>
            </Box>
          </Paper>
        </Grid>

        <Grid item xs={12} md={4}>
          <Paper>
            <Box p={4}>
              <Box sx={{ fontSize: 24 }}>Facebook Review</Box>
              <Box sx={{ fontSize: 20, color: "success.light" }}>+$100 credit value</Box>
              <Box mt={4}>
                Get $100 worth of credits by writing a review of Demand IQ on Facebook. Once it is verified by a team
                member we will add the credits to your account.
              </Box>
              <Box mt={2} sx={{ fontSize: 14 }}>
                * While we appreciate multiple reviews, credits are limited to 1 review, per network per account
              </Box>
              <Box mt={4}>
                <Button variant="contained" onClick={handleFacebookReview}>
                  CLAIM REWARD
                </Button>
              </Box>
            </Box>
          </Paper>
        </Grid>

        <Grid item xs={12} md={4}>
          <Paper>
            <Box p={4}>
              <Box sx={{ fontSize: 24 }}>Trustpilot Review</Box>
              <Box sx={{ fontSize: 20, color: "success.light" }}>+$100 credit value</Box>
              <Box mt={4}>
                Get $100 worth of credits by writing a review of Demand IQ on Trustpilot. Once it is verified by a team
                member we will add the credits to your account.
              </Box>
              <Box mt={2} sx={{ fontSize: 14 }}>
                * While we appreciate multiple reviews, credits are limited to 1 review, per network per account
              </Box>
              <Box mt={4}>
                <Button variant="contained" onClick={handleTrustpilotReview}>
                  CLAIM REWARD
                </Button>
              </Box>
            </Box>
          </Paper>
        </Grid>
      </Grid>

      <Dialog
        open={Boolean(reviewLink)}
        onClose={() => setReviewLink("")}
        maxWidth="md"
        PaperProps={{ sx: { width: "100%" } }}
      >
        <Box p={4}>
          <Box sx={{ fontSize: 28 }}>How it works</Box>
          <Box my={8} sx={{ display: "flex", justifyContent: "space-evenly" }}>
            <Box sx={{ width: "30%", display: "flex", flexDirection: "column", alignItems: "center" }}>
              <Box component="img" src="/images/icon-review.png" alt="Share Your Experience" sx={{ height: 90 }} />
              <Box mt={2} sx={{ textAlign: "center" }}>
                Share Your Experience
              </Box>
            </Box>

            <Box sx={{ width: "5%", height: "2px", backgroundColor: "#333", position: "relative", top: 45 }}></Box>

            <Box sx={{ width: "30%", display: "flex", flexDirection: "column", alignItems: "center" }}>
              <Box
                component="img"
                src="/images/icon-screenshot.png"
                alt="Email a Screenshot to Your Account Manager"
                sx={{ height: 90 }}
              />
              <Box mt={2} sx={{ textAlign: "center" }}>
                Email a Screenshot to Your Account Manager
              </Box>
            </Box>

            <Box sx={{ width: "5%", height: "2px", backgroundColor: "#333", position: "relative", top: 45 }}></Box>

            <Box sx={{ width: "30%", display: "flex", flexDirection: "column", alignItems: "center" }}>
              <Box component="img" src="/images/icon-salary.png" alt="Earn Rewards" sx={{ height: 90 }} />
              <Box mt={2} sx={{ textAlign: "center" }}>
                Earn Rewards
              </Box>
            </Box>
          </Box>

          <Box mt={4} sx={{ textAlign: "center" }}>
            <Button component="a" href={reviewLink} target="_blank" variant="contained">
              LET'S DO THIS
            </Button>
          </Box>
        </Box>
      </Dialog>
    </Box>
  );
};

export default EarnRewardsPage;

import React from "react";
import { Box } from "@mui/material";
import useQuickSetupWizard from "hooks/useQuickSetupWizard";
import FileUpload from "shared/wizard/FileUpload";

const UploadBackground = () => {
  const setupWizard = useQuickSetupWizard();
  const [background, setBackground] = React.useState(null);

  React.useEffect(() => {
    setBackground(setupWizard.client.background);
    // eslint-disable-next-line
  }, []);

  const handleBackgroundChange = (file, url) => {
    setBackground(url);
    setupWizard.update("background", file);
  };

  return (
    <Box>
      <FileUpload label="Upload Background" onChange={handleBackgroundChange} />
      {background && (
        <Box mt={4} sx={{ maxWidth: 360 }}>
          <img src={background} alt="background" />
        </Box>
      )}
    </Box>
  );
};

export default UploadBackground;

import React from "react";
import { Stack, Box, Button, IconButton, Tooltip } from "@mui/material";
import { AiOutlineQuestionCircle } from "react-icons/ai";
import useConfigurator from "hooks/useConfigurator";
import DialogEmail from "./DialogEmail";

const SetupDnsEntries = ({ loading }) => {
  const mounted = React.useRef(true);
  const hConfigurator = useConfigurator();
  const [form, setForm] = React.useState(false);

  React.useEffect(() => {
    return () => {
      mounted.current = false;
    };
    // eslint-disable-next-line
  }, []);

  const handleOpen = () => {
    setForm(true);
  };

  return (
    <Box
      sx={{
        display: "flex",
        opacity: hConfigurator.client.custom_domain ? 1 : 0.3,
      }}
    >
      <Box
        sx={{
          width: 40,
          height: 40,
          background: "#666",
          color: "#fff",
          borderRadius: "50%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        2
      </Box>
      <Box ml={2} sx={{ flex: 1 }}>
        <Box sx={{ display: "flex" }}>
          <Box mt={0.5} mr={2} sx={{ fontSize: 20 }}>
            Setup DNS entries
          </Box>
          <Tooltip title="Learn more information" placement="top">
            <Box
              component="a"
              href="https://knowledge.demand-iq.com/update-dns-records-for-demand-iq"
              target="_blank"
              rel="noopener noreferrer"
            >
              <IconButton sx={{ color: "info.main" }}>
                <AiOutlineQuestionCircle />
              </IconButton>
            </Box>
          </Tooltip>
        </Box>
        <Stack mt={1} spacing={2}>
          <Box
            sx={{
              fontSize: 12,
              color: "#888",
            }}
          >
            <Box>
              Specified DNS entries need to be registered to the DNS provider. (usually your domain registrar, e.g.
              GoDaddy)
            </Box>
            <Box
              sx={{
                marginTop: 1,
                "& b": {
                  color: "#333",
                },
              }}
            >
              You can check the required entries on the <b>DNS entries</b> table below.
            </Box>
          </Box>
          {hConfigurator.client.custom_domain && (
            <Box>
              <Button variant="contained" disabled={loading !== ""} onClick={handleOpen}>
                Email My Webmaster
              </Button>
            </Box>
          )}
        </Stack>
      </Box>
      <DialogEmail open={form} closeDialog={() => setForm(false)} />
    </Box>
  );
};

export default SetupDnsEntries;

import React from "react";
import { Box, Checkbox, FormControlLabel } from "@mui/material";
import useConfigurator from "hooks/useConfigurator";

const NewTab = () => {
  const hConfigurator = useConfigurator();
  const [value, setValue] = React.useState(false);

  React.useEffect(() => {
    setValue(hConfigurator.client.banner_is_new_tab);
    // eslint-disable-next-line
  }, []);

  const handleChange = (e) => {
    setValue(e.target.checked);
    hConfigurator.updateSingle("banner_is_new_tab", e.target.checked);
  };

  return (
    <Box>
      <FormControlLabel control={<Checkbox checked={value} onChange={handleChange} />} label="Open in new tab" />
    </Box>
  );
};

export default NewTab;

import React from "react";
import { useSearchParams, Navigate, useNavigate, Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Box, CircularProgress, Stack, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/styles";
import LoginLogo from "shared/LoginLogo";
import FormText from "shared/FormText";
import RoundedButton from "shared/RoundedButton";
import useAlert from "hooks/useAlert";
import { apiCheckInviteKey, apiAcceptInvite } from "store/api";

const Main = ({ showPromotion, onOpenPromotion }) => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

  const [searchParams] = useSearchParams();
  const key = searchParams.get("key");
  const [loading, setLoading] = React.useState(false);
  const { control, handleSubmit, setValue } = useForm();
  const hAlert = useAlert();
  const navigate = useNavigate();

  React.useEffect(() => {
    const checkInvitationKey = async () => {
      setLoading(true);

      const { data } = await apiCheckInviteKey(key);

      const { email, first_name, last_name, phone, status, message } = data;

      if (status === "accepted") {
        hAlert.show(message, false);
        navigate("/login");
      }

      if (status === "expired") {
        hAlert.show(message, false);
        navigate("/login");
      }

      if (status === "joined") {
        hAlert.show(message, true);
        navigate("/login");
      }

      if (message === "An invalid invitation key was submitted.") {
        hAlert.show(message, false);
        navigate("/login");
      }

      setValue("email", email);
      setValue("first_name", first_name);
      setValue("last_name", last_name);
      setValue("phone", phone);

      setLoading(false);
    };

    checkInvitationKey();
  }, [key, setValue]);

  const onSubmit = async (d) => {
    setLoading(true);

    const { first_name, last_name, email, phone, password } = d;

    const formData = new FormData();
    formData.append("invitation_key", key);
    formData.append("first_name", first_name);
    formData.append("last_name", last_name);
    formData.append("email", email);
    formData.append("phone", phone);
    formData.append("password", password);

    const res = await apiAcceptInvite(formData);

    if (res.status === 201) {
      navigate("/login");
    } else {
      for (let p in res.data) {
        hAlert.show(res.data[p][0], false);
        break;
      }
    }

    setLoading(false);
  };

  if (!key) {
    return <Navigate to="/login" replace={true} />;
  }

  return (
    <Stack className="section-main">
      <Box p={isDesktop ? 4 : 2} sx={{ display: "flex", justifyContent: "center" }}>
        <Box sx={{ width: isDesktop ? "100%" : "600px" }}>
          <LoginLogo />
        </Box>
      </Box>

      <Stack p={isDesktop ? 4 : 2} sx={{ flexGrow: 1, justifyContent: "center", alignItems: "center" }}>
        <Stack mt={-10} sx={{ maxWidth: "600px", width: 1, height: 1 }}>
          <Box my={3} sx={{ fontSize: 18, fontWeight: 900 }}>
            Invitation to join Demand IQ
          </Box>

          <Box>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Stack spacing={3}>
                <FormText
                  control={control}
                  disabled={loading}
                  InputProps={{
                    readOnly: true,
                  }}
                  name="first_name"
                  label="First Name *"
                />

                <FormText
                  control={control}
                  disabled={loading}
                  InputProps={{
                    readOnly: true,
                  }}
                  name="last_name"
                  label="Last Name *"
                />

                <FormText
                  control={control}
                  disabled={loading}
                  InputProps={{
                    readOnly: true,
                  }}
                  name="email"
                  label="Email Address *"
                />

                <FormText
                  control={control}
                  disabled={loading}
                  InputProps={{
                    readOnly: true,
                  }}
                  name="phone"
                  label="Phone Number *"
                />

                <FormText control={control} disabled={loading} type="password" name="password" label="Password *" />

                <Box sx={{ display: "flex", justifyContent: "center" }}>
                  <RoundedButton
                    type="submit"
                    variant="contained"
                    fullWidth={!isDesktop}
                    sx={{ minWidth: 150 }}
                    disabled={loading}
                  >
                    {loading ? <CircularProgress color="secondary" size={22} /> : "Create Account"}
                  </RoundedButton>
                </Box>
              </Stack>
            </form>
          </Box>

          <Box mt={3} sx={{ textAlign: "center" }}>
            <Box
              component={Link}
              to="/login"
              sx={{
                color: "primary.main",
                fontSize: 14,
                fontWeight: 900,
                textDecoration: "none",
                "&:hover": { textDecoration: "underline" },
              }}
            >
              Back to login page
            </Box>
          </Box>

          <Box mt={3} sx={{ opacity: showPromotion ? 1 : 0, transition: "opacity .1s ease-in-out" }}>
            <Box sx={{ fontSize: 14, fontWeight: 900, textAlign: "center" }}>
              <Box component="span" mr={1}>
                <img src="images/promotion.svg" width="14px" alt="" /> Check out our
              </Box>

              <Box
                component="span"
                sx={{
                  color: "primary.main",
                  fontSize: 14,
                  cursor: "pointer",
                }}
                onClick={onOpenPromotion}
              >
                Promotional Offer
              </Box>
            </Box>
          </Box>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default Main;

import React from "react";
import { Box } from "@mui/material";
import { useRecoilState } from "recoil";
import useQuickSetupWizard from "hooks/useQuickSetupWizard";
import { quickSetupAtom } from "store/configurator";
import Welcome from "./welcome/Welcome";
import Wizard from "./wizard/Wizard";

const QuickSetup = () => {
  const hWizard = useQuickSetupWizard();
  const [quickSetupState, setQuickSetupState] = useRecoilState(quickSetupAtom);
  const [wizard, setWizard] = React.useState(false);

  React.useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const code = searchParams.get("code");

    if (code) {
      setWizard(true);
      setTimeout(() => {
        setQuickSetupState({
          ...quickSetupState,
          form: 11,
        });
      }, 100);
    }

    return () => {
      hWizard.reset();
    };
    // eslint-disable-next-line
  }, []);

  const showWizard = () => {
    setWizard(true);
  };

  const closeWizard = () => {
    setWizard(false);
    hWizard.reset();
  };

  return <Box>{wizard ? <Wizard closeWizard={closeWizard} /> : <Welcome showWizard={showWizard} />}</Box>;
};

export default QuickSetup;

import React from "react";
import clsx from "clsx";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  bannerWrapper: {
    position: (props) => {
      if (props.banner_type === "dynamic" && props.position === "top") return "relative";
      else if (props.banner_type === "dynamic" && props.position === "bottom") return "sticky";
      else return props.banner_type;
    },
    backgroundColor: (props) => props.banner_bg_color,
    width: "100%",
    zIndex: 98,
    padding: 8,
    margin: "0 auto",
  },

  banner: {
    backgroundColor: (props) => props.banner_bg_color,
    maxWidth: "1024px",
    margin: "0 auto",
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    alignItems: "center",
    justifyContent: "space-around",
  },

  bannerPositionTop: {
    top: 0,
  },

  bannerPositionBottom: {
    bottom: 0,
  },

  bannerAdv: {
    color: (props) => props.banner_text_color,
    fontSize: 27,
    lineHeight: "27px",
    textAlign: "center",
    margin: "8px 0",
  },

  btn: {
    color: (props) => props.banner_button_text_color,
    backgroundColor: (props) => props.banner_button_bg_color,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    textDecoration: "none",
    fontSize: 20,
    lineHeight: "20px",
    boxShadow: "0px 3px 8px 0px rgba(0, 0, 0, 0.15)",
    height: 45,
    padding: 16,
    margin: "8px 0",
    border: "none",
    borderRadius: (props) =>
      props.banner_button_shape === "semiround" ? 12 : props.banner_button_shape === "round" ? 30 : 0,

    "&:hover": {
      color: (props) => props.banner_button_hover_text_color,
      backgroundColor: (props) => props.banner_button_hover_bg_color,
    },
  },

  btnClose: {
    color: (props) => props.banner_button_text_color,
    backgroundColor: (props) => props.banner_button_bg_color,
    position: "absolute",
    right: 5,
    top: 8,
    borderRadius: 10,
    width: 18,
    height: 18,
    border: "none",

    "&:hover": {
      color: (props) => props.banner_button_hover_text_color,
      backgroundColor: (props) => props.banner_button_hover_bg_color,
    },
  },
});

export default function Banner({ theme, position = "top" }) {
  const { banner_text, banner_button_text } = theme;

  const classes = useStyles({ ...theme, position });

  return (
    <div
      className={clsx(classes.bannerWrapper, {
        [classes.bannerPositionTop]: position === "top",
        [classes.bannerPositionBottom]: position === "bottom",
      })}
    >
      <div className={classes.banner}>
        <p className={classes.bannerAdv}>{banner_text}</p>

        <button className={classes.btn}>{banner_button_text}</button>

        {theme.banner_position === "bottom" && <button className={classes.btnClose}>X</button>}
      </div>
    </div>
  );
}

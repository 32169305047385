import React from "react";
import { Box, TextField, InputAdornment } from "@mui/material";
import useQuickSetupWizard from "hooks/useQuickSetupWizard";

const ChangeOffset = () => {
  const setupWizard = useQuickSetupWizard();
  const [offset, setOffset] = React.useState("");
  const [error, setError] = React.useState("");

  React.useEffect(() => {
    setOffset(setupWizard.client.offset || "");
    // eslint-disable-next-line
  }, []);

  React.useEffect(() => {
    setError(setupWizard.errors.offset);
    // eslint-disable-next-line
  }, [setupWizard.errors]);

  const handleOffsetChange = (e) => {
    if (e.target.value === "") {
      setError("required");
    } else {
      setError("");
    }
    setOffset(e.target.value);
    setupWizard.update("offset", e.target.value);
  };

  return (
    <Box>
      <Box mb={2} sx={{ fontSize: 12 }}>
        We recommend using an offset between 80% and 120%
      </Box>
      {error && (
        <Box sx={{ color: "error.main" }}>
          {error === "required" && "Required"}
          {error !== "required" && error}
        </Box>
      )}
      <TextField
        InputProps={{ startAdornment: <InputAdornment position="start">%</InputAdornment> }}
        type="number"
        value={offset}
        onChange={handleOffsetChange}
      />
    </Box>
  );
};

export default ChangeOffset;

import React from "react";
import { Box, MobileStepper, Button } from "@mui/material";
import useSpanishSetupWizard from "hooks/useSpanishSetupWizard";
import WizardForm from "./shared/WizardForm";
import Step from "./shared/Step";
import CodeBox from "./shared/CodeBox";
import ChangeAiName from "./components/ChangeAiName";
import UploadAiAvatar from "./components/UploadAiAvatar";
import ChangeMeetingDescription from "./components/ChangeMeetingDescription";
import ChangeMeetingLocation from "./components/ChangeMeetingLocation";
import ChangeTestimonialLink from "./components/ChangeTestimonialLink";
import ChangeThanksStatement from "./components/ChangeThanksStatement";
import Complete from "./components/Complete";
import ChangeWelcome from "./components/ChangeWelcome";

const wizardLength = 4;

const Wizard = ({ closeWizard }) => {
  const spanishWizard = useSpanishSetupWizard();

  const handleBack = () => {
    spanishWizard.back();
  };

  const handleNext = () => {
    spanishWizard.next();
  };

  return (
    <Box p={4}>
      <MobileStepper
        variant="progress"
        steps={wizardLength}
        position="static"
        activeStep={spanishWizard.form - 1}
        backButton={
          <Button size="small" variant="outlined" onClick={handleBack} disabled={spanishWizard.form === 1}>
            Back
          </Button>
        }
        nextButton={
          <Button
            size="small"
            variant="contained"
            disableElevation
            onClick={handleNext}
            disabled={spanishWizard.form === wizardLength}
          >
            {spanishWizard.updates.length > 0 ? "Save & Next" : "Skip"}
          </Button>
        }
      />
      {spanishWizard.form === 1 && (
        <WizardForm label="CUSTOMIZE YOUR AI" required={["spanish_ai_name", "spanish_ai_avatar"]}>
          <Step index={1} label="What would you like to name your Spanish-speaking AI?">
            <ChangeAiName />
          </Step>
          <Step index={2} label="Choose an avatar.">
            <UploadAiAvatar />
          </Step>
        </WizardForm>
      )}
      {spanishWizard.form === 2 && (
        <WizardForm
          label="CUSTOMIZE YOUR MEETING INFO"
          required={["calendar_meeting_description_es", "calendar_meeting_location_es"]}
        >
          <Box mt={4} sx={{ fontSize: 14 }}>
            Use <CodeBox label="{{Address}}" /> and <CodeBox label="{{Phone}}" /> to add custom lead details to the
            meeting location and description.
          </Box>
          <Step index={3} label="Calendar Meeting Description">
            <ChangeMeetingDescription />
          </Step>
          <Step index={4} label="Calendar Meeting Location">
            <ChangeMeetingLocation />
          </Step>
        </WizardForm>
      )}
      {spanishWizard.form === 3 && (
        <WizardForm
          label="CUSTOMIZATION FOR THANK YOU"
          required={["spanish_testimonials_url", "spanish_thanks_caption", "spanish_thanks_statement"]}
        >
          <Step index={5} label="Provide a link to your Spanish testimonials page.">
            <ChangeTestimonialLink />
          </Step>

          <Step index={6} label="Please provide a custom Spanish-statement to display on the landing page.">
            <ChangeWelcome />
          </Step>

          <Step index={7} label="Please provide a custom Spanish-statement to display on your thank you page.">
            <ChangeThanksStatement />
          </Step>
        </WizardForm>
      )}
      {spanishWizard.form === 4 && <Complete closeWizard={closeWizard} />}
    </Box>
  );
};

export default Wizard;

import React from "react";
import { useRecoilState } from "recoil";
import { Box, Table, TableHead, TableBody, TableRow, TableCell } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { authAtom } from "store/auth";
import { isSuper } from "utils/authHelper";
import { apiGetFields, apiGetClientQuestions } from "store/api";
import {
  apiQuestionCustomList,
  apiQuestionCustomCreate,
  apiQuestionCustomUpdate,
  apiQuestionCustomDelete,
} from "utils/api_v2";
import useAlert from "hooks/useAlert";
import useQuickSetupWizard from "hooks/useQuickSetupWizard";
import CustomQuestionItem from "./CustomQuestionItem";

const CustomQuestion = () => {
  const setupWizard = useQuickSetupWizard();
  const hAlert = useAlert();
  const [authState] = useRecoilState(authAtom);
  const [questionChoices, setQuestionChoices] = React.useState([]);
  const [fieldChoices, setFieldChoices] = React.useState([]);
  const [customQuestions, setCustomQuestions] = React.useState([]);

  React.useEffect(() => {
    const load = async () => {
      const clientId = setupWizard.client.id;
      Promise.all([apiGetClientQuestions(clientId, "en"), apiGetFields(), apiQuestionCustomList()]).then((values) => {
        if (values[0].status === 200 && values[1].status === 200 && values[2].status === 200) {
          setQuestionChoices(values[0].data);
          setFieldChoices(values[1].data);
          setCustomQuestions(values[2].data);
        }
      });
    };

    load();

    // eslint-disable-next-line
  }, []);

  const handleCreate = async () => {
    const orders = customQuestions.map((d) => d.order);
    const orderMax = Math.max(-1, ...orders);
    const res = await apiQuestionCustomCreate({
      order: orderMax + 1,
    });
    if (res.status === 201) {
      hAlert.show("Created new question!");
      setCustomQuestions([...customQuestions, res.data]);
    } else {
      hAlert.show("Something went wrong", false);
    }
  };

  const handleUpdate = async (item) => {
    const res = await apiQuestionCustomUpdate(item.id, item);
    if (res.status === 200) {
      setCustomQuestions(customQuestions.map((d) => (d.id === item.id ? res.data : d)));
    } else {
      hAlert.show("Update failed", false);
    }
  };

  const handleDelete = async (item) => {
    const res = await apiQuestionCustomDelete(item.id);
    if (res.status === 200) {
      setCustomQuestions(customQuestions.filter((d) => d.id !== item.id));
    } else {
      hAlert.show("Delete failed", false);
    }
  };

  if (!isSuper(authState.user)) {
    return null;
  }

  return (
    <Box>
      <Box my={2}>
        <LoadingButton variant="contained" onClick={handleCreate}>
          Create Custom Question
        </LoadingButton>
      </Box>
      <Box my={4}>
        <Box sx={{ fontWeight: 900 }}>Please map custom questions</Box>
        <Box sx={{ fontSize: 12 }}>Caution: You must input your custom questions by order.</Box>
      </Box>
      <Box sx={{ display: "flex" }}>
        <Box sx={{ border: "1px solid #ccc", borderRadius: 2 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell width="30">Order</TableCell>
                <TableCell width="300">Question</TableCell>
                <TableCell width="30"></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {customQuestions
                .sort((a, b) => a.order - b.order)
                .map((d, i) => (
                  <CustomQuestionItem
                    key={d.id}
                    index={i}
                    item={d}
                    questionChoices={questionChoices}
                    fieldChoices={fieldChoices}
                    onUpdate={handleUpdate}
                    onDelete={handleDelete}
                  />
                ))}
            </TableBody>
          </Table>
        </Box>
      </Box>
    </Box>
  );
};

export default CustomQuestion;

import React from "react";
import { Box, IconButton, Stack, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/styles";
import RoundedButton from "shared/RoundedButton";
import { CloseIcon } from "utils/icons";

const SideBar = ({ onClose }) => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <Box className="section-left">
      <Box className="section-left-content">
        <Box className="close">
          <IconButton onClick={onClose}>
            <CloseIcon style={{ color: "white" }} />
          </IconButton>
        </Box>
        <Stack className="promotion">
          <Box className="promotional-offer">Promotional Offer</Box>
          {isDesktop ? (
            <Stack spacing={4}>
              <Box>
                <img src="/images/promotion.png" alt="" style={{ minWidth: "120px", maxWidth: "120px" }} />
              </Box>
              <Box sx={{ fontSize: 40, fontWeight: "bold", lineHeight: 1.2 }}>FREE YETI COOLER</Box>
              <Box sx={{ fontSize: 22, lineHeight: 1.5 }}>when you refer a friend.</Box>
              <Box>
                <RoundedButton
                  component="a"
                  variant="contained"
                  sx={{ backgroundColor: "transparent", "&:hover": { backgroundColor: "transparent" } }}
                  href="https://www.demand-iq.com/referral"
                  target="_blank"
                >
                  Refer a friend
                </RoundedButton>
              </Box>
            </Stack>
          ) : (
            <Stack direction="row" spacing={2} sx={{ alignItems: "center" }}>
              <Box>
                <img src="/images/promotion.png" alt="" style={{ minWidth: "24px", maxWidth: "24px" }} />
              </Box>
              <Box>
                <Box component="span" sx={{ fontWeight: 900 }}>
                  FREE YETI COOLER{" "}
                </Box>
                when you{" "}
                <Box
                  component="a"
                  sx={{ color: "white", fontWeight: 900, textDecoration: "underline", cursor: "pointer" }}
                  href="https://www.demand-iq.com/referral"
                  target="_blank"
                >
                  refer a friend.
                </Box>
              </Box>
            </Stack>
          )}
        </Stack>
      </Box>
    </Box>
  );
};

export default SideBar;

import React from "react";
import { useRecoilValue } from "recoil";
import { Navigate } from "react-router-dom";
import { Backdrop, Box, CircularProgress, Snackbar, Alert } from "@mui/material";
import useAlert from "hooks/useAlert";
import { authAtom } from "store/auth";
import { loadingAtom } from "store/common";

const Layout1 = ({ children }) => {
  const hAlert = useAlert();
  const loading = useRecoilValue(loadingAtom);
  const { user } = useRecoilValue(authAtom);

  if (user !== null) {
    return <Navigate to="/dashboard" replace />;
  }

  return (
    <>
      {children}
      <Backdrop
        sx={{
          backgroundColor: "#000c",
          color: "#fff",
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={loading !== ""}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <CircularProgress color="inherit" />
          <Box mt={2}>{loading}</Box>
        </Box>
      </Backdrop>
      <Snackbar
        open={hAlert.open}
        autoHideDuration={3000}
        onClose={hAlert.close}
        anchorOrigin={{
          horizontal: "center",
          vertical: "top",
        }}
      >
        <Alert variant="filled" severity={hAlert.success ? "success" : "error"} sx={{ width: "100%" }}>
          {hAlert.message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default Layout1;

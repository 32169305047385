import { SINGLE_CHOICE_QN, OPEN_ENDED_QN } from "utils/constant";

export const lockedQuestions = [
  {
    id: "default1",
    question_desc: "Where is the property located?",
    choices: [{ option: "Address" }, { option: "Apartment/Unit number" }],
  },
  {
    id: "default2",
    question_desc: "When would you like the work completed?",
    choices: [{ option: "ASAP" }, { option: "In the next 6 months" }, { option: "Still researching" }],
  },
];

export const findUnsorted = (questions) => {
  let result = [];
  for (let i = 0; i < questions.length; i++) {
    if (questions[i].position !== i) {
      result.push({
        ...questions[i],
        position: i,
      });
    }
  }
  return result;
};

export const validateQuestionData = ({ type, description, choices, label }) => {
  if (description.length === 0 || description.length > 108) {
    return false;
  }
  if (type === SINGLE_CHOICE_QN) {
    if (!choices || choices.length === 0) {
      return false;
    }
    for (const choice of choices) {
      if (choice.option === "") {
        return false;
      }
    }
    return true;
  }
  if (type === OPEN_ENDED_QN) {
    return label.length && label.length <= 24;
  }
  return false;
};

export const generateKey = (label) => {
  return label.toLowerCase().trim().replace(/\s+/g, "_");
};

import React from "react";
import { useRecoilState } from "recoil";
import { Scrollbars } from "react-custom-scrollbars";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Button, Box, Paper, Stack, MenuItem } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import useAlert from "hooks/useAlert";
import { authAtom } from "store/auth";
import { clientAtom } from "store/client";
import { apiGetUser, apiUpdateUser } from "store/api";
import FormText from "shared/FormText";
import { ArrowLeftIcon } from "utils/icons";
import { getPermissions, getDisplayName } from "utils/generators";

const TeamUserPage = () => {
  const hAlert = useAlert();
  const params = useParams();
  const navigate = useNavigate();
  const [authState] = useRecoilState(authAtom);
  const [clientState] = useRecoilState(clientAtom);
  const { control, handleSubmit, setValue } = useForm();
  const [saving, setSaving] = React.useState(false);
  const [user, setUser] = React.useState(null);
  const mounted = React.useRef(true);

  React.useEffect(() => {
    const load = async () => {
      const res = await apiGetUser(params.id);
      if (res.status === 200) {
        const { first_name, last_name, phone, email, role, status } = res.data;
        setUser(res.data);
        setValue("first_name", first_name);
        setValue("last_name", last_name);
        setValue("phone", phone || "");
        setValue("email", email);
        setValue("role", role);
        setValue("status", status);
      }
    };
    load();

    return () => {
      mounted.current = false;
    };
    // eslint-disable-next-line
  }, []);

  const onSubmit = async (d) => {
    setSaving(true);
    const res = await apiUpdateUser(params.id)(
      {
        solar_company: clientState.currentId,
      },
      {
        id: params.id,
        email: d.email,
        first_name: d.first_name,
        last_name: d.last_name,
        phone: d.phone,
        role: d.role,
        status: d.status,
      }
    );
    if (res.status === 200) {
      hAlert.show("User saved");
      navigate("/settings/team");
    }

    if (mounted.current) {
      setSaving(false);
    }
  };

  return (
    <Box px={4} sx={{ height: 1 }}>
      <Box sx={{ height: 136, display: "flex", flexDirection: "column", justifyContent: "center" }}>
        <Box mb={2}>
          <Button component={Link} to="/settings/team" size="small" startIcon={<ArrowLeftIcon />}>
            Team
          </Button>
        </Box>
        <Box sx={{ fontSize: 24, color: "primary.main" }}>{user && getDisplayName(user)}</Box>
      </Box>
      <Paper
        sx={{
          height: "calc(100% - 136px)",
          borderTopLeftRadius: 16,
          borderTopRightRadius: 16,
          borderBottomLeftRadius: 0,
          borderBottomRightRadius: 0,
        }}
      >
        <Box sx={{ height: 1 }}>
          <Scrollbars>
            <Box p={4}>
              <form onSubmit={handleSubmit(onSubmit)}>
                <Stack spacing={4}>
                  <FormText required disabled={saving} control={control} name="first_name" label="First Name *" />
                  <FormText required disabled={saving} control={control} name="last_name" label="Last Name *" />
                  <FormText disabled={saving} control={control} name="phone" label="Phone Number" />
                  <FormText required disabled={saving} control={control} name="email" label="Work Email *" />
                  <FormText required select disabled={saving} control={control} name="role" label="Role *">
                    {getPermissions(authState.user, clientState.current?.is_default).map((d) => (
                      <MenuItem key={d.value} {...d}>
                        {d.label}
                      </MenuItem>
                    ))}
                  </FormText>
                  <FormText required select disabled={saving} control={control} name="status" label="Status">
                    <MenuItem value="active">Active</MenuItem>
                    <MenuItem value="suspended">Suspended</MenuItem>
                  </FormText>
                </Stack>
                <Box mt={4}>
                  <LoadingButton fullWidth loading={saving} variant="contained" type="submit">
                    SAVE USER
                  </LoadingButton>
                </Box>
              </form>
            </Box>
          </Scrollbars>
        </Box>
      </Paper>
    </Box>
  );
};

export default TeamUserPage;

import React from "react";
import { Box } from "@mui/material";
import Start from "./components/start/Start";
import Success from "./components/success/Success";
import Failure from "./components/failure/Failure";
import { apiGetEstimate } from "store/api";
import { clientAtom } from "store/client";
import { useRecoilState } from "recoil";

const InstantEstimatePage = () => {
  const [clientState] = useRecoilState(clientAtom);
  const [step, setStep] = React.useState("start");
  const [defaultValues, setDefaultValues] = React.useState(null);
  const [url, setUrl] = React.useState("");
  const [error, setError] = React.useState("");

  const handleSend = async (data) => {
    setDefaultValues(data);
    const {
      formData: { input_place, ...formData },
      placeInfo,
    } = data;

    const res = await apiGetEstimate(
      {
        leads: [
          {
            ...formData,
            avg_bill: formData.avg_bill || 0,
            address: placeInfo.address,
            city: placeInfo.city,
            state: placeInfo.state,
            unit: "",
            zip_code: placeInfo.zipCode || formData.zip_code,
            rooftop_latitude: placeInfo.rooftopLatitude,
            rooftop_longitude: placeInfo.rooftopLongitude,
          },
        ],
      },
      { "x-api-key": clientState.current.api_key }
    );

    setError("");

    if (res.status === 200) {
      if (res.data[0].estimate_url) {
        setUrl(res.data[0].estimate_url);
        setStep("success");
      } else if (res.data[0].errors) {
        setError(res.data[0].errors);
        setStep("failure");
      }
    } else {
      setError(res.data.detail || res.data.message);
      setStep("failure");
    }
  };

  const handleRestart = () => {
    setDefaultValues(null);
    setStep("start");
  };

  const handleRetry = () => {
    setStep("start");
  };

  return (
    <Box sx={{ height: 1 }}>
      {step === "start" && <Start defaultValues={defaultValues || {}} onSend={handleSend} />}
      {step === "success" && <Success url={url} onRestart={handleRestart} />}
      {step === "failure" && <Failure error={error} onRetry={handleRetry} />}
    </Box>
  );
};

export default InstantEstimatePage;

import React from "react";
import { Box, Stack, Button } from "@mui/material";
import { CheckIcon } from "utils/icons";
import useConfigurator from "hooks/useConfigurator";
import { generateEmbedAddressWidgetScript, generateEmbedZipcodeWidgetScript } from "utils/generators";
import EmbedScript from "shared/EmbedScript";
import "./style.scss";

const requiredFields = [
  "energy_type",
  "button_shape",
  "billing_email",
  "advertise_email",
  "website_url",
  "testimonials_url",
  "privacy_policy",
  "reviews_url",
  "offset",
  "ai_name",
];

const checkCompleted = (client) => {
  for (const field of requiredFields) {
    if (!client[field]) {
      return false;
    }
  }
  return true;
};

const getFullCustomDomain = (domain) => {
  return `https://${domain}`;
};

const Welcome = ({ openWizard }) => {
  const hConfigurator = useConfigurator();
  const { custom_domain, company_slug } = hConfigurator.client;
  const addressWidgetScript = generateEmbedAddressWidgetScript(
    hConfigurator.customDomainValidated && custom_domain,
    company_slug,
    "es"
  );
  const zipcodeWidgetScript = generateEmbedZipcodeWidgetScript(
    hConfigurator.customDomainValidated && custom_domain,
    company_slug,
    "es"
  );
  const completed = checkCompleted(hConfigurator.client);
  const liveLink = `${
    hConfigurator.customDomainValidated
      ? getFullCustomDomain(hConfigurator.client.custom_domain)
      : hConfigurator.client.app_url
  }/?lang=es`;

  return (
    <Box p={4}>
      {completed && (
        <Box sx={{ display: "flex", alignItems: "center", color: "success.main" }}>
          <CheckIcon fontSize={20} />
          <Box ml={0.5}>Setup Completed</Box>
        </Box>
      )}
      <Stack mt={8} sx={{ alignItems: "center" }} spacing={4}>
        <img className="spanish-icon" src="/images/spanish.png" alt="_" />
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <img className="new-icon" src="/images/icon-new.png" alt="_" />
          <Box ml={2} sx={{ fontSize: 24 }}>
            {completed ? "Spanish is fully configured!" : "Configure Stella in Spanish"}
          </Box>
        </Box>
        <Box sx={{ fontSize: 14 }}>
          {completed
            ? "Click below to preview the funnel."
            : "Click below to set up a new Spanish variation of your Stella funnel!"}
        </Box>
        {completed ? (
          <Stack spacing={2} sx={{ width: 1, alignItems: "center" }}>
            <Button fullWidth sx={{ maxWidth: 400 }} variant="contained" component="a" href={liveLink} target="_blank">
              View Live
            </Button>
            <Button fullWidth sx={{ maxWidth: 400 }} variant="outlined" onClick={openWizard}>
              Edit
            </Button>
          </Stack>
        ) : (
          <Button variant="contained" onClick={openWizard}>
            Vámonos
          </Button>
        )}
      </Stack>
      <Stack sx={{ margin: "64px auto", maxWidth: 600 }} spacing={4}>
        <EmbedScript
          title="Embed Zip Code Entry Widget"
          description="Place this code in the location you want the widget to appear"
          script={zipcodeWidgetScript}
        />
        <EmbedScript
          title="Embed Address Entry Widget"
          description="Place this code in the location you want the widget to appear"
          script={addressWidgetScript}
        />
      </Stack>
    </Box>
  );
};

export default Welcome;

import React from "react";
import { Box } from "@mui/material";
import useQuickSetupWizard from "hooks/useQuickSetupWizard";
import Switch from "shared/wizard/Switch";

const InviteAttendee = () => {
  const hWizard = useQuickSetupWizard();
  const [inviteAttendee, setInviteAttendee] = React.useState(false);

  React.useEffect(() => {
    setInviteAttendee(hWizard.client.is_auto_invite_attendee);
    // eslint-disable-next-line
  }, []);

  const handleChange = (e) => {
    setInviteAttendee(e.target.checked);
    hWizard.update("is_auto_invite_attendee", e.target.checked);
  };

  return (
    <Box pl={1} my={2}>
      <Switch label="Invite appointment attendees automatically?" checked={inviteAttendee} onChange={handleChange} />
    </Box>
  );
};

export default InviteAttendee;

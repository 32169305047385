import React from "react";
import { Stack, Box, ButtonBase, Collapse } from "@mui/material";
import { ArrowDownIcon, ArrowUpIcon, LockIcon } from "utils/icons";

const QuestionBoxLocked = ({ question }) => {
  const [collapsed, setCollapsed] = React.useState(true);

  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };

  return (
    <Box
      my={2}
      sx={{
        backgroundColor: "#fff",
        border: "1px solid #ccc",
        color: "#888",
        borderRadius: 4,
        overflow: "hidden",
      }}
    >
      <Box sx={{ display: "flex" }}>
        <Box
          sx={{
            width: 50,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexShrink: 0,
            borderRight: "1px solid #ddd",
          }}
        >
          <LockIcon />
        </Box>
        <ButtonBase sx={{ padding: 2, width: 1, fontSize: 16 }} onClick={toggleCollapsed}>
          <Box sx={{ flexGrow: 1, textAlign: "left" }}>{question.question_desc}</Box>
          {collapsed ? <ArrowDownIcon /> : <ArrowUpIcon />}
        </ButtonBase>
      </Box>
      <Collapse in={!collapsed}>
        <Stack spacing={1} sx={{ borderTop: "1px solid #ccc", padding: 4 }}>
          {question.choices.map((choice) => (
            <Box key={choice.option} sx={{ display: "flex" }}>
              <Box p={1} sx={{ color: "#ccc", backgroundColor: "#eee" }}>
                DQ
              </Box>
              <Box ml={2} px={2} py={1} sx={{ flexGrow: 1, border: "1px solid #ccc", color: "#666" }}>
                {choice.option}
              </Box>
            </Box>
          ))}
        </Stack>
      </Collapse>
    </Box>
  );
};

export default QuestionBoxLocked;

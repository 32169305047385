import React from "react";
import { useRecoilState } from "recoil";
import { CardNumberElement, useStripe, CardCvcElement, CardExpiryElement, useElements } from "@stripe/react-stripe-js";
import { Box, Stack, Input, Button } from "@mui/material";
import useCommon from "hooks/useCommon";
import useAlert from "hooks/useAlert";
import { apiAttachPaymentMethod } from "store/api";
import { clientAtom } from "store/client";

const CARD_OPTIONS = {
  style: {
    base: {
      color: "#000",
      fontWeight: 500,
      fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
      fontSize: "16px",
      fontSmoothing: "antialiased",
    },
    invalid: {
      iconColor: "red",
      color: "red",
    },
  },
};

const CreatePayment = ({ closeDialog, onCreate }) => {
  const stripe = useStripe();
  const elements = useElements();
  const hCommon = useCommon();
  const hAlert = useAlert();
  const [clientState] = useRecoilState(clientAtom);
  const [cardNumberComplete, setCardNumberComplete] = React.useState(false);
  const [cardExpiryComplete, setCardExpiryComplete] = React.useState(false);
  const [cardHolderComplete, setCardHolderComplete] = React.useState(false);
  const [cardZipcodeComplete, setCardZipcodeComplete] = React.useState(false);
  const [cardCvcComplete, setCardCvcComplete] = React.useState(false);

  const handleCardNumberUpdate = (e) => {
    setCardNumberComplete(e.complete);
  };

  const handleCardExpiryUpdate = (e) => {
    setCardExpiryComplete(e.complete);
  };

  const handleCardCvcUpdate = (e) => {
    setCardCvcComplete(e.complete);
  };

  const handleCardHolderUpdate = (e) => {
    setCardHolderComplete(!!e.target.value);
  };

  const handleCardZipcodeUpdate = (e) => {
    setCardZipcodeComplete(!!e.target.value);
  };

  const handleSubmit = () => {
    hCommon.loading("Creating Payment Method ...");
    stripe
      .createPaymentMethod({
        type: "card",
        card: elements.getElement(CardNumberElement),
      })
      .then(async (res) => {
        if (res.error) {
          hAlert.show(res.error.message);
          return;
        }
        if (!res.paymentMethod) {
          hAlert.show("Something went wrong. Try again.");
          return;
        }
        hCommon.loading("Attaching card ...");
        const res2 = await apiAttachPaymentMethod({
          customer: clientState.current.customer_id,
          payment_method_id: res.paymentMethod.id,
          type: "attach",
        });
        if (res2.status === 200) {
          hAlert.show("Card attached successfully");
          onCreate();
          closeDialog();
        } else {
          hAlert.show("Your card didn't attached", false);
        }
        hCommon.loading("");
      });
  };

  return (
    <Box>
      <Box mb={4} sx={{ fontSize: 20, fontWeight: 500 }}>
        Create Payment Method
      </Box>
      <Stack spacing={2}>
        <Box sx={{ display: "flex" }}>
          <Box p={1} mr={2} sx={{ height: 40, flexGrow: 1, border: "1px solid #ccc" }}>
            <CardNumberElement options={CARD_OPTIONS} onChange={handleCardNumberUpdate} />
          </Box>
          <Box p={1} sx={{ width: 100, height: 40, border: "1px solid #ccc" }}>
            <CardExpiryElement options={CARD_OPTIONS} onChange={handleCardExpiryUpdate} />
          </Box>
        </Box>
        <Box px={1} sx={{ display: "flex", alignItems: "center", height: 40, border: "1px solid #ccc" }}>
          <Input fullWidth disableUnderline placeholder="Card Holder Name" onChange={handleCardHolderUpdate} />
        </Box>
        <Box sx={{ display: "flex" }}>
          <Box
            px={1}
            mr={2}
            sx={{ display: "flex", alignItems: "center", height: 40, flexGrow: 1, border: "1px solid #ccc" }}
          >
            <Input fullWidth disableUnderline placeholder="Zip Code" onChange={handleCardZipcodeUpdate} />
          </Box>
          <Box p={1} sx={{ width: 100, height: 40, border: "1px solid #ccc" }}>
            <CardCvcElement options={CARD_OPTIONS} onChange={handleCardCvcUpdate} />
          </Box>
        </Box>
        <Box sx={{ textAlign: "right" }}>
          <Button variant="outlined" sx={{ marginRight: 1 }} onClick={closeDialog}>
            CANCEL
          </Button>
          <Button
            disabled={
              !cardNumberComplete ||
              !cardExpiryComplete ||
              !cardHolderComplete ||
              !cardZipcodeComplete ||
              !cardCvcComplete
            }
            disableElevation
            variant="contained"
            onClick={handleSubmit}
          >
            ADD CARD
          </Button>
        </Box>
      </Stack>
    </Box>
  );
};

export default CreatePayment;

import React from "react";
import { Box, Stack, TextField } from "@mui/material";
import useQuickSetupWizard from "hooks/useQuickSetupWizard";
import DesktopPreview from "shared/Preview/DesktopPreview";
import UploadThanksIcon from "./UploadThanksIcon";
import { validateURL } from "../../../../../../../utils/helpers";

const ChangeThanks = () => {
  const setupWizard = useQuickSetupWizard();

  const [thanks, setThanks] = React.useState({
    thanks_caption: "",
    thanks_statement: "",
    thanks_icon: "",
    thanks_icon_width: "",
    thanks_icon_height: "",
    redirect_text: "",
    redirect_url: "",
  });

  const [errors, setErrors] = React.useState({
    thanks_caption: "",
    thanks_statement: "",
    thanks_icon: "",
    thanks_icon_width: "",
    thanks_icon_height: "",
    redirect_text: "",
    redirect_url: "",
  });

  React.useEffect(() => {
    setThanks({
      thanks_caption: setupWizard.client.thanks_caption || "Your Quote is Being Prepared!",
      thanks_statement: setupWizard.client.thanks_statement || "",
      thanks_icon: setupWizard.client.thanks_icon || "",
      thanks_icon_width: setupWizard.client.thanks_icon_width || "",
      thanks_icon_height: setupWizard.client.thanks_icon_height || "",
      redirect_text: setupWizard.client.redirect_text || "BACK TO OUR HOMEPAGE",
      redirect_url: setupWizard.client.redirect_url || setupWizard.client.website_url,
    });
    // eslint-disable-next-line
  }, []);

  React.useEffect(() => {
    setErrors({
      thanks_caption: setupWizard.errors.thanks_caption,
      thanks_statement: setupWizard.errors.thanks_statement,
      thanks_icon: setupWizard.errors.thanks_icon,
      thanks_icon_width: setupWizard.errors.thanks_icon_width,
      thanks_icon_height: setupWizard.errors.thanks_icon_height,
      redirect_text: setupWizard.errors.redirect_text,
      redirect_url: setupWizard.errors.redirect_url,
    });
    // eslint-disable-next-line
  }, [setupWizard.errors]);

  const handleChange = (e, key, len) => {
    if (e.target.value.length <= len) {
      if (e.target.value === "") {
        setErrors((state) => ({
          ...state,
          [key]: "required",
        }));
      } else if (key === "redirect_url" && !validateURL(e.target.value)) {
        setErrors((state) => ({
          ...state,
          [key]: "Invalid format",
        }));
      } else {
        setErrors((state) => ({
          ...state,
          [key]: "",
        }));
      }
      setThanks((state) => ({ ...state, [key]: e.target.value }));
      setupWizard.update(key, e.target.value);
    }
  };

  const handleChangeIconSize = (e, key) => {
    if (e.target.value === "") {
      setErrors((state) => ({
        ...state,
        [key]: "required",
      }));
    } else {
      setErrors((state) => ({
        ...state,
        [key]: "",
      }));
    }

    setThanks((state) => ({ ...state, [key]: e.target.value }));
    setupWizard.update(key, e.target.value);
  };

  const handleBlurIconSize = (e, key) => {
    if (parseInt(thanks[key]) < 50) {
      setThanks((state) => ({ ...state, [key]: 50 }));
      setupWizard.update(key, 50);
    }

    if (parseInt(thanks[key]) > 250) {
      setThanks((state) => ({ ...state, [key]: 250 }));
      setupWizard.update(key, 250);
    }
  };

  const handleChangeIcon = (file, url) => {
    setThanks((state) => ({ ...state, thanks_icon: url }));
    setupWizard.update("thanks_icon", file);
  };

  const handleRemoveIcon = () => {
    setThanks((state) => ({ ...state, thanks_icon: "" }));
    setupWizard.update("thanks_icon", new File([], ""));
  };

  return (
    <Box>
      <Stack mt={2} spacing={2}>
        {[
          { key: "thanks_caption", len: 32 },
          { key: "thanks_statement", len: 255 },
          { key: "redirect_text", label: "Redirect Button Text" },
          { key: "redirect_url", label: "Redirect Button Link" },
        ].map(({ key, label, len }) => (
          <Box key={key}>
            {errors[key] && (
              <Box sx={{ color: "error.main", mb: "4px" }}>
                {errors[key] === "required" && "Required"}
                {errors[key] !== "required" && errors[key]}
              </Box>
            )}
            <TextField
              fullWidth
              value={thanks[key]}
              onChange={(e) => handleChange(e, key, len ?? 255)}
              label={label ?? `${thanks[key].length}/${len}`}
            />
          </Box>
        ))}

        <Box sx={{ pt: 4, display: "flex", flexWrap: "wrap", gap: 2 }}>
          {[
            { key: "thanks_icon_width", label: "Icon width (pixel)" },
            { key: "thanks_icon_height", label: "Icon height (pixel)" },
          ].map(({ key, label }) => (
            <Box key={key}>
              <TextField
                fullWidth
                placeholder="px"
                type="number"
                InputProps={{ inputProps: { min: 50, max: 250 } }}
                value={thanks[key]}
                onChange={(e) => handleChangeIconSize(e, key)}
                onBlur={(e) => handleBlurIconSize(e, key)}
                label={label}
              />
              {errors[key] && (
                <Box sx={{ color: "error.main" }}>
                  {errors[key] === "required" && "Required"}
                  {errors[key] !== "required" && errors[key]}
                </Box>
              )}
            </Box>
          ))}
        </Box>

        <UploadThanksIcon
          icon={thanks.thanks_icon}
          width={thanks.thanks_icon_width}
          height={thanks.thanks_icon_height}
          onChange={handleChangeIcon}
          onRemove={handleRemoveIcon}
        />
      </Stack>

      <Box mt={4}>
        <DesktopPreview page="thankyou" isQuickSetup />
      </Box>
    </Box>
  );
};

export default ChangeThanks;

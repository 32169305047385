import React from "react";
import { TextField, MenuItem } from "@mui/material";
import { leadStatusLabels, leadStatusLevels, leadStatusList } from "../../shared/const";

const LeadStatusSelect = ({ status }) => {
  const handleStatusChange = (e) => {};

  return (
    <TextField select size="small" sx={{ width: 200 }} value={status} onChange={handleStatusChange}>
      {leadStatusList
        .filter((d) => leadStatusLevels[d] <= leadStatusLevels[status])
        .map((d) => (
          <MenuItem key={d} value={d}>
            {leadStatusLabels[d]}
          </MenuItem>
        ))}
    </TextField>
  );
};

export default LeadStatusSelect;

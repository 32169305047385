import React from "react";
import { Button } from "@mui/material";
import { UploadIcon } from "utils/icons";

const TextUpload = ({ label, onChange }) => {
  const handleChange = (e) => {
    if (e.target.files.length === 1) {
      let reader = new FileReader();
      const file = e.target.files[0];
      reader.onload = function (e) {
        onChange(file, e.target.result);
      };
      reader.readAsText(file);
    }
  };

  return (
    <Button color="inherit" component="label" variant="outlined" endIcon={<UploadIcon />}>
      {label}
      <input style={{ display: "none" }} type="file" accept=".csv" onChange={handleChange} />
    </Button>
  );
};

export default TextUpload;

import React from "react";
import { Bar } from "react-chartjs-2";
import { Box, CircularProgress } from "@mui/material";
import useExperiments from "hooks/useExperiments";
import { apiGetExperimentReport } from "store/api";

const chartOptions = {
  legend: {
    display: true,
  },
  maintainAspectRatio: false,
  layout: {
    padding: {
      top: 70,
      left: 32,
      right: 32,
    },
  },
  barValueSpacing: 20,
  scales: {
    xAxes: [
      {
        gridLines: {
          display: false,
          drawBorder: false,
          tickMarkLength: 18,
        },
        ticks: {
          fontColor: "#2196f3",
        },
        display: false,
      },
    ],
    yAxes: [
      {
        display: false,
        ticks: {
          min: 0,
          beginAtZero: true,
        },
        stacked: false,
      },
    ],
  },
};

const AnalyzeChart = ({ experiment }) => {
  const hExperiments = useExperiments();
  const [loading, setLoading] = React.useState(true);
  const [dataset, setDataset] = React.useState([]);

  React.useEffect(() => {
    let mounted = true;
    const load = async () => {
      const res = await apiGetExperimentReport(experiment.id)({
        solar_company: hExperiments.client.id,
      });
      if (res.status === 200 && mounted) {
        const { data } = res.data;
        let _dataset = [];
        setLoading(false);
        Object.keys(data).forEach((slug) => {
          let variation = [];
          data[slug].data.forEach((item, i) => {
            variation.push({
              label: item.lead_status_display === "Appointment with Bill" ? "Utility Bills" : item.lead_status_display,
              values: [item.count_id, data[slug].data[i].count_id],
            });
          });
          _dataset.push({
            label: data[slug].label,
            data: variation.filter((item) => item.label !== "Disqualified Lead" && item.label !== "Contact"),
          });
        });
        setDataset(_dataset);
      }
    };
    load();
    return () => {
      mounted = false;
    };
    // eslint-disable-next-line
  }, []);

  if (loading) {
    return (
      <Box sx={{ height: 200, display: "flex", alignItems: "center", justifyContent: "center" }}>
        <CircularProgress size={20} />
      </Box>
    );
  }

  return (
    <Box>
      <Box sx={{ height: 300 }}>
        {dataset.length > 0 ? (
          <Bar
            data={{
              labels: dataset[0].data.map((item) => item.label),
              datasets: dataset.map((data, i) => {
                return {
                  label: data.label,
                  data: data.data.map((item) => item.values[0]),
                  backgroundColor: i ? "#529cfc" : "#ff3d6a",
                  strokeColor: i ? "#529cfc" : "#ff3d6a",
                };
              }),
            }}
            options={chartOptions}
          />
        ) : (
          <Box p={4}>No Data</Box>
        )}
      </Box>
      <Box px={4} py={2} sx={{ display: "flex" }}>
        {dataset.length > 0 &&
          dataset[0].data.map((d, i) => (
            <Box key={i} sx={{ flex: "1 1 0px", textAlign: "center", fontSize: 12 }}>
              <Box>{d.label}</Box>
            </Box>
          ))}
      </Box>
    </Box>
  );
};

export default AnalyzeChart;

import React from "react";
import { Box, Stack, Button, Tooltip, ClickAwayListener } from "@mui/material";

const EmbedScript = ({ title, description, script }) => {
  const [tooltip, setTooltip] = React.useState(false);

  const handleCopy = () => {
    navigator.clipboard.writeText(script);
    setTooltip(true);
  };

  const handleTooltipClose = () => {
    setTooltip(false);
  };

  return (
    <Stack spacing={1}>
      <Box sx={{ fontSize: 20 }}>{title}</Box>
      <Box sx={{ fontSize: 12 }}>{description}</Box>
      <Box p={4} sx={{ borderRadius: 4, backgroundColor: "background.secondary" }}>
        <Box mb={1}>
          <ClickAwayListener onClickAway={handleTooltipClose}>
            <Tooltip
              arrow
              title="Copied!"
              placement="top"
              PopperProps={{ disablePortal: true }}
              onClose={handleTooltipClose}
              open={tooltip}
              disableFocusListener
              disableHoverListener
              disableTouchListener
            >
              <Button variant="outlined" size="small" color="inherit" onClick={handleCopy}>
                Copy
              </Button>
            </Tooltip>
          </ClickAwayListener>
        </Box>
        <Box sx={{ fontFamily: "monospace" }}>{script}</Box>
      </Box>
    </Stack>
  );
};

export default EmbedScript;

import React from "react";
import { useRecoilState } from "recoil";
import { Box } from "@mui/material";
import { clientAtom } from "store/client";

const Logo = () => {
  const [clientState] = useRecoilState(clientAtom);

  return (
    <Box sx={{ width: 1, height: 64, display: "flex", alignItems: "center" }}>
      <img src={clientState.current.logo} style={{ maxWidth: 196, maxHeight: 64 }} />
    </Box>
  );
};

export default Logo;

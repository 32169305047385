import React from "react";
import { useRecoilValue } from "recoil";
import { Scrollbars } from "react-custom-scrollbars";
import { Box, Paper, Tabs, Tab } from "@mui/material";
import useExperiments from "hooks/useExperiments";
import PageLoading from "shared/PageLoading";
import Design from "./components/design/Design";
import View from "./components/view/View";
import Variations from "./components/variations/Variations";
import { clientAtom } from "store/client";

const ExperimentsPage = () => {
  const clientState = useRecoilValue(clientAtom);

  const hExperiments = useExperiments();

  const [tab, setTab] = React.useState(0);

  React.useEffect(() => {
    if (!hExperiments.initialized) {
      hExperiments.init();
    }
    // eslint-disable-next-line
  }, [clientState.currentId]);

  const handleChange = (e, v) => {
    setTab(v);
  };

  if (!hExperiments.initialized || !hExperiments.client) {
    return <PageLoading />;
  }

  return (
    <Box px={4} sx={{ height: 1 }}>
      <Box sx={{ height: 136, display: "flex", alignItems: "center" }}>
        <Box sx={{ fontSize: 24, color: "primary.main", flexGrow: 1 }}>Experiments</Box>
      </Box>
      <Paper
        sx={{
          height: "calc(100% - 136px)",
          borderTopLeftRadius: 16,
          borderTopRightRadius: 16,
          borderBottomLeftRadius: 0,
          borderBottomRightRadius: 0,
        }}
      >
        <Box px={2} sx={{ borderBottom: 1, borderColor: "divider", height: 50 }}>
          <Tabs value={tab} onChange={handleChange} sx={{ height: 50 }}>
            <Tab label="Design" />
            <Tab label="View" />
            <Tab label="Variations" />
          </Tabs>
        </Box>
        <Box sx={{ height: "calc(100% - 50px)" }}>
          <Scrollbars>
            {tab === 0 && <Design openTab={setTab} />}
            {tab === 1 && <View />}
            {tab === 2 && <Variations />}
          </Scrollbars>
        </Box>
      </Paper>
    </Box>
  );
};

export default ExperimentsPage;

import { atom } from "recoil";

export const clientAtom = atom({
  key: "clientAtom",
  default: {
    all: null,
    current: null,
    currentId: null,
    companyName: "",
    customDomainValidated: false,
  },
});

export const clientCreateAtom = atom({
  key: "clientCreateAtom",
  default: {
    form: 1,
    data: {
      level: "starter",
      date_contract_signed: new Date(),
      is_free_trial: true,
    },
    required: [],
    needVerified: [],
    verified: {},
    errors: {},
    warnings: {},
  },
});

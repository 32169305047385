import React from "react";
import { Box, Divider, Stack } from "@mui/material";
import RadioInput from "../shared/RadioInput";
import useConfigurator from "hooks/useConfigurator";
import { makeStyles } from "@mui/styles";

const dividerStyle = makeStyles({
  root: {
    color: "#15ABE6",

    "&:before": {
      borderColor: "#15ABE6",
    },

    "&:after": {
      borderColor: "#15ABE6",
    },
  },
});

const typeOptions1 = [{ label: "Dynamic", value: "dynamic" }];

const typeOptions2 = [
  { label: "Fixed", value: "relative" },
  { label: "Sticky", value: "sticky" },
];

const positionOptions = [
  { label: "Top", value: "top" },
  { label: "Bottom", value: "bottom" },
];

const Position = () => {
  const hConfigurator = useConfigurator();
  const [values, setValues] = React.useState({
    banner_type: "",
    banner_position: "",
  });

  const dividerClass = dividerStyle();

  React.useEffect(() => {
    setValues({
      banner_type: hConfigurator.client.banner_type,
      banner_position: hConfigurator.client.banner_position,
    });
    // eslint-disable-next-line
  }, []);

  const handleTypeChange = (e, value) => {
    if (value === "dynamic") {
      setValues({
        banner_type: value,
        banner_position: "both",
      });

      hConfigurator.updateMany([
        { key: "banner_type", value },
        { key: "banner_position", value: "both" },
      ]);
    } else {
      setValues({
        banner_type: value,
        banner_position: values.banner_position === "both" ? "top" : values.banner_position,
      });

      hConfigurator.updateMany([
        { key: "banner_type", value },
        { key: "banner_position", value: values.banner_position === "both" ? "top" : values.banner_position },
      ]);
    }
  };

  const handlePositionChange = (e, value) => {
    setValues({
      ...values,
      banner_position: value,
    });
    hConfigurator.updateSingle("banner_position", value);
  };

  return (
    <Box
      display="flex"
      flexDirection={{ xs: "column", lg: "row" }}
      justifyContent="space-between"
      alignItems={{ xs: "flex-start", lg: "stretch" }}
      maxWidth={600}
    >
      <Stack spacing={4}>
        <RadioInput
          legend="Select the position of the widget"
          options={positionOptions}
          value={values.banner_position}
          onChange={handlePositionChange}
          disabled={values.banner_type === "dynamic"}
        />

        <RadioInput
          legend="Select the type of the widget"
          options={typeOptions2}
          value={values.banner_type}
          onChange={handleTypeChange}
        />
      </Stack>

      <Box display={{ xs: "none", lg: "block" }}>
        <Divider orientation="vertical" classes={dividerClass}>
          OR
        </Divider>
      </Box>

      <Box width={1} display={{ xs: "block", lg: "none" }}>
        <Divider orientation="horizontal" flexItem classes={dividerClass}>
          OR
        </Divider>
      </Box>

      <Box display="flex" alignItems="center">
        <RadioInput value={values.banner_type} options={typeOptions1} onChange={handleTypeChange} />
      </Box>
    </Box>
  );
};

export default Position;

import React from "react";
import { Box, Button, Dialog, Stack, TextField } from "@mui/material";

const MAX_TITLE_LENGTH = 54;
const MAX_DESCRIPTION_LENGTH = 255;

const BatteryForm = ({ isAutoBattery, form, header, onSave, onCancel }) => {
  const [title, setTitle] = React.useState(form.title ?? "");
  const [description, setDescription] = React.useState(form.description ?? "");
  const [cost, setCost] = React.useState(form.cost ?? "");
  const [systemSizeMin, setSystemSizeMin] = React.useState(form.system_size_min ?? "");
  const [systemSizeMax, setSystemSizeMax] = React.useState(form.system_size_max ?? "");

  const handleTitleChange = (e) => {
    if (e.target.value.length > MAX_TITLE_LENGTH) return;
    setTitle(e.target.value);
  };

  const handleDescriptionChange = (e) => {
    if (e.target.value.length > MAX_DESCRIPTION_LENGTH) return;
    setDescription(e.target.value);
  };

  const handleCostChange = (e) => {
    setCost(e.target.value);
  };

  const handleSystemSizeMinChange = (e) => {
    setSystemSizeMin(e.target.value);
  };

  const handleSystemSizeMaxChange = (e) => {
    setSystemSizeMax(e.target.value);
  };

  const handleSave = () => {
    onSave({
      id: form.id,
      title,
      description,
      cost,
      system_size_min: isNaN(parseInt(systemSizeMin)) ? null : systemSizeMin,
      system_size_max: isNaN(parseInt(systemSizeMax)) ? null : systemSizeMax,
    });
  };

  const validate = () => {
    if (title.length <= 0 || title.length > MAX_TITLE_LENGTH) return false;
    if (description.length <= 0 || description.length > MAX_DESCRIPTION_LENGTH) return false;
    if (cost.length <= 0 || isNaN(parseInt(cost)) || cost < 0) return false;
    if (isAutoBattery) {
      if (systemSizeMin.length <= 0 || isNaN(parseInt(systemSizeMin)) || systemSizeMin < 0) return false;
      if (systemSizeMax.length <= 0 || isNaN(parseInt(systemSizeMax)) || systemSizeMax < 0) return false;
    }
    return true;
  };

  return (
    <Dialog open={!!form} onClose={onCancel} PaperProps={{ sx: { width: "100%" } }}>
      <Box p={2} sx={{ borderBottom: "1px solid #ccc", fontSize: 20, textTransform: "capitalize" }}>
        {header}
      </Box>

      <Box p={2} sx={{ borderBottom: "1px solid #ccc" }}>
        <Box mb={1} sx={{ fontSize: 12 }}>
          <span style={{ color: "red" }}>* </span>
          Title ({title.length} / {MAX_TITLE_LENGTH})
        </Box>

        <TextField fullWidth size="small" sx={{ mb: 2 }} value={title} onChange={handleTitleChange} />

        <Box mb={1} sx={{ fontSize: 12 }}>
          <span style={{ color: "red" }}>* </span>
          Description ({description.length} / {MAX_DESCRIPTION_LENGTH})
        </Box>

        <TextField
          fullWidth
          multiline
          size="small"
          sx={{ mb: 2 }}
          value={description}
          onChange={handleDescriptionChange}
        />

        <Box mb={1} sx={{ fontSize: 12 }}>
          <span style={{ color: "red" }}>* </span>Monthly Cost
        </Box>

        <TextField fullWidth type="number" size="small" sx={{ mb: 2 }} value={cost} onChange={handleCostChange} />

        <Box sx={{ display: "flex", justifyContent: "space-between", gap: 3 }}>
          <Box sx={{ width: "50%" }}>
            <Box mb={1} sx={{ fontSize: 12 }}>
              {isAutoBattery && <span style={{ color: "red" }}>* </span>}System Size Min
            </Box>
            <TextField
              fullWidth
              type="number"
              size="small"
              sx={{ mb: 2 }}
              value={systemSizeMin}
              onChange={handleSystemSizeMinChange}
            />
          </Box>

          <Box sx={{ width: "50%" }}>
            <Box mb={1} sx={{ fontSize: 12 }}>
              {isAutoBattery && <span style={{ color: "red" }}>* </span>}System Size Max
            </Box>
            <TextField
              fullWidth
              type="number"
              size="small"
              sx={{ mb: 2 }}
              value={systemSizeMax}
              onChange={handleSystemSizeMaxChange}
            />
          </Box>
        </Box>
      </Box>

      <Stack p={2} direction="row" justifyContent="space-between">
        <Stack spacing={2} direction="row">
          <Button variant="contained" disableElevation disabled={!validate()} onClick={() => handleSave()}>
            Save
          </Button>
          <Button variant="outlined" onClick={() => onCancel()}>
            Cancel
          </Button>
        </Stack>
        <Box sx={{ alignSelf: "flex-end", fontSize: "13px", fontWeight: 500 }}>
          <span style={{ color: "red" }}>*</span> Indicates required field
        </Box>
      </Stack>
    </Dialog>
  );
};

export default BatteryForm;

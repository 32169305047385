import React from "react";
import { useRecoilState } from "recoil";
import { Box } from "@mui/material";
import { arrayMoveImmutable } from "array-move";
import { loadingAtom } from "store/common";
import {
  apiLoadDefaultQuestions,
  apiGetClientQuestions,
  apiCreateClientQuestion,
  apiUpdateClientQuestion,
  apiDeleteClientQuestion,
  apiChooseClientQuestion,
} from "store/api";
import useQuickSetupWizard from "hooks/useQuickSetupWizard";
import ChooseQuestion from "./ChooseQuestion";
import CreateQuestion from "./CreateQuestion";
import EditQuestionForm from "./EditQuestionForm";
import QuestionContainer from "./QuestionContainer";
import QuestionBox from "./question-box/QuestionBox";
import QuestionBoxLocked from "./QuestionBoxLocked";
import QuestionBoxReferral from "./QuestionBoxReferral";
import { lockedQuestions, findUnsorted } from "./utils";

const QuestionBuilder = ({ lang = "en" }) => {
  const setupWizard = useQuickSetupWizard();
  const [isReferralOn, setIsReferralOn] = React.useState(false);
  const [, setLoading] = useRecoilState(loadingAtom);
  const [state, setState] = React.useState({
    loading: true,
    defaults: [],
    questions: [],
    question: null,
  });

  React.useEffect(() => {
    let mounted = true;
    const init = async () => {
      const res = await Promise.all([
        apiLoadDefaultQuestions(lang),
        apiGetClientQuestions(setupWizard.client.id, lang),
      ]);
      if (mounted) {
        setState({
          loading: false,
          defaults: res[0].data,
          questions: res[1].data.sort((a, b) => a.position - b.position),
        });
      }
    };
    init();
    setIsReferralOn(setupWizard.client.is_project_detail_referral_on);

    return () => {
      mounted = false;
    };
    // eslint-disable-next-line
  }, []);

  const handlePositionSort = async (questions) => {
    const unsorted = findUnsorted(questions);
    if (unsorted.length > 0) {
      const unsortedIds = Object.fromEntries(unsorted.map((d) => [d.id, d]));
      const res = await Promise.all(
        unsorted.map((question) => apiUpdateClientQuestion(setupWizard.client.id, question.id)(question))
      );
      if (res[0] && res[0].status === 200) {
        setState({
          ...state,
          questions: questions.map((d) => (unsortedIds[d.id] ? unsortedIds[d.id] : d)),
        });
      }
    } else {
      setState({ ...state, questions });
    }
  };

  const handleQuestionEditOpen = (question) => {
    setState({
      ...state,
      question,
    });
  };

  const onSortEnd = async ({ oldIndex, newIndex }) => {
    const questions = arrayMoveImmutable(state.questions, oldIndex, newIndex);
    setState({ ...state, questions });
    setLoading(" ");
    await handlePositionSort(questions);
    setLoading("");
  };

  const chooseTemplate = async (templateId) => {
    setLoading(" ");
    const res = await apiChooseClientQuestion(setupWizard.client.id)({ template_question: templateId });
    if (res.status === 201) {
      await handlePositionSort([...state.questions, res.data]);
    }
    setLoading("");
  };

  const createQuestion = async ({ type, description, choices, label, key }) => {
    setLoading(" ");
    const res = await apiCreateClientQuestion(
      setupWizard.client.id,
      lang
    )({
      question_type: type,
      question_desc: description,
      question_label: label,
      question_key: key,
      choices,
      original_template: null,
    });
    if (res.status === 201) {
      await handlePositionSort([...state.questions, res.data]);
      setLoading("");
      return true;
    } else {
      setLoading("");
      return false;
    }
  };

  const updateQuestion = async ({ question, description, choices, label, key }) => {
    setLoading(" ");
    const res = await apiUpdateClientQuestion(
      setupWizard.client.id,
      question.id
    )({
      ...question,
      question_desc: description,
      question_label: label,
      question_key: key,
      choices,
    });
    if (res.status === 200) {
      setState({
        ...state,
        questions: state.questions.map((d) => (d.id === question.id ? res.data : d)),
        question: null,
      });
    }
    setLoading("");
  };

  const deleteQuestion = async (question) => {
    setLoading(" ");
    const res = await apiDeleteClientQuestion(setupWizard.client.id, question.id);
    if (res.status === 204) {
      await handlePositionSort(state.questions.filter((d) => d.id !== question.id));
    }
    setLoading("");
  };

  const referralSet = (value) => {
    setupWizard.update("is_project_detail_referral_on", value);
    setIsReferralOn(value);
  };

  if (state.loading) {
    return <Box>loading ...</Box>;
  }

  return (
    <Box>
      <Box sx={{ display: "flex" }}>
        <ChooseQuestion
          state={state}
          chooseTemplate={chooseTemplate}
          isReferralOn={isReferralOn}
          referralOn={() => referralSet(true)}
        />
        <CreateQuestion createQuestion={createQuestion} />
      </Box>
      <QuestionBoxLocked question={lockedQuestions[0]} />
      <QuestionContainer onSortEnd={onSortEnd} useDragHandle>
        {state.questions.map((question, index) => (
          <QuestionBox
            key={question.id}
            index={index}
            value={question}
            deleteQuestion={deleteQuestion}
            openEdit={() => handleQuestionEditOpen(question)}
          />
        ))}
      </QuestionContainer>
      <QuestionBoxLocked question={lockedQuestions[1]} />
      {isReferralOn && <QuestionBoxReferral referralOff={() => referralSet(false)} />}
      <EditQuestionForm
        question={state.question}
        closeForm={() => handleQuestionEditOpen(null)}
        updateQuestion={updateQuestion}
      />
    </Box>
  );
};

export default QuestionBuilder;

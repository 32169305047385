import React from "react";
import { IconButton, Menu, MenuItem } from "@mui/material";
import { DotIcon } from "utils/icons";

const TableAction = ({ onRemove }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleRemove = () => {
    onRemove();
    handleClose();
  };

  return (
    <>
      <IconButton onClick={handleClick}>
        <DotIcon />
      </IconButton>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        <MenuItem onClick={handleRemove}>Remove</MenuItem>
      </Menu>
    </>
  );
};

export default TableAction;

import { Button, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import { Link, useNavigate } from "react-router-dom";

export default function HeroRedirect(){
    return <Box display="flex" width="600" flexDirection="row" gap={3} backgroundColor="#D2E8F6" padding={2} alignItems="center" borderRadius={1}>
        <Box display="flex" flexDirection="column">
            <Typography fontWeight={600}>Looking to manage teams on Hero?</Typography>
            <Typography fontSize={12}>Hero Teams are managed from the Hero Admin Portal</Typography>
        </Box>
        <Button variant="contained" target="_blank" href="https://portal.hero.demand-iq.com/login" >Go to Hero Admin Portal</Button>
    </Box>
}
import React from "react";
import { Navigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import { authAtom } from "store/auth";
import { isRepOrUserOrAdminOrSuper } from "utils/authHelper";

function RepLayer({ children }) {
  const [authState] = useRecoilState(authAtom);

  if (isRepOrUserOrAdminOrSuper(authState.user)) {
    return children;
  }

  return <Navigate to="/login" replace />;
}

export default RepLayer;

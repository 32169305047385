import React from "react";
import { Box, Stack, CircularProgress } from "@mui/material";
import useReporting from "hooks/useReporting";
import { apiGetActivityReport } from "store/api";

const EngagementStats = () => {
  const hReporting = useReporting();
  const [loading, setLoading] = React.useState(false);
  const [phoneClickCount, setPhoneClickCount] = React.useState(0);

  React.useEffect(() => {
    updateData();
    // eslint-disable-next-line
  }, [hReporting.main.filter.startDate, hReporting.main.filter.endDate]);

  const updateData = async () => {
    setLoading(true);
    const res = await apiGetActivityReport({
      solar_company: hReporting.client.id,
      start_at: hReporting.formatStartDate(hReporting.main.filter.startDate),
      end_at: hReporting.formatEndDate(hReporting.main.filter.endDate),
      product: "stella1",
      stella2_only: hReporting.main.filter.stella2only,
    });
    if (res.status === 200) {
      const phoneClick = res.data.data.find((d) => d.type === "phone_clicks");
      if (phoneClick) {
        setPhoneClickCount(phoneClick.progress_count);
      }
    }
    setLoading(false);
  };

  return (
    <Box p={2} sx={{ borderRadius: 2, border: "1px solid #ccc" }}>
      <Box sx={{ fontSize: 20, color: "primary.main" }}>ENGAGEMENT STATS</Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-evenly",
          color: "primary.main",
          height: 300,
        }}
      >
        {loading ? (
          <Box mt={4} sx={{ textAlign: "center" }}>
            <CircularProgress size={20} />
          </Box>
        ) : (
          <>
            <Stack spacing={2} sx={{ alignItems: "center" }}>
              <Box sx={{ fontSize: 48 }}>{phoneClickCount}</Box>
              <Box>Phone Number Clicks</Box>
            </Stack>
            <Stack spacing={2} sx={{ alignItems: "center" }}>
              <Box sx={{ fontSize: 48 }}>{hReporting.main.data.disqualifiedRate}%</Box>
              <Box>Disqualification Rate</Box>
            </Stack>
          </>
        )}
      </Box>
    </Box>
  );
};

export default EngagementStats;

import { useRecoilState } from "recoil";
import { uiAtom } from "store/common";

const useUI = () => {
  const [uiState, setUiState] = useRecoilState(uiAtom);

  const showMobileSidebar = () => {
    setUiState({
      ...uiState,
      openMobileSidebar: true,
    });
  };

  const hideMobileSidebar = () => {
    setUiState({
      ...uiState,
      openMobileSidebar: false,
    });
  };

  return {
    ...uiState,
    showMobileSidebar,
    hideMobileSidebar,
  };
};

export default useUI;

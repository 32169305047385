import { useTheme } from "@emotion/react";
import { Typography, Button, useMediaQuery } from "@mui/material";
import Box from "@mui/material/Box";
import { useEffect } from "react";

export default function HeroMessage() {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  return (
    <Box
      display="flex"
      padding={3}
      justifyContent="center"
      alignContent="center"
      backgroundColor="#D2E8F6"
      flexDirection={isDesktop ? "column" : "row"}
      width={isDesktop ? "300px" : "100%"}
      height={isDesktop? "300px": "auto"}
      gap={3}
      borderRadius={5}
    >
      <Box display="flex" justifyContent="center" padding={1}>
        <img src="/images/hero/hero.svg" style={{width: "90px", height: "32px"}} />
      </Box>
      <Box display="flex" flexDirection="column" gap={3}>
        <Typography fontWeight={600} fontSize={18} textAlign="center">
          Looking for your Hero Admin Portal?
        </Typography>
        <Button variant="contained" target="_blank" href="https://portal.hero.demand-iq.com/login" sx={{borderRadius: 32, textAlign: "center"}}>
          Go to Hero Admin Portal
        </Button>
      </Box>
    </Box>
  );
}

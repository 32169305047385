import { atom } from "recoil";
import { subDays } from "date-fns";

export const reportingClientAtom = atom({
  key: "reportingClientAtom",
  default: {
    client: null,
  },
});

export const leadMainFilterAtom = atom({
  key: "leadMainFilterAtom",
  default: {
    startDate: subDays(new Date(), 29),
    endDate: new Date(),
    locations: [],
    currentLocation: null,
    filterType: "date",
    stella2only: false,
  },
});

export const leadMainDataAtom = atom({
  key: "leadMainDataAtom",
  default: {
    chart: null,
    disqualifiedRate: 0,
    minDate: subDays(new Date(), 10000),
    maxDate: new Date(),
  },
});

export const leadConversionFilterAtom = atom({
  key: "leadConversionFilterAtom",
  default: {
    startDate: subDays(new Date(), 29),
    endDate: new Date(),
  },
});

export const leadConversionDataAtom = atom({
  key: "leadConversionDataAtom",
  default: {
    chart: null,
    leadViewEstimate: null,
    minDate: subDays(new Date(), 10000),
    maxDate: new Date(),
  },
});

export const leadSonicFilterAtom = atom({
  key: "leadSonicFilterAtom",
  default: {
    startDate: subDays(new Date(), 29),
    endDate: new Date(),
    locations: [],
    currentLocation: null,
    filterType: "date",
  },
});

export const leadSonicDataAtom = atom({
  key: "leadSonicDataAtom",
  default: {
    chart: null,
    disqualifiedRate: 0,
    minDate: subDays(new Date(), 10000),
    maxDate: new Date(),
  },
});

export const leadStella2FilterAtom = atom({
  key: "leadStella2FilterAtom",
  default: {
    startDate: subDays(new Date(), 29),
    endDate: new Date(),
    locations: [],
    currentLocation: null,
    filterType: "date",
  },
});

export const leadStella2DataAtom = atom({
  key: "leadStella2DataAtom",
  default: {
    chart: null,
    disqualifiedRate: 0,
    minDate: subDays(new Date(), 10000),
    maxDate: new Date(),
  },
});

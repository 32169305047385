import React from "react";
import Div100vh from "react-div-100vh";
import { Box } from "@mui/material";
import { styled } from "@mui/styles";

const Image = styled("img")({
  width: 125,
  marginBottom: 32,
});

const SiteLoading = () => (
  <Div100vh>
    <Box
      pb={4}
      sx={{
        width: 1,
        height: 1,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "#fff",
      }}
    >
      <Image src="/images/loading.gif" alt="..." />
    </Box>
  </Div100vh>
);

export default SiteLoading;

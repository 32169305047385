import React from "react";
import { Box, ClickAwayListener } from "@mui/material";
import { SketchPicker } from "react-color";

const ColorPicker = ({ value, onChange, width = 100 }) => {
  const [show, setShow] = React.useState(false);
  const [color, setColor] = React.useState({});

  React.useEffect(() => {
    setColor({ hex: value });
  }, [value]);

  const handleClick = () => {
    setShow(true);
  };

  const handleClose = () => {
    setShow(false);
  };

  const handleChange = ({ hex }) => {
    setColor({ hex });
    onChange(hex);
  };

  return (
    <Box>
      <Box
        sx={{
          position: "relative",
          cursor: "pointer",
          width: width,
          height: 30,
          borderRadius: "3px",
          border: "1px rgb(73, 169, 222) solid",
          background: color.hex,
        }}
        onClick={handleClick}
      >
        {show && (
          <ClickAwayListener onClickAway={handleClose}>
            <Box sx={{ position: "absolute", top: 40, left: 0, zIndex: "2" }}>
              <SketchPicker color={color} onChange={handleChange} disableAlpha />
            </Box>
          </ClickAwayListener>
        )}
      </Box>
    </Box>
  );
};

export default ColorPicker;

import React from "react";
import { Box, Button, Dialog } from "@mui/material";

const Confirm = ({ open, onYes, onNo }) => {
  return (
    <Dialog open={open} onClose={() => onNo()}>
      <Box p={4}>
        <Box sx={{ fontSize: 18, mb: 4 }}>There are unsaved changes. Do you really want to leave?</Box>
        <Box sx={{ textAlign: "right" }}>
          <Button variant="contained" sx={{ mr: 1 }} onClick={() => onYes()}>
            Yes
          </Button>
          <Button variant="outlined" onClick={() => onNo()}>
            No
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
};

export default Confirm;

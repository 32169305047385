import React from "react";
import { Stack, Box, Button, Dialog, TextField } from "@mui/material";
import ChoiceForm from "./ChoiceForm";
import ChooseQuestionType from "./ChooseQuestionType";
import { validateQuestionData, generateKey } from "./utils";
import { OPEN_ENDED_QN, SINGLE_CHOICE_QN } from "utils/constant";

const CreateQuestion = ({ createQuestion }) => {
  const [form, setForm] = React.useState(false);
  const [type, setType] = React.useState(SINGLE_CHOICE_QN);
  const [description, setDescription] = React.useState("");
  const [choices, setChoices] = React.useState([]);
  const [label, setLabel] = React.useState("");

  const handleFormOpen = () => {
    setForm(true);
  };

  const handleFormClose = () => {
    setForm(false);
    setType(SINGLE_CHOICE_QN);
    setDescription("");
    setChoices([]);
    setLabel("");
  };

  const handleCreateQuestion = async () => {
    let success;
    if (type === SINGLE_CHOICE_QN) {
      success = await createQuestion({ type, description, choices });
    }
    if (type === OPEN_ENDED_QN) {
      success = await createQuestion({ type, description, label, key: generateKey(label) });
    }
    if (success) {
      handleFormClose();
    }
  };

  const handleTypeChange = (_, value) => {
    setType(value);
  };

  const handleDescriptionChange = (e) => {
    if (e.target.value.length <= 108) {
      setDescription(e.target.value);
    }
  };

  const handleLabelChange = (e) => {
    if (e.target.value.length <= 24) {
      setLabel(e.target.value);
    }
  };

  return (
    <>
      <Button variant="contained" onClick={handleFormOpen}>
        Add New Question
      </Button>
      <Dialog open={form} onClose={() => {}} PaperProps={{ sx: { width: "100%" } }}>
        <Box p={2} sx={{ borderBottom: "1px solid #ccc", fontSize: 20 }}>
          New Question
        </Box>
        <Box p={2} sx={{ borderBottom: "1px solid #ccc" }}>
          <Box mb={1} sx={{ fontSize: 12 }}>
            Question Type
          </Box>
          <ChooseQuestionType value={type} onChange={handleTypeChange} />
        </Box>
        <Box p={2} sx={{ borderBottom: "1px solid #ccc" }}>
          <Box mb={2} sx={{ fontSize: 12 }}>
            Description ({description.length} / 108)
          </Box>
          <TextField fullWidth size="small" value={description} onChange={handleDescriptionChange} />
        </Box>
        {type === SINGLE_CHOICE_QN && (
          <Box p={2} sx={{ borderBottom: "1px solid #ccc" }}>
            <Box mb={2} sx={{ fontSize: 12 }}>
              Choices
            </Box>
            <ChoiceForm initial={choices} onUpdate={setChoices} />
          </Box>
        )}
        {type === OPEN_ENDED_QN && (
          <Box p={2} sx={{ borderBottom: "1px solid #ccc" }}>
            <Box mb={2} sx={{ fontSize: 12 }}>
              Label ({label.length} / 24)
            </Box>
            <TextField size="small" value={label} onChange={handleLabelChange} sx={{ width: 240 }} />
          </Box>
        )}
        <Stack p={2} spacing={2} direction="row">
          <Button
            variant="contained"
            disableElevation
            disabled={!validateQuestionData({ type, description, choices, label })}
            onClick={handleCreateQuestion}
          >
            Submit
          </Button>
          <Button variant="outlined" onClick={handleFormClose}>
            Cancel
          </Button>
        </Stack>
      </Dialog>
    </>
  );
};

export default CreateQuestion;

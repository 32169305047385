import React from "react";
import { Box, MenuItem, Select } from "@mui/material";
import Sungage from "./Sungage";
import Sunlight from "./Sunlight";
import useQuickSetupWizard from "hooks/useQuickSetupWizard";

const PreQualification = () => {
  const setupWizard = useQuickSetupWizard();
  const [partner, setPartner] = React.useState("none");
  const [sungageValues, setSungageValues] = React.useState();
  const [sunlightValues, setSunlightValues] = React.useState();

  React.useEffect(() => {
    setPartner(setupWizard.client.pre_qualification_partner || "none");
    setSungageValues({
      sungage_query_ak: setupWizard.client.sungage_query_ak,
      sungage_query_ue: setupWizard.client.sungage_query_ue,
    });
    setSunlightValues({
      sunlight_username: setupWizard.client.sunlight_username,
      sunlight_password: setupWizard.client.sunlight_password,
      sunlight_email: setupWizard.client.sunlight_email,
    });
  }, []);

  const handleSelectPartner = (e) => {
    setPartner(e.target.value);
    setupWizard.update("pre_qualification_partner", e.target.value === "none" ? "" : e.target.value);
  };

  const handleSungageValueChange = (key) => (e) => {
    setSungageValues({
      ...sungageValues,
      [key]: e.target.value,
    });
    setupWizard.update(key, e.target.value);
  };

  const handleSunlightValueChange = (key, value) => {
    setSunlightValues({
      ...sunlightValues,
      [key]: value,
    });
    setupWizard.update(key, value);
  };

  return (
    <Box>
      <Select value={partner} onChange={handleSelectPartner} sx={{ width: "240px" }}>
        {partnerList.map((item, i) => (
          <MenuItem key={i} value={item.value}>
            {item.label}
          </MenuItem>
        ))}
      </Select>

      {partner === "sungage" && <Sungage values={sungageValues} onChange={handleSungageValueChange} />}
      {partner === "sunlight" && <Sunlight values={sunlightValues} onChange={handleSunlightValueChange} />}
    </Box>
  );
};

const partnerList = [
  {
    value: "none",
    label: "None",
  },
  {
    value: "sungage",
    label: "Sungage",
  },
  {
    value: "sunlight",
    label: "Sunlight",
  },
];

export default PreQualification;

import React from "react";
import moment from "moment-timezone";
import {
  Box,
  Stack,
  Paper,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  CircularProgress,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import useExperiments from "hooks/useExperiments";
import useAlert from "hooks/useAlert";
import { apiGetExperiments, apiStartExperiment, apiStopExperiment } from "store/api";
import ConfirmStopDialog from "./ConfirmStopDialog";
import AnalyzeDialog from "./AnalyzeDialog";

const objectiveLabels = {
  lead: "Leads",
  qualified_lead: "Qualified Leads",
  appointment: "Appointments",
  appointment_with_bill: "Appointments with Bills",
  average_forward_progress: "Average Forward Progress",
};

const ExperimentTable = ({ setEditItem }) => {
  const hExperiments = useExperiments();
  const hAlert = useAlert();

  const mounted = React.useRef(true);

  const [isConfirm, setIsConfirm] = React.useState(false);
  const [state, setState] = React.useState({
    loading: true,
    experiments: [],
    currentId: null,
    current: null,
    showAnalyze: false,
    isEditing: false,
    isRunning: false,
  });

  React.useEffect(() => {
    load();

    return () => {
      mounted.current = false;
    };
    // eslint-disable-next-line
  }, []);

  const load = async () => {
    const res = await apiGetExperiments({
      solar_company: hExperiments.client.id,
    });

    if (mounted.current) {
      if (res.status === 200) {
        setState({
          ...state,
          loading: false,
          experiments: res.data,
        });
      } else {
        setState({
          ...state,
          loading: false,
        });
      }
    }
  };

  const handleRun = async (experiment) => {
    if (experiment.status === "running") {
      setState({
        ...state,
        currentId: experiment.id,
      });

      setIsConfirm(true);
    } else {
      setState({
        ...state,
        isRunning: true,
        currentId: experiment.id,
      });

      const res = await apiStartExperiment(experiment.id)({
        solar_company: hExperiments.client.id,
      });

      if (mounted.current) {
        if (res.status === 200) {
          setState({
            ...state,
            isRunning: false,
            currentId: null,
            experiments: state.experiments.map((d) => (d.id === experiment.id ? res.data : d)),
          });
        } else {
          setState({
            ...state,
            isRunning: false,
            currentId: null,
          });
          hAlert.show(res.data.detail || "Something went wrong", false);
        }
      }
    }
  };

  const handleStop = async () => {
    setIsConfirm(false);

    setState({
      ...state,
      isRunning: true,
    });

    const res = await apiStopExperiment(state.currentId)({
      solar_company: hExperiments.client.id,
    });

    if (mounted.current) {
      if (res.status === 200) {
        setState({
          ...state,
          isRunning: false,
          currentId: null,
          experiments: state.experiments.map((d) => (d.id === state.currentId ? res.data : d)),
        });
      } else {
        setState({
          ...state,
          isRunning: false,
          currentId: null,
        });
      }
    }
  };

  const closeConfirm = () => {
    setIsConfirm(false);
  };

  const openAnalyze = (experiment) => {
    setState({
      ...state,
      showAnalyze: true,
      current: experiment,
    });
  };

  const closeAnalyze = () => {
    setState({
      ...state,
      showAnalyze: false,
    });
  };

  return (
    <Box>
      {state.loading ? (
        <CircularProgress size={20} />
      ) : (
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 900 }}>
            <TableHead>
              <TableRow>
                <TableCell align="center">Name</TableCell>
                <TableCell align="center">Type</TableCell>
                <TableCell align="center">Start Date</TableCell>
                <TableCell align="center">Status</TableCell>
                <TableCell align="center">Objective</TableCell>
                <TableCell align="center">End On</TableCell>
                <TableCell align="center" sx={{ width: 250 }}>
                  Action
                </TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {state.experiments.map((d) => (
                <TableRow key={d.id}>
                  <TableCell>{d.name}</TableCell>
                  <TableCell align="center" sx={{ textTransform: "capitalize" }}>
                    {d.type}
                  </TableCell>
                  <TableCell align="center">{moment(d.start_datetime).format("YYYY-MM-DDTHH:mm:ssZ")}</TableCell>
                  <TableCell align="center">{d.status}</TableCell>
                  <TableCell align="center">{objectiveLabels[d.objective]}</TableCell>
                  <TableCell align="center">{d.result}</TableCell>
                  <TableCell align="center">
                    <Stack direction="row" spacing={1}>
                      <LoadingButton
                        size="small"
                        variant="contained"
                        disabled={state.isRunning}
                        loading={state.isRunning && state.currentId === d.id}
                        onClick={() => handleRun(d)}
                      >
                        {d.status === "running" ? "Stop" : "Run"}
                      </LoadingButton>

                      <LoadingButton
                        size="small"
                        variant="contained"
                        disabled={state.isRunning}
                        onClick={() => setEditItem(d)}
                      >
                        Edit
                      </LoadingButton>

                      <LoadingButton
                        size="small"
                        variant="contained"
                        disabled={d.status === "pending" || state.isRunning}
                        onClick={() => openAnalyze(d)}
                      >
                        Analyze
                      </LoadingButton>
                    </Stack>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      <ConfirmStopDialog open={isConfirm} onClose={closeConfirm} onConfirm={handleStop} />

      <AnalyzeDialog experiment={state.current} open={state.showAnalyze} onClose={closeAnalyze} />
    </Box>
  );
};

export default ExperimentTable;

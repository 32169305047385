import { Box, Stack, Tooltip, IconButton } from "@mui/material";
import SingleText from "../shared/SingleText";
import { QuestionCircleIcon } from "utils/icons";
import "shared/tooltip.scss";

const GoogleAds = () => {
  return (
    <Box p={4}>
      <Box mb={4} sx={{ display: "flex", alignItems: "center" }}>
        <Box mr={1} sx={{ fontSize: 20, fontWeight: "bold" }}>
          Google Ads
        </Box>

        <Tooltip
          classes={{
            tooltip: "tooltip-background",
            arrow: "tooltip-caret",
          }}
          title={
            <Box sx={{ fontSize: 14, fontWeight: 400 }}>
              <Box>To learn more about this please visit</Box>
              <a
                className="tooltip-link"
                href="https://knowledge.demand-iq.com/google-ads-server-to-server-conversion-tracking"
                target="_blank"
                rel="noopener noreferrer"
              >
                our knowledge base article
              </a>
            </Box>
          }
          placement="right"
          arrow
        >
          <IconButton size="small">
            <QuestionCircleIcon />
          </IconButton>
        </Tooltip>
      </Box>

      <Stack spacing={4}>
        <SingleText field="google_ads_global_site_tag" label="Global Site Tag" />
        <SingleText field="google_ads_code_lead" label="Google Ads Code: Lead" />
        <SingleText field="google_ads_code_apt" label="Google Ads Code: Appointment" />
        <SingleText field="google_ads_code_bill" label="Google Ads Code: Bill" />
      </Stack>
    </Box>
  );
};

export default GoogleAds;

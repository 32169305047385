import * as React from "react";
import Box from "@mui/material/Box";
import RadioInput from "./RadioInput";
import { OPEN_ENDED_QN, SINGLE_CHOICE_QN } from "utils/constant";

const QuestionType = ({ value, onChange }) => {
  const options = [
    { label: "Single Choice", value: SINGLE_CHOICE_QN },
    { label: "Open-Ended", value: OPEN_ENDED_QN },
  ];

  return (
    <Box>
      <RadioInput value={value} options={options} onChange={onChange} />
    </Box>
  );
};

export default QuestionType;

import _ from "lodash";
import moment from "moment";
import {
  isAdminOrSuper,
  isSuper,
  isUserOrAdminOrSuper,
  isRepOrUserOrAdminOrSuper,
  isAnalystOrUserOrAdminOrSuper,
} from "./authHelper";

export const getDisplayName = (user, checkEmail = false) => {
  let displayName = "";
  if (user.first_name) {
    displayName = user.first_name;
  }
  if (user.last_name) {
    if (displayName) {
      displayName += " ";
    }
    displayName += user.last_name;
  }
  if (!displayName && checkEmail) {
    displayName += user.email ? user.email.split("@")[0] : "";
  }
  return displayName;
};

export const queryToJson = (str) => {
  const search = str.substring(str[0] === "?" ? 1 : 0);
  try {
    const obj = JSON.parse(
      '{"' + decodeURI(search).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"') + '"}'
    );
    return obj;
  } catch (err) {
    return null;
  }
};

export const getSlug = (value) => {
  const string = value || "";
  return string
    .toString()
    .trim()
    .toLowerCase()
    .replace(/\s+/g, "-")
    .replace(/[^\w-]+/g, "")
    .replace(/--+/g, "-")
    .replace(/^-+/, "")
    .replace(/-+$/, "");
};

export function getPermissions(user, isDefaultCompany) {
  const menus = [];
  menus.push({ value: "analyst", label: "Analyst", auth: isAnalystOrUserOrAdminOrSuper });
  menus.push({ value: "rep", label: "Rep", auth: isRepOrUserOrAdminOrSuper });
  menus.push({ value: "user", label: "User", auth: isUserOrAdminOrSuper });
  menus.push({ value: "admin", label: "Admin", auth: isAdminOrSuper });
  isDefaultCompany && menus.push({ value: "superuser", label: "Super Admin", auth: isSuper });

  return menus.map(({ auth, ...menu }) => ({
    ...menu,
    disabled: !auth(user),
  }));
}

export const getUrl = (custom_domain, company_slug) => {
  let productionUrl = "";
  try {
    if (!_.isNil(custom_domain) && custom_domain) {
      productionUrl = new URL(`https://${custom_domain}`).host;
    } else {
      productionUrl = `${company_slug}.estimate.demand-iq.com`;
    }
  } catch (error) {
    console.error(error);
    productionUrl = `${company_slug}.estimate.demand-iq.com`;
  }

  const env = process.env.REACT_APP_TRACKING_ENV;
  if (env === "production") {
    return productionUrl;
  } else if (env === "staging") {
    return `${company_slug}.estimate-staging.demand-iq.com`;
  } else {
    return `${company_slug}.calculator-dev.demand-iq.com`;
  }
};

const getStellaSubdomain = (env) => {
  if (env === "production") {
    return "stella";
  } else if (env === "staging") {
    return "stella-staging";
  } else {
    return "stella-dev";
  }
};

export const generateEmbedBannerScript = (custom_domain, company_slug) => {
  const url = getUrl(custom_domain, company_slug);
  const sub = getStellaSubdomain(process.env.REACT_APP_TRACKING_ENV);

  const widgetScript = `<script type="text/javascript" src="https://${sub}.demand-iq.com/banner-widget/${url}/" data-utm-content=""></script>`;

  return widgetScript;
};

export const generateEmbedAddressWidgetScript = (custom_domain, company_slug, lang = "en") => {
  const url = getUrl(custom_domain, company_slug);
  const sub = getStellaSubdomain(process.env.REACT_APP_TRACKING_ENV);

  let dataLangContent = "";
  if (lang !== "en") {
    dataLangContent = ` data-lang="${lang}"`;
  }

  const widgetScript = `<div class="demand-iq-stella-widget" data-utm-content=""${dataLangContent}></div><script type="text/javascript" src="https://${sub}.demand-iq.com/widget-address/${url}/"></script>`;

  return widgetScript;
};

export const generateEmbedZipcodeWidgetScript = (custom_domain, company_slug, lang = "en") => {
  const url = getUrl(custom_domain, company_slug);
  const sub = getStellaSubdomain(process.env.REACT_APP_TRACKING_ENV);

  let dataLangContent = "";
  if (lang !== "en") {
    dataLangContent = ` data-lang="${lang}"`;
  }

  const widgetScript = `<div class="diq-stella-zipcode-entry-widget" data-utm-content=""${dataLangContent}></div><script type="text/javascript" src="https://${sub}.demand-iq.com/widget-zipcode/${url}/"></script>`;

  return widgetScript;
};

export const generateEmbedChatWidgetScript = (appUrl = "") => {
  const trackingEnv = process.env.REACT_APP_TRACKING_ENV;
  let domain = "";

  if (trackingEnv === "production") {
    domain = "stella2.demand-iq.com";
  } else if (trackingEnv === "staging") {
    domain = "stella2-staging.demand-iq.com";
  } else {
    domain = "stella2-dev.demand-iq.com";
  }

  const widgetScript = `<script type="text/javascript" defer src="https://${domain}/embed?app_url=${appUrl}"></script>`;
  return widgetScript;
};

export const calculateTotalMinutes = (events) => {
  return events.reduce((sum, event) => {
    return sum + Math.abs(moment(event.end).diff(moment(event.start), "minutes"));
  }, 0);
};

export const SUBSCRITPTION_PLANS_DATA = {
  starter: {
    label: "Starter",
    minimum_monthly_fee: 300,
    cost_per_lead: 10,
    cost_per_appointment: 15,
    cost_per_appointment_with_bill: 25,
  },
  premier: {
    label: "Premier",
    minimum_monthly_fee: 800,
    cost_per_lead: 8,
    cost_per_appointment: 12,
    cost_per_appointment_with_bill: 20,
  },
  pro: {
    label: "Pro",
    minimum_monthly_fee: 1750,
    cost_per_lead: 7,
    cost_per_appointment: 10.5,
    cost_per_appointment_with_bill: 17.5,
  },
  enterprise: {
    label: "Enterprise",
    minimum_monthly_fee: 6000,
    cost_per_lead: 6,
    cost_per_appointment: 9,
    cost_per_appointment_with_bill: 15,
  },
};

import React from "react";
import { Box } from "@mui/material";
import useQuickSetupWizard from "hooks/useQuickSetupWizard";
import FileUpload from "shared/wizard/FileUpload";

const UploadAiAvatar = () => {
  const setupWizard = useQuickSetupWizard();
  const [avatar, setAvatar] = React.useState(null);

  React.useEffect(() => {
    setAvatar(setupWizard.client.ai_avatar);
    // eslint-disable-next-line
  }, []);

  const handleChange = (file, url) => {
    setAvatar(url);
    setupWizard.update("ai_avatar", file);
  };

  return (
    <Box>
      <FileUpload label="Upload Ai Avatar" onChange={handleChange} />
      {avatar && (
        <Box mt={4} sx={{ maxWidth: 360 }}>
          <img src={avatar} alt="avatar" />
        </Box>
      )}
    </Box>
  );
};

export default UploadAiAvatar;

import React from "react";
import { Box } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import useConfigurator from "hooks/useConfigurator";

const VerifyDns = ({ loading, onVerifyClick }) => {
  const hConfigurator = useConfigurator();

  return (
    <Box
      sx={{
        display: "flex",
        opacity: hConfigurator.client.custom_domain ? 1 : 0.3,
      }}
    >
      <Box
        sx={{
          width: 40,
          height: 40,
          background: "#666",
          color: "#fff",
          borderRadius: "50%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        3
      </Box>
      <Box ml={2} sx={{ flex: 1 }}>
        <Box mt={0.5} sx={{ fontSize: 20 }}>
          Verify your domain
        </Box>
        <Box
          sx={{
            fontSize: 12,
            color: "#888",
            mt: 1,
          }}
        >
          Configure this so that your customers see this as your tool.
        </Box>
        {hConfigurator.client.custom_domain && (
          <Box mt={2}>
            <LoadingButton
              variant="contained"
              disabled={loading !== ""}
              loading={loading === "verifying"}
              onClick={onVerifyClick}
            >
              Verify
            </LoadingButton>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default VerifyDns;

import React from "react";
import { Box, TextField, MenuItem } from "@mui/material";
import { LocalizationProvider, DateTimePicker } from "@mui/lab";
import DateAdapter from "@mui/lab/AdapterDateFns";
import useExperimentsEditor from "hooks/useExperimentsEditor";

const RESULT_DATE = "Particular Date Time";
const RESULT_VISITOR = "Certain number of visitors";
const RESULT_PERCENT = "Percent confidence";

const results = [
  {
    value: RESULT_DATE,
    label: "End on a particular date & time",
  },
  {
    value: RESULT_VISITOR,
    label: "End after a certain number of visitors",
  },
  {
    value: RESULT_PERCENT,
    label: "End on 95% confidence in result",
  },
];

const EndDateForm = () => {
  const hEditor = useExperimentsEditor();

  React.useEffect(() => {
    if (hEditor.values.result === RESULT_VISITOR && !hEditor.values.end_visitors_amount) {
      hEditor.setValues(
        {},
        {
          end_visitors_amount: "Required",
        }
      );
    }
    // eslint-disable-next-line
  }, []);

  const handleResultChange = (e) => {
    let values = {
      result: e.target.value,
    };

    let errors = {
      result: null,
      end_visitors_amount: null,
    };

    if (e.target.value === RESULT_VISITOR && !hEditor.values.end_visitors_amount) {
      errors = {
        ...errors,
        end_visitors_amount: "Required",
      };
    }

    hEditor.setValues(values, errors);
  };

  const handleEndDateChange = (value) => {
    hEditor.setValue("end_datetime", value);
  };

  const handleVisitorAmountChange = (e) => {
    if (e.target.value) {
      hEditor.setValue("end_visitors_amount", e.target.value);
    } else {
      hEditor.setValue("end_visitors_amount", e.target.value, "Required");
    }
  };

  return (
    <Box>
      <Box mb={2}>Choose a method of ending the experiment</Box>

      <Box>
        <TextField
          fullWidth
          select
          sx={{ maxWidth: 400 }}
          value={hEditor.values.result || ""}
          onChange={handleResultChange}
        >
          {results.map((d) => (
            <MenuItem key={d.value} value={d.value}>
              {d.label}
            </MenuItem>
          ))}
        </TextField>
      </Box>

      {hEditor.errors.result && hEditor.showError && <Box sx={{ color: "error.main" }}>{hEditor.errors.result}</Box>}

      {hEditor.values.result === RESULT_DATE && (
        <Box mt={2}>
          <LocalizationProvider dateAdapter={DateAdapter}>
            <DateTimePicker
              renderInput={(params) => <TextField {...params} />}
              value={hEditor.values.end_datetime}
              onChange={handleEndDateChange}
            />
          </LocalizationProvider>
        </Box>
      )}

      {hEditor.values.result === RESULT_VISITOR && (
        <Box mt={2}>
          <TextField
            fullWidth
            type="number"
            sx={{ maxWidth: 400 }}
            value={hEditor.values.end_visitors_amount || ""}
            onChange={handleVisitorAmountChange}
          />
        </Box>
      )}

      {hEditor.errors.end_visitors_amount && hEditor.showError && (
        <Box sx={{ color: "error.main" }}>{hEditor.errors.end_visitors_amount}</Box>
      )}
    </Box>
  );
};

export default EndDateForm;

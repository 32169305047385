import React from "react";
import { Box, Table, TableHead, TableBody, TableRow, TableCell, CircularProgress } from "@mui/material";
import useConfigurator from "hooks/useConfigurator";
import DnsRecord from "./DnsRecord";

const DnsRecordTable = ({ loading, emailDomain, dnsRecords, dnsValidations }) => {
  const hConfigurator = useConfigurator();

  const extractDomainName = () => {
    let words = hConfigurator.client.custom_domain.split(".");
    let extension = words.pop();
    let name = words.pop();
    return `${name}.${extension}`;
  };

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
        }}
      >
        {hConfigurator.client.custom_domain && <Box mr={2}>DNS entries</Box>}
        {(loading === "verifying" || loading === "refreshing") && <CircularProgress size={20} color="inherit" />}
      </Box>
      {hConfigurator.client.custom_domain && emailDomain && (
        <Box mt={2} sx={{ border: "1px solid #ccc" }}>
          <Table sx={{ minWidth: 650 }}>
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Type</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Value</TableCell>
                <TableCell>TTL</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell colSpan={6} sx={{ backgroundColor: "#eee" }}>
                  <Box sx={{ textAlign: "center" }}>
                    Update DNS for <b>{extractDomainName() || "Not exist"}</b>
                  </Box>
                </TableCell>
              </TableRow>
              <DnsRecord record={{ ...dnsRecords.custom_domain, ...dnsValidations.custom_domain }} />
              <TableRow>
                <TableCell colSpan={6} sx={{ backgroundColor: "#eee" }}>
                  <Box sx={{ textAlign: "center" }}>
                    Update DNS for <b>{emailDomain || "Not exist"}</b>
                  </Box>
                </TableCell>
              </TableRow>
              <DnsRecord record={{ ...dnsRecords.mail_cname, ...dnsValidations.mail_cname }} />
              <DnsRecord record={{ ...dnsRecords.dkim1, ...dnsValidations.dkim1 }} />
              <DnsRecord record={{ ...dnsRecords.dkim2, ...dnsValidations.dkim2 }} />
              <DnsRecord record={{ ...dnsRecords.domain_cname, ...dnsValidations.domain_cname }} />
              <DnsRecord record={{ ...dnsRecords.owner_cname, ...dnsValidations.owner_cname }} />
            </TableBody>
          </Table>
        </Box>
      )}
    </Box>
  );
};

export default DnsRecordTable;

const esprima = require("esprima");

const extractJavaScript = (script) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(script, "text/html");
  const scriptTags = doc.getElementsByTagName("script");
  let extractedCode = "";

  for (let i = 0; i < scriptTags.length; i++) {
    extractedCode += scriptTags[i].textContent;
  }

  return extractedCode;
};

export const validateJavaScript = (script) => {
  if (!script) return true;

  const extractedCode = extractJavaScript(script);

  try {
    esprima.parseScript(extractedCode, { tolerant: true });
    return true;
  } catch (error) {
    console.error(error);
    return false;
  }
};

import React from "react";
import { Box, Stack, TextField, MenuItem } from "@mui/material";
import { LocalizationProvider, DatePicker } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import useReporting from "hooks/useReporting";
import DateTemplateMenu from "../../shared/DateTemplateMenu";

const Filter = () => {
  const hReporting = useReporting();
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    loadLocations();
    // eslint-disable-next-line
  }, [hReporting.sonic.filter.startDate, hReporting.sonic.filter.endDate]);

  const loadLocations = async () => {
    setLoading(true);
    await hReporting.getSonicLocations();
    setLoading(false);
  };

  const handleLocationChange = (e) => {
    hReporting.sonic.setFilter({
      currentLocation: e.target.value,
      filterType: "location",
    });
  };

  const handleStartDateUpdate = (date) => {
    hReporting.sonic.setFilter({
      startDate: date,
      currentLocation: "all",
      filterType: "date",
    });
  };

  const handleEndDateUpdate = (date) => {
    hReporting.sonic.setFilter({
      endDate: date,
      currentLocation: "all",
      filterType: "date",
    });
  };

  const handleTemplateSelect = (startDate, endDate) => {
    hReporting.sonic.setFilter({
      startDate,
      endDate,
      currentLocation: "all",
      filterType: "date",
    });
  };

  return (
    <Box p={4}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <Stack direction="row" spacing={2} sx={{ alignItems: "center" }}>
          <DateTemplateMenu disabled={loading} onSelect={handleTemplateSelect} />
          <DatePicker
            disabled={loading}
            value={hReporting.sonic.filter.startDate}
            maxDate={hReporting.sonic.filter.endDate}
            onChange={handleStartDateUpdate}
            renderInput={(params) => <TextField {...params} />}
          />
          <Box sx={{ width: 10, height: "1px", backgroundColor: "#000" }} />
          <DatePicker
            disabled={loading}
            value={hReporting.sonic.filter.endDate}
            minDate={hReporting.sonic.filter.startDate}
            onChange={handleEndDateUpdate}
            renderInput={(params) => <TextField {...params} />}
          />
          <TextField
            select
            disabled={loading}
            value={hReporting.sonic.filter.currentLocation || ""}
            onChange={handleLocationChange}
            sx={{ minWidth: 200 }}
          >
            {hReporting.sonic.filter.locations.map((d) => (
              <MenuItem key={d.value} value={d.value}>
                {d.label}
              </MenuItem>
            ))}
          </TextField>
        </Stack>
      </LocalizationProvider>
    </Box>
  );
};

export default Filter;

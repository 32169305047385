import React from "react";
import { Box } from "@mui/material";
import ApiKey from "./components/ApiKey";
import CustomDomain from "./components/CustomDomain";
import Instapage from "./components/Instapage";
import Facebook from "./components/Facebook";
import GoogleAnalytics from "./components/GoogleAnalytics";
import GoogleAds from "./components/GoogleAds";
import CustomCodes from "./components/CustomCodes";
import CustomHtml from "./components/CustomHtml";
import CustomHtmlOutside from "./components/CustomHtmlOutside";
import PrismaticSetting from "./components/PrismaticSetting";

const Integration = () => {
  return (
    <Box sx={{ "& > *+*": { borderTop: "1px solid #ddd" } }}>
      <ApiKey />

      <CustomDomain />

      <Instapage />

      <Facebook />

      <GoogleAnalytics />

      <GoogleAds />

      <CustomCodes />

      <CustomHtml />

      <CustomHtmlOutside />

      <PrismaticSetting />
    </Box>
  );
};

export default Integration;

import React from "react";
import moment from "moment";
import { Button, Dialog, Stack, Box, FormControlLabel, Checkbox } from "@mui/material";
import useAlert from "hooks/useAlert";
import useCommon from "hooks/useCommon";
import useReporting from "hooks/useReporting";
import { apiGetExportLeads } from "store/api";
import { DownloadIcon } from "utils/icons";
import { exportLeadStatus, exportLeadStatusDefault } from "../../shared/const";

const ExportLead = () => {
  const hAlert = useAlert();
  const hCommon = useCommon();
  const hReporting = useReporting();
  const [open, setOpen] = React.useState(false);
  const [data, setData] = React.useState(exportLeadStatusDefault);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (e, status) => {
    let newData = [];
    if (e.target.checked) {
      newData = [...data, status];
    } else {
      newData = data.filter((d) => d !== status);
    }
    setData(newData);
  };

  const handleExport = async () => {
    if (data.length === 0) {
      hAlert.show("Please select at least one status", false);
      return;
    }

    hCommon.loading("Exporting ...");
    const res = await apiGetExportLeads({
      is_instant_estimate: true,
      format: "csv",
      min_last_session: hReporting.conversion.filter.startDate,
      max_first_session: hReporting.conversion.filter.endDate,
      timezone: moment.tz.guess(),
      lead_status: data,
      solar_company: hReporting.client.id,
    });
    if (res.status === 200) {
      const filename = `report-${moment(hReporting.conversion.filter.startDate).format("YYYYMMDD")}-${moment(
        hReporting.conversion.filter.endDate
      ).format("YYYYMMDD")}.csv`;
      const element = document.createElement("a");
      element.setAttribute("href", "data:text/plain;charset=utf-8," + encodeURIComponent(res.data));
      element.setAttribute("download", filename);
      element.style.display = "none";
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
    } else {
      hAlert.show("Something went wrong", false);
    }
    hCommon.loading("");
  };

  return (
    <>
      <Button variant="outlined" startIcon={<DownloadIcon />} onClick={handleClickOpen}>
        Export Leads
      </Button>
      <Dialog open={open} onClose={handleClose} PaperProps={{ sx: { width: "100%" } }}>
        <Stack p={4} spacing={2}>
          <Box sx={{ fontSize: 20, color: "primary.main" }}>Export Leads</Box>
          <Box sx={{ fontSize: 14, color: "#888" }}>Select the statuses you'd like to export</Box>
          <Box>
            <Box sx={{ display: "flex", flexWrap: "wrap" }}>
              {exportLeadStatus.map((d) => (
                <Box sx={{ width: 250 }} key={d.value}>
                  <FormControlLabel
                    control={<Checkbox checked={data.includes(d.value)} onChange={(e) => handleChange(e, d.value)} />}
                    label={d.label}
                  />
                </Box>
              ))}
            </Box>
          </Box>
          <Stack direction="row" spacing={1} sx={{ justifyContent: "flex-end" }}>
            <Button variant="outlined" onClick={handleClose}>
              Cancel
            </Button>
            <Button disableElevation variant="contained" onClick={handleExport}>
              Export
            </Button>
          </Stack>
        </Stack>
      </Dialog>
    </>
  );
};

export default ExportLead;

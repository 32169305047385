import React from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import moment from "moment";
import useQuickSetupWizard from "hooks/useQuickSetupWizard";
import { Box } from "@mui/material";

const daysOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
const START_TIME = "07:00:00";
const END_TIME = "21:00:00";

const AppointmentCalendar = () => {
  const hWizard = useQuickSetupWizard();
  const initialEvents = hWizard.client.appointment_schedule ? JSON.parse(hWizard.client.appointment_schedule) : [];

  const handleDateSelect = (selectInfo) => {
    let calendarApi = selectInfo.view.calendar;
    calendarApi.unselect();

    let title = new Date().valueOf();

    if (title) {
      calendarApi.addEvent({
        id: new Date().valueOf(),
        title,
        start: selectInfo.startStr,
        end: selectInfo.endStr,
        constraint: "businessHours",
      });
    }
  };

  const handleEvents = (events) => {
    const current = JSON.stringify(events.map((e) => e.toPlainObject()));
    if (current !== hWizard.client.appointment_schedule) {
      hWizard.update("appointment_schedule", current);
    }
  };

  const handleEventClick = (clickInfo) => {
    clickInfo.event.remove();
  };

  return (
    <FullCalendar
      timeZone="UTC"
      plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
      headerToolbar={{ left: "", center: "", right: "" }}
      initialEvents={initialEvents}
      initialDate={new Date("2020/01/01")}
      initialView="timeGridWeek"
      contentHeight="auto"
      editable={true}
      selectable={true}
      selectMirror={true}
      dayMaxEvents={true}
      dayMaxEventRows={false}
      dayHeaderContent={(arg) => (
        <Box component="span" sx={{ color: "black" }}>
          {daysOfWeek[moment(arg.date).utc().get("day")]}
        </Box>
      )}
      weekends={true}
      select={handleDateSelect}
      eventContent={(eventInfo) => {
        return (
          <b>
            {moment(eventInfo.event.start).utc().format("hh:mm")} -{moment(eventInfo.event.end).utc().format("hh:mm")}
          </b>
        );
      }}
      eventClick={handleEventClick}
      eventsSet={handleEvents}
      selectOverlap={false}
      allDaySlot={false}
      slotMinTime={START_TIME}
      slotMaxTime={END_TIME}
      slotEventOverlap={false}
      slotLabelContent={(arg) => <span>{arg.text}</span>}
      eventDrop={(info) => {
        let startTime = moment(START_TIME, "HH:mm");
        let endTime = moment(END_TIME, "HH:mm");
        const { start: droppedStart, end: droppedEnd } = info.event._instance.range;

        const parseTime = (timeString) => moment(timeString, "HH:mm");

        const setTime = (time, date) => {
          const newDate = date.clone();
          return newDate.set({
            hour: time.get("hour"),
            minute: time.get("minute"),
            second: time.get("second"),
          });
        };

        const startUTC = moment(droppedStart).utc();
        const endUTC = moment(droppedEnd).utc();

        const endDiff = startUTC.get("hour") > endUTC.get("hour") ? 1 : 0;

        const momentStart = setTime(moment(droppedStart).utc(), startTime);
        if (parseTime(momentStart).isBefore(startTime)) {
          info.event.setStart(setTime(startTime, moment(droppedStart).utc()).format());
        }

        const realEndDate = endTime.clone().add(endDiff, "days");
        const momentEnd = setTime(moment(droppedEnd).utc(), realEndDate);
        if (parseTime(momentEnd).isAfter(endTime)) {
          info.event.setEnd(setTime(endTime, moment(droppedEnd).subtract(endDiff, "days").utc()).format());
        }
      }}
      eventOverlap={false}
      nextDayThreshold={START_TIME}
      allDayMaintainDuration={false}
      businessHours={{
        startTime: START_TIME,
        endTime: END_TIME,
        daysOfWeek: [0, 1, 2, 3, 4, 5, 6],
      }}
      selectConstraint="businessHours"
    />
  );
};

export default AppointmentCalendar;

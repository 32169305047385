import React from "react";
import { Box, TableContainer, Table, TableBody, TableCell, TableRow, Button, Paper } from "@mui/material";
import useExperiments from "hooks/useExperiments";

const Variations = () => {
  const hExperiments = useExperiments();

  const getVariationLink = (slug) => {
    const app_url = hExperiments.client.app_url;
    if (app_url && slug) {
      return `${app_url}/?variation=${slug}`;
    }
    if (app_url) {
      return app_url;
    }
    return "#";
  };

  return (
    <Box p={4}>
      <Box mb={4} sx={{ color: "primary.main", fontSize: 20 }}>
        VARIATION LIBRARY
      </Box>

      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 300 }}>
          <TableBody>
            {hExperiments.variations.map((d) => (
              <TableRow key={d.id}>
                <TableCell>{d.name}</TableCell>

                <TableCell align="center">
                  <Button
                    color="inherit"
                    size="small"
                    variant="outlined"
                    component="a"
                    target="_blank"
                    href={getVariationLink(d.variation_slug)}
                    sx={{ whiteSpace: "nowrap" }}
                  >
                    Live Preview
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default Variations;

import React from "react";
import Banner from "./Banner";
import { Box } from "@mui/material";
import useConfigurator from "hooks/useConfigurator";
import { assign, cloneDeep } from "lodash-es";
import Scrollbars from "react-custom-scrollbars";

const DYNAMIC_BANNER_SHOW_THRESHOLD_HEIGHT = 200;

const BannerPreview = () => {
  const hConfigurator = useConfigurator();

  const [client, setClient] = React.useState(hConfigurator.client);
  const [showDynamicBottom, setShowDynamicBottom] = React.useState(false);

  React.useEffect(() => {
    setClient(hConfigurator.client);
  }, [hConfigurator.client]);

  React.useEffect(() => {
    if (hConfigurator.updates.length <= 0) return;

    setClient(assign(cloneDeep(client), cloneDeep(hConfigurator.updates)));
  }, [hConfigurator.updates]);

  const handleScrollFrame = (e) => {
    if (client.banner_type === "dynamic" && e.scrollTop > DYNAMIC_BANNER_SHOW_THRESHOLD_HEIGHT) {
      setShowDynamicBottom(true);
    } else {
      setShowDynamicBottom(false);
    }
  };

  return (
    <Box
      sx={{
        backgroundImage: "url(/images/iphone.png)",
        backgroundSize: "434px 888px",
        backgroundRepeat: "no-repeat",
        padding: "109px 29px 112px 30px",
        width: 434,
        height: 888,
      }}
    >
      <Box
        sx={{
          position: "relative",
          height: "100%",
        }}
      >
        <Box
          sx={{
            position: "relative",
            overflowY: "auto",
            display: "flex",
            flexDirection: "column",
            height: "100%",
            backgroundColor: "#fff",
            fontSize: "16px",
            fontFamily: "Roboto, arial",
          }}
        >
          <Scrollbars onScrollFrame={handleScrollFrame}>
            {client.banner_is_enabled && client.banner_type !== "dynamic" && client.banner_position === "top" && (
              <Banner position="top" theme={client} />
            )}

            {client.banner_is_enabled && client.banner_type === "dynamic" && <Banner position="top" theme={client} />}

            <div>
              <div style={{ height: 400, backgroundColor: "#1b262c" }}></div>
              <div style={{ height: 300, backgroundColor: "#0f4c75" }}></div>
              <div style={{ height: 200, backgroundColor: "#3282b8" }}></div>
              <div style={{ height: 100, backgroundColor: "#bbe1fa" }}></div>
            </div>

            {client.banner_is_enabled && client.banner_type !== "dynamic" && client.banner_position === "bottom" && (
              <Banner position="bottom" theme={client} />
            )}

            {client.banner_is_enabled && client.banner_type === "dynamic" && showDynamicBottom && (
              <Banner position="bottom" theme={client} />
            )}
          </Scrollbars>
        </Box>
      </Box>
    </Box>
  );
};

export default BannerPreview;

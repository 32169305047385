import React from "react";
import Div100vh from "react-div-100vh";
import { Box, Collapse, useMediaQuery } from "@mui/material";
import "./style.scss";
import SideBar from "./components/SideBar";
import Main from "./components/Main";

const InvitationPage = () => {
  const [showPromotion, setShowPromotion] = React.useState(true);
  const isDesktop = useMediaQuery("(min-width:900px)");

  const handleClosePromotion = () => {
    setShowPromotion(false);
  };

  const handleOpenPromotion = () => {
    setShowPromotion(true);
  };

  const handleRefer = () => {
    const emailCC = "jordy@demand-iq.com,marcr@demand-iq.com";
    const emailSubject = `Referral (from <YOUR_FIRST_NAME> <YOUR_LAST_NAME>)`;
    const emailBody =
      "I'm excited to share a referral to Demand IQ: an online lead generation software system that helps us get more leads without purchasing them.\n\nI love the software, and think you will too!\n\nJust reply to this email and they'll take care of you.\n\nWith my referral, you'll get your $200 in credits + free onboarding - a $700 discount :)\n\nI've cc'ed their teammate Marc to help.";
    window.open(`mailto:?cc=${emailCC}&subject=${emailSubject}&body=${encodeURIComponent(emailBody)}`);
  };

  return (
    <Div100vh className="invitation-page">
      <Collapse
        orientation={isDesktop ? "horizontal" : "vertical"}
        in={showPromotion}
        timeout={100}
        sx={{ minWidth: "unset !important", minHeight: "fit-content !important" }}
      >
        <SideBar onClose={handleClosePromotion} onRefer={handleRefer} />
      </Collapse>

      <Box sx={{ width: 1, height: 1 }}>
        <Main showPromotion={!showPromotion} onOpenPromotion={handleOpenPromotion} />
      </Box>
    </Div100vh>
  );
};

export default InvitationPage;

import React from "react";
import { BrowserRouter } from "react-router-dom";
import RouterChangeDetector from "pages/RouterChangeDetector";
import PageRoutes from "pages/PageRoutes";
import { useRecoilState } from "recoil";
import { authAtom } from "store/auth";
import { clientAtom } from "store/client";
import { quickSetupAtom } from "store/configurator";
import { apiGetRefreshToken, apiGetClients, apiGetClient, apiValidateClientDns } from "store/api";
import SiteLoading from "shared/SiteLoading";
import Layout1 from "shared/layout1/Layout1";
import Layout2 from "shared/layout2/Layout2";
import useAlert from "hooks/useAlert";
import { parseStoken } from "utils/helpers";
import "utils/hotjar.js";

function App() {
  const hAlert = useAlert();

  const [authState, setAuthState] = useRecoilState(authAtom);
  const [clientState, setClientState] = useRecoilState(clientAtom);
  const Layout = authState.user ? Layout2 : Layout1;

  React.useEffect(() => {
    if (!authState.init) {
      checkToken();
    }
    // eslint-disable-next-line
  }, []);

  React.useEffect(() => {
    if (authState.init && authState.user) {
      getClients();
    }
    // eslint-disable-next-line
  }, [authState.user]);

  const checkToken = async () => {
    const refreshToken = window.localStorage.getItem("jwt_refresh_token");
    const res = await apiGetRefreshToken({ refresh: refreshToken });

    if (!res) return;

    if (res.status === 200) {
      setAuthState({
        init: true,
        user: res.data.user,
      });
      window.localStorage.setItem("jwt_access_token", res.data.access);
    } else {
      const {
        data: { code },
      } = res;

      if (code === "token_not_valid") hAlert.show("Your session has expired, please log back in.", false);

      setAuthState({
        init: true,
        user: null,
      });
    }
  };

  const getClients = async () => {
    const res = await apiGetClients();
    if (res.status === 200) {
      let clientId = authState.user.solar_company;
      const searchParams = new URLSearchParams(location.search);
      const stoken = searchParams.get("stoken");
      const appointmentConnectCode = searchParams.get("code"); // company id

      if (stoken) {
        const { company_id } = parseStoken(stoken);
        clientId = company_id;
      }
      if (appointmentConnectCode) {
        clientId = localStorage.getItem("appointment_connect_company");
      }

      localStorage.setItem("company", clientId);

      res.data.sort((a, b) => {
        if (a.company_name.toString().toLowerCase() >= b.company_name.toString().toLowerCase()) {
          return 1;
        }
        return -1;
      });

      const [clientRes, dnsRes] = await Promise.all([apiGetClient(clientId), apiValidateClientDns(clientId)()]);

      if (clientRes.status === 200 && dnsRes.status === 200) {
        setClientState({
          all: res.data,
          current: clientRes.data,
          currentId: clientRes.data.id,
          companyName: clientRes.data.company_name,
          customDomainValidated: dnsRes.data.custom_domain?.valid,
        });
      }
    }
  };

  if (!authState.init || (authState.user && !clientState.all)) {
    return <SiteLoading />;
  }

  return (
    <BrowserRouter>
      <Layout>
        <RouterChangeDetector>
          <PageRoutes />
        </RouterChangeDetector>
      </Layout>
    </BrowserRouter>
  );
}

export default App;

import React from "react";
import { Box, Slider } from "@mui/material";
import { withStyles } from "@mui/styles";
import useExperimentsCreator from "hooks/useExperimentsCreator";

const TrafficSlider = withStyles({
  root: {
    height: 8,
  },
  thumb: {
    height: 24,
    width: 24,
    backgroundColor: "#fff",
    border: "2px solid currentColor",
    "&:focus, &:hover, &$active": {
      boxShadow: "inherit",
    },
  },
  active: {},
  valueLabel: {
    left: "calc(-50% + 4px)",
  },
  track: {
    height: 8,
    borderRadius: 4,
  },
  rail: {
    height: 8,
    borderRadius: 4,
  },
})(Slider);

const TrafficForm = () => {
  const hCreator = useExperimentsCreator();
  const [value, setValue] = React.useState(0);

  React.useEffect(() => {
    setValue(hCreator.values.traffic_volume);
    // eslint-disable-next-line
  }, []);

  const handleChange = (e, value) => {
    setValue(value);
  };

  const handleUpdate = (e, value) => {
    if (value === 0) {
      hCreator.setValue("traffic_volume", 0, "Should not be zero");
    } else {
      hCreator.setValue("traffic_volume", value);
    }
  };

  return (
    <Box>
      <Box mb={2}>Use the slider below to determine how much traffic will go to the experimental version.</Box>
      <Box sx={{ display: "flex" }}>
        <TrafficSlider
          valueLabelDisplay="off"
          value={value}
          color="primary"
          sx={{ maxWidth: 400 }}
          onChange={handleChange}
          onChangeCommitted={handleUpdate}
        />
        <Box ml={2} sx={{ fontSize: 20 }}>
          {value} %
        </Box>
      </Box>
      {hCreator.errors.traffic_volume && hCreator.showError && (
        <Box sx={{ color: "error.main" }}>{hCreator.errors.traffic_volume}</Box>
      )}
    </Box>
  );
};

export default TrafficForm;

import React from "react";
import { TableCell, TableRow, Checkbox } from "@mui/material";
import useAlert from "hooks/useAlert";
import { apiApptCalendlyEventShow } from "utils/api_v2";

const ConnectCalendlyEvent = ({ event, token, showAll }) => {
  const hAlert = useAlert();
  const [active, setActive] = React.useState(false);

  React.useEffect(() => {
    setActive(event.active);
  }, [event]);

  const handleToggleEvent = async (id) => {
    setActive(!active);
    const res = await apiApptCalendlyEventShow(id, {
      active: !active,
    });
    if (res.status === 200) {
      hAlert.show("Event selection updated!");
    } else {
      hAlert.show("Event selection update failed!", false);
      setActive(active);
    }
  };

  return (
    <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
      <TableCell>{token.user_email}</TableCell>
      <TableCell>{event.name}</TableCell>
      <TableCell>{event.pooling_type || ""}</TableCell>
      <TableCell>{event.scheduling_url}</TableCell>
      <TableCell width={30} align="right">
        {!showAll && <Checkbox checked={active} onChange={() => handleToggleEvent(event.id)} />}
      </TableCell>
    </TableRow>
  );
};

export default ConnectCalendlyEvent;

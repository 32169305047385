import React from "react";
import { Avatar, Box, Button, Stack } from "@mui/material";
import useConfigurator from "hooks/useConfigurator";
import FileUpload from "shared/wizard/FileUpload";
import { DeleteIcon } from "utils/icons";

const UploadAiAvatar = () => {
  const hConfigurator = useConfigurator();

  const [values, setValues] = React.useState({
    is_default: !hConfigurator.client.chat_widget_ai_avatar,
    ai_name: hConfigurator.client.chat_widget_ai_name,
    ai_avatar: hConfigurator.client.chat_widget_ai_avatar,
  });

  const handleChange = (file, url) => {
    setValues({ ...values, is_default: false, ai_avatar: url });
    hConfigurator.updateSingle("chat_widget_ai_avatar", file);
  };

  const handleRemove = () => {
    setValues({ ...values, is_default: true, ai_avatar: "" });
    hConfigurator.updateSingle("chat_widget_ai_avatar", new File([], ""));
  };

  return (
    <Stack sx={{ width: 240 }}>
      <Box sx={{ fontSize: 12, color: "#333", mb: 1 }}>AI avatar</Box>

      <Box sx={{ width: 1, mb: 2, display: "flex", justifyContent: "space-between" }}>
        <FileUpload label="Upload" onChange={handleChange} />

        {!values.is_default && (
          <Button color="error" component="label" variant="outlined" endIcon={<DeleteIcon />} onClick={handleRemove}>
            Remove
          </Button>
        )}
      </Box>

      <Avatar
        alt={values.ai_name}
        src={values.is_default ? "/stella2/default_ai_avatar.jpg" : values.ai_avatar}
        sx={{ width: 240, height: 240, border: "1px solid #ccc" }}
      />
    </Stack>
  );
};

export default UploadAiAvatar;

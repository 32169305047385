import React from "react";
import { Box, Button, Stack } from "@mui/material";
import useConfigurator from "hooks/useConfigurator";
import FileUpload from "shared/wizard/FileUpload";
import { DeleteIcon } from "utils/icons";

const UploadStarterHero = () => {
  const hConfigurator = useConfigurator();

  const [values, setValues] = React.useState({
    is_default: !hConfigurator.client.chat_widget_starter_hero,
    starter_hero: hConfigurator.client.chat_widget_starter_hero,
  });

  const handleChange = (file, url) => {
    setValues({ is_default: false, starter_hero: url });
    hConfigurator.updateSingle("chat_widget_starter_hero", file);
  };

  const handleRemove = () => {
    setValues({ is_default: true, starter_hero: "" });
    hConfigurator.updateSingle("chat_widget_starter_hero", new File([], ""));
  };

  return (
    <Stack sx={{ width: 240 }}>
      <Box sx={{ fontSize: 12, color: "#333", mb: 1 }}>Starter card image</Box>

      <Box sx={{ width: 1, mb: 2, display: "flex", justifyContent: "space-between" }}>
        <FileUpload label="Upload" onChange={handleChange} />

        {!values.is_default && (
          <Button color="error" component="label" variant="outlined" endIcon={<DeleteIcon />} onClick={handleRemove}>
            Remove
          </Button>
        )}
      </Box>

      <Box
        component="img"
        alt="Starter card image"
        src={values.is_default ? "/stella2/default_starter_hero.png" : values.starter_hero}
        sx={{ maxWidth: 240, border: "1px solid #ccc", borderRadius: "5px" }}
      />
    </Stack>
  );
};

export default UploadStarterHero;

import React from "react";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Box, InputAdornment, IconButton, Stack, useMediaQuery, CircularProgress } from "@mui/material";
import { useTheme } from "@mui/styles";
import FormText from "shared/FormText";
import { EyeIcon, EyeInvisibleIcon, PersonIcon } from "utils/icons";
import RoundedButton from "shared/RoundedButton";
import LoginLogo from "shared/LoginLogo";

const Main = ({ loading, showPromotion, onOpenPromotion, onSubmit }) => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

  const { control, handleSubmit } = useForm();
  const [showPassword, setShowPassword] = React.useState(false);

  const handleShowHidePassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <Stack className="section-main" p={isDesktop ? 4:2} >
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <Box sx={{ width: isDesktop ? "100%" : "600px" }}>
          <LoginLogo />
        </Box>
      </Box>
      <Stack sx={{ flexGrow: 1, justifyContent: "center", alignItems: "center" }}>
        <Box sx={{ maxWidth: "600px", width: 1 }}>
          <Box mb={3}  sx={{ fontSize: 18, fontWeight: 900 }}>
            Account Login
          </Box>
          <Box>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Box>
                <Box mb={4}>
                  <FormText
                    disabled={loading}
                    control={control}
                    name="email"
                    label="Email *"
                    required
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <PersonIcon style={{ fontSize: 24, color: "black", marginRight: "7px" }} />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Box>
                <Box mb={1}>
                  <FormText
                    disabled={loading}
                    control={control}
                    name="password"
                    label="Password *"
                    type={showPassword ? "text" : "password"}
                    required
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={handleShowHidePassword}>
                            {showPassword ? (
                              <EyeIcon style={{ fontSize: 24, color: "black" }} />
                            ) : (
                              <EyeInvisibleIcon style={{ fontSize: 24, color: "black" }} />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                  <Box mt={1} sx={{ textAlign: "right" }}>
                    <Box
                      component={Link}
                      to="/forgot-password"
                      sx={{
                        color: "primary.main",
                        fontSize: 14,
                        textDecoration: "none",
                        "&:hover": { textDecoration: "underline" },
                      }}
                    >
                      Forgot Password?
                    </Box>
                  </Box>
                </Box>
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                  <RoundedButton
                    type="submit"
                    variant="contained"
                    fullWidth={!isDesktop}
                    sx={{ minWidth: 150 }}
                    disabled={loading}
                  >
                    {loading ? <CircularProgress color="secondary" size={22} /> : "Login"}
                  </RoundedButton>
                </Box>
              </Box>
            </form>
          </Box>
          <Box mt={3}>
            <Box sx={{ fontSize: 14, fontWeight: 900, textAlign: "center" }}>
              <Box component="span" mr={1}>
                Don't have an account?
              </Box>
              <Box
                component="a"
                href="https://www.demand-iq.com/contact"
                sx={{
                  color: "primary.main",
                  fontSize: 14,
                  textDecoration: "none",
                  cursor: "pointer",
                  "&:hover": { textDecoration: "underline" },
                }}
              >
                Contact Us
              </Box>
            </Box>
          </Box>
          <Box mt={3} sx={{ opacity: showPromotion ? 1 : 0, transition: "opacity .1s ease-in-out" }}>
            <Box sx={{ fontSize: 14, fontWeight: 900, textAlign: "center" }}>
              <Box component="span" mr={1}>
                <img src="images/promotion.png" width="14px" alt="" /> Check out our
              </Box>
              <Box
                component="span"
                sx={{
                  color: "primary.main",
                  fontSize: 14,
                  cursor: "pointer",
                }}
                onClick={onOpenPromotion}
              >
                Promotional Offer
              </Box>
            </Box>
          </Box>
        </Box>
      </Stack>
    </Stack>
  );
};

export default Main;

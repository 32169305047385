import React from "react";
import { Box, Button, Menu, MenuItem, ListItemIcon, ListItemText } from "@mui/material";
import { CheckIcon } from "utils/icons";

const ChooseQuestion = ({ state, chooseTemplate, isReferralOn, referralOn }) => {
  const chosenTemplateIds = Object.fromEntries(state.questions.map((d) => [d.original_template, true]));
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChooseTemplate = (templateId) => {
    handleClose();
    chooseTemplate(templateId);
  };

  const handleReferralOn = () => {
    referralOn();
    handleClose();
  };

  return (
    <>
      <Button variant="contained" color="success" onClick={handleClick} sx={{ marginRight: 2 }}>
        Add Qualifying Questions To Your List
      </Button>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        {state.defaults.map((d) => (
          <Box key={d.id}>
            {chosenTemplateIds[d.id] ? (
              <MenuItem disabled>
                <ListItemIcon>
                  <CheckIcon />
                </ListItemIcon>
                <ListItemText>{d.question_desc}</ListItemText>
              </MenuItem>
            ) : (
              <MenuItem onClick={() => handleChooseTemplate(d.id)}>{d.question_desc}</MenuItem>
            )}
          </Box>
        ))}
        {isReferralOn ? (
          <MenuItem disabled>
            <ListItemIcon>
              <CheckIcon />
            </ListItemIcon>
            <ListItemText>Is there anything else you'd like us to know?</ListItemText>
          </MenuItem>
        ) : (
          <MenuItem onClick={handleReferralOn}>Is there anything else you'd like us to know?</MenuItem>
        )}
      </Menu>
    </>
  );
};

export default ChooseQuestion;

import React from "react";
import { Box, TextField, InputAdornment } from "@mui/material";
import useQuickSetupWizard from "hooks/useQuickSetupWizard";

const ChangeEscalation = () => {
  const setupWizard = useQuickSetupWizard();
  const [escalation, setEscalation] = React.useState("");
  const [error, setError] = React.useState("");

  React.useEffect(() => {
    setEscalation(setupWizard.client.escalation || "");
    // eslint-disable-next-line
  }, []);

  React.useEffect(() => {
    setError(setupWizard.errors.escalation);
    // eslint-disable-next-line
  }, [setupWizard.errors]);

  const handleEscalationChange = (e) => {
    if (e.target.value === "") {
      setError("required");
      setEscalation("");
      setupWizard.update("escalation", "required");
    } else {
      if (e.target.value > 0 && e.target.value <= 15) {
        setError("");
        setEscalation(e.target.value);
        setupWizard.update("escalation", e.target.value);
      }
    }
  };

  return (
    <Box>
      <Box mb={2} sx={{ fontSize: 12 }}>
        Max rate is 15
      </Box>
      {error && (
        <Box sx={{ color: "error.main" }}>
          {error === "required" && "Required"}
          {error !== "required" && error}
        </Box>
      )}
      <TextField
        InputProps={{ startAdornment: <InputAdornment position="start">%</InputAdornment> }}
        type="number"
        value={escalation}
        onChange={handleEscalationChange}
      />
    </Box>
  );
};

export default ChangeEscalation;

import React from "react";
import Iframe from "react-iframe";
import { Box } from "@mui/material";
import useQuickSetupWizard from "hooks/useQuickSetupWizard";
import useExperiments from "hooks/useExperiments";
import { assign, cloneDeep } from "lodash-es";

const PhonePreview = ({ isQuickSetup = false, isExperiment = false, variation = "" }) => {
  const setupWizard = useQuickSetupWizard();
  const experiment = useExperiments();
  const [client, setClient] = React.useState(null);
  const [iFrameSrc, setIFrameSrc] = React.useState("");

  const variationParam = isExperiment && variation ? `&variation=${variation}` : "";

  const getPhonePreviewIFrameSrc = (companySlug) => {
    const trackingEnv = process.env.REACT_APP_TRACKING_ENV;

    if (!companySlug) {
      return "";
    }

    if (trackingEnv === "production") {
      return `https://stella.demand-iq.com?app_url=https://${companySlug}.estimate.demand-iq.com&preview=true${variationParam}`;
    } else if (trackingEnv === "staging") {
      return `https://stella-staging.demand-iq.com?app_url=https://${companySlug}.estimate-staging.demand-iq.com&preview=true${variationParam}`;
    } else {
      return `https://stella-dev.demand-iq.com?app_url=https://${companySlug}.calculator-dev.demand-iq.com&preview=true${variationParam}`;
    }
  };

  React.useEffect(() => {
    if (setupWizard.updates.length <= 0) return;

    let updates = {};
    for (let i = 0; i < setupWizard.updates.length; i++) {
      updates = { ...updates, [setupWizard.updates[i].key]: setupWizard.updates[i].value };
    }

    const theme = assign(cloneDeep(client), updates);
    const previewIframe = document.getElementById("phonePreviewIframe");
    previewIframe.contentWindow.postMessage(theme, previewIframe.src);
  }, [setupWizard.updates]);

  React.useEffect(() => {
    if (isQuickSetup) {
      setClient(setupWizard.client);
      setIFrameSrc(getPhonePreviewIFrameSrc(setupWizard.client.company_slug));
    }

    if (isExperiment) {
      setClient(experiment.client);
      setIFrameSrc(getPhonePreviewIFrameSrc(experiment.client.company_slug));
    }
  }, []);

  return (
    <Box
      sx={{
        backgroundImage: "url(/images/iphone.png)",
        backgroundSize: "434px 888px",
        backgroundRepeat: "no-repeat",
        padding: "109px 29px 112px 30px",
        width: 434,
        height: 888,
      }}
    >
      <Iframe src={iFrameSrc} height="100%" width="100%" id="phonePreviewIframe" />
    </Box>
  );
};

export default PhonePreview;

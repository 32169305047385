import React from "react";
import { v4 as uuidv4 } from "uuid";
import { Box, Button } from "@mui/material";
import Charger from "./Charger";
import ChargerForm from "./ChargerForm";
import useQuickSetupWizard from "hooks/useQuickSetupWizard";
import { apiGetChargers, apiCreateCharger, apiUpdateCharger, apiDeleteCharger } from "store/api";

const ADD = "add";
const EDIT = "edit";

const Chargers = ({ off }) => {
  const setupWizard = useQuickSetupWizard();
  const [chargers, setChargers] = React.useState([]);
  const [form, setForm] = React.useState(null);
  const [action, setAction] = React.useState("");

  React.useEffect(() => {
    const init = async () => {
      const res = await apiGetChargers({ solar_company: setupWizard.client.id });
      if (res.status === 200) {
        if (res.data.length) setChargers(res.data);
        else handleAdd();
      }
    };

    init();
  }, []);

  const handleAdd = () => {
    setForm({ id: uuidv4() });
    setAction(ADD);
  };

  const handleEdit = (charger) => {
    setForm(charger);
    setAction(EDIT);
  };

  const handleDelete = async (id) => {
    const res = await apiDeleteCharger(id);
    if (res.status === 204) {
      setChargers(chargers.filter((charger) => charger.id !== id));
    }
  };

  const handleSave = async (data) => {
    if (action === ADD) {
      const res = await apiCreateCharger(
        { solar_company: setupWizard.client.id },
        {
          ...data,
          id: undefined,
          solar_company: setupWizard.client.id,
        }
      );

      if (res.status === 201) {
        setChargers([...chargers, res.data]);
        setForm(null);
      }
    } else if (action === EDIT) {
      const res = await apiUpdateCharger(data.id)(
        { solar_company: setupWizard.client.id },
        {
          ...data,
          solar_company: setupWizard.client.id,
        }
      );

      if (res.status === 200) {
        setChargers(chargers.map((charger) => (charger.id === res.data.id ? res.data : charger)));
        setForm(null);
      }
    }
  };

  const handleCancel = () => {
    setForm(null);
    if (chargers.length === 0) off();
  };

  return (
    <Box>
      <Box sx={{ display: "flex", pl: 1 }}>
        {chargers.map((charger, index) => (
          <Charger
            key={index}
            {...charger}
            showDelete={index > 0}
            onEdit={() => handleEdit(charger)}
            onDelete={() => handleDelete(charger.id)}
          />
        ))}
      </Box>

      {form && (
        <ChargerForm
          key={form.id}
          form={form}
          header={`${action} Charger`}
          onSave={handleSave}
          onCancel={handleCancel}
        />
      )}
    </Box>
  );
};

export default Chargers;

import React from "react";
import { Scrollbars } from "react-custom-scrollbars";
import { Box, IconButton, Tooltip } from "@mui/material";
import { apiCreateIncentives, apiDeleteIncentives, apiGetIncentives } from "store/api";
import useQuickSetupWizard from "hooks/useQuickSetupWizard";
import TextUpload from "shared/wizard/TextUpload";
import { DeleteIcon, QuestionCircleIcon } from "utils/icons";
import "shared/tooltip.scss";
import useAlert from "hooks/useAlert";

const IncentiveView = ({ label, data, color }) => (
  <Box>
    <Box mt={2} sx={{ border: "1px solid", borderColor: color, maxWidth: 400 }}>
      <Box p={2} sx={{ backgroundColor: "#eee" }}>
        <Box sx={{ color }}>{label}</Box>
        <Box sx={{ color: "#666", fontSize: 12 }}>{data.file}</Box>
        <Box sx={{ color: "#666", fontSize: 12 }}>{data.list.length} items</Box>
      </Box>
      <Box sx={{ height: 300 }}>
        <Scrollbars>
          <Box p={2}>
            {data.list.map((d, key) => (
              <Box mt={1} key={key}>
                {d}
              </Box>
            ))}
          </Box>
        </Scrollbars>
      </Box>
    </Box>
  </Box>
);

const UploadIncentives = () => {
  const setupWizard = useQuickSetupWizard();
  const hAlert = useAlert();

  const [loading, setLoading] = React.useState(true);
  const [oldData, setOldData] = React.useState({
    file: null,
    list: null,
  });
  const [newData, setNewData] = React.useState({
    file: null,
    list: null,
  });

  React.useEffect(() => {
    let mounted = true;
    const init = async () => {
      const res = await apiGetIncentives(setupWizard.client.id);
      if (mounted) {
        if (res.status === 200) {
          setOldData({
            file: res.data.filename,
            list: res.data.incentives.map((d) => d.join(", ")),
          });
        }
        setLoading(false);
      }
    };

    init();

    return () => {
      mounted = false;
    };
    // eslint-disable-next-line
  }, []);

  const handleFileSelect = async (file, text) => {
    const data = text.split(/\r\n/g).slice(1, -1);
    setNewData({
      file: file.name,
      list: data,
    });

    const formData = new FormData();
    formData.append("incentives_csv", file);
    const res = await apiCreateIncentives(setupWizard.client.id, formData);

    if (res.status === 201) {
      hAlert.show("Uploading customized incentives succeeded.");
    } else if (res.status === 500) {
      hAlert.show("There's something wrong in uploading incentives.", false);
    } else if (res.status === 400) {
      hAlert.show("Incentive csv file is not valid.", false);
    }
  };

  const handleRemove = async () => {
    const res = await apiDeleteIncentives(setupWizard.client.id);

    if (res.status === 204) {
      hAlert.show("Removing customized incentives succeeded.");

      setOldData({
        file: null,
        list: null,
      });

      setNewData({
        file: null,
        list: null,
      });
    } else if (res.status === 500) {
      hAlert.show("There's something wrong in removing incentives.", false);
    }
  };

  if (loading) {
    return <Box>loading ...</Box>;
  }

  return (
    <Box>
      <Box my={2}>
        Upload custom incentives ($/watt value) or performance-based incentives ($/MWh value with a lifespan in years)
        by ZIP code.
        <Tooltip
          classes={{
            tooltip: "tooltip-background",
            arrow: "tooltip-caret",
          }}
          title={
            <Box sx={{ fontSize: 14, fontWeight: 400 }}>
              <Box>To learn more about this please visit</Box>
              <a
                className="tooltip-link"
                href="https://knowledge.demand-iq.com/add-incentives"
                target="_blank"
                rel="noopener noreferrer"
              >
                our knowledge base article
              </a>
            </Box>
          }
          arrow
        >
          <IconButton size="medium">
            <QuestionCircleIcon />
          </IconButton>
        </Tooltip>
      </Box>

      <Box my={2}>
        <Box
          component="a"
          href="/csv/custom_incentive_by_zipcode.csv"
          target="_blank"
          download="custom_incentive_by_zipcode.csv"
          sx={{ color: "#15ABE6" }}
        >
          Expected File Format
        </Box>
      </Box>

      <Box display="flex" justifyContent="space-between" sx={{ maxWidth: 400 }}>
        <TextUpload label="Upload Custom Incentive" onChange={handleFileSelect} />

        {(oldData.file || newData.file) && (
          <IconButton color="error" onClick={handleRemove}>
            <DeleteIcon />
          </IconButton>
        )}
      </Box>

      {newData.file && <IncentiveView label="New Incentive Rates" data={newData} color="#ff5722" />}
      {oldData.file && <IncentiveView label="Uploaded Incentive Rates" data={oldData} color="#666" />}
    </Box>
  );
};

export default UploadIncentives;

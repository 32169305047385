import React from "react";
import { Pie } from "react-chartjs-2";
import { Box, CircularProgress } from "@mui/material";
import { apiGetInstantEstimateApiReport } from "store/api";
import useReporting from "hooks/useReporting";

const LeadFailEstimate = () => {
  const hReporting = useReporting();
  const [loading, setLoading] = React.useState(false);
  const [data, setData] = React.useState({
    failed: 0,
    success: 0,
  });

  React.useEffect(() => {
    const getData = async () => {
      setLoading(true);
      const res = await apiGetInstantEstimateApiReport({
        start_at: hReporting.conversion.filter.startDate,
        end_at: hReporting.conversion.filter.endDate,
        solar_company: hReporting.client.id,
      });
      if (res.status === 200) {
        setData({
          failed: res.data.failed_requests,
          success: res.data.success_requests,
        });
      }
      setLoading(false);
    };
    getData();
    // eslint-disable-next-line
  }, [hReporting.conversion.filter.startDate, hReporting.conversion.filter.endDate]);

  return (
    <Box p={2} sx={{ border: "1px solid #ccc", borderRadius: 2 }}>
      <Box sx={{ color: "primary.dark", fontSize: 20 }}>
        How often do API requests containing leads fail to generate an instant estimate?
      </Box>
      <Box mt={2}>
        {loading ? (
          <Box sx={{ width: 1, minHeight: 200, display: "flex", alignItems: "center", justifyContent: "center" }}>
            <CircularProgress size={20} />
          </Box>
        ) : (
          <Pie
            data={{
              labels: ["Successful API Requests", "Unsuccessful API Requests"],
              datasets: [
                {
                  data: [data.success, data.failed],
                  backgroundColor: ["#2196f3", "#ccc"],
                  hoverBackgroundColor: ["#2196f3", "#ccc"],
                },
              ],
            }}
          />
        )}
      </Box>
    </Box>
  );
};

export default LeadFailEstimate;

import React from "react";
import { Box, TextField } from "@mui/material";
import { LocalizationProvider, DateTimePicker } from "@mui/lab";
import DateAdapter from "@mui/lab/AdapterDateFns";
import useExperimentsCreator from "hooks/useExperimentsCreator";

const StartDateForm = () => {
  const hCreator = useExperimentsCreator();

  const handleChange = (value) => {
    hCreator.setValue("start_datetime", value);
  };

  return (
    <Box>
      <Box mb={2}>Choose a starting date for the experiment</Box>
      <LocalizationProvider dateAdapter={DateAdapter}>
        <DateTimePicker
          renderInput={(params) => <TextField {...params} />}
          value={hCreator.values.start_datetime}
          onChange={handleChange}
        />
      </LocalizationProvider>
    </Box>
  );
};

export default StartDateForm;

import * as React from "react";
import { Typography, Autocomplete, Grid } from "@mui/material";
import parse from "autosuggest-highlight/parse";
import throttle from "lodash/throttle";

const GOOGLE_MAPS_API_KEY = process.env.REACT_APP_GOOGLE_MAP_KEY;

const loadScript = (src, position, id) => {
  if (!position) {
    return;
  }

  const script = document.createElement("script");
  script.setAttribute("async", "");
  script.setAttribute("id", id);
  script.src = src;
  position.appendChild(script);
};

const isScriptLoaded = (src) => {
  const scripts = document.scripts;
  for (let i = 0; i < scripts.length; i++) if (scripts[i].src.includes(src)) return true;
  return false;
};

const autocompleteService = { current: null };
const placeService = { current: null };

export default function GoogleMaps(props) {
  const { defaultValue, renderInput, onSelectPlace } = props;

  const [value, setValue] = React.useState(defaultValue);
  const [inputValue, setInputValue] = React.useState("");
  const [options, setOptions] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const loaded = React.useRef(false);

  const parseValuesFromPlace = (place) => {
    const values = {};

    place.address_components.forEach((item) => {
      if (item.types.indexOf("street_number") > -1) {
        values.address = item.short_name;
      }

      if (item.types.indexOf("route") > -1) {
        if (!values.address) {
          values.address = item.long_name;
        } else {
          values.address = `${values.address} ${item.long_name}`;
        }
      }

      if (values.city === undefined && item.types.indexOf("neighborhood") > -1) {
        values.city = item.long_name;
      }

      if (item.types.indexOf("locality") > -1) {
        values.city = item.long_name;
      }

      if (item.types.indexOf("administrative_area_level_1") > -1) {
        values.state = item.short_name;
      }

      if (item.types.indexOf("country") > -1) {
        values.country = item.short_name;
      }

      if (item.types.indexOf("postal_code") > -1) {
        values.zipCode = item.short_name;
      }
    });

    values.formattedAddress = place.formatted_address;
    values.rooftopLatitude = Math.round(place.geometry.location.lat() * 10 ** 11) / 10 ** 11;
    values.rooftopLongitude = Math.round(place.geometry.location.lng() * 10 ** 11) / 10 ** 11;

    return values;
  };

  if (typeof window !== "undefined" && !loaded.current) {
    if (!isScriptLoaded("maps.googleapis.com") && !document.querySelector("#google-maps")) {
      loadScript(
        `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_API_KEY}&libraries=places,visualization`,
        document.querySelector("head"),
        "google-maps"
      );
    }

    loaded.current = true;
  }

  const fetch = React.useMemo(
    () =>
      throttle((request, callback) => {
        const options = {
          types: ["address"],
          componentRestrictions: { country: "us" },
        };
        if (autocompleteService.current) {
          autocompleteService.current.getPlacePredictions({ ...options, ...request }, callback);
        }
      }, 200),
    []
  );

  React.useEffect(() => {
    let active = true;

    if (!placeService.current && window?.google?.maps?.places?.PlacesService) {
      placeService.current = new window.google.maps.places.PlacesService(document.createElement("div"));
    }
    if (!autocompleteService.current && window?.google?.maps?.places?.AutocompleteService) {
      autocompleteService.current = new window.google.maps.places.AutocompleteService();
    }
    if (!placeService.current) {
      return undefined;
    }
    if (!autocompleteService.current) {
      return undefined;
    }

    if (inputValue === "") {
      setOptions(value ? [value] : []);
      return undefined;
    }

    fetch({ input: inputValue }, (results) => {
      if (active) {
        let newOptions = [];

        if (value) {
          newOptions = [value];
        }

        if (results) {
          newOptions = [...newOptions, ...results];
        }

        setOptions(newOptions);
      }
    });

    return () => {
      active = false;
    };
  }, [value, inputValue, fetch]);

  return (
    <Autocomplete
      classes={props.classes}
      id="google-map-demo"
      getOptionLabel={(option) => (typeof option === "string" ? option : option.description)}
      filterOptions={(x) => x}
      options={options}
      onOpen={() => {
        if (inputValue.length > 0) setOpen(true);
      }}
      onClose={() => setOpen(false)}
      open={open}
      includeInputInList
      filterSelectedOptions
      value={value}
      disabled={props.disabled || false}
      onChange={(event, newValue) => {
        setOptions(newValue ? [newValue, ...options] : options);
        setValue(newValue);
        setOpen(false);

        if (newValue) {
          if (!placeService.current) return;

          placeService.current.getDetails({ placeId: newValue.place_id }, (place) => {
            onSelectPlace(newValue, parseValuesFromPlace(place));
          });
        } else {
          onSelectPlace(null, null);
        }
      }}
      onInputChange={(event, newInputValue) => {
        if (newInputValue.length === 0) setOpen(false);
        setInputValue(newInputValue);
      }}
      renderInput={(params) => renderInput(params)}
      renderOption={(props, option) => {
        const matches = option.structured_formatting.main_text_matched_substrings;
        const parts = parse(
          option.structured_formatting.main_text,
          matches.map((match) => [match.offset, match.offset + match.length])
        );

        return (
          <li {...props}>
            <Grid container alignItems="center">
              <Grid item xs>
                {parts.map((part, index) => (
                  <span
                    key={index}
                    style={{
                      fontWeight: part.highlight ? 700 : 400,
                    }}
                  >
                    {part.text}
                  </span>
                ))}

                <Typography variant="body2" color="text.secondary">
                  {option.structured_formatting.secondary_text}
                </Typography>
              </Grid>
            </Grid>
          </li>
        );
      }}
    />
  );
}

import React from "react";
import { Box, Stack } from "@mui/material";
import Switch from "shared/Switch";
import Batteries from "./battery/Batteries";
import Chargers from "./charger/Chargers";
import useQuickSetupWizard from "hooks/useQuickSetupWizard";

const BATTERY = "battery";
const CHARGER = "charger";

const Main = () => {
  const setupWizard = useQuickSetupWizard();
  const [isBatteryOn, setIsBatteryOn] = React.useState(false);
  const [isChargerOn, setIsChargerOn] = React.useState(false);

  React.useState(() => {
    setIsBatteryOn(setupWizard.client.is_battery_on);
    setIsChargerOn(setupWizard.client.is_charger_on);
  }, []);

  const handleToggle = (type) => (e) => {
    if (type === BATTERY) {
      setIsBatteryOn(e.target.checked);
      setupWizard.update("is_battery_on", e.target.checked);
    }
    if (type === CHARGER) {
      setIsChargerOn(e.target.checked);
      setupWizard.update("is_charger_on", e.target.checked);
    }
  };

  const handleOffBattery = () => {
    setIsBatteryOn(false);
    setupWizard.update("is_battery_on", false);
  };

  const handleOffCharger = () => {
    setIsChargerOn(false);
    setupWizard.update("is_charger_on", false);
  };

  return (
    <Box>
      <Box mb={2} sx={{ fontSize: 12 }}>
        Based on {setupWizard.client.product_apr || 2}% APR financing for {setupWizard.client.product_term || 10} years.
      </Box>

      <Stack spacing={1}>
        <Box>
          <Switch label="Use Battery" checked={isBatteryOn} onChange={handleToggle(BATTERY)} />
          {isBatteryOn && <Batteries off={handleOffBattery} />}
        </Box>

        <Box>
          <Switch label="Use Charger" checked={isChargerOn} onChange={handleToggle(CHARGER)} />
          {isChargerOn && <Chargers off={handleOffCharger} />}
        </Box>
      </Stack>
    </Box>
  );
};

export default Main;

import React from "react";
import { useSearchParams } from "react-router-dom";
import { Box, TextField, MenuItem } from "@mui/material";
import useQuickSetupWizard from "hooks/useQuickSetupWizard";
import useCommon from "hooks/useCommon";
import useAlert from "hooks/useAlert";
import { apiApptCalendlyTokenConnect, apiApptCronofyTokenConnect } from "utils/api_v2";
import FormHead from "../FormHead";
import FormStep from "../FormStep";
import TabSelf from "./TabSelf";
import TabCalendly from "./TabCalendly";
import TabCronofy from "./TabCronofy";

const APPOINTMENT_METHOD = {
  DIRECT: "DIRECT",
  CALENDLY: "CALENDLY",
  CRONOFY: "CRONOFY",
};

const AppointmentForm = () => {
  const hCommon = useCommon();
  const hAlert = useAlert();
  const hWizard = useQuickSetupWizard();
  const [searchParams, setSearchParams] = useSearchParams();
  const [appointmentMethod, setAppointmentMethod] = React.useState("");
  const [verifyingConnect, setVerifyingConnect] = React.useState(true);

  React.useEffect(() => {
    const code = searchParams.get("code");

    if (code) {
      const method = localStorage.getItem("appointment_connect_method");

      if (method === "calendly") {
        connectCalendly(code);
      }

      if (method === "cronofy") {
        connectCronofy(code);
      }

      localStorage.removeItem("appointment_connect_method");
      localStorage.removeItem("appointment_connect_company");
      searchParams.delete("code");
      setSearchParams(searchParams);
    } else {
      setVerifyingConnect(false);
    }

    setAppointmentMethod(hWizard.client.appointment_method);
    // eslint-disable-next-line
  }, []);

  const handleSelectMethod = (e) => {
    setAppointmentMethod(e.target.value);
    hWizard.update("appointment_method", e.target.value);
  };

  const connectCalendly = async (code) => {
    hCommon.loading("Finalizing calendly connect");
    const res = await apiApptCalendlyTokenConnect({ code });
    hCommon.loading("");
    if (res.status === 200) {
      hAlert.show("Successfully connected!");
    } else {
      hAlert.show(res.data.error_message, false);
    }
    setVerifyingConnect(false);
    deleteCodeParams();
  };

  const connectCronofy = async (code) => {
    hCommon.loading("Finalizing calendar connect");
    const res = await apiApptCronofyTokenConnect({ code });
    hCommon.loading("");
    if (res.status === 200) {
      hAlert.show("Successfully connected!");
    } else {
      hAlert.show(res.data.error_message, false);
    }
    setVerifyingConnect(false);
    deleteCodeParams();
  };

  const deleteCodeParams = () => {
    searchParams.delete("code");
    searchParams.delete("state");
    setSearchParams(searchParams);
  };

  return (
    <Box>
      <FormHead text="APPOINTMENT SCHEDULING" />
      <Box my={4}>
        <FormStep index={23} label="Select appointment method">
          <TextField select sx={{ width: 300 }} value={appointmentMethod} onChange={handleSelectMethod}>
            <MenuItem value={APPOINTMENT_METHOD.DIRECT}>Direct Call</MenuItem>
            <MenuItem value={APPOINTMENT_METHOD.CALENDLY}>Calendly</MenuItem>
            <MenuItem value={APPOINTMENT_METHOD.CRONOFY}>Other Calendars</MenuItem>
          </TextField>
        </FormStep>
      </Box>
      <Box mt={4}>
        {!verifyingConnect && (
          <FormStep index={24} label="Configure your appointment">
            {appointmentMethod === APPOINTMENT_METHOD.DIRECT && <TabSelf />}
            {appointmentMethod === APPOINTMENT_METHOD.CALENDLY && <TabCalendly />}
            {appointmentMethod === APPOINTMENT_METHOD.CRONOFY && <TabCronofy />}
          </FormStep>
        )}
      </Box>
    </Box>
  );
};

export default AppointmentForm;

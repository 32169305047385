import React from "react";
import { Stack, useMediaQuery } from "@mui/material";

const LoginLogo = () => {
  const isDesktop = useMediaQuery("(min-width: 900px)");

  return (
    <Stack direction="row" spacing={isDesktop ? 3: 0} sx={{ alignItems: "center" }}>
      {isDesktop ? (
        <>
          <a href="https://demand-iq.com">
            <img src="/images/logomark.svg" alt="logomark" />
          </a>
          <a href="https://demand-iq.com">
            <img src="/images/wordmark.svg" alt="wordmark" />
          </a>
        </>
      ) : (
        <a href="https://demand-iq.com">
          <img src="/images/logomark.svg" alt="logomark" style={{ width: 27 }} />
        </a>
      )}
    </Stack>
  );
};

export default LoginLogo;

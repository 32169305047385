import { useRecoilState, useResetRecoilState } from "recoil";
import { apiUpdateClient } from "store/api";
import { configuratorAtom, spanishSetupAtom } from "store/configurator";
import { loadingAtom } from "store/common";

const useSpanishSetupWizard = () => {
  const [, setLoading] = useRecoilState(loadingAtom);
  const [confState, setConfState] = useRecoilState(configuratorAtom);
  const [spanishSetupState, setSpanishSetupState] = useRecoilState(spanishSetupAtom);
  const resetSpanishSetupState = useResetRecoilState(spanishSetupAtom);

  const update = async (key, value) => {
    setSpanishSetupState({
      ...spanishSetupState,
      updates: [...spanishSetupState.updates.filter((d) => d.key !== key), { key, value }],
    });
  };

  const updateMany = async (values) => {
    const keys = values.map((d) => d.key);
    setSpanishSetupState({
      ...spanishSetupState,
      updates: [...spanishSetupState.updates.filter((d) => !keys.includes(d.key)), ...values],
    });
  };

  const drop = async (key) => {
    setSpanishSetupState({
      ...spanishSetupState,
      updates: [...spanishSetupState.updates.filter((d) => d.key !== key)],
    });
  };

  const dropMany = async (keys) => {
    setSpanishSetupState({
      ...spanishSetupState,
      updates: [...spanishSetupState.updates.filter((d) => !keys.includes(d.key))],
    });
  };

  const back = () => {
    setSpanishSetupState({
      ...spanishSetupState,
      form: spanishSetupState.form - 1,
      updates: [],
      errors: {},
    });
  };

  const next = async () => {
    const formData = new FormData();
    const restrictions = ["required", "not-checked", "not-valid"];

    let hasError = false;
    let errors = {};

    if (spanishSetupState.updates.length) {
      for (let i = 0; i < spanishSetupState.updates.length; i++) {
        if (restrictions.includes(spanishSetupState.updates[i].value)) {
          hasError = true;
          errors = {
            ...errors,
            [spanishSetupState.updates[i].key]: spanishSetupState.updates[i].value,
          };
        }
      }

      if (hasError) {
        setSpanishSetupState({
          ...spanishSetupState,
          errors,
        });
        return;
      }

      for (let i = 0; i < spanishSetupState.updates.length; i++) {
        formData.append(spanishSetupState.updates[i].key, spanishSetupState.updates[i].value);
      }

      setLoading("Updating ...");
      const res = await apiUpdateClient(confState.client.id)(formData);
      setLoading("");

      if (res.status === 200) {
        setConfState({
          ...confState,
          client: res.data,
        });
        setSpanishSetupState({
          ...spanishSetupState,
          form: spanishSetupState.form + 1,
          updates: [],
          errors: {},
        });
      } else {
        setSpanishSetupState({
          ...spanishSetupState,
          errors: res.data,
        });
      }
    } else {
      setSpanishSetupState({
        ...spanishSetupState,
        form: spanishSetupState.form + 1,
      });
    }
  };

  return {
    reset: resetSpanishSetupState,
    update,
    updateMany,
    drop,
    dropMany,
    back,
    next,
    client: confState.client,
    form: spanishSetupState.form,
    updates: spanishSetupState.updates,
    errors: spanishSetupState.errors,
  };
};

export default useSpanishSetupWizard;

import React from "react";
import { Box, Divider, Dialog, IconButton } from "@mui/material";
import { CloseIcon } from "utils/icons";
import { Scrollbars } from "react-custom-scrollbars";
import AnalyzeChart from "./AnalyzeChart";
import AnalyzeReport from "./AnalyzeReport";

const AnalyzeDialog = ({ experiment, open, onClose }) => {
  return (
    <Dialog open={open} onClose={() => {}} PaperProps={{ sx: { width: "100%" } }}>
      {experiment && (
        <Box sx={{ width: 1, height: 1 }}>
          <Box p={2} sx={{ display: "flex" }}>
            <Box sx={{ flexGrow: 1, fontSize: 20 }}>{experiment.name}</Box>
            <IconButton size="small" onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </Box>
          <Scrollbars style={{ height: "calc(100vh - 200px)" }}>
            <Divider />
            <AnalyzeChart experiment={experiment} />
            <Divider />
            <AnalyzeReport experiment={experiment} />
          </Scrollbars>
        </Box>
      )}
    </Dialog>
  );
};

export default AnalyzeDialog;

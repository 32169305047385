import * as React from "react";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";

const useStyles = makeStyles({
  root: {
    "& .MuiPaper-root": {
      maxWidth: "1200px",
    },
  },
});

const ImageDialog = ({ open, setOpen, className, ...props }) => {
  const classes = useStyles();
  const [loaded, setLoaded] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const onImgLoaded = () => {
    setLoaded(true);
  };

  const descriptionElementRef = React.useRef(null);

  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  return (
    <div>
      <Dialog open={open} onClose={handleClose} scroll="paper" className={clsx(className, classes.root)} {...props}>
        <DialogTitle>Instant Solar Estimate</DialogTitle>
        <DialogContent dividers={true}>
          <DialogContentText ref={descriptionElementRef} tabIndex={-1}>
            {!loaded && (
              <Box
                sx={{
                  width: "928px",
                  height: "500px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <CircularProgress size={20} />
              </Box>
            )}
            <img
              src="/images/instant-estimate-sample.jpg"
              style={{ display: loaded ? "block" : "none" }}
              onLoad={onImgLoaded}
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ImageDialog;

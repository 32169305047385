import React from "react";
import { Scrollbars } from "react-custom-scrollbars";
import { Box, Paper, Tabs, Tab } from "@mui/material";
import Basic from "./components/basic/Basic";
import Password from "./components/Password";

const ProfilePage = () => {
  const [tab, setTab] = React.useState(0);

  React.useEffect(() => {
    // eslint-disable-next-line
  }, []);

  const handleChange = (e, v) => {
    setTab(v);
  };

  return (
    <Box px={4} sx={{ height: 1 }}>
      <Box sx={{ height: 136, display: "flex", alignItems: "center" }}>
        <Box sx={{ fontSize: 24, color: "primary.main", flexGrow: 1 }}>My Profile</Box>
      </Box>
      <Paper
        sx={{
          height: "calc(100% - 136px)",
          borderTopLeftRadius: 16,
          borderTopRightRadius: 16,
          borderBottomLeftRadius: 0,
          borderBottomRightRadius: 0,
        }}
      >
        <Box px={2} sx={{ borderBottom: 1, borderColor: "divider", height: 50 }}>
          <Tabs value={tab} onChange={handleChange} sx={{ height: 50 }}>
            <Tab label="Basic Info" />
            <Tab label="Password" />
          </Tabs>
        </Box>
        <Box sx={{ height: "calc(100% - 50px)" }}>
          <Scrollbars>
            {tab === 0 && <Basic />}
            {tab === 1 && <Password />}
          </Scrollbars>
        </Box>
      </Paper>
    </Box>
  );
};

export default ProfilePage;

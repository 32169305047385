import { Paper, Box, Input } from "@mui/material";
import { SearchIcon } from "utils/icons";

const SearchBox = (props) => {
  return (
    <Paper>
      <Box px={2} sx={{ display: "flex", alignItems: "center", height: 36 }}>
        <SearchIcon size={24} />
        <Input fullWidth disableUnderline sx={{ marginLeft: 2 }} placeholder="Search ..." {...props} />
      </Box>
    </Paper>
  );
};

export default SearchBox;

import React from "react";
import { Box, CircularProgress } from "@mui/material";

const PageLoading = () => (
  <Box
    pb={4}
    sx={{
      width: 1,
      height: 1,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: "#fff",
    }}
  >
    <CircularProgress />
  </Box>
);

export default PageLoading;

import React from "react";
import { Link, useMatch } from "react-router-dom";
import { ButtonBase, Box } from "@mui/material";
import { ExternalLinkIcon } from "utils/icons";
import { useRecoilState } from "recoil";
import { authAtom } from "store/auth";
import { clientAtom } from "store/client";
import useUI from "hooks/useUI";

const NavItem = ({ data, enabled = true }) => {
  const hUI = useUI();
  const match = useMatch(`${data.url}/*`);
  const [authState] = useRecoilState(authAtom);
  const [clientState] = useRecoilState(clientAtom);
  const isInstantEstimateApiEnabled = clientState.current?.is_instant_estimate_api_enabled;
  const isPrismaticEnabled = clientState.current?.is_prismatic_enabled;

  if (data.auth && data.auth(authState.user) === false) {
    return null;
  }

  if (data.url === "/products/instant-estimate" && !isInstantEstimateApiEnabled) {
    return null;
  }
  if (data.url === "/products/integrations" && !isPrismaticEnabled) {
    return null;
  }

  if (data.group) {
    return (
      <Box sx={{ height: 40, paddingLeft: 4, fontSize: 12, color: "#fff9", display: "flex", alignItems: "center" }}>
        {data.group}
      </Box>
    );
  }

  if (data.extUrl) {
    return (
      <Box mr={2}>
        <ButtonBase
          component="a"
          href={data.extUrl}
          target="_blank"
          sx={{
            height: 40,
            paddingLeft: 4,
            width: 1,
            justifyContent: "flex-start",
            borderTopRightRadius: 20,
            borderBottomRightRadius: 20,
            "&:hover": {
              backgroundColor: "#fff1",
            },
          }}
        >
          {data.icon}
          <Box mx={2} sx={{ fontSize: 14 }}>
            {data.label}
          </Box>
          <ExternalLinkIcon />
        </ButtonBase>
      </Box>
    );
  }

  if (data.noltUrl) {
    return (
      <Box mr={2}>
        <ButtonBase
          component="a"
          data-nolt="modal"
          disabled={!enabled}
          href={data.noltUrl}
          sx={{
            height: 40,
            paddingLeft: 4,
            width: 1,
            justifyContent: "flex-start",
            borderTopRightRadius: 20,
            borderBottomRightRadius: 20,
            backgroundColor: match ? "#fff" : "primary.main",
            color: enabled ? (match ? "primary.main" : "#fff") : "#bbbbbb",
            "&:hover": {
              backgroundColor: match ? "#fff" : "#fff1",
            },
          }}
        >
          {data.icon}
          <Box mx={2} sx={{ fontSize: 14 }}>
            {data.label}
          </Box>
        </ButtonBase>
      </Box>
    );
  }

  return (
    <Box mr={2}>
      <ButtonBase
        component={Link}
        to={data.url}
        sx={{
          height: 40,
          paddingLeft: 4,
          width: 1,
          justifyContent: "flex-start",
          borderTopRightRadius: 20,
          borderBottomRightRadius: 20,
          backgroundColor: match ? "#fff" : "primary.main",
          color: match ? "primary.main" : "#fff",
          "&:hover": {
            backgroundColor: match ? "#fff" : "#fff1",
          },
        }}
        onClick={() => hUI.hideMobileSidebar()}
      >
        {data.icon}
        <Box mx={2} sx={{ fontSize: 14 }}>
          {data.label}
        </Box>
      </ButtonBase>
    </Box>
  );
};

export default NavItem;

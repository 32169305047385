import { Stack } from "@mui/material";
import WizardText from "../shared/WizardText";
import WizardEmail from "../shared/WizardEmail";

const InfoForm = () => {
  return (
    <Stack spacing={2}>
      <WizardText fullWidth name="company_name" label="Company Name *" />
      <WizardText fullWidth name="company_slug" label="Company Slug *" />
      <WizardText fullWidth name="primary_first_name" label="Primary First Name *" />
      <WizardText fullWidth name="primary_last_name" label="Primary Last Name *" />
      <WizardText fullWidth name="phone" label="Phone Number *" />
      <WizardEmail fullWidth name="email" label="Work Email *" />
      <WizardText fullWidth name="address" label="Address" />
      <WizardText fullWidth name="city" label="City" />
      <WizardText fullWidth name="state" label="State" />
      <WizardText fullWidth name="zip_code" label="Zipcode" />
    </Stack>
  );
};

export default InfoForm;

import React from "react";
import { Box, TextField } from "@mui/material";
import useQuickSetupWizard from "hooks/useQuickSetupWizard";

const PhoneEditForm = ({ field, label = null }) => {
  const setupWizard = useQuickSetupWizard();

  const handlePhoneChange = async (e) => {
    setupWizard.update(field, e.target.value);
  };

  return (
    <Box>
      <TextField
        label={label ?? ""}
        defaultValue={setupWizard.client[field] || ""}
        inputProps={{
          onKeyUp: handlePhoneChange,
        }}
        sx={{ minWidth: 400 }}
      />
    </Box>
  );
};

export default PhoneEditForm;

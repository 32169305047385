import React from "react";
import { Stack, Box, TextField } from "@mui/material";
import useConfigurator from "hooks/useConfigurator";

const SelectGridRate = () => {
  const hConfigurator = useConfigurator();
  const [values, setValues] = React.useState({
    grid_rate: hConfigurator.client.grid_rate,
  });

  React.useEffect(() => {
    // eslint-disable-next-line
  }, []);

  const handleGridRateChange = (field) => (e) => {
    setValues({
      ...values,
      [field]: e.target.value,
    });
    hConfigurator.updateSingle(field, e.target.value);
  };

  return (
    <Stack spacing={2}>
      <Box>
        <Box sx={{ fontSize: 12 }}>Grid Rate</Box>
        <Box mt={1}>
          <TextField
            size="small"
            placeholder="$/kwh"
            type="number"
            value={values.grid_rate}
            onChange={handleGridRateChange("grid_rate")}
          />
        </Box>
      </Box>
    </Stack>
  );
};

export default SelectGridRate;

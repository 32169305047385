import React from "react";
import { Box, IconButton, Stack } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { DeleteIcon, EditIcon } from "utils/icons";

const useStyles = makeStyles({
  container: {
    width: "20.5rem",
    minHeight: "14.125rem",
    border: "1px solid #dcdcdc",
    boxSizing: "border-box",
    borderRadius: "10px",
    margin: "1.875rem 1rem",
    boxShadow: "0px 10px 24px rgba(0, 0, 0, 0.1)",
  },

  content: {
    alignItems: "center",
    padding: "24px 20px 0 20px",
    flex: 1,
  },

  header: {
    fontSize: "1rem",
    textAlign: "center",
    textTransform: "uppercase",
    color: "rgba(0, 0, 0, 0.87)",
    margin: "1rem 0px",
    fontWeight: "500",
    letterSpacing: "0.07rem",
  },

  action: {
    padding: "4px 8px",
    width: "100%",
    borderTop: "1px solid #dcdcdc",
    fontWeight: "bold",
    justifyContent: "flex-end",
  },
});

const Charger = ({ title, description, cost, onEdit }) => {
  const classes = useStyles();

  return (
    <Stack className={classes.container}>
      <Stack className={classes.content}>
        <img src="/images/charger.svg" alt="charger" width="40px" />
        {title && <Box className={classes.header}>{title}</Box>}
        <Box
          sx={{
            fontSize: "0.875rem",
            marginTop: title ? "0" : "1rem",
            textAlign: "center",
            color: "rgba(0, 0, 0, 0.87)",
            flexGrow: 1,
            lineHeight: "1.25rem",
            display: "flex",
            alignItems: title ? "start" : "center",
          }}
        >
          {description}
        </Box>
        <Box
          sx={{
            fontWeight: "bold",
            fontSize: title ? "0.75rem" : "1rem",
            textAlign: "center",
            color: "rgba(0, 0, 0, 0.87)",
            margin: "1rem 0rem",

            "& span": {
              fontSize: title ? "0.875rem" : "1.275rem",
            },
          }}
        >
          <span>+${cost}/mo</span>
        </Box>
      </Stack>
      <Stack className={classes.action} direction="row">
        <IconButton onClick={onEdit}>
          <EditIcon />
        </IconButton>
      </Stack>
    </Stack>
  );
};

export default Charger;

import React from "react";
import { useRecoilState } from "recoil";
import { useForm } from "react-hook-form";
import { Box, Grid, Stack, TextField } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import useAlert from "hooks/useAlert";
import { authAtom } from "store/auth";
import { apiUpdateProfile } from "store/api";
import FormText from "shared/FormText";
import Avatar from "./Avatar";

const Basic = () => {
  const hAlert = useAlert();
  const [authState, setAuthState] = useRecoilState(authAtom);
  const [newAvatar, setNewAvatar] = React.useState(null);
  const { control, handleSubmit } = useForm({
    defaultValues: {
      first_name: authState.user.first_name,
      last_name: authState.user.last_name,
      phone: authState.user.phone,
    },
  });
  const mounted = React.useRef(true);
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    return () => {
      mounted.current = false;
    };
    // eslint-disable-next-line
  }, []);

  const handleAvatarChange = (file) => {
    setNewAvatar(file);
  };

  const onSubmit = async (d) => {
    setLoading(true);
    const formData = new FormData();
    formData.append("first_name", d.first_name);
    formData.append("last_name", d.last_name);
    formData.append("phone", d.phone);
    if (newAvatar) {
      formData.append("avatar", newAvatar);
    }
    const res = await apiUpdateProfile(formData);
    if (res.status === 200) {
      setAuthState({
        ...authState,
        user: {
          ...authState.user,
          ...res.data,
        },
      });
      hAlert.show("Profile updated successfully");
    }
    if (mounted.current) {
      setLoading(false);
    }
  };

  return (
    <Box p={4}>
      <Grid container spacing={4}>
        <Grid item xs={12} md={4}>
          <Avatar disabled={loading} onChange={handleAvatarChange} />
        </Grid>
        <Grid item xs={12} md={8}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Stack spacing={4}>
              <FormText required disabled={loading} control={control} name="first_name" label="First Name *" />
              <FormText required disabled={loading} control={control} name="last_name" label="Last Name *" />
              <FormText disabled={loading} control={control} name="phone" label="Phone" />
              <TextField disabled label="Email *" defaultValue={authState.user.email} />
            </Stack>
            <Box mt={4}>
              <LoadingButton loading={loading} type="submit" variant="contained">
                Save
              </LoadingButton>
            </Box>
          </form>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Basic;

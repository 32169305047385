import React from "react";
import { Box, Button, IconButton, Table, TableBody, TableCell, TableHead, TableRow, Radio } from "@mui/material";
import useQuickSetupWizard from "hooks/useQuickSetupWizard";
import { MdClose, MdRefresh } from "react-icons/md";
import {
  apiApptCronofyAll,
  apiApptCronofyTokenReconnect,
  apiApptCronofyTokenDisconnect,
  apiApptCronofyCalendarSelect,
} from "utils/api_v2";
import AppointmentLeadTime from "../shared/AppointmentLeadTime";
import AppointmentAvailability from "../shared/AppointmentAvailability";
import AppointmentCalendar from "../shared/AppointmentCalendar";
import InviteAttendee from "../shared/InviteAttendee";
import MeetingDescription from "../shared/MeetingDescription";
import MeetingLocation from "../shared/MeetingLocation";
import useAlert from "hooks/useAlert";

const sortClendar = (a, b) => {
  if (a.profile_name < b.profile_name) return -1;
  if (a.profile_name > b.profile_name) return 1;
  if (a.calendar_name < b.calendar_name) return -1;
  if (a.calendar_name > b.calendar_name) return 1;
  return 0;
};

const TabCronofy = () => {
  const setupWizard = useQuickSetupWizard();
  const [selected, setSelected] = React.useState(null);
  const [tokens, setTokens] = React.useState([]);
  const [calendars, setCalendars] = React.useState([]);
  const hAlert = useAlert();

  React.useEffect(() => {
    const load = async () => {
      const res = await apiApptCronofyAll();
      if (res.status === 200) {
        setSelected(res.data.appointment_cronofy);
        setTokens(res.data.cronofy_token);
        setCalendars(res.data.cronofy_calendars);
      } else {
        hAlert.show("Loading calendars failed!", false);
      }
    };

    load();

    // eslint-disable-next-line
  }, []);

  const handleConnectClick = () => {
    localStorage.setItem("appointment_connect_method", "cronofy");
    localStorage.setItem("appointment_connect_company", setupWizard.client.id);
    window.location.assign(
      `${process.env.REACT_APP_CRONOFY_OAUTH_URL}?client_id=${process.env.REACT_APP_CRONOFY_CLIENT_ID}&response_type=code&redirect_uri=${process.env.REACT_APP_CRONOFY_AUTH_REDIRECT_ENDPOINT}&scope=${process.env.REACT_APP_CRONOFY_SCOPE}&state=${setupWizard.client.id}`
    );
  };

  const handleRefresh = async (token) => {
    const { id } = token;
    const res = await apiApptCronofyTokenReconnect(id);
    if (res.status === 200) {
      setTokens(res.data.cronofy_token);
      setCalendars(res.data.cronofy_calendars);
      hAlert.show("Refresh success!");
    } else {
      hAlert.show("Refresh failed!", false);
    }
  };

  const handleDisconnect = async (token) => {
    const { id } = token;
    const res = await apiApptCronofyTokenDisconnect(id);
    if (res.status === 200) {
      setTokens(res.data.cronofy_token);
      setCalendars(res.data.cronofy_calendars);
      hAlert.show("Disconnect success!");
    } else {
      hAlert.show("Disconnect failed!", false);
    }
  };

  const handleSelect = async (calendar) => {
    setSelected(calendar.id);
    const res = await apiApptCronofyCalendarSelect(calendar.id);
    if (res.status === 200) {
      hAlert.show("Calendar selection success!");
    } else {
      setSelected(selected);
      hAlert.show("Calendar selection failed!", false);
    }
  };

  return (
    <Box>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Button
          variant="contained"
          component="a"
          sx={{ width: 300 }}
          // href={`${process.env.REACT_APP_CRONOFY_OAUTH_URL}?client_id=${process.env.REACT_APP_CRONOFY_CLIENT_ID}&response_type=code&redirect_uri=${process.env.REACT_APP_CRONOFY_AUTH_REDIRECT_ENDPOINT}&scope=${process.env.REACT_APP_CRONOFY_SCOPE}&state=${setupWizard.client.id}`}
          onClick={handleConnectClick}
        >
          Connect Calendars
        </Button>
        <Box mx={2}>(Google, iCloud, Office365, Exchange, Outlook)</Box>
      </Box>
      <Box sx={{ mt: 4, border: "1px solid #ccc", borderRadius: 2 }}>
        <Box sx={{ p: 2, color: "#888", fontSize: 12 }}>Connected accounts</Box>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>User</TableCell>
              <TableCell>Provider</TableCell>
              <TableCell>Email</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tokens.map((d) => (
              <TableRow key={d.id} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                <TableCell>{d.user ? d.user.first_name + " " + d.user.last_name : ""}</TableCell>
                <TableCell>{d.linking_provider}</TableCell>
                <TableCell>{d.linking_profile_name}</TableCell>
                <TableCell width={30}>
                  <Box sx={{ display: "flex" }}>
                    <IconButton size="small" onClick={() => handleRefresh(d)}>
                      <MdRefresh />
                    </IconButton>
                    <IconButton size="small" onClick={() => handleDisconnect(d)}>
                      <MdClose />
                    </IconButton>
                  </Box>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <Box sx={{ p: 2, borderTop: "1px solid #ddd", color: "#888", fontSize: 12 }}>Available calendars</Box>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Email</TableCell>
              <TableCell>Name</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {calendars.sort(sortClendar).map((d) => (
              <TableRow key={d.id} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                <TableCell>{d.profile_name}</TableCell>
                <TableCell>{d.calendar_name}</TableCell>
                <TableCell width={30}>
                  <Radio checked={d.id === selected} onChange={() => handleSelect(d)} />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Box>
      <Box mt={4}>
        <AppointmentLeadTime />
        <InviteAttendee />
        <MeetingDescription />
        <MeetingLocation />
        <AppointmentAvailability />
        <AppointmentCalendar />
      </Box>
    </Box>
  );
};

export default TabCronofy;

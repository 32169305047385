import React from "react";
import { Box, Stack } from "@mui/material";
import useQuickSetupWizard from "hooks/useQuickSetupWizard";
import FileUpload from "shared/wizard/FileUpload";

const UploadLogo = () => {
  const setupWizard = useQuickSetupWizard();
  const [logo, setLogo] = React.useState(null);

  React.useEffect(() => {
    setLogo(setupWizard.client.logo);
    // eslint-disable-next-line
  }, []);

  const handleLogoChange = (file, url) => {
    setLogo(url);
    setupWizard.update("logo", file);
  };

  return (
    <Box>
      <FileUpload label="Upload Logo" onChange={handleLogoChange} />
      {logo && (
        <Stack spacing={2} mt={4}>
          <Box>
            <img src={logo} alt="logo" />
          </Box>
          <Box>How your logo will look like on mobile:</Box>
          <Box>
            <img style={{ height: 40 }} src={logo} alt="logo" />
          </Box>
          <Box>How your logo will look like on desktop:</Box>
          <Box>
            <img style={{ height: 54 }} src={logo} alt="logo" />
          </Box>
        </Stack>
      )}
    </Box>
  );
};

export default UploadLogo;

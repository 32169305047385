import axios from "axios";
var qs = require("qs");

const baseURL = process.env.REACT_APP_API_URL || "/api";

axios.defaults.withCredentials = true;
axios.defaults.baseURL = baseURL;

export const api = () => {
  let headers = {};
  const token = localStorage.getItem("jwt_access_token") || "";
  if (token) {
    headers["Authorization"] = "Bearer " + token;
  }

  const instance = axios.create({ baseURL, headers });

  instance.interceptors.response.use(
    (response) => {
      return response;
    },
    async (error) => {
      if (
        error.response &&
        error.response.status === 401 &&
        error.config.url !== "/auth/token/refresh/" &&
        error.response.status !== "/auth/token/"
      ) {
        try {
          const res = await axios.post("/auth/token/refresh/", { refresh: localStorage.getItem("jwt_refresh_token") });
          window.localStorage.setItem("jwt_access_token", res.data.access);
          error.config.__isRetryRequest = true;
          error.config.headers.Authorization = "Bearer " + res.data.access;
          instance.defaults.headers.common["Authorization"] = "Bearer " + res.data.access;
          return instance(error.config);
        } catch (e) {
          if (window.location.pathname !== "/login") window.location.href = "/login";
        }
      }
      return error.response;
    }
  );
  return instance;
};

export const apiGet = (url) => {
  return async () => {
    return await api().get(url);
  };
};

export const apiGetParams = (url) => {
  return async (params) => {
    return await api().get(url, { params, paramsSerializer });
  };
};

export const apiPost = (url) => {
  return async (req) => {
    return await api().post(url, req);
  };
};

export const apiPostParams = (url) => {
  return async (params, req = null) => {
    return await api().post(url, req, { params, paramsSerializer });
  };
};

export const apiPostMulti = (url) => {
  return async (req) => {
    const headers = {
      "Content-Type": "multipart/form-data",
    };
    return await api().post(url, req, { headers });
  };
};

export const apiPostBlob = (url) => {
  return async (req) => {
    return await api().post(url, req, { responseType: "blob", timeout: 0 });
  };
};

export const apiPostHeaders = (url) => {
  return async (req, headers) => {
    return await api().post(url, req, { headers });
  };
};

export const apiPatch = (url) => {
  return async (req) => {
    return await api().patch(url, req);
  };
};

export const apiPatchParams = (url) => {
  return async (params, req) => {
    return await api().patch(url, req, { params, paramsSerializer });
  };
};

export const apiPatchMulti = (url) => {
  return async (req) => {
    const headers = {
      "Content-Type": "multipart/form-data",
    };
    return await api().patch(url, req, { headers });
  };
};

export const apiPut = (url) => {
  return async (req) => {
    return await api().put(url, req);
  };
};

export const apiDelete = (url) => {
  return async (req) => {
    return await api().delete(url, req);
  };
};

export const apiFile = (url) => {
  return async (req) => {
    const headers = {
      "Content-Type": "multipart/form-data",
    };
    return await api().post(url, req, { headers });
  };
};

const paramsSerializer = (params) => qs.stringify(params, { arrayFormat: "repeat" });

export const delay = (n) => {
  return new Promise(function (resolve) {
    setTimeout(resolve, n * 1000);
  });
};

import React from "react";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Box, CircularProgress, InputAdornment, Stack, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/styles";
import LoginLogo from "shared/LoginLogo";
import FormText from "shared/FormText";
import RoundedButton from "shared/RoundedButton";
import { PersonIcon } from "utils/icons";

const Main = ({ loading, resetEmail, onSubmit, showPromotion, onOpenPromotion }) => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

  const { control, handleSubmit, reset } = useForm({
    defaultValues: {
      email: "",
    },
  });

  React.useEffect(() => {
    if (resetEmail) reset();
    // eslint-disable-next-line
  }, [resetEmail]);

  return (
    <Stack className="section-main">
      <Box p={isDesktop ? 4 : 2} sx={{ display: "flex", justifyContent: "center" }}>
        <Box sx={{ width: isDesktop ? "100%" : "600px" }}>
          <LoginLogo />
        </Box>
      </Box>
      <Stack p={isDesktop ? 4 : 2} sx={{ flexGrow: 1, justifyContent: "center", alignItems: "center" }}>
        <Stack mt={-10} sx={{ maxWidth: "600px", width: 1, height: 1 }}>
          <Box my={3} sx={{ fontSize: 18, fontWeight: 900 }}>
            Forgot Password
          </Box>

          <Box>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Box mb={1} sx={{ fontSize: 14 }}>
                Please enter your email that is used for registration.
              </Box>
              <FormText
                disabled={loading}
                control={control}
                name="email"
                label="Email *"
                required
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <PersonIcon style={{ fontSize: 24, color: "black", marginRight: "7px" }} />
                    </InputAdornment>
                  ),
                }}
              />
              <Box mt={5} sx={{ display: "flex", justifyContent: "center" }}>
                <RoundedButton
                  type="submit"
                  variant="contained"
                  fullWidth={!isDesktop}
                  sx={{ minWidth: 150 }}
                  disabled={loading}
                >
                  {loading ? <CircularProgress color="secondary" size={22} /> : "Send"}
                </RoundedButton>
              </Box>
            </form>
          </Box>
          <Box mt={3} sx={{ textAlign: "center" }}>
            <Box
              component={Link}
              to="/login"
              sx={{
                color: "primary.main",
                fontSize: 14,
                fontWeight: 900,
                textDecoration: "none",
                "&:hover": { textDecoration: "underline" },
              }}
            >
              Back to login page
            </Box>
          </Box>
          <Box mt={3} sx={{ opacity: showPromotion ? 1 : 0, transition: "opacity .1s ease-in-out" }}>
            <Box sx={{ fontSize: 14, fontWeight: 900, textAlign: "center" }}>
              <Box component="span" mr={1}>
                <img src="images/promotion.svg" width="14px" alt="" /> Check out our
              </Box>
              <Box
                component="span"
                sx={{
                  color: "primary.main",
                  fontSize: 14,
                  cursor: "pointer",
                }}
                onClick={onOpenPromotion}
              >
                Promotional Offer
              </Box>
            </Box>
          </Box>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default Main;

import React from "react";
import { Box, Stack, Button, Dialog, TextField, MenuItem } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import useAlert from "hooks/useAlert";
import { apiSetBalanceTransactions } from "store/api";

const toUSD = (balance) => {
  if (balance >= 0) {
    return `$${(balance / 100).toFixed(2)}`;
  } else {
    return `-$${(-balance / 100).toFixed(2)}`;
  }
};

const AdjustBalance = ({ client, balance, onAdjust }) => {
  const mounted = React.useRef(true);
  const hAlert = useAlert();
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [type, setType] = React.useState(-1);
  const [amount, setAmount] = React.useState("");
  const [note, setNote] = React.useState("");

  React.useEffect(() => {
    return () => {
      mounted.current = false;
    };
    // eslint-disable-next-line
  }, []);

  React.useEffect(() => {
    if (!open) {
      setType(-1);
      setAmount("");
      setNote("");
    }
    // eslint-disable-next-line
  }, [open]);

  const handleTypeChange = (e) => {
    setType(e.target.value);
  };

  const handleAmountChange = (e) => {
    setAmount(e.target.value);
  };

  const handleNoteChange = (e) => {
    setNote(e.target.value);
  };

  const handleApply = async () => {
    setLoading(true);
    const res = await apiSetBalanceTransactions({
      customer: client.customer_id,
      amount: type * amount,
      description: note,
    });
    if (res.status === 200) {
      hAlert.show("Adjusted balance successfully");
      setOpen(false);
      onAdjust();
    } else {
      hAlert.show("Something went wrong", false);
    }
    if (mounted.current) {
      setLoading(false);
    }
  };

  return (
    <>
      <Button variant="outlined" onClick={() => setOpen(true)}>
        Adjust Balance
      </Button>
      <Dialog open={open} onClose={() => {}} PaperProps={{ sx: { width: "100%" } }}>
        <Box p={4}>
          <Box sx={{ fontSize: 20 }}>Customer balance adjustment</Box>
          <Box
            my={4}
            py={4}
            sx={{
              borderTop: "1px solid #ccc",
              borderBottom: "1px solid #ccc",
              display: "flex",
              justifyContent: "space-evenly",
              textAlign: "center",
            }}
          >
            <Box>
              <Box>Starting balance</Box>
              <Box sx={{ fontSize: 32 }}>{toUSD(balance)}</Box>
            </Box>
            <Box sx={{ borderLeft: "1px solid #ccc" }} />
            <Box>
              <Box>New balance</Box>
              <Box sx={{ fontSize: 32 }}>{toUSD(balance + type * 100 * (amount || 0))}</Box>
            </Box>
          </Box>
          <Stack spacing={4}>
            <Box>
              <Box mb={1}>Adjustment type</Box>
              <TextField
                select
                fullWidth
                disabled={loading}
                value={type}
                onChange={handleTypeChange}
                sx={{ maxWidth: 200 }}
              >
                <MenuItem value="-1">Credit</MenuItem>
                <MenuItem value="1">Debit</MenuItem>
              </TextField>
            </Box>
            <Box>
              <Box mb={1}>Amount</Box>
              <TextField
                fullWidth
                type="number"
                disabled={loading}
                value={amount}
                onChange={handleAmountChange}
                sx={{ maxWidth: 200 }}
              />
            </Box>
            <Box>
              <Box mb={1}>Add internal note</Box>
              <TextField fullWidth multiline minRows={3} disabled={loading} value={note} onChange={handleNoteChange} />
            </Box>
            <Box sx={{ fontSize: 14 }}>
              This space can be used to provide additional information about your adjustment. This is an internal note
              and will not be visible to your Customer.
            </Box>
            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
              <Button disabled={loading} variant="outlined" sx={{ marginRight: 2 }} onClick={() => setOpen(false)}>
                Cancel
              </Button>
              <LoadingButton
                disabled={!note || !amount || parseInt(amount) === 0}
                loading={loading}
                disableElevation
                variant="contained"
                onClick={handleApply}
              >
                Apply to Account Balance
              </LoadingButton>
            </Box>
          </Stack>
        </Box>
      </Dialog>
    </>
  );
};

export default AdjustBalance;

import React from "react";
import { Box, FormControlLabel, Checkbox } from "@mui/material";
import useQuickSetupWizard from "hooks/useQuickSetupWizard";

const SelectUtilityIncentive = () => {
  const setupWizard = useQuickSetupWizard();
  const [utilityIncentive, setUtilityIncentive] = React.useState(false);

  React.useEffect(() => {
    setUtilityIncentive(setupWizard.client.is_utility_incentive);
    // eslint-disable-next-line
  }, []);

  const handleChange = (e) => {
    setUtilityIncentive(e.target.checked);
    setupWizard.update("is_utility_incentive", e.target.checked);
  };

  return (
    <Box sx={{ display: "flex" }}>
      <FormControlLabel
        control={<Checkbox checked={utilityIncentive} onChange={handleChange} />}
        label="Utility Incentives"
      />
    </Box>
  );
};

export default SelectUtilityIncentive;

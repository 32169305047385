import { useRecoilState, useResetRecoilState } from "recoil";
import { clientCreateAtom } from "store/client";

const useClientCreateWizard = () => {
  const reset = useResetRecoilState(clientCreateAtom);
  const [state, setState] = useRecoilState(clientCreateAtom);

  const setRestrictions = (required, needVerified) => {
    setState({
      ...state,
      required,
      needVerified,
    });
  };

  const setVerified = (key, message, status) => {
    setState({
      ...state,
      verified: {
        ...state.verified,
        [key]: true,
      },
      warnings: {
        ...state.warnings,
        [key]: status === "Valid" ? null : message || "Risky",
      },
      errors: {
        ...state.errors,
        [key]: null,
      },
    });
  };

  const submitFailed = (errors) => {
    setState({
      ...state,
      form: 1,
      errors: {
        ...state.errors,
        ...errors,
      },
    });
  };

  const setVerifyFailed = (key, message) => {
    setState({
      ...state,
      verified: {
        ...state.verified,
        [key]: false,
      },
      warnings: {
        ...state.warnings,
        [key]: null,
      },
      errors: {
        ...state.errors,
        [key]: message || "Failed",
      },
    });
  };

  const update = (key, value) => {
    setState({
      ...state,
      data: {
        ...state.data,
        [key]: value,
      },
      verified: {
        ...state.verified,
        [key]: false,
      },
      errors: {
        ...state.errors,
        [key]: null,
      },
    });
  };

  const back = () => {
    setState({
      ...state,
      form: state.form - 1,
    });
  };

  const next = () => {
    let valid = true;
    let errors = {};

    for (const k of state.required) {
      if (!state.data[k]) {
        valid = false;
        errors[k] = "Required";
      } else {
        errors[k] = null;
      }
    }

    for (const k of state.needVerified) {
      if (!state.verified[k]) {
        valid = false;
        errors[k] = "Should be verified";
      } else {
        errors[k] = null;
      }
    }

    if (valid) {
      setState({
        ...state,
        form: state.form + 1,
        errors: {},
      });
    } else {
      setState({
        ...state,
        errors: {
          ...state.errors,
          ...errors,
        },
      });
    }
  };

  return {
    ...state,
    reset,
    update,
    back,
    next,
    setRestrictions,
    setVerified,
    setVerifyFailed,
    submitFailed,
  };
};

export default useClientCreateWizard;

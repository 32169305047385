import React from "react";
import { useRecoilState } from "recoil";
import { Button } from "@mui/material";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import { clientAtom } from "store/client";

const defaultStyles = ({ color, backColor, hoverColor, hoverBackColor }) =>
  makeStyles({
    root: {
      color,
      backgroundColor: backColor,
      boxShadow: "none",
      border: "1px solid " + backColor,
      borderRadius: "50px",
      padding: "16px 45px",
      fontWeight: "900",
      fontSize: "16px",
      lineHeight: "22px",
      letterSpacing: "0.1em",
      textAlign: "center",

      "&:hover": {
        color: hoverColor,
        backgroundColor: hoverBackColor,
        boxShadow: "none",
        textDecoration: "none !important",
      },

      "&.Mui-disabled": {
        color,
        backgroundColor: backColor,
      },
    },
  });

const secondaryStyles = ({ color, backColor, hoverColor, hoverBackColor }) =>
  makeStyles({
    root: {
      color,
      backgroundColor: backColor,
      border: "1px solid " + backColor,
      boxShadow: "none",
      borderRadius: "50px",
      padding: "0 45px",
      lineHeight: "22px",
      letterSpacing: "0.1em",
      textAlign: "center",

      "&:hover": {
        border: "1px solid " + backColor,
        color: hoverColor,
        backgroundColor: hoverBackColor,
        boxShadow: "none",
        textDecoration: "none !important",
      },
    },
  });

const RoundedButton = ({ kind, className, ...props }) => {
  const [clientState] = useRecoilState(clientAtom);
  const {
    color_scheme_button: backColor,
    color_scheme_button_text: color,
    color_scheme_button_hover: hoverBackColor,
    color_scheme_button_hover_text: hoverColor,
  } = clientState.current;
  const defaultClasses = defaultStyles({ color, backColor, hoverColor, hoverBackColor })();
  const secondaryClasses = secondaryStyles({ color, backColor, hoverColor, hoverBackColor })();
  let classes;

  switch (kind) {
    case "secondary":
      classes = secondaryClasses;
      break;
    default:
      classes = defaultClasses;
  }

  return <Button variant={props.variant ?? "contained"} classes={classes} {...props} />;
};

export default RoundedButton;

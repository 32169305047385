import React from "react";
import { useRecoilState } from "recoil";
import { Scrollbars } from "react-custom-scrollbars";
import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Button, Box, Paper, Stack, MenuItem } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import useAlert from "hooks/useAlert";
import { authAtom } from "store/auth";
import { clientAtom } from "store/client";
import { apiSendInvite } from "store/api";
import FormText from "shared/FormText";
import { ArrowLeftIcon } from "utils/icons";
import { getPermissions } from "utils/generators";
import HeroRedirect from "pages/settings/team/HeroRedirect";

const TeamInvitePage = () => {
  const hAlert = useAlert();
  const navigate = useNavigate();
  const [authState] = useRecoilState(authAtom);
  const [clientState] = useRecoilState(clientAtom);
  const { control, handleSubmit } = useForm({
    defaultValues: {
      role: "user",
    },
  });
  const [loading, setLoading] = React.useState(false);
  const mounted = React.useRef(true);

  React.useEffect(() => {
    return () => {
      mounted.current = false;
    };
    // eslint-disable-next-line
  }, []);

  const onSubmit = async (d) => {
    setLoading(true);

    const res = await apiSendInvite({
      email: d.email,
      first_name: d.first_name,
      last_name: d.last_name,
      phone: d.phone,
      role: d.role,
      solar_company: clientState.currentId,
    });

    if (res.status === 200) {
      hAlert.show(res.data.detail);
      navigate("/settings/team");
    } else {
      hAlert.show("Sending invitation has been failed.", false);
    }

    if (mounted.current) {
      setLoading(false);
    }
  };

  return (
    <Box px={4} py={2} sx={{ height: 1 }} display="flex" gap={2} flexDirection="column">
      <Box display="flex" flexDirection="row" justifyContent="space-between">
        <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", gap: 2 }}>
          <Box display="flex" justifyContent="space-between">
            <Button component={Link} to="/settings/team" size="small" startIcon={<ArrowLeftIcon />}>
              Team
            </Button>
          </Box>
          <Box sx={{ fontSize: 24, color: "primary.main" }}>Send Invitation</Box>
        </Box>
        <Box alignSelf="end">{clientState.current.enable_hero_prompt && <HeroRedirect />}</Box>
        <Box />
      </Box>
      <Paper
        sx={{
          height: "calc(100% - 136px)",
          borderTopLeftRadius: 16,
          borderTopRightRadius: 16,
          borderBottomLeftRadius: 0,
          borderBottomRightRadius: 0,
        }}
      >
        <Box sx={{ height: 1 }}>
          <Scrollbars>
            <Box p={4}>
              <form onSubmit={handleSubmit(onSubmit)}>
                <Stack spacing={4}>
                  <FormText required disabled={loading} control={control} name="first_name" label="First Name *" />
                  <FormText required disabled={loading} control={control} name="last_name" label="Last Name *" />
                  <FormText required disabled={loading} control={control} name="email" label="Work Email *" />
                  <FormText required disabled={loading} control={control} name="phone" label="Phone Number *" />
                  <FormText required select disabled={loading} control={control} name="role" label="Role *">
                    {getPermissions(authState.user, clientState.current?.is_default).map((d) => (
                      <MenuItem key={d.value} {...d}>
                        {d.label}
                      </MenuItem>
                    ))}
                  </FormText>
                </Stack>
                <Box mt={4}>
                  <LoadingButton fullWidth loading={loading} variant="contained" type="submit">
                    SEND
                  </LoadingButton>
                </Box>
              </form>
            </Box>
          </Scrollbars>
        </Box>
      </Paper>
    </Box>
  );
};

export default TeamInvitePage;

import { useRecoilState, useResetRecoilState } from "recoil";
import { apiUpdateClient } from "store/api";
import { configuratorAtom, quickSetupAtom } from "store/configurator";
import { loadingAtom } from "store/common";

const useQuickSetupWizard = () => {
  const [, setLoading] = useRecoilState(loadingAtom);
  const [confState, setConfState] = useRecoilState(configuratorAtom);
  const [quickSetupState, setQuickSetupState] = useRecoilState(quickSetupAtom);
  const resetQuickSetupState = useResetRecoilState(quickSetupAtom);

  const update = async (key, value) => {
    setQuickSetupState({
      ...quickSetupState,
      updates: [...quickSetupState.updates.filter((d) => d.key !== key), { key, value }],
    });
  };

  const updateMany = async (values) => {
    const keys = values.map((d) => d.key);
    setQuickSetupState({
      ...quickSetupState,
      updates: [...quickSetupState.updates.filter((d) => !keys.includes(d.key)), ...values],
    });
  };

  const drop = async (key) => {
    setQuickSetupState({
      ...quickSetupState,
      updates: [...quickSetupState.updates.filter((d) => d.key !== key)],
    });
  };

  const dropMany = async (keys) => {
    setQuickSetupState({
      ...quickSetupState,
      updates: [...quickSetupState.updates.filter((d) => !keys.includes(d.key))],
    });
  };

  const back = () => {
    setQuickSetupState({
      ...quickSetupState,
      form: quickSetupState.form - 1,
      updates: [],
      errors: {},
    });
  };

  const next = async () => {
    const formData = new FormData();
    const restrictions = ["required", "not-checked", "not-valid"];

    let hasError = false;
    let errors = {};

    if (quickSetupState.updates.length) {
      for (let i = 0; i < quickSetupState.updates.length; i++) {
        if (restrictions.includes(quickSetupState.updates[i].value)) {
          hasError = true;
          errors = {
            ...errors,
            [quickSetupState.updates[i].key]: quickSetupState.updates[i].value,
          };
        }
      }

      if (hasError) {
        setQuickSetupState({
          ...quickSetupState,
          errors,
        });
        return;
      }

      for (let i = 0; i < quickSetupState.updates.length; i++) {
        formData.append(quickSetupState.updates[i].key, quickSetupState.updates[i].value);
      }

      setLoading("Updating ...");
      const res = await apiUpdateClient(confState.client.id)(formData);
      setLoading("");

      if (res.status === 200) {
        setConfState({
          ...confState,
          client: res.data,
        });
        setQuickSetupState({
          ...quickSetupState,
          form: quickSetupState.form + 1,
          updates: [],
          errors: {},
        });
      } else {
        setQuickSetupState({
          ...quickSetupState,
          errors: res.data,
        });
      }
    } else {
      setQuickSetupState({
        ...quickSetupState,
        form: quickSetupState.form + 1,
      });
    }
  };

  return {
    reset: resetQuickSetupState,
    update,
    updateMany,
    drop,
    dropMany,
    back,
    next,
    client: confState.client,
    form: quickSetupState.form,
    updates: quickSetupState.updates,
    errors: quickSetupState.errors,
  };
};

export default useQuickSetupWizard;

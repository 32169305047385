import React from "react";
import GoogleMapReact from "google-map-react";
import { Box, CircularProgress } from "@mui/material";
import useReporting from "hooks/useReporting";
import { apiGetLeadStatusTraffic } from "store/api";

const TrafficHeatMap = () => {
  const hReporting = useReporting();
  const [loading, setLoading] = React.useState(false);
  const [data, setData] = React.useState(null);

  React.useEffect(() => {
    updateData();
    // eslint-disable-next-line
  }, [hReporting.main.filter.startDate, hReporting.main.filter.endDate]);

  const updateData = async () => {
    setLoading(true);
    const res = await apiGetLeadStatusTraffic({
      solar_company: hReporting.client.id,
      start_at: hReporting.formatStartDate(hReporting.main.filter.startDate),
      end_at: hReporting.formatEndDate(hReporting.main.filter.endDate),
      product: "stella1",
      stella2_only: hReporting.main.filter.stella2only,
    });
    if (res.status === 200) {
      const _data = res.data.data.map((d) => ({
        weight: d.count,
        lat: d.rooftop_latitude,
        lng: d.rooftop_longitude,
      }));
      setData(_data);
    }
    setLoading(false);
  };

  return (
    <Box p={2} sx={{ borderRadius: 2, border: "1px solid #ccc" }}>
      <Box mb={4} sx={{ fontSize: 20, color: "primary.main" }}>
        TRAFFIC HEAT MAP
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-evenly",
          color: "primary.main",
          height: 400,
        }}
      >
        {loading ? (
          <Box mt={4} sx={{ textAlign: "center" }}>
            <CircularProgress size={20} />
          </Box>
        ) : (
          <GoogleMapReact
            bootstrapURLKeys={{
              key: process.env.REACT_APP_GOOGLE_MAP_KEY,
              libraries: ["places", "visualization"],
            }}
            zoom={5}
            center={[39.8333333, -98.585522]}
            heatmapLibrary={true}
            heatmap={{
              positions: data,
              options: {
                radius: 30,
                opacity: 0.7,
              },
            }}
          />
        )}
      </Box>
    </Box>
  );
};

export default TrafficHeatMap;

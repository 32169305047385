import React, { useMemo } from "react";
import { Box, Divider, rgbToHex, Stack, TextField, Typography, useTheme, Tooltip, Button } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import useConfigurator from "hooks/useConfigurator";
import EmbedScript from "../../shared/EmbedScript";
import { generateEmbedChatWidgetScript } from "utils/generators";
import RadioInput from "../banner-config/shared/RadioInput";
import AntSwitch from "./components/AntSwitch";
import ColorPicker from "shared/wizard/ColorPicker";
import UploadAiAvatar from "./components/UploadAiAvatar";
import UploadStarterHero from "./components/UploadStarterHero";

const EmbedChatWidget = () => {
  const hConfigurator = useConfigurator();

  const theme = useTheme();

  const launcherTypeOptions = [
    { label: "Chat Icon", value: "icon" },
    { label: "Drawer", value: "drawer" },
    { label: "None", value: "none" },
  ];

  const positionOptions = [
    { label: "Left", value: "left" },
    { label: "Right", value: "right" },
  ];

  const [values, setValues] = React.useState({
    primary_color: hConfigurator.client.chat_widget_primary_color,
    accent_color: hConfigurator.client.chat_widget_accent_color,

    launcher_type: hConfigurator.client.chat_widget_launcher_type,

    ribbon_text: hConfigurator.client.chat_widget_ribbon_text,

    starter_display: hConfigurator.client.chat_widget_starter_display,
    starter_delay: hConfigurator.client.chat_widget_starter_delay,
    starter_text: hConfigurator.client.chat_widget_starter_text,
    starter_cta: hConfigurator.client.chat_widget_starter_cta,

    ai_name: hConfigurator.client.chat_widget_ai_name,
    ai_subtitle: hConfigurator.client.chat_widget_ai_subtitle,
    ai_engine: hConfigurator.client.chat_widget_ai_engine ?? false,
    ai_engine_instant_estimate_label: hConfigurator.client.chat_widget_ai_engine_instant_estimate_label ?? "",
    ai_engine_select_label: hConfigurator.client.chat_widget_ai_engine_select_label ?? "",

    position: hConfigurator.client.chat_widget_position,
    embed_widget: hConfigurator.client.embed_widget,
    enable_stepper: hConfigurator.client.enable_stepper,
    config_link_questions: hConfigurator.client.config_link_questions,
  });

  const widgetTypes = [
    { label: "Chat", value: "chat" },
    {
      label: "Stepper",
      value: "wizard",
      disabled: !values.enable_stepper,
      hint: !values.enable_stepper && (
        <Tooltip title="Coming soon">
          <Box
            sx={{
              marginBottom: "auto",
              marginTop: "auto",
              marginLeft: "-12px",
              color: "text.disabled",
              display: "flex",
              alignContent: "center",
            }}
          >
            <InfoOutlinedIcon fontSize="small" />
          </Box>
        </Tooltip>
      ),
    },
  ];

  let widgetScript = generateEmbedChatWidgetScript(hConfigurator.client.app_url);

  const handleEmbedWidgetChange = (e, value) => {
    setValues({
      ...values,
      embed_widget: value,
    });
    hConfigurator.updateSingle("embed_widget", value);
  };

  const handlePrimaryColorChange = (color) => {
    setValues({
      ...values,
      primary_color: rgbToHex(color),
      accent_color: rgbToHex(theme.palette.getContrastText(color)),
    });

    hConfigurator.updateMany([
      { key: "chat_widget_primary_color", value: rgbToHex(color) },
      { key: "chat_widget_accent_color", value: rgbToHex(theme.palette.getContrastText(color)) },
    ]);
  };

  const handleAccentColorChange = (e, value) => {
    setValues({
      ...values,
      accent_color: value ? "#000000de" : "#fff",
    });

    hConfigurator.updateSingle("chat_widget_accent_color", value ? "#000000de" : "#fff");
  };

  const handleLauncherTypeChange = (e, value) => {
    if (value === "icon") {
      setValues({
        ...values,
        launcher_type: value,
      });

      hConfigurator.updateSingle("chat_widget_launcher_type", value);
    } else {
      setValues({
        ...values,
        launcher_type: value,
        position: "right",
      });

      hConfigurator.updateMany([
        { key: "chat_widget_launcher_type", value: value },
        { key: "chat_widget_position", value: "right" },
      ]);
    }
  };

  const handleRibbonTextChange = (e) => {
    if (e.target.value.length <= 64) {
      setValues({
        ...values,
        ribbon_text: e.target.value,
      });

      hConfigurator.updateSingle("chat_widget_ribbon_text", e.target.value);
    }
  };

  const handleStarterDisplayChange = (e, value) => {
    setValues({
      ...values,
      starter_display: value,
    });

    hConfigurator.updateSingle("chat_widget_starter_display", value);
  };

  const handleStarterDelayChange = (e) => {
    setValues({
      ...values,
      starter_delay: e.target.value,
    });

    hConfigurator.updateSingle("chat_widget_starter_delay", e.target.value);
  };

  const handleStarterTextChange = (e) => {
    if (e.target.value.length <= 128) {
      setValues({
        ...values,
        starter_text: e.target.value,
      });

      hConfigurator.updateSingle("chat_widget_starter_text", e.target.value);
    }
  };

  const handleStarterCTAChange = (e) => {
    if (e.target.value.length <= 32) {
      setValues({
        ...values,
        starter_cta: e.target.value,
      });

      hConfigurator.updateSingle("chat_widget_starter_cta", e.target.value);
    }
  };

  const handleAiNameChange = (e) => {
    setValues({
      ...values,
      ai_name: e.target.value,
    });

    hConfigurator.updateSingle("chat_widget_ai_name", e.target.value);
  };

  const handleAiSubtitleChange = (e) => {
    setValues({
      ...values,
      ai_subtitle: e.target.value,
    });

    hConfigurator.updateSingle("chat_widget_ai_subtitle", e.target.value);
  };

  const handleAiEngineChange = (e, value) => {
    setValues({
      ...values,
      ai_engine: value,
    });

    hConfigurator.updateSingle("chat_widget_ai_engine", value);
  };

  const handleAiEngineInstantEstimateLabelChange = (e) => {
    if (e.target.value.length <= 64) {
      setValues({
        ...values,
        ai_engine_instant_estimate_label: e.target.value,
      });

      hConfigurator.updateSingle("chat_widget_ai_engine_instant_estimate_label", e.target.value);
    }
  };

  const handleAiEngineSelectLabelChange = (e) => {
    if (e.target.value.length <= 64) {
      setValues({
        ...values,
        ai_engine_select_label: e.target.value,
      });

      hConfigurator.updateSingle("chat_widget_ai_engine_select_label", e.target.value);
    }
  };

  const handlePositionChange = (e, value) => {
    setValues({
      ...values,
      position: value,
    });
    hConfigurator.updateSingle("chat_widget_position", value);
  };

  const custom_link_questions = useMemo(() => {
    try {
      return JSON.parse(values.config_link_questions);
    } catch (_) {
      return [];
    }
  }, [values]);

  return (
    <Stack>
      <Box sx={{ p: 4 }}>
        <Box sx={{ fontSize: 20, mb: 4 }}>Widget Type</Box>

        <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
          <RadioInput
            legend="Select the embed widget type"
            row
            options={widgetTypes}
            value={values.embed_widget}
            onChange={handleEmbedWidgetChange}
          />
        </Box>
      </Box>

      <Divider />

      <Box sx={{ p: 4 }}>
        <Box sx={{ fontSize: 20, mb: 4 }}>Color</Box>

        <Box sx={{ display: "flex", flexWrap: "wrap", gap: 4 }}>
          <Stack spacing={4}>
            <Stack spacing={1}>
              <Box sx={{ fontSize: 12, color: "#333" }}>Primary color</Box>
              <ColorPicker value={values.primary_color} onChange={handlePrimaryColorChange} width={160} />
            </Stack>

            <Stack spacing={1}>
              <Box sx={{ fontSize: 12, color: "#333" }}>Accent color</Box>
              <Stack direction="row" spacing={1} alignItems="center">
                <Box
                  sx={{
                    width: 58,
                    height: 30,
                    backgroundColor: "#fff",
                    borderRadius: "3px",
                    border: "1px rgb(73, 169, 222) solid",
                  }}
                />
                <AntSwitch checked={values.accent_color === "#000000de"} onChange={handleAccentColorChange} />
                <Box
                  sx={{
                    width: 58,
                    height: 30,
                    backgroundColor: "#000000de",
                    borderRadius: "3px",
                    border: "1px rgb(73, 169, 222) solid",
                  }}
                />
              </Stack>
            </Stack>
          </Stack>

          <Stack spacing={1}>
            <Box sx={{ fontSize: 12, color: "#333" }}>Preview</Box>
            <Box
              sx={{
                width: 160,
                minWidth: 160,
                maxWidth: 160,
                p: 2,
                flexGrow: 1,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: values.primary_color,
                fontSize: 24,
                color: values.accent_color,
                border: "1px solid #000",
                borderRadius: "5px",
              }}
            >
              Stella Embedded
            </Box>
          </Stack>
        </Box>
      </Box>

      <Divider />

      <Box sx={{ p: 4 }}>
        <Box sx={{ fontSize: 20, mb: 4 }}>Launcher Type</Box>

        <Stack direction="row" spacing={1} alignItems="center">
          <RadioInput
            legend="Select the launcher type of widget"
            row
            options={launcherTypeOptions}
            value={values.launcher_type}
            onChange={handleLauncherTypeChange}
          />
        </Stack>
      </Box>

      <Divider />

      <Box sx={{ p: 4 }}>
        <Box sx={{ fontSize: 20, mb: 4 }}>Drawer Launcher</Box>

        <Stack spacing={1}>
          <TextField
            fullWidth
            disabled={values.launcher_type !== "drawer"}
            value={values.ribbon_text}
            onChange={(e) => {
              if (e.target.value.length <= 64) {
                setValues({ ...values, ribbon_text: e.target.value });
              }
            }}
            onBlur={handleRibbonTextChange}
            label={`Text of the ribbon (${values.ribbon_text.length}/64)`}
          />
        </Stack>
      </Box>

      <Divider />

      <Box sx={{ p: 4 }}>
        <Box sx={{ fontSize: 20, mb: 4 }}>Starter Card</Box>

        <Stack spacing={4}>
          <Box sx={{ display: "flex", flexWrap: "wrap", gap: 4 }}>
            <Stack spacing={1} sx={{ minWidth: 160 }}>
              <Box sx={{ fontSize: 12, color: "#333" }}>Turn on/off the start card</Box>
              <Stack direction="row" spacing={1} alignItems="center">
                <Typography>Off</Typography>
                <AntSwitch checked={values.starter_display} onChange={handleStarterDisplayChange} />
                <Typography>On</Typography>
              </Stack>
            </Stack>

            <Stack spacing={1}>
              <Box sx={{ fontSize: 12, color: "#333" }}>Delay duration of the start card (millisecond)</Box>
              <TextField
                size="small"
                placeholder="millisecond"
                type="number"
                value={values.starter_delay}
                onChange={(e) => {
                  setValues({ ...values, starter_delay: e.target.value });
                  hConfigurator.setTouched(true);
                }}
                onBlur={handleStarterDelayChange}
                sx={{ maxWidth: 160 }}
              />
            </Stack>
          </Box>

          <Stack spacing={1}>
            <TextField
              fullWidth
              value={values.starter_text}
              onChange={(e) => {
                if (e.target.value.length <= 128) {
                  setValues({ ...values, starter_text: e.target.value });
                  hConfigurator.setTouched(true);
                }
              }}
              onBlur={handleStarterTextChange}
              label={`Text of the start card (${values.starter_text.length}/128)`}
            />
          </Stack>

          <UploadStarterHero />

          <Stack spacing={1}>
            <TextField
              fullWidth
              value={values.starter_cta}
              onChange={(e) => {
                if (e.target.value.length <= 32) {
                  setValues({ ...values, starter_cta: e.target.value });
                  hConfigurator.setTouched(true);
                }
              }}
              onBlur={handleStarterCTAChange}
              label={`Text of the CTA on the start card (${values.starter_cta.length}/32)`}
            />
          </Stack>
        </Stack>
      </Box>

      <Divider />

      <Box sx={{ p: 4 }}>
        <Box sx={{ fontSize: 20, mb: 4 }}>AI</Box>

        <Stack spacing={4}>
          <Box sx={{ display: "flex", flexWrap: "wrap", gap: 4 }}>
            <Stack spacing={1}>
              <Box sx={{ fontSize: 12, color: "#333" }}>AI name</Box>
              <TextField
                size="small"
                placeholder="AI name"
                value={values.ai_name}
                onChange={(e) => {
                  setValues({ ...values, ai_name: e.target.value });
                  hConfigurator.setTouched(true);
                }}
                onBlur={handleAiNameChange}
                sx={{ maxWidth: 160 }}
              />
            </Stack>

            <Stack spacing={1}>
              <Box sx={{ fontSize: 12, color: "#333" }}>AI subtitle</Box>
              <TextField
                size="small"
                placeholder="AI subtitle"
                value={values.ai_subtitle}
                onChange={(e) => {
                  setValues({ ...values, ai_subtitle: e.target.value });
                  hConfigurator.setTouched(true);
                }}
                onBlur={handleAiSubtitleChange}
                sx={{ maxWidth: 160 }}
              />
            </Stack>
          </Box>

          <UploadAiAvatar />
        </Stack>

        {hConfigurator.client.is_solar_ai_engine_enabled && (
          <Stack spacing={2}>
            <Stack spacing={1} sx={{ mt: 4, minWidth: 160 }}>
              <Box sx={{ fontSize: 12, color: "#333" }}>Turn on/off the AI Engine</Box>
              <Stack direction="row" spacing={1} alignItems="center">
                <Typography>Off</Typography>
                <AntSwitch checked={values.ai_engine} onChange={handleAiEngineChange} />
                <Typography>On</Typography>
              </Stack>
            </Stack>

            <Box sx={{ display: "flex", flexWrap: "wrap", gap: 2 }}>
              <Stack spacing={1} sx={{ flex: 1 }}>
                <TextField
                  fullWidth
                  value={values.ai_engine_instant_estimate_label}
                  onChange={(e) => {
                    if (e.target.value.length <= 64) {
                      setValues({ ...values, ai_engine_instant_estimate_label: e.target.value });
                      hConfigurator.setTouched(true);
                    }
                  }}
                  onBlur={handleAiEngineInstantEstimateLabelChange}
                  label={`Instant Estimate Label (${values.ai_engine_instant_estimate_label.length}/64)`}
                  sx={{ minWidth: 200 }}
                />
              </Stack>

              <Stack spacing={1} sx={{ flex: 1 }}>
                <TextField
                  fullWidth
                  value={values.ai_engine_select_label}
                  onChange={(e) => {
                    if (e.target.value.length <= 64) {
                      setValues({ ...values, ai_engine_select_label: e.target.value });
                      hConfigurator.setTouched(true);
                    }
                  }}
                  onBlur={handleAiEngineSelectLabelChange}
                  label={`AI Engine Select Label (${values.ai_engine_select_label.length}/64)`}
                  sx={{ minWidth: 200 }}
                />
              </Stack>
            </Box>
          </Stack>
        )}
        <Box pt={1} pb={1} sx={{ fontSize: 18, color: "#333" }}>
          Set Custom Starter Links
        </Box>
        <Box pb={1} sx={{ display: "flex", flexWrap: "wrap", gap: 4 }}>
          <Stack pl={1} spacing={1} sx={{ flex: 1 }}>
            {(!custom_link_questions || custom_link_questions?.length === 0) && (
              <Box pt={1} pb={1} sx={{ fontSize: 12, color: "#333" }}>
                No Custom Starter Links Set
              </Box>
            )}
            {custom_link_questions?.map((q, i) => (
              <>
                <Stack spacing={1}>
                  <Box sx={{ fontSize: 12, color: "#333" }}>Button Text</Box>
                  <TextField
                    fullWidth
                    placeholder="Button Text"
                    value={Object.keys(q)[0]}
                    onChange={(e) => {
                      custom_link_questions[i] = { [e.target.value]: Object.values(q)[0] || "" };
                      setValues({ ...values, config_link_questions: JSON.stringify(custom_link_questions) });
                      hConfigurator.setTouched(true);
                    }}
                    onBlur={(e) => {
                      hConfigurator.updateSingle("config_link_questions", values.config_link_questions);
                    }}
                    sx={{ minWidth: 200 }}
                  />
                </Stack>

                <Stack spacing={1}>
                  <Box sx={{ fontSize: 12, color: "#333" }}>Button Response</Box>
                  <TextField
                    fullWidth
                    placeholder="Button Response"
                    value={Object.values(q)[0]}
                    onChange={(e) => {
                      custom_link_questions[i] = { [Object.keys(q)[0]]: e.target.value };
                      setValues({ ...values, config_link_questions: JSON.stringify(custom_link_questions) });
                      hConfigurator.setTouched(true);
                    }}
                    onBlur={() => {
                      hConfigurator.updateSingle("config_link_questions", values.config_link_questions);
                    }}
                    sx={{ minWidth: 200 }}
                  />
                </Stack>
              </>
            ))}
          </Stack>
        </Box>
        <Stack pt={1} direction="row" spacing={1}>
          <Button
            pt={1}
            pr={1}
            disabled={custom_link_questions?.length > 4}
            component="label"
            variant="outlined"
            onClick={() => {
              if (custom_link_questions) {
                const newQuestions = [...custom_link_questions, {}];
                setValues({ ...values, config_link_questions: JSON.stringify(newQuestions) });
              } else {
                setValues({ ...values, config_link_questions: "[{}]" });
              }
            }}
          >
            Add Starter Link
          </Button>
          <Button
            pl={1}
            pt={1}
            disabled={!custom_link_questions || custom_link_questions?.length === 0}
            component="label"
            variant="outlined"
            onClick={() => {
              custom_link_questions.pop();
              setValues({ ...values, config_link_questions: JSON.stringify(custom_link_questions) });
              hConfigurator.setTouched(true);
              hConfigurator.updateSingle("config_link_questions", custom_link_questions);
            }}
          >
            Remove Last Starter Link
          </Button>
        </Stack>
      </Box>

      <Divider />

      <Box sx={{ p: 4 }}>
        <Box sx={{ fontSize: 20, mb: 4 }}>Embed Chat Widget</Box>

        <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
          <RadioInput
            legend="Select the position of embed chat widget"
            row
            options={positionOptions}
            value={values.position}
            onChange={handlePositionChange}
            disabled={values.launcher_type !== "icon"}
          />

          <EmbedScript
            description="Place this code in the location you want the widget to appear"
            script={widgetScript}
          />
        </Box>
      </Box>
    </Stack>
  );
};

export default EmbedChatWidget;

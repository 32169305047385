import React from "react";
import { Box } from "@mui/material";
import AppointmentLeadTime from "../shared/AppointmentLeadTime";
import AppointmentAvailability from "../shared/AppointmentAvailability";
import AppointmentCalendar from "../shared/AppointmentCalendar";

const TabSelf = () => {
  return (
    <Box>
      <AppointmentLeadTime />
      <AppointmentAvailability />
      <AppointmentCalendar />
    </Box>
  );
};

export default TabSelf;

import React from "react";
import { arrayMoveImmutable } from "array-move";
import { sortableContainer, sortableElement, sortableHandle } from "react-sortable-hoc";
import { Box, Button, ButtonBase, InputBase } from "@mui/material";
import { MoveIcon, AddIcon, DeleteIcon } from "utils/icons";

const Container = sortableContainer(({ children }) => <Box>{children}</Box>);

const DragHandle = sortableHandle(() => (
  <Box
    sx={{
      width: 40,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexShrink: 0,
      cursor: "pointer",
      borderRight: "1px solid #ccc",
    }}
  >
    <MoveIcon />
  </Box>
));

const Item = sortableElement(({ value: choice, onChange, onDelete }) => {
  const toggleDQ = () => {
    onChange({
      ...choice,
      is_disqualifier: !choice.is_disqualifier,
    });
  };

  const handleChange = (e) => {
    onChange({
      ...choice,
      option: e.target.value,
    });
  };

  return (
    <Box
      my={1}
      sx={{
        display: "flex",
        border: "1px solid #ccc",
        backgroundColor: "#fff",
        borderRadius: 2,
        overflow: "hidden",
        zIndex: 2000,
      }}
    >
      <DragHandle />
      <ButtonBase
        sx={{
          width: 40,
          flexShrink: 0,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          borderRight: "1px solid #ccc",
          backgroundColor: choice.is_disqualifier ? "#333" : "#fff",
          color: choice.is_disqualifier ? "#fff" : "#333",
        }}
        onClick={toggleDQ}
      >
        DQ
      </ButtonBase>
      <InputBase
        sx={{ flexGrow: 1, "& input": { padding: "8px 16px" } }}
        placeholder="*required"
        value={choice.option}
        onChange={handleChange}
      />
      <ButtonBase
        sx={{
          width: 40,
          flexShrink: 0,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          color: "#333",
          borderLeft: "1px solid #ccc",
        }}
        onClick={onDelete}
      >
        <DeleteIcon fontSize={20} />
      </ButtonBase>
    </Box>
  );
});

const ChoiceForm = ({ initial, onUpdate }) => {
  const [choices, setChoices] = React.useState([]);

  React.useEffect(() => {
    setChoices(initial);
    // eslint-disable-next-line
  }, []);

  const onSortEnd = async ({ oldIndex, newIndex }) => {
    const newChoices = arrayMoveImmutable(choices, oldIndex, newIndex);
    for (let i = 0; i < newChoices.length; i++) {
      newChoices[i].position = i;
    }
    setChoices(newChoices);
    onUpdate(newChoices);
  };

  const handleAdd = () => {
    const newChoices = [
      ...choices,
      {
        is_disqualifier: false,
        option: "",
        position: choices.length,
      },
    ];
    setChoices(newChoices);
    onUpdate(newChoices);
  };

  const handleChange = (choice, index) => {
    const updates = choices.map((d, id) => (id === index ? choice : d));
    setChoices(updates);
    onUpdate(updates);
  };

  const handleDelete = (index) => {
    const updates = choices.filter((d, id) => id !== index);
    setChoices(updates);
    onUpdate(updates);
  };

  return (
    <Box>
      <Container onSortEnd={onSortEnd} useDragHandle>
        {choices.map((choice, index) => (
          <Item
            key={index}
            index={index}
            value={choice}
            onChange={(choice) => handleChange(choice, index)}
            onDelete={() => handleDelete(index)}
          />
        ))}
      </Container>
      <Box mt={2}>
        <Button startIcon={<AddIcon />} variant="outlined" color="inherit" size="small" onClick={handleAdd}>
          New Option
        </Button>
      </Box>
    </Box>
  );
};

export default ChoiceForm;

import React from "react";
import { sortableHandle } from "react-sortable-hoc";
import { Box } from "@mui/material";
import { MoveIcon } from "utils/icons";

const DragHandle = sortableHandle(() => (
  <Box
    sx={{
      width: 50,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexShrink: 0,
      borderRight: "1px solid #ddd",
      cursor: "pointer",
    }}
  >
    <MoveIcon />
  </Box>
));

export default DragHandle;

import React from "react";
import { Box, TextField } from "@mui/material";
import { LocalizationProvider, DateTimePicker } from "@mui/lab";
import DateAdapter from "@mui/lab/AdapterDateFns";
import useExperimentsEditor from "hooks/useExperimentsEditor";

const StartDateForm = () => {
  const hEditor = useExperimentsEditor();

  const handleChange = (value) => {
    hEditor.setValue("start_datetime", value);
  };

  return (
    <Box>
      <Box mb={2}>Choose a starting date for the experiment</Box>

      <LocalizationProvider dateAdapter={DateAdapter}>
        <DateTimePicker
          renderInput={(params) => <TextField {...params} />}
          value={hEditor.values.start_datetime}
          onChange={handleChange}
        />
      </LocalizationProvider>
    </Box>
  );
};

export default StartDateForm;

import React from "react";
import { Box, Button, Grid, Stack, TextField } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  root: {
    color: "#3b4151",
    fontFamily: "sans-serif",
    "& code": {
      padding: "2px 4px",
      color: "#c7254e",
      backgroundColor: "#f9f2f4",
      borderRadius: "4px",
    },
    "& h4": {
      fontSize: "18px",
      fontWeight: 600,
      margin: "5px 0 10px",
    },
    "& p": {
      fontSize: "12px",
      margin: "0 0 5px",
    },
  },
});

function Sungage({ values, onChange }) {
  const classes = useStyles();
  const [visible, setVisible] = React.useState({});

  const handleVisible = (key) => {
    setVisible({
      ...visible,
      [key]: visible[key] ? false : true,
    });
  };

  return (
    <>
      <AboutSungage />
      <Box mt={4}>
        <Grid container spacing={4}>
          {authFields.map((item, index) => (
            <Grid item xs={12} sm={6} key={index}>
              <Stack className={classes.root} mb={2} key={index}>
                <h4 className="header">
                  <code>{item.label}</code> (apiKey)
                </h4>
                <p>
                  Name: <code>{item.name}</code>
                </p>
                <p>
                  In: <code>{item.in}</code>
                </p>
                <Box sx={{ display: "flex", flexDirection: "row", gap: 1 }}>
                  <TextField
                    size="small"
                    type={visible[item.key] ? "text" : "password"}
                    value={values?.[item.key] || ""}
                    onChange={onChange(item.key)}
                    sx={{ width: "360px" }}
                  />
                  {visible[item.key] ? (
                    <Button onClick={() => handleVisible(item.key)}>Hide</Button>
                  ) : (
                    <Button color="error" onClick={() => handleVisible(item.key)}>
                      Show
                    </Button>
                  )}
                </Box>
              </Stack>
            </Grid>
          ))}
        </Grid>
      </Box>
    </>
  );
}

function AboutSungage() {
  return (
    <Box sx={{ display: "flex", alignItems: "center", flexDirection: "column" }}>
      <Box height="120px" sx={{ display: "flex", alignItems: "center", mt: { xs: 4, lg: 2, xl: -2 } }}>
        <img src="/images/big-sungage-logo-v2.png" alt="sungage" />
      </Box>
      <Box sx={{ py: 2, fontSize: 16, lineHeight: 1.8 }}>
        Sungage offers the lowest possible monthly payments in the industry, with unmatched customer service and
        enhanced underwriting that gives credit decisions to customers in seconds. Set up your connection to Sungage
        through Demand IQ to instantly prequalify homeowners for financing when they’re ready to go solar.
        <br />
        <br />
        <b>Already a Sungage partner?</b> Contact your Sungage account manager for your API key to enable
        pre-qualification access through Demand IQ. Enter your key below along with the email associated with your
        Sungage login.
        <br />
        <br />
        <b>Not a partner yet?</b>{" "}
        <Box
          component="a"
          href="https://www.sungage.com/installers#ready-to-go"
          target="_blank"
          sx={{ color: "#15ABE6" }}
        >
          Reach out to Sungage today
        </Box>{" "}
        to become a Certified installer.
      </Box>
    </Box>
  );
}

const authFields = [
  { key: "sungage_query_ak", name: "ak", in: "query", label: "accountKeyQ" },
  { key: "sungage_query_ue", name: "ue", in: "query", label: "userEmailQ" },
  // { name: "Sungage-ak", in: "header", label: "accountKeyH" },
  // { name: "pk", in: "query", label: "platformKeyQ" },
  // { name: "Sungage-pk", in: "header", label: "platformKeyH" },
  // { name: "uk", in: "query", label: "userKeyQ" },
  // { name: "Sungage-uk", in: "header", label: "userKeyH" },
  // { name: "Sungage-ue", in: "header", label: "userEmailH" }
];

export default Sungage;

import { atom } from "recoil";

export const customMapsAtom = atom({
  key: "customMapsAtom",
  default: {
    customMaps: [],
    questions: [],
    fields: [],
  },
});

import React from "react";
import { Box } from "@mui/material";
import ExperimentTable from "./components/table/ExperimentTable";
import ExperimentEditor from "./components/editor/ExperimentEditor";

const View = () => {
  const [editItem, setEditItem] = React.useState(null);

  return (
    <Box p={4}>
      <Box mb={4} sx={{ color: "primary.main", fontSize: 20 }}>
        VIEW EXPERIMENTS
      </Box>

      {editItem ? (
        <ExperimentEditor editItem={editItem} setEditItem={setEditItem} />
      ) : (
        <ExperimentTable setEditItem={setEditItem} />
      )}
    </Box>
  );
};

export default View;

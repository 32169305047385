import React from "react";
import { Navigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import { authAtom } from "store/auth";
import { clientAtom } from "store/client";
import { isAnalyst, isRep } from "utils/authHelper";

function AuthLayer({ children }) {
  const [authState] = useRecoilState(authAtom);
  const [clientState] = useRecoilState(clientAtom);

  if (authState.init && !authState.user) {
    return children;
  }

  if (isRep(authState.user)) {
    if (clientState.current.is_instant_estimate_api_enabled)
      return <Navigate to="/products/instant-estimate" replace />;

    return <Navigate to="/settings/profile" replace />;
  }

  if (isAnalyst(authState.user)) {
    return <Navigate to="/products/reporting" replace />;
  }

  return <Navigate to="/dashboard" replace />;
}

export default AuthLayer;

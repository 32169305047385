import React from "react";
import { useRecoilValue } from "recoil";
import { Scrollbars } from "react-custom-scrollbars";
import { Box, Paper, Grid } from "@mui/material";
import { apiGetBalance, apiGetInvoices } from "store/api";
import { clientAtom } from "store/client";
import Balance from "./components/Balance";
import Invoice from "./components/Invoice";
import Payment from "./components/payment/Payment";
import PageLoading from "shared/PageLoading";

const PaymentPage = () => {
  const clientState = useRecoilValue(clientAtom);
  const [balance, setBalance] = React.useState(null);
  const [invoices, setInvoices] = React.useState(null);

  React.useEffect(() => {
    let mounted = true;

    if (!clientState.current) return;

    const loadBalance = async () => {
      const res = await apiGetBalance({
        solar_company: clientState.currentId,
      });
      if (res.status === 200 && mounted) {
        setBalance(res.data);
      }
    };

    const loadInvoices = async () => {
      const res = await apiGetInvoices({
        customer: clientState.current.customer_id,
      });
      if (res.status === 200 && mounted) {
        const totalInvoices = res.data.invoices.data
          .filter((d) => d.paid && d.start_at && d.end_at)
          .sort((a, b) => b.created - a.created);
        setInvoices(totalInvoices.slice(0, 3));
      }
    };

    loadBalance();
    loadInvoices();

    return () => {
      mounted = false;
    };
    // eslint-disable-next-line
  }, [clientState.current]);

  if (!clientState.current) {
    return <PageLoading />;
  }

  return (
    <Box px={4} sx={{ height: 1 }}>
      <Box sx={{ height: 136, display: "flex", alignItems: "center" }}>
        <Box sx={{ fontSize: 24, color: "primary.main", flexGrow: 1 }}>Payments</Box>
      </Box>
      <Paper
        sx={{
          height: "calc(100% - 136px)",
          borderTopLeftRadius: 16,
          borderTopRightRadius: 16,
          borderBottomLeftRadius: 0,
          borderBottomRightRadius: 0,
        }}
      >
        <Scrollbars>
          <Box p={4}>
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <Balance balance={balance} invoice={invoices && invoices[0]} />
              </Grid>
              <Grid item xs={12} md={6}>
                <Invoice invoices={invoices} />
              </Grid>
              <Grid item xs={12} md={6}>
                <Payment balance={balance} />
              </Grid>
            </Grid>
          </Box>
        </Scrollbars>
      </Paper>
    </Box>
  );
};

export default PaymentPage;

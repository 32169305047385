import React from "react";
import { useRecoilState } from "recoil";
import { Box, TextField, Button } from "@mui/material";
import { loadingAtom } from "store/common";
import { apiSgEmailCheck } from "store/api";
import useQuickSetupWizard from "hooks/useQuickSetupWizard";
import { validate } from "email-validator";

const EmailEditForm = ({ field, label = null, canValidate = true }) => {
  const setupWizard = useQuickSetupWizard();
  const [, setLoading] = useRecoilState(loadingAtom);

  const [email, setEmail] = React.useState("");
  const [error, setError] = React.useState("");
  const inputRef = React.useRef();

  React.useEffect(() => {
    setError(setupWizard.errors[field]);
    if (setupWizard.errors[field]) {
      inputRef.current.focus();
    }
    // eslint-disable-next-line
  }, [setupWizard.errors]);

  const handleEmailChange = async (e) => {
    setEmail(e.target.value);

    if (canValidate) {
      if (e.target.value === "") {
        setError("required");
        setupWizard.update(field, "required");
      } else {
        if (setupWizard.client[field] !== e.target.value) {
          setError("not-checked");
          setupWizard.update(field, "not-checked");
        } else {
          setError("");
          setupWizard.drop(field);
        }
      }
    } else {
      if (setupWizard.client[field] !== e.target.value) {
        if (e.target.value) {
          if (validate(e.target.value)) {
            setError("");
            setupWizard.update(field, e.target.value);
          } else {
            setError("not-valid");
          }
        } else {
          setError("");
          setupWizard.update(field, e.target.value);
        }
      } else {
        setError("");
        setupWizard.drop(field);
      }
    }
  };

  const validateEmail = async () => {
    setLoading("Validating ...");

    const res = await apiSgEmailCheck({ email });

    if (res.status === 200) {
      setError("");
      setupWizard.update(field, email);
    } else {
      setError("not-valid");
    }

    setLoading("");
  };

  return (
    <Box>
      <Box sx={{ color: "error.main" }}>
        {error === "required" && "Required"}
        {error === "not-checked" && "Must be validated before submit"}
        {error === "not-valid" && "Not valid"}
      </Box>

      <TextField
        inputRef={inputRef}
        label={label ?? ""}
        defaultValue={setupWizard.client[field] || ""}
        inputProps={{
          onKeyUp: handleEmailChange,
        }}
        sx={{ minWidth: 400 }}
      />

      <Box mt={2}>
        {error === "not-checked" && (
          <Button color="inherit" variant="outlined" onClick={validateEmail}>
            Validate Email
          </Button>
        )}
      </Box>
    </Box>
  );
};

export default EmailEditForm;

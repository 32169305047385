import React from "react";
import { Box, Button } from "@mui/material";
import useQuickSetupWizard from "hooks/useQuickSetupWizard";

const SelectPriceSystem = () => {
  const setupWizard = useQuickSetupWizard();
  const [system, setSystem] = React.useState(null);

  React.useEffect(() => {
    setSystem(setupWizard.client.price_system_display);
    // eslint-disable-next-line
  }, []);

  const handleChange = (value) => {
    setSystem(value);
    setupWizard.update("price_system_display", value);
  };

  return (
    <Box>
      <Button
        disableElevation
        variant="contained"
        color={system ? "primary" : "inherit"}
        onClick={() => handleChange(true)}
      >
        Range
      </Button>
      <Button
        disableElevation
        variant="contained"
        color={system ? "inherit" : "primary"}
        onClick={() => handleChange(false)}
      >
        Exact
      </Button>
    </Box>
  );
};

export default SelectPriceSystem;

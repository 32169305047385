import React from "react";
import { Box, ButtonBase, IconButton } from "@mui/material";
import { EditIcon, DeleteIcon } from "utils/icons";
import DragHandle from "./shared/DragHandle";

const OpenEndedBox = ({ value: question, deleteQuestion, openEdit }) => {
  const handleDeleteQuestion = () => {
    let confirmed = window.confirm("Are you sure to delete question?");
    if (confirmed) {
      deleteQuestion(question);
    }
  };

  return (
    <Box
      my={2}
      sx={{
        backgroundColor: "#fff",
        border: "1px solid #2196f3",
        color: "#2196f3",
        borderRadius: 4,
        overflow: "hidden",
      }}
    >
      <Box sx={{ display: "flex" }}>
        <DragHandle />
        <ButtonBase sx={{ padding: 2, width: 1, fontSize: 16 }} onClick={openEdit}>
          <Box sx={{ flexGrow: 1, textAlign: "left" }}>{question.question_desc}</Box>
        </ButtonBase>
        <Box px={2} sx={{ display: "flex", alignItems: "center" }}>
          <IconButton onClick={openEdit}>
            <EditIcon />
          </IconButton>
          <IconButton onClick={handleDeleteQuestion}>
            <DeleteIcon />
          </IconButton>
        </Box>
      </Box>
    </Box>
  );
};

export default OpenEndedBox;

import React from "react";
import moment from "moment";
import { Line } from "react-chartjs-2";
import { Box, CircularProgress } from "@mui/material";
import useExperiments from "hooks/useExperiments";
import { apiGetConversionRateReport } from "store/api";

const extractDataset = (data, key) => {
  let _dataset = [];
  Object.keys(data).forEach((slug) => {
    const result = Object.keys(data[slug][key] || {}).map((k) => ({
      label: k,
      value: (data[slug][key] || {})[k],
    }));

    _dataset.push({
      label: data[slug].label,
      data: result,
    });
  });
  return _dataset;
};

const getChartOptions = (label) => {
  return {
    legend: {
      display: false,
    },
    title: {
      display: false,
    },
    maintainAspectRatio: false,
    layout: {
      padding: {
        top: 70,
        left: 32,
        right: 32,
      },
    },
    scales: {
      xAxes: [
        {
          gridLines: {
            display: false,
            drawBorder: false,
            tickMarkLength: 18,
          },
          ticks: {
            fontColor: "#2196f3",
            display: false,
          },
          scaleLabel: {
            display: true,
            labelString: label,
          },
        },
      ],
      yAxes: [
        {
          ticks: {
            min: 0,
            display: true,
          },
          scaleLabel: {
            display: true,
            labelString: "Conversion Rate",
          },
        },
      ],
    },
  };
};

const AnalyzeReport = ({ experiment }) => {
  const hExperiments = useExperiments();
  const [loading, setLoading] = React.useState(true);
  const [dateDataset, setDateDataset] = React.useState([]);
  const [visitorDataset, setVisitorDataset] = React.useState([]);

  React.useEffect(() => {
    let mounted = true;
    const load = async () => {
      const res = await apiGetConversionRateReport(experiment.id)({
        solar_company: hExperiments.client.id,
      });
      if (res.status === 200 && mounted) {
        const { data } = res.data;
        setDateDataset(extractDataset(data, "date_report"));
        setVisitorDataset(extractDataset(data, "visitors_report"));
        setLoading(false);
      }
    };
    load();
    return () => {
      mounted = false;
    };
    // eslint-disable-next-line
  }, []);

  if (loading) {
    return (
      <Box sx={{ height: 200, display: "flex", alignItems: "center", justifyContent: "center" }}>
        <CircularProgress size={20} />
      </Box>
    );
  }

  return (
    <Box>
      <Box sx={{ display: "flex" }}>
        <Box sx={{ height: 300, width: "50%" }}>
          {dateDataset.length ? (
            <Line
              data={{
                labels: dateDataset[0].data.map((d) => moment(d.label).format("MMMM Do YYYY, h:mm:ss a")),
                datasets: dateDataset.map((data, i) => {
                  return {
                    label: data.label,
                    data: data.data.map((item) => item.value.toFixed(2)),
                    borderColor: i ? "#529cfc" : "#ff3d6a",
                    backgroundColor: i ? "#529cfc" : "#ff3d6a",
                    fill: false,
                  };
                }),
              }}
              options={getChartOptions("Date")}
            />
          ) : (
            <Box p={4}>No Data</Box>
          )}
        </Box>
        <Box sx={{ height: 300, width: "50%" }}>
          {visitorDataset.length ? (
            <Line
              data={{
                labels: visitorDataset[0].data.map((d) => d.label),
                datasets: visitorDataset.map((data, i) => {
                  return {
                    label: data.label,
                    data: data.data.map((item) => item.value.toFixed(2)),
                    borderColor: i ? "#529cfc" : "#ff3d6a",
                    backgroundColor: i ? "#529cfc" : "#ff3d6a",
                    fill: false,
                  };
                }),
              }}
              options={getChartOptions("Visitors")}
            />
          ) : (
            <Box p={4}>No Data</Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default AnalyzeReport;

import { Box, Button } from "@mui/material";

const Complete = ({ closeWizard }) => {
  return (
    <Box>
      <Box mt={8} sx={{ textAlign: "center", color: "success.main" }}>
        <Box sx={{ fontSize: 30 }}>Congratulations</Box>
        <Box mt={4} sx={{ fontSize: 20 }}>
          Your version of Stella is fully configured!
        </Box>
        <Box mt={4} sx={{ display: "flex", justifyContent: "center" }}>
          <Button variant="contained" onClick={closeWizard}>
            Done
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default Complete;

import React from "react";
import { useRecoilState } from "recoil";
import { clientAtom } from "store/client";
import { Box, Button, Stack, useMediaQuery } from "@mui/material";
import { WarningAmberIcon, RestoreIcon } from "utils/icons";
import Logo from "../../shared/Logo";

const Failure = ({ error, onRetry }) => {
  const matches = useMediaQuery((theme) => theme.breakpoints.up("sm"));
  const [clientState] = useRecoilState(clientAtom);
  const { color_scheme_font, color_scheme_button } = clientState.current;

  return (
    <Stack
      sx={{
        width: 1,
        minHeight: "calc(100vh - 64px)",
        color: color_scheme_font,
        backgroundColor: "white",
        px: { xs: 2, sm: 4 },
        py: { xs: 2 },
      }}
    >
      <Logo />
      <Stack
        sx={{
          width: 1,
          flex: 1,
          alignItems: "center",
          py: { xs: 2 },
        }}
      >
        <Stack
          sx={{ maxWidth: 600, flex: 1, mb: { sm: 12 }, alignItems: "center", justifyContent: "center" }}
          spacing={{ xs: 4, md: 6 }}
        >
          <WarningAmberIcon style={{ fontSize: matches ? 72 : 54 }} />
          <Box sx={{ fontSize: { xs: 20, sm: 28, md: 32 }, textAlign: "center", textTransform: "capitalize" }}>
            Woops!
            <Box>{error}</Box>
            Try again!
          </Box>
          <Button
            width="fit-content"
            startIcon={<RestoreIcon />}
            onClick={() => onRetry()}
            sx={{
              color: color_scheme_button,
            }}
          >
            Restart
          </Button>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default Failure;

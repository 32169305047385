import { Box, Stack, Tooltip, IconButton } from "@mui/material";
import { QuestionCircleIcon } from "utils/icons";
import "shared/tooltip.scss";
import SingleTextArea from "../shared/SingleTextArea";
import SingleText from "../shared/SingleText";

const PrismaticSetting = () => {
  return (
    <Box p={4}>
      <Box mb={4} sx={{ display: "flex", alignItems: "center" }}>
        <Box mr={1} sx={{ fontSize: 20, fontWeight: "bold" }}>
          Prismatic Setting
        </Box>
        <Tooltip
          classes={{
            tooltip: "tooltip-background",
            arrow: "tooltip-caret",
          }}
          title={
            <Box sx={{ fontSize: 14, fontWeight: 400 }}>
              <Box>To learn more about this please visit</Box>
              <a className="tooltip-link" href="https://prismatic.io/docs/" target="_blank" rel="noopener noreferrer">
                Prismatic Article
              </a>
            </Box>
          }
          placement="right"
          arrow
        >
          <IconButton size="small">
            <QuestionCircleIcon />
          </IconButton>
        </Tooltip>
      </Box>
      <Stack spacing={4}>
        <SingleText field="prismatic_customer_id" label="Prismatic Customer ID" />
        <SingleText field="prismatic_user_id" label="Prismatic Customer User ID" />
      </Stack>
    </Box>
  );
};
export default PrismaticSetting;

import React from "react";
import { Box } from "@mui/material";

const Step = ({ index, label, children }) => {
  return (
    <Box>
      <Box my={4} sx={{ fontSize: 20 }}>
        <Box mr={2} component="span" sx={{ fontWeight: 900, textTransform: "uppercase" }}>
          step {index}:
        </Box>
        {label}
      </Box>
      {children}
    </Box>
  );
};

export default Step;

export const FAQs = [
  {
    question: "What is an insant solar estimate?",
    answer:
      "An instant solar estimate provides homeowners with a ballpark estimate of how much going solar will cost, including an estimated monthly payment, 25-year savings compared to a utility bill, and all available incentives. Additionally, users will be shown a heatmap of their roof, environmental impact, and a recommended system size range.",
  },
  {
    question: "Will it still work if my home isn't on google maps?",
    answer:
      "Yes! Our solar pricing calculations run with or without data from Google Project Sunroof. If an address can't be matched though because it is malformed, the API will return an error alerting the developer to the problem.",
  },
];

import React from "react";
import { Box, Dialog, IconButton, Stack, MenuItem } from "@mui/material";
import { useRecoilState } from "recoil";
import { authAtom } from "store/auth";
import { SendIcon } from "utils/icons";
import { useForm } from "react-hook-form";
import FormText from "shared/FormText";
import { LoadingButton } from "@mui/lab";
import { getPermissions } from "utils/generators";
import { clientAtom } from "store/client";
import { apiSendInvite } from "store/api";
import useAlert from "hooks/useAlert";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

const reinviteSchema = Yup.object().shape({
  first_name: Yup.string().required("Required"),
  last_name: Yup.string().required("Required"),
  email: Yup.string().strict(false).trim().email().required("Required"),
  phone: Yup.string().strict(false).trim().required("Required"),
});

const Reinvite = ({ user, onInvite }) => {
  const hAlert = useAlert();

  const [authState] = useRecoilState(authAtom);
  const [clientState] = useRecoilState(clientAtom);

  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const {
    formState: { isDirty, isValid, isSubmitSuccessful },
    control,
    handleSubmit,
  } = useForm({
    resolver: yupResolver(reinviteSchema),
    defaultValues: {
      first_name: user.first_name,
      last_name: user.last_name,
      email: user.email,
      phone: user.phone ?? "",
      role: "user",
    },
  });

  const handleClickReinvite = async () => {
    if (isSubmitSuccessful || (!isDirty && isValid)) {
      const res = await apiSendInvite({
        first_name: user.first_name,
        last_name: user.last_name,
        email: user.email,
        phone: user.phone,
        role: user.role,
        solar_company: clientState.currentId,
      });

      if (res.status === 200) {
        hAlert.show(res.data.detail);
      } else {
        hAlert.show("Sending invitation has been failed.", false);
      }
    } else {
      setOpen(true);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onSubmit = async (data) => {
    setLoading(true);

    const res = await apiSendInvite({
      email: data.email,
      first_name: data.first_name,
      last_name: data.last_name,
      phone: data.phone,
      role: data.role,
      solar_company: clientState.currentId,
    });

    if (res.status === 200) {
      hAlert.show(res.data.detail);
      setOpen(false);
      onInvite({
        ...user,
        email: data.email,
        first_name: data.first_name,
        last_name: data.last_name,
        phone: data.phone,
        role: data.role,
        solar_company: clientState.currentId,
      });
    } else {
      hAlert.show("Sending invitation has been failed.", false);
    }

    setLoading(false);
  };

  return (
    <Box onClick={(e) => e.stopPropagation()}>
      <IconButton onClick={handleClickReinvite}>
        <SendIcon />
      </IconButton>

      <Dialog open={open} onClose={handleClose} PaperProps={{ sx: { width: "100%" } }}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack p={4} spacing={2}>
            <Box sx={{ mb: 2, fontSize: 20, color: "primary.main" }}>Send Invitation</Box>

            <FormText
              required
              InputProps={{
                readOnly: true,
              }}
              disabled={loading}
              control={control}
              name="first_name"
              label="First Name *"
            />
            <FormText
              required
              InputProps={{
                readOnly: true,
              }}
              disabled={loading}
              control={control}
              name="last_name"
              label="Last Name *"
            />
            <FormText
              required
              InputProps={{
                readOnly: true,
              }}
              disabled={loading}
              control={control}
              name="email"
              label="Work Email *"
            />
            <FormText required autoFocus disabled={loading} control={control} name="phone" label="Phone Number *" />
            <FormText
              required
              InputProps={{
                readOnly: true,
              }}
              select
              disabled={loading}
              control={control}
              name="role"
              label="Role *"
            >
              {getPermissions(authState.user, clientState.current?.is_default).map((d) => (
                <MenuItem key={d.value} {...d}>
                  {d.label}
                </MenuItem>
              ))}
            </FormText>

            <Box
              sx={{
                pt: 2,
                display: "flex",
                justifyContent: "flex-end",
                gap: 2,
              }}
            >
              <LoadingButton loading={loading} variant="outlined" onClick={handleClose}>
                Cancel
              </LoadingButton>

              <LoadingButton loading={loading} type="submit" variant="contained">
                Send
              </LoadingButton>
            </Box>
          </Stack>
        </form>
      </Dialog>
    </Box>
  );
};

export default Reinvite;

import LinkEditForm from "shared/wizard/LinkEditForm";
import { Box, Typography } from "@mui/material";

const ChangePrivacyLink = () => (
  <>
    <Box mb={2}>
      <Typography variant="body2">This should be a page containing privacy policy of your business.</Typography>
    </Box>
    <LinkEditForm field="privacy_policy" label="Privacy Policy" />
  </>
);

export default ChangePrivacyLink;

import React from "react";
import { Stack, Box, Button, Dialog, TextField } from "@mui/material";
import ChoiceForm from "./ChoiceForm";
import { generateKey, validateQuestionData } from "./utils";
import { OPEN_ENDED_QN, SINGLE_CHOICE_QN } from "utils/constant";

const EditQuestionForm = ({ question, closeForm, updateQuestion }) => {
  const [description, setDescription] = React.useState("");
  const [choices, setChoices] = React.useState([]);
  const [label, setLabel] = React.useState("");

  const type = question?.question_type ?? SINGLE_CHOICE_QN;

  React.useEffect(() => {
    if (question) {
      setDescription(question.question_desc);
      setChoices(question.choices || []);
      setLabel(question.question_label || "");
    } else {
      setDescription("");
      setChoices([]);
      setLabel("");
    }
  }, [question]);

  const handleSave = async () => {
    if (type === SINGLE_CHOICE_QN) {
      updateQuestion({ question, description, choices });
    }
    if (type === OPEN_ENDED_QN) {
      updateQuestion({ question, description, label, key: generateKey(label) });
    }
  };

  return (
    <Dialog open={!!question} onClose={() => {}} PaperProps={{ sx: { width: "100%" } }}>
      <Box p={2} sx={{ borderBottom: "1px solid #ccc", fontSize: 20 }}>
        Edit Question
      </Box>
      <Box p={2} sx={{ borderBottom: "1px solid #ccc" }}>
        <Box mb={2} sx={{ fontSize: 12 }}>
          Description ({description.length} / 108)
        </Box>
        <TextField fullWidth size="small" value={description} onChange={(e) => setDescription(e.target.value)} />
      </Box>
      {type === SINGLE_CHOICE_QN && (
        <Box p={2} sx={{ borderBottom: "1px solid #ccc" }}>
          <Box mb={2} sx={{ fontSize: 12 }}>
            Choices
          </Box>
          <ChoiceForm initial={choices} onUpdate={setChoices} />
        </Box>
      )}
      {type === OPEN_ENDED_QN && (
        <Box p={2} sx={{ borderBottom: "1px solid #ccc" }}>
          <Box mb={2} sx={{ fontSize: 12 }}>
            Label ({label.length} / 24)
          </Box>
          <TextField size="small" value={label} onChange={(e) => setLabel(e.target.value)} sx={{ width: 240 }} />
        </Box>
      )}
      <Stack p={2} spacing={2} direction="row">
        <Button
          variant="contained"
          disableElevation
          disabled={!validateQuestionData({ type, description, choices, label })}
          onClick={handleSave}
        >
          Save
        </Button>
        <Button variant="outlined" onClick={closeForm}>
          Cancel
        </Button>
      </Stack>
    </Dialog>
  );
};

export default EditQuestionForm;

import React from "react";
import { TextField } from "@mui/material";
import useConfigurator from "hooks/useConfigurator";

const SingleTextArea = ({ field, label, validator, helperText }) => {
  const hConfigurator = useConfigurator();
  const [touched, setTouched] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [value, setValue] = React.useState("");
  const [error, setError] = React.useState(false);

  React.useEffect(() => {
    setValue(hConfigurator.client[field]);
    // eslint-disable-next-line
  }, []);

  const handleChange = (e) => {
    if (error) setError(false);
    setTouched(true);
    setValue(e.target.value);
    hConfigurator.setTouched(true);
  };

  const handleBlur = () => {
    if (touched) {
      if (validator && validator(value) === false) {
        setError(true);
      } else {
        hConfigurator.updateSingle(field, value);
        setTouched(false);
      }
    }
  };

  return (
    <TextField
      fullWidth
      multiline
      minRows={3}
      label={label}
      disabled={loading}
      value={value}
      onChange={handleChange}
      onBlur={handleBlur}
      error={error}
      helperText={error && helperText}
    />
  );
};

export default SingleTextArea;

import React from "react";
import { Box, MobileStepper, Button } from "@mui/material";
import useExperimentsCreator from "hooks/useExperimentsCreator";
import CreatorForm from "./shared/CreatorForm";
import Step from "./shared/Step";
import NameForm from "./components/NameForm";
import VariationForm from "./components/VariationForm";
import TrafficForm from "./components/TrafficForm";
import ObjectiveForm from "./components/ObjectiveForm";
import StartDateForm from "./components/StartDateForm";
import EndDateForm from "./components/EndDateForm";
import SubmitForm from "./components/SubmitForm";
import TypeForm from "./components/TypeForm";
import BannerForm from "./components/BannerForm";

const Design = ({ openTab }) => {
  const hCreator = useExperimentsCreator();

  const [type, setType] = React.useState(hCreator.type ?? "funnel");
  const [length, setLength] = React.useState(type === "funnel" ? 7 : 7);

  const handleBack = () => {
    hCreator.back();
  };

  const handleNext = () => {
    hCreator.next();
  };

  React.useEffect(() => {
    setType(hCreator.type);
    setLength(hCreator.type === "funnel" ? 7 : 7);
  }, [hCreator.type]);

  return (
    <Box p={4}>
      <MobileStepper
        variant="progress"
        steps={length}
        position="static"
        activeStep={hCreator.form - 1}
        backButton={
          <Button size="small" variant="outlined" onClick={handleBack} disabled={hCreator.form === 1}>
            Back
          </Button>
        }
        nextButton={
          <Button
            size="small"
            variant="contained"
            disableElevation
            onClick={handleNext}
            disabled={hCreator.form === length}
          >
            Next
          </Button>
        }
      />

      {hCreator.form === 1 && (
        <CreatorForm label="DESIGN EXPERIMENT" required={["name"]}>
          <Step index={1} label="What would you like to name this experiment?">
            <NameForm />
          </Step>
        </CreatorForm>
      )}

      {hCreator.form === 2 && (
        <CreatorForm label="DESIGN EXPERIMENT">
          <Step index={2} label="Choose your experiment type.">
            <TypeForm />
          </Step>
        </CreatorForm>
      )}

      {type === "funnel" && (
        <>
          {hCreator.form === 3 && (
            <CreatorForm label="DESIGN EXPERIMENT" required={["variations"]}>
              <Step index={3} label="Find the developer created variation of the app experience.">
                <VariationForm />
              </Step>
            </CreatorForm>
          )}

          {hCreator.form === 4 && (
            <CreatorForm label="DESIGN EXPERIMENT" required={["traffic_volume"]}>
              <Step index={4} label="How much traffic would you like to designate to this test?">
                <TrafficForm />
              </Step>
            </CreatorForm>
          )}

          {hCreator.form === 5 && (
            <CreatorForm label="DESIGN EXPERIMENT" required={["objective"]}>
              <Step index={5} label="Choose your conversion objective.">
                <ObjectiveForm />
              </Step>
            </CreatorForm>
          )}

          {hCreator.form === 6 && (
            <CreatorForm label="DESIGN EXPERIMENT" required={["result"]}>
              <Step index={6} label="When will the experiment start?">
                <StartDateForm />
              </Step>
              <Step index={7} label="How will you determine when the experiment ends?">
                <EndDateForm />
              </Step>
            </CreatorForm>
          )}

          {hCreator.form === 7 && (
            <CreatorForm label="DESIGN EXPERIMENT">
              <SubmitForm openTab={openTab} />
            </CreatorForm>
          )}
        </>
      )}

      {type === "banner" && (
        <>
          {hCreator.form === 3 && (
            <CreatorForm label="DESIGN EXPERIMENT">
              <Step index={3} label="Customize your banner">
                <BannerForm />
              </Step>
            </CreatorForm>
          )}

          {hCreator.form === 4 && (
            <CreatorForm label="DESIGN EXPERIMENT" required={["traffic_volume"]}>
              <Step index={4} label="How much traffic would you like to designate to this test?">
                <TrafficForm />
              </Step>
            </CreatorForm>
          )}

          {hCreator.form === 5 && (
            <CreatorForm label="DESIGN EXPERIMENT" required={["objective"]}>
              <Step index={5} label="Choose your conversion objective.">
                <ObjectiveForm />
              </Step>
            </CreatorForm>
          )}

          {hCreator.form === 6 && (
            <CreatorForm label="DESIGN EXPERIMENT" required={["result"]}>
              <Step index={6} label="When will the experiment start?">
                <StartDateForm />
              </Step>
              <Step index={7} label="How will you determine when the experiment ends?">
                <EndDateForm />
              </Step>
            </CreatorForm>
          )}

          {hCreator.form === 7 && (
            <CreatorForm label="DESIGN EXPERIMENT">
              <SubmitForm openTab={openTab} />
            </CreatorForm>
          )}
        </>
      )}
    </Box>
  );
};

export default Design;

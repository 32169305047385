import React from "react";
import { useRecoilState } from "recoil";
import FormCheck from "shared/FormCheck";
import { CheckBoxBlankIcon } from "utils/icons";
import { clientAtom } from "store/client";

const StellaFormCheck = (props) => {
  const [clientState] = useRecoilState(clientAtom);
  const { color_scheme_font } = clientState.current;
  return <FormCheck icon={<CheckBoxBlankIcon style={{ color: color_scheme_font, fontSize: 23 }} />} {...props} />;
};

export default StellaFormCheck;

import React from "react";
import { Box, Divider, Stack } from "@mui/material";
import Filter from "./components/filter/Filter";
import LeadStatus from "./components/lead-status/LeadStatus";
import EngagementStats from "./components/engagement-stats/EngagementStats";
import TrafficHeatMap from "./components/traffic-heat-map/TrafficHeatMap";
import EmailNotification from "./components/email-notification/EmailNotification";
import ExportLead from "./components/export-lead/ExportLead";

const SonicReport = () => {
  return (
    <Box>
      <Stack direction="row" justifyContent="space-between">
        <Filter />
        <Stack p={4} direction="row" spacing={2} sx={{ justifyContent: "flex-end" }}>
          <EmailNotification />
          <ExportLead />
        </Stack>
      </Stack>

      <Divider />

      <Stack p={4} spacing={4}>
        <LeadStatus />
        <EngagementStats />
        <TrafficHeatMap />
      </Stack>
    </Box>
  );
};

export default SonicReport;

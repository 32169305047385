import React from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import { Box, Button, Stack, CircularProgress } from "@mui/material";
import { apiGetPaymentMethod, apiGetDefaultPaymentMethod } from "store/api";
import { clientAtom } from "store/client";
import { authAtom } from "store/auth";
import ManagePayment from "./ManagePayment";
import { isSuper } from "utils/authHelper";

const Payment = ({ balance }) => {
  const [clientState] = useRecoilState(clientAtom);
  const [authState] = useRecoilState(authAtom);
  const mounted = React.useRef(true);
  const [payments, setPayments] = React.useState(null);
  const [payment, setPayment] = React.useState(null);
  const navigate = useNavigate();

  React.useEffect(() => {
    loadPayments();
    loadPayment();
    return () => {
      mounted.current = false;
    };
    // eslint-disable-next-line
  }, []);

  const loadPayments = async () => {
    setPayments(null);
    const res = await apiGetPaymentMethod({
      customer: clientState.current.customer_id,
      type: "card",
    });
    if (res.status === 200 && mounted.current) {
      setPayments(res.data.data);
    }
  };

  const loadPayment = async () => {
    setPayment(null);
    const res = await apiGetDefaultPaymentMethod({
      customer: clientState.current.customer_id,
    });
    if (mounted.current) {
      if (res.status === 200 && res.data !== "") {
        setPayment(res.data);
      } else {
        setPayment("none");
      }
    }
  };

  const handleUpdateDefault = () => {
    loadPayment();
  };
  const handleCreate = () => {
    loadPayments();
  };
  const handleDelete = () => {
    loadPayments();
    loadPayment();
  };

  return (
    <Box p={4} sx={{ border: "1px solid #eee" }}>
      <Box mb={4} sx={{ display: "flex", alignItems: "center" }}>
        <Box sx={{ fontSize: 24, fontWeight: 500, flexGrow: 1 }}>How you pay</Box>
        {isSuper(authState.user) && (
          <Button
            variant="outlined"
            sx={{ marginRight: 1 }}
            onClick={() => navigate(`/settings/clients/${clientState.current.id}`)}
          >
            ADD CREDITS
          </Button>
        )}
        {payments ? (
          <ManagePayment
            payments={payments}
            onUpdateDefault={handleUpdateDefault}
            onCreate={handleCreate}
            onDelete={handleDelete}
          />
        ) : (
          <CircularProgress size={20} />
        )}
      </Box>
      <Stack spacing={4}>
        {balance ? (
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Box
              mr={4}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: 136,
                height: 88,
                backgroundColor: "#f8f8f8",
              }}
            >
              <Box
                component="img"
                src="/images/gift.svg"
                alt={`credit of $${-balance.customer.balance}`}
                sx={{ height: "65%" }}
              />
            </Box>
            <Box>Credits: ${-balance.customer.balance}</Box>
          </Box>
        ) : (
          <CircularProgress size={20} />
        )}
        {payment ? (
          <>
            {payment !== "none" && (
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Box
                  mr={4}
                  component="img"
                  src={`/images/card-brand-logos/light-color/${payment.card.brand}.png`}
                  alt={payment.card.brand}
                  sx={{ width: 136, height: 88 }}
                />
                <Box>
                  <Box mb={1} sx={{ textTransform: "capitalize" }}>
                    {`${payment.card.brand} ****${payment.card.last4}`}
                  </Box>
                  <Box>{`Expires ${payment.card.exp_month} / ${payment.card.exp_year}`}</Box>
                </Box>
              </Box>
            )}
          </>
        ) : (
          <CircularProgress size={20} />
        )}
      </Stack>
    </Box>
  );
};

export default Payment;

import React from "react";
import { ButtonBase, Box } from "@mui/material";
import useExperimentsCreator from "hooks/useExperimentsCreator";

const TypeButton = ({ type, radius, selected, ...props }) => (
  <ButtonBase
    sx={{
      width: 150,
      height: 50,
      fontSize: 20,
      border: "1px solid #15ABE6",
      backgroundColor: selected ? "primary.main" : "#fff",
      color: selected ? "#fff" : "primary.main",
      textTransform: "uppercase",
      borderRadius: `${radius}px`,
    }}
    {...props}
  >
    {type}
  </ButtonBase>
);

const BannerButtonShape = () => {
  const hCreator = useExperimentsCreator();

  const [buttonShape, setButtonShape] = React.useState(null);

  React.useEffect(() => {
    setButtonShape(hCreator.bannerValues.banner_button_shape);
    // eslint-disable-next-line
  }, []);

  const handleShapeChange = (shape) => async () => {
    setButtonShape(shape);
    await hCreator.setBannerValue("banner_button_shape", shape);
  };

  return (
    <Box>
      <Box mb={2}>Choose your button shape.</Box>

      <Box sx={{ display: "flex", flexWrap: "wrap", gap: 2, justifyContent: "space-between", maxWidth: 600 }}>
        <TypeButton
          type="square"
          radius={0}
          selected={buttonShape === "square"}
          onClick={handleShapeChange("square")}
        />

        <TypeButton
          type="semiround"
          radius={12}
          selected={buttonShape === "semiround"}
          onClick={handleShapeChange("semiround")}
        />

        <TypeButton type="round" radius={30} selected={buttonShape === "round"} onClick={handleShapeChange("round")} />
      </Box>
    </Box>
  );
};

export default BannerButtonShape;

import React from "react";
import { ButtonBase, Box } from "@mui/material";
import useQuickSetupWizard from "hooks/useQuickSetupWizard";

const TypeButton = ({ type, radius, selected, ...props }) => (
  <ButtonBase
    sx={{
      width: 150,
      height: 50,
      fontSize: 20,
      border: "1px solid #15ABE6",
      backgroundColor: selected ? "primary.main" : "#fff",
      color: selected ? "#fff" : "primary.main",
      textTransform: "uppercase",
      borderRadius: `${radius}px`,
    }}
    {...props}
  >
    {type}
  </ButtonBase>
);

const SelectButtonShape = () => {
  const setupWizard = useQuickSetupWizard();
  const [buttonShape, setButtonShape] = React.useState(null);

  React.useEffect(() => {
    setButtonShape(setupWizard.client.button_shape);
    // eslint-disable-next-line
  }, []);

  const handleShapeChange = (shape) => () => {
    setButtonShape(shape);
    setupWizard.update("button_shape", shape);
  };

  return (
    <Box>
      {setupWizard.errors.button_shape && <Box sx={{ color: "error.main" }}>Please select button shape</Box>}

      <Box sx={{ display: "flex", flexWrap: "wrap", gap: 2, justifyContent: "space-between", maxWidth: 600 }}>
        <TypeButton
          type="square"
          radius={0}
          selected={buttonShape === "square"}
          onClick={handleShapeChange("square")}
        />

        <TypeButton
          type="semiround"
          radius={12}
          selected={buttonShape === "semiround"}
          onClick={handleShapeChange("semiround")}
        />

        <TypeButton type="round" radius={30} selected={buttonShape === "round"} onClick={handleShapeChange("round")} />
      </Box>
    </Box>
  );
};

export default SelectButtonShape;

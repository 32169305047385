import React from "react";
import { Box } from "@mui/material";
import ConnectCalendly from "./ConnectCalendly";
import CustomQuestion from "./CustomQuestion";

const TabCalendly = () => {
  return (
    <Box>
      <ConnectCalendly />
      <Box mt={8}>
        <CustomQuestion />
      </Box>
    </Box>
  );
};

export default TabCalendly;

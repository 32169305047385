import React from "react";
import { Box, Divider } from "@mui/material";
import SelectPricePerWatt from "./components/SelectPricePerWatt";
import SelectGridRate from "./components/SelectGridRate";
import ChangeProductApr from "./components/ChangeProductApr";
import ChangeProductTerm from "./components/ChangeProductTerm";
import UploadZipcode from "../quick-setup/wizard/components/UploadZipcode";
import UploadPricePerWatt from "../quick-setup/wizard/components/UploadPricePerWatt";
import UploadGridRate from "../quick-setup/wizard/components/UploadGridRate";

const Settings = () => {
  return (
    <Box>
      <Box p={4}>
        <Box mb={4} sx={{ fontSize: 20, fontWeight: 700 }}>
          Product
        </Box>
        <SelectPricePerWatt />
        <UploadPricePerWatt />
        <Box mb={4} />
        <SelectGridRate />
        <UploadGridRate />
      </Box>

      <Divider />

      <Box p={4}>
        <Box mb={4} sx={{ fontSize: 20, fontWeight: 700 }}>
          Financing
        </Box>
        <ChangeProductApr />
        <ChangeProductTerm />
      </Box>

      <Divider />

      <Box p={4}>
        <Box mb={4} sx={{ fontSize: 20, fontWeight: 700 }}>
          Zip Codes
        </Box>
        <UploadZipcode />
      </Box>
    </Box>
  );
};

export default Settings;

import LinkEditForm from "shared/wizard/LinkEditForm";
import { Box, Typography } from "@mui/material";

const ChangeReviewLink = () => (
  <>
    <Box mb={2}>
      <Typography variant="body2">Examples: Google, Yelp, etc.</Typography>
    </Box>
    <LinkEditForm field="reviews_url" label="Third Party Reviews URL" />
  </>
);

export default ChangeReviewLink;

import React from "react";
import { useRecoilState } from "recoil";
import { CircularProgress, Box, Paper, Grid } from "@mui/material";
import { Line } from "react-chartjs-2";
import moment from "moment-timezone";
import { clientAtom } from "store/client";
import { apiGetLeadStatusByPeriod } from "store/api";
import { TrendUpIcon, TrendDownIcon } from "utils/icons";
import { calcDashBoardDummy as calcDummy } from "utils/dummy";

const reportTypes = [
  {
    label: "Leads",
    lead_status: "lead",
  },
  {
    label: "Appointments",
    lead_status: "appointment",
  },
  {
    label: "Bills",
    lead_status: "appointment_with_bill",
  },
];

const statusTypes = reportTypes.map((d) => d.lead_status);

const Widget = ({ label, loading, data }) => {
  const percent = data ? parseFloat(data.percent) : 0;

  return (
    <Paper>
      <Box p={2}>
        <Box sx={{ color: "#0008" }}>{label}</Box>
        {loading ? (
          <Box sx={{ height: 120, display: "flex", alignItems: "center", justifyContent: "center" }}>
            <CircularProgress />
          </Box>
        ) : (
          <>
            <Box>
              <Box sx={{ fontSize: 56 }}>{data.count}</Box>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                {percent >= 0 ? (
                  <TrendUpIcon fontSize={24} className="trend-up" />
                ) : (
                  <TrendDownIcon fontSize={24} className="trend-down" />
                )}
                <Box ml={1} sx={{ fontSize: 14 }}>
                  {percent || 0}% over last period
                </Box>
              </Box>
            </Box>

            <Box sx={{ height: 96 }}>
              <Line
                data={{
                  labels: data.data.map((d) => `${d.month}/${d.day}/${d.year}`),
                  datasets: [
                    {
                      data: data.data.map((d) => d.count),
                      label: "",
                      fill: false,
                      borderColor: percent >= 0 ? "#15ABE6" : "#f44336",
                    },
                  ],
                }}
                options={{
                  spanGaps: false,
                  legend: {
                    display: false,
                  },
                  maintainAspectRatio: false,
                  elements: {
                    point: {
                      radius: 2,
                      borderWidth: 1,
                      hoverRadius: 2,
                      hoverBorderWidth: 1,
                    },
                    line: {
                      tension: 0,
                    },
                  },
                  layout: {
                    padding: {
                      top: 24,
                      left: 16,
                      right: 16,
                      bottom: 16,
                    },
                  },
                  scales: {
                    xAxes: [
                      {
                        display: false,
                      },
                    ],
                    yAxes: [
                      {
                        display: false,
                      },
                    ],
                  },
                }}
              />
            </Box>
          </>
        )}
      </Box>
    </Paper>
  );
};

const ReportWidgets = () => {
  const [clientState] = useRecoilState(clientAtom);
  const [state, setState] = React.useState({
    loading: true,
    hash: {},
  });

  React.useEffect(() => {
    let isMounted = true;

    const loadData = async () => {
      setState({
        ...state,
        loading: true,
      });
      const res = await apiGetLeadStatusByPeriod({
        period_length: 28,
        period: "days",
        compare: true,
        solar_company: clientState.currentId,
        timezone: moment.tz.guess(),
      });
      if (res.status === 200 && isMounted) {
        const data =
          clientState.companyName === "Demand IQ"
            ? calcDummy(res.data.filter((d) => statusTypes.includes(d.lead_status)))
            : res.data.filter((d) => statusTypes.includes(d.lead_status));
        const hash = Object.fromEntries(data.map((d) => [d.lead_status, d]));
        setState({
          loading: false,
          hash,
        });
      }
    };

    loadData();

    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line
  }, [clientState.currentId]);

  return (
    <Box p={4}>
      <Box mb={4}>How much volume was achieved in last 30 days?</Box>
      <Grid container spacing={4}>
        {reportTypes.map((reportType) => (
          <Grid item xs={12} sm={6} key={reportType.lead_status}>
            <Widget label={reportType.label} loading={state.loading} data={state.hash[reportType.lead_status]} />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default ReportWidgets;

import React from "react";
import { useRecoilState } from "recoil";
import { format } from "date-fns";
import { Box, CircularProgress, Button, Stack, IconButton } from "@mui/material";
import { clientAtom } from "store/client";
import { downloadInvoice } from "utils/payment";
import { DownloadIcon } from "utils/icons";

const Invoice = ({ invoices }) => {
  const [clientState] = useRecoilState(clientAtom);

  return (
    <Box p={4} sx={{ border: "1px solid #eee" }}>
      <Box mb={4} sx={{ display: "flex" }}>
        <Box sx={{ fontSize: 24, fontWeight: 500, flexGrow: 1 }}>Invoices</Box>
        {invoices && invoices.length > 0 && (
          <Button variant="outlined" onClick={() => downloadInvoice([], clientState.current.customer_id)}>
            DOWNLOAD ALL
          </Button>
        )}
      </Box>
      {invoices ? (
        <>
          {invoices.length > 0 ? (
            <>
              <Box mb={2} sx={{ fontSize: 14 }}>
                Last Invoices
              </Box>
              <Stack spacing={1}>
                {invoices.map((d) => (
                  <Box key={d.id} sx={{ display: "flex", alignItems: "center" }}>
                    <Box sx={{ flexGrow: 1 }}>
                      {format(new Date(d.start_at * 1000), "MMM dd, yyyy")} -{" "}
                      {format(new Date(d.end_at * 1000), "MMM dd, yyyy")}
                    </Box>
                    <Box>${parseInt(d.amount / 100)}</Box>
                    <Box>
                      <IconButton onClick={() => downloadInvoice(d.receipt_url, clientState.current.customer_id)}>
                        <DownloadIcon />
                      </IconButton>
                    </Box>
                  </Box>
                ))}
              </Stack>
            </>
          ) : (
            <Box>No Invoices</Box>
          )}
        </>
      ) : (
        <CircularProgress size={20} />
      )}
    </Box>
  );
};

export default Invoice;

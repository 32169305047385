import { atom } from "recoil";

export const loadingAtom = atom({
  key: "loadingAtom",
  default: "",
});

export const alertAtom = atom({
  key: "alertAtom",
  default: {
    open: false,
    success: true,
    message: "",
  },
});

export const uiAtom = atom({
  key: "uiAtom",
  default: {
    openMobileSidebar: false,
  },
});

import React from "react";
import { Box, Stack } from "@mui/material";
import useQuickSetupWizard from "hooks/useQuickSetupWizard";
import FileUpload from "shared/wizard/FileUpload";

const SelectHeroImage = () => {
  const setupWizard = useQuickSetupWizard();
  const [values, setValues] = React.useState({
    hero_image_desktop: null,
    hero_image_mobile: null,
  });

  React.useEffect(() => {
    setValues({
      hero_image_desktop: setupWizard.client.hero_image_desktop,
      hero_image_mobile: setupWizard.client.hero_image_mobile,
    });
    // eslint-disable-next-line
  }, []);

  const handleHeroImageDesktopChange = (file, url) => {
    setValues({
      ...values,
      hero_image_desktop: url,
    });
    setupWizard.update("hero_image_desktop", file);
  };

  const handleHeroImageMobileChange = (file, url) => {
    setValues({
      ...values,
      hero_image_mobile: url,
    });
    setupWizard.update("hero_image_mobile", file);
  };

  return (
    <Box>
      <Stack mt={4} spacing={4}>
        <Box>
          <FileUpload label="Upload A Hero Image for Desktop" onChange={handleHeroImageDesktopChange} />
          {values.hero_image_desktop && (
            <Box mt={4} sx={{ maxWidth: 360 }}>
              <img src={values.hero_image_desktop} alt="hero desktop" />
            </Box>
          )}
        </Box>

        <Box>
          <FileUpload label="Upload A Hero Image for Mobile" onChange={handleHeroImageMobileChange} />
          {values.hero_image_mobile && (
            <Box mt={4} sx={{ maxWidth: 360 }}>
              <img src={values.hero_image_mobile} alt="hero mobile" />
            </Box>
          )}
        </Box>
      </Stack>
    </Box>
  );
};

export default SelectHeroImage;

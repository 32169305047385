import React from "react";
import { Box, MobileStepper, Button, Divider, IconButton } from "@mui/material";
import useExperimentsEditor from "hooks/useExperimentsEditor";
import { ArrowLeftIcon } from "utils/icons";
import EditorForm from "./shared/EditorForm";
import Step from "./shared/Step";
import NameForm from "./components/NameForm";
import VariationForm from "./components/VariationForm";
import TrafficForm from "./components/TrafficForm";
import ObjectiveForm from "./components/ObjectiveForm";
import StartDateForm from "./components/StartDateForm";
import EndDateForm from "./components/EndDateForm";
import TypeForm from "./components/TypeForm";
import BannerForm from "./components/BannerForm";

const ExperimentEditor = ({ editItem, setEditItem }) => {
  const hEditor = useExperimentsEditor();

  const [type, setType] = React.useState(hEditor.type ?? "funnel");
  const [length, setLength] = React.useState(type === "funnel" ? 6 : 6);

  React.useEffect(() => {
    hEditor.init(editItem);

    return () => {
      hEditor.close();
    };
    // eslint-disable-next-line
  }, []);

  React.useEffect(() => {
    setType(hEditor.type);
    setLength(hEditor.type === "funnel" ? 6 : 6);
  }, [hEditor.type]);

  const handleClose = () => {
    setEditItem(null);
  };

  const handleBack = () => {
    hEditor.back();
  };

  const handleNext = () => {
    hEditor.next();
  };

  return (
    <Box sx={{ border: "1px solid #ccc" }}>
      <Box p={2} sx={{ display: "flex", alignItems: "center", backgroundColor: "primary.main", color: "#fff" }}>
        <IconButton size="small" color="inherit" onClick={handleClose}>
          <ArrowLeftIcon />
        </IconButton>

        <Box mx={2} sx={{ flexGrow: 1, fontSize: 20 }}>
          {hEditor.values.name}
        </Box>

        {hEditor.touched && (
          <Button size="small" color="inherit" variant="outlined" onClick={hEditor.save}>
            Save
          </Button>
        )}
      </Box>

      <Divider />

      <Box p={4}>
        <MobileStepper
          variant="progress"
          steps={length}
          position="static"
          activeStep={hEditor.form - 1}
          backButton={
            <Button size="small" variant="outlined" onClick={handleBack} disabled={hEditor.form === 1}>
              Back
            </Button>
          }
          nextButton={
            <Button
              size="small"
              variant="contained"
              disableElevation
              onClick={handleNext}
              disabled={hEditor.form === length}
            >
              Next
            </Button>
          }
        />

        {hEditor.form === 1 && (
          <EditorForm label="DESIGN EXPERIMENT" required={["name"]}>
            <Step index={1} label="What would you like to name this experiment?">
              <NameForm />
            </Step>
          </EditorForm>
        )}

        {hEditor.form === 2 && (
          <EditorForm label="DESIGN EXPERIMENT">
            <Step index={2} label="Choose your experiment type.">
              <TypeForm />
            </Step>
          </EditorForm>
        )}

        {type === "funnel" && (
          <>
            {hEditor.form === 3 && (
              <EditorForm label="DESIGN EXPERIMENT" required={["variations"]}>
                <Step index={3} label="Find the developer created variation of the app experience.">
                  <VariationForm />
                </Step>
              </EditorForm>
            )}

            {hEditor.form === 4 && (
              <EditorForm label="DESIGN EXPERIMENT" required={["traffic_volume"]}>
                <Step index={4} label="How much traffic would you like to designate to this test?">
                  <TrafficForm />
                </Step>
              </EditorForm>
            )}

            {hEditor.form === 5 && (
              <EditorForm label="DESIGN EXPERIMENT" required={["objective"]}>
                <Step index={5} label="Choose your conversion objective.">
                  <ObjectiveForm />
                </Step>
              </EditorForm>
            )}

            {hEditor.form === 6 && (
              <EditorForm label="DESIGN EXPERIMENT" required={["result"]}>
                <Step index={6} label="When will the experiment start?">
                  <StartDateForm />
                </Step>

                <Step index={7} label="How will you determine when the experiment ends?">
                  <EndDateForm />
                </Step>
              </EditorForm>
            )}
          </>
        )}

        {type === "banner" && (
          <>
            {hEditor.form === 3 && (
              <EditorForm label="DESIGN EXPERIMENT">
                <Step index={3} label="Customize your banner">
                  <BannerForm />
                </Step>
              </EditorForm>
            )}

            {hEditor.form === 4 && (
              <EditorForm label="DESIGN EXPERIMENT" required={["traffic_volume"]}>
                <Step index={4} label="How much traffic would you like to designate to this test?">
                  <TrafficForm />
                </Step>
              </EditorForm>
            )}

            {hEditor.form === 5 && (
              <EditorForm label="DESIGN EXPERIMENT" required={["objective"]}>
                <Step index={5} label="Choose your conversion objective.">
                  <ObjectiveForm />
                </Step>
              </EditorForm>
            )}

            {hEditor.form === 6 && (
              <EditorForm label="DESIGN EXPERIMENT" required={["result"]}>
                <Step index={6} label="When will the experiment start?">
                  <StartDateForm />
                </Step>

                <Step index={7} label="How will you determine when the experiment ends?">
                  <EndDateForm />
                </Step>
              </EditorForm>
            )}
          </>
        )}
      </Box>
    </Box>
  );
};

export default ExperimentEditor;

import React from "react";
import { Box, Button } from "@mui/material";
import useConfigurator from "hooks/useConfigurator";

const ApiKey = () => {
  const hConfigurator = useConfigurator();
  const [visible, setVisible] = React.useState(false);
  const [copied, setCopied] = React.useState(false);

  const copyToClipboard = () => {
    navigator.clipboard.writeText(hConfigurator.client.api_key);
    setCopied(true);
  };

  const handleHide = () => {
    setVisible(false);
    setCopied(false);
  };

  return (
    <Box p={4}>
      <Box sx={{ fontSize: 20, fontWeight: "bold" }}>Active API Key</Box>
      <Box mt={2} sx={{ display: "flex", alignItems: "center" }}>
        <Box mr={2} sx={{ fontSize: 14 }}>
          {visible ? hConfigurator.client.api_key : "****************************************"}
        </Box>
        {visible ? (
          <>
            <Button size="small" variant="outlined" onClick={handleHide}>
              Hide
            </Button>
            <Button sx={{ marginLeft: 1 }} size="small" variant="outlined" onClick={copyToClipboard}>
              {copied ? "Copied!" : "Copy"}
            </Button>
          </>
        ) : (
          <Button color="error" size="small" variant="outlined" onClick={() => setVisible(true)}>
            Show
          </Button>
        )}
      </Box>
    </Box>
  );
};

export default ApiKey;

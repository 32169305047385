import React from "react";
import { Box, MobileStepper, Button, Grid, Stack } from "@mui/material";
import _ from "lodash";

import useQuickSetupWizard from "hooks/useQuickSetupWizard";
import WizardForm from "shared/wizard/WizardForm";
import Step from "shared/wizard/Step";
import UploadLogo from "./components/UploadLogo";
import UploadFavicon from "./components/UploadFavicon";
import UploadFont from "./components/UploadFont";
import UploadBackground from "./components/UploadBackground";
import SelectHeroImage from "./components/SelectHeroImage";
import SelectColorScheme from "./components/SelectColorScheme";
import SelectButtonShape from "./components/SelectButtonShape";
import ChangeBillingEmail from "./components/ChangeBillingEmail";
import ChangeAdvertisePhone from "./components/ChangeAdvertisePhone";
import ChangeAdvertiseEmail from "./components/ChangeAdvertiseEmail";
import ChangeWebsiteLink from "./components/ChangeWebsiteLink";
import ChangeTestimonialsLink from "./components/ChangeTestimonialsLink";
import ChangePrivacyLink from "./components/ChangePrivacyLink";
import ChangeReviewLink from "./components/ChangeReviewLink";
import UploadZipcode from "./components/UploadZipcode";
import SelectPricePerWatt from "./components/SelectPricePerWatt";
import ChangeOffset from "./components/ChangeOffset";
import ChangeEscalation from "./components/ChangeEscalation";
import UploadEscalation from "./components/UploadEscalation";
import ChangeProductApr from "./components/ChangeProductApr";
import ChangeProductTerm from "./components/ChangeProductTerm";
import SelectPriceSystem from "./components/SelectPriceSystem";
import SelectStateIncentive from "./components/SelectStateIncentive";
import SelectUtilityIncentive from "./components/SelectUtilityIncentive";
import UploadIncentives from "./components/UploadIncentives";
import QuestionEditor from "./components/question-editor/Main";
import ChangeAiName from "./components/ChangeAiName";
import UploadAiAvatar from "./components/UploadAiAvatar";
import ChangeThanks from "./components/ChangeThanks";
import Complete from "./components/Complete";
import PhonePreview from "shared/Preview/PhonePreview";
import UploadPricePerWatt from "./components/UploadPricePerWatt";
import PreQualification from "./components/pre-qualification/Main";
import UpsellEditor from "./components/upsell-editor/Main";
import AppointmentForm from "./forms/AppointmentForm";
import ChangeWelcome from "./components/ChangeWelcome";

const getWizardLength = (client) => ({
  rooftop: 16 + (client.is_finance_integrations_enabled ? 1 : 0),
  community: 10,
});

const Wizard = ({ closeWizard }) => {
  const setupWizard = useQuickSetupWizard();
  const energyType = setupWizard.client.energy_type;
  const isFinancingEnabled = setupWizard.client.is_finance_integrations_enabled;
  const wizardLength = getWizardLength(setupWizard.client);

  const handleBack = () => {
    setupWizard.back();
  };

  const handleNext = () => {
    setupWizard.next();
  };

  return (
    <Box p={4}>
      <MobileStepper
        variant="progress"
        steps={wizardLength.rooftop}
        position="static"
        activeStep={setupWizard.form - 1}
        backButton={
          <Button size="small" variant="outlined" onClick={handleBack} disabled={setupWizard.form === 1}>
            Back
          </Button>
        }
        nextButton={
          <Button
            size="small"
            variant="contained"
            disableElevation
            onClick={handleNext}
            disabled={setupWizard.form === wizardLength[energyType]}
          >
            {setupWizard.updates.length > 0 ? "Save & Next" : "Skip"}
          </Button>
        }
      />
      {setupWizard.form === 1 && (
        <WizardForm label="Select Logo">
          <Step index={1} label="Upload your logo and favicon.">
            <Grid container spacing={4}>
              <Grid item xs={12} md={6}>
                <UploadLogo />
              </Grid>
              <Grid item xs={12} md={6}>
                <UploadFavicon />
              </Grid>
            </Grid>
          </Step>
        </WizardForm>
      )}
      {setupWizard.form === 2 && (
        <WizardForm label="Select Font">
          <Step index={2} label="Upload a font for your Stella experience.">
            <UploadFont />
          </Step>
        </WizardForm>
      )}
      {setupWizard.form === 3 && (
        <WizardForm label="Select Background">
          <Step index={3} label="Upload a background image for the desktop experience.">
            <UploadBackground />
          </Step>
        </WizardForm>
      )}
      {setupWizard.form === 4 && (
        <WizardForm label="Select Hero Image">
          <Step index={4} label="Choose hero images">
            <SelectHeroImage />
          </Step>
        </WizardForm>
      )}
      {setupWizard.form === 5 && (
        <WizardForm label="Customizations" required={["button_shape"]}>
          <Box sx={{ display: "flex", flexDirection: { xs: "column", md: "row" }, gap: 2 }}>
            <Box sx={{ flexGrow: 1 }}>
              <Step index={5} label="Choose the scheme of your Stella experience.">
                <SelectColorScheme />
              </Step>
              <Step index={6} label="Choose your button shape.">
                <SelectButtonShape />
              </Step>
            </Box>
            <Box sx={{ width: 434, alignSelf: "center" }}>
              <PhonePreview isQuickSetup />
            </Box>
          </Box>
        </WizardForm>
      )}
      {setupWizard.form === 6 && (
        <WizardForm
          label="Business Info"
          required={["billing_email", "website_url", "testimonials_url", "privacy_policy", "reviews_url"]}
        >
          <Step index={7} label="Who should receive Demand IQ invoices?">
            <ChangeBillingEmail />
          </Step>
          <Step index={8} label="What phone number would you like to advertise?">
            <ChangeAdvertisePhone />
          </Step>
          <Step index={9} label="What email address would you like to advertise?">
            <ChangeAdvertiseEmail />
          </Step>
          <Step index={10} label="Provide a link to your website.">
            <ChangeWebsiteLink />
          </Step>
          <Step index={11} label="Provide a link to your testimonials page.">
            <ChangeTestimonialsLink />
          </Step>
          <Step index={12} label="Provide a link to your privacy policy page.">
            <ChangePrivacyLink />
          </Step>
          <Step index={13} label="Third Party Reviews">
            <ChangeReviewLink />
          </Step>
        </WizardForm>
      )}
      {setupWizard.form === 7 && (
        <WizardForm label="Service Area">
          <Step index={14} label="Upload the zip codes of your service area.">
            <UploadZipcode />
          </Step>
        </WizardForm>
      )}
      {setupWizard.form === 8 && (
        <WizardForm label="Solar Product" required={["offset"]}>
          <Step index={15} label="What $/watt would you like to use in calculations?">
            <SelectPricePerWatt />
            <UploadPricePerWatt />
          </Step>
          <Step index={16} label="What offset would you like to use?">
            <ChangeOffset />
          </Step>
          <Step index={17} label="What utility rate escalator would like to use?">
            <ChangeEscalation />
            <UploadEscalation />
          </Step>
          <Step index={18} label="What lending product do you offer?">
            <ChangeProductApr />
            <ChangeProductTerm />
          </Step>
          <Step index={19} label="Would you like to show a system size/price range or an exact value?">
            <SelectPriceSystem />
          </Step>
        </WizardForm>
      )}
      {setupWizard.form === 9 && (
        <WizardForm label="Incentives">
          <Step
            index={20}
            label="Would you like to factor in local and/or utility incentives into your solar savings calculations?"
          >
            <Stack direction="row" spacing={2}>
              <SelectStateIncentive />
              <SelectUtilityIncentive />
            </Stack>
          </Step>
          <Step index={21} label="Upload custom incentives.">
            <UploadIncentives />
          </Step>
        </WizardForm>
      )}
      {setupWizard.form === 10 && (
        <WizardForm label="Qualification Questions">
          <Step index={22} label="In the drop down choose from the available questions? Indicate your disqualifiers.">
            <QuestionEditor />
          </Step>
        </WizardForm>
      )}
      {setupWizard.form === 11 && <AppointmentForm />}
      {setupWizard.form === 12 && (
        <WizardForm label="Customize Your AI" required={["ai_name"]}>
          <Step index={25} label="What would you like to name your AI?">
            <ChangeAiName />
          </Step>
          <Step index={26} label="Choose an avatar for your AI.">
            <UploadAiAvatar />
          </Step>
        </WizardForm>
      )}
      {setupWizard.form === 13 && (
        <WizardForm label="Customize Your Landing Page">
          <Step index={27} label="Please provide a custom statement to display on the landing page.">
            <ChangeWelcome />
          </Step>
        </WizardForm>
      )}
      {setupWizard.form === 14 && (
        <WizardForm label="Customize Your Thanks Page">
          <Step index={28} label="Please provide a custom statement & icon to display on your thank you page.">
            <ChangeThanks />
          </Step>
        </WizardForm>
      )}
      {setupWizard.form === 15 && (
        <WizardForm label="Upsell">
          <Step index={29} label="Upsell opportunities for Batteries and EV Chargers">
            <UpsellEditor />
          </Step>
        </WizardForm>
      )}
      {setupWizard.form === 16 && isFinancingEnabled && (
        <WizardForm label="Pre Qualification">
          <Step index={30} label="Please Select a pre-qualification partner.">
            <PreQualification />
          </Step>
        </WizardForm>
      )}
      {setupWizard.form === 17 - (isFinancingEnabled ? 0 : 1) && <Complete closeWizard={closeWizard} />}
    </Box>
  );
};

export default Wizard;

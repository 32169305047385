import React from "react";
import { Dialog, Stack, Box, TextField, Button } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { apiSendEmailToCoworker } from "store/api";
import useAlert from "hooks/useAlert";
import useConfigurator from "hooks/useConfigurator";

const sampleMessage =
  "I'm trying to setup DNS records for Stella by Demand IQ, but I can't do it. Can you help me add these records, so that we can activate our new software from Demand IQ?";

const DialogEmail = ({ open, closeDialog }) => {
  const hAlert = useAlert();
  const hConfigurator = useConfigurator();
  const [loading, setLoading] = React.useState(false);
  const [email, setEmail] = React.useState("");
  const [message, setMessage] = React.useState(sampleMessage);

  React.useEffect(() => {
    if (open === true) {
      setEmail("");
      setMessage(sampleMessage);
    }
    // eslint-disable-next-line
  }, [open]);

  const handleSend = async () => {
    setLoading(true);
    let valid = true;
    email.split(",").map((d) => {
      const _email = d.trim();
      if (!/[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,15}/g.test(_email)) {
        valid = false;
      }
      return _email;
    });

    if (valid) {
      const res = await apiSendEmailToCoworker(hConfigurator.client.id)({
        emails: email,
        message,
      });
      if (res.status === 200) {
        hAlert.show("Sent an instruction email to your coworkers successfully!");
        closeDialog();
      } else {
        hAlert.show(res.data.detail || "Something went wrong", false);
      }
    } else {
      hAlert.show("Please enter correct email addresses", false);
    }
    setLoading(false);
  };

  return (
    <Dialog
      open={open}
      onClose={() => {}}
      PaperProps={{
        sx: { width: "100%" },
      }}
    >
      <Stack p={4} spacing={4}>
        <Box sx={{ fontSize: 24 }}>Send To A Coworker</Box>
        <Box
          sx={{
            fontSize: 14,
            color: "#666",
          }}
        >
          Enter a coworker's email address and we'll send them everything they need to install these records.
        </Box>
        <TextField
          fullWidth
          label="Recipients (use comma to include multiple address)"
          disabled={loading}
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <TextField
          fullWidth
          multiline
          minRows={3}
          label="Message *"
          disabled={loading}
          value={message}
          onChange={(e) => setMessage(e.target.value)}
        />
        <Box sx={{ display: "flex" }}>
          <LoadingButton
            variant="contained"
            sx={{ marginRight: 2 }}
            loading={loading}
            disabled={!email || !message}
            onClick={handleSend}
          >
            Send
          </LoadingButton>
          <Box sx={{ flexGrow: 1 }} />
          <Button variant="outlined" onClick={closeDialog} disabled={loading}>
            Cancel
          </Button>
        </Box>
      </Stack>
    </Dialog>
  );
};

export default DialogEmail;

import React from "react";
import { Box, TextField } from "@mui/material";
import useExperimentsCreator from "hooks/useExperimentsCreator";

const HeadlineText = () => {
  const hCreator = useExperimentsCreator();

  const [value, setValue] = React.useState("");
  const [saving, setSaving] = React.useState(false);

  React.useEffect(() => {
    setValue(hCreator.bannerValues.banner_text);
    // eslint-disable-next-line
  }, []);

  const handleChange = (e) => {
    if (e.target.value.length <= 72) {
      setValue(e.target.value);
    }
  };

  const handleSave = async () => {
    setSaving(true);
    hCreator.setBannerValue("banner_text", value);
    setSaving(false);
  };

  return (
    <Box>
      <TextField
        fullWidth
        label={`Headline Text (${value.length}/72 chars max)`}
        value={value}
        onChange={handleChange}
        onBlur={handleSave}
        disabled={saving}
      />
    </Box>
  );
};

export default HeadlineText;

import React from "react";
import { useRecoilValue } from "recoil";
import { Scrollbars } from "react-custom-scrollbars";
import { Box, Paper, Tabs, Tab } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import PageLoading from "shared/PageLoading";
import Confirm from "shared/Confirm";
import useConfigurator from "hooks/useConfigurator";
import QuickSetup from "./components/quick-setup/QuickSetup";
import Integration from "./components/integration/Integration";
import Banner from "./components/banner-config/Banner";
import EmbedWidget from "./components/embed-widget/EmbedWidget";
import SpanishSetup from "./components/spanish-setup/SpanishSetup";
import Settings from "./components/settings/Settings";
import { clientAtom } from "store/client";
import EmbedChatWidget from "./components/embed-chat-widget/EmbedChatWidget";
import useCallbackPrompt from "hooks/useCallbackPrompt";

const ConfiguratorPage = () => {
  const clientState = useRecoilValue(clientAtom);
  const hConfigurator = useConfigurator();

  const [tab, setTab] = React.useState(0);
  const [showPrompt, confirmNavigation, cancelNavigation] = useCallbackPrompt(hConfigurator.touched);

  React.useEffect(() => {
    hConfigurator.init();
    // eslint-disable-next-line
  }, [clientState.current]);

  const handleChange = (e, v) => {
    setTab(v);
  };

  if (!hConfigurator.client) {
    return <PageLoading />;
  }

  return (
    <Box px={4} sx={{ height: 1 }}>
      <Box sx={{ height: 136, display: "flex", alignItems: "center" }}>
        <Box sx={{ fontSize: 24, color: "primary.main", flexGrow: 1 }}>Configurator</Box>

        {tab !== 0 && (
          <LoadingButton
            loading={hConfigurator.saving}
            variant="contained"
            onClick={hConfigurator.saveAll}
            disabled={!hConfigurator.touched}
          >
            Save
          </LoadingButton>
        )}
      </Box>
      <Paper
        sx={{
          height: "calc(100% - 136px)",
          borderTopLeftRadius: 16,
          borderTopRightRadius: 16,
          borderBottomLeftRadius: 0,
          borderBottomRightRadius: 0,
        }}
      >
        <Box px={2} sx={{ borderBottom: 1, borderColor: "divider", height: 50 }}>
          <Tabs value={tab} onChange={handleChange} sx={{ height: 50 }}>
            <Tab label="Quick Setup" />
            <Tab label="Integration" />
            <Tab label="Banner" />
            <Tab label="Embed Widget" />

            {hConfigurator.client.enable_chat && <Tab label="Stella Embedded" />}

            <Tab label="Spanish Setup" />
            <Tab label="Settings" />
          </Tabs>
        </Box>
        <Box sx={{ height: "calc(100% - 50px)" }}>
          <Scrollbars>
            {tab === 0 && <QuickSetup />}
            {tab === 1 && <Integration />}
            {tab === 2 && <Banner />}
            {tab === 3 && <EmbedWidget />}

            {tab === 4 && hConfigurator.client.enable_chat && <EmbedChatWidget />}

            {tab === 4 + hConfigurator.client.enable_chat && <SpanishSetup />}
            {tab === 5 + hConfigurator.client.enable_chat && <Settings />}
          </Scrollbars>
        </Box>
      </Paper>

      <Confirm
        open={showPrompt}
        onYes={() => {
          hConfigurator.init();
          confirmNavigation();
        }}
        onNo={cancelNavigation}
      />
    </Box>
  );
};

export default ConfiguratorPage;

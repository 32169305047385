import React from "react";
import { Box } from "@mui/material";
import VisitorGraph from "./components/VisitorGraph";
import ReportWidgets from "./components/ReportWidgets";
import "./style.scss";

const DashboardPage = () => {
  return (
    <Box>
      <VisitorGraph />
      <ReportWidgets />
    </Box>
  );
};

export default DashboardPage;

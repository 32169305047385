import React from "react";
import { useRecoilState } from "recoil";
import { clientAtom } from "store/client";
import { makeStyles } from "@mui/styles";
import FormText from "shared/FormText";

const textLabelStyle = (color) =>
  makeStyles({
    root: {
      color,

      "&$focused:not(.Mui-error)": {
        color,
        paddingLeft: 2,
        paddingRight: 2,
      },
    },

    focused: {},
  });

const textInputStyle = (color) =>
  makeStyles({
    root: {
      color,
      "&:not(.Mui-error) $notchedOutline": {
        borderColor: color,
      },
      "& .MuiInputAdornment-root": {
        color,
      },
    },

    input: {
      "&:-webkit-autofill": {
        WebkitTextFillColor: "white",
      },
      "&::-webkit-inner-spin-button, &::-webkit-outer-spin-button": {
        "-webkit-appearance": "none",
        margin: 0,
      },
      "-moz-appearance": "textfield",
    },

    focused: {},

    notchedOutline: {},
  });

const StellaFormText = (props) => {
  const [clientState] = useRecoilState(clientAtom);
  const { color_scheme_font } = clientState.current;
  const textLabelClasses = textLabelStyle(color_scheme_font)();
  const textInputClasses = textInputStyle(color_scheme_font)();

  return (
    <FormText
      {...props}
      InputLabelProps={{ ...props.InputLabelProps, classes: textLabelClasses }}
      InputProps={{ ...props.InputProps, classes: textInputClasses }}
    />
  );
};

export default StellaFormText;

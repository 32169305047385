import React from "react";
import moment from "moment";
import { Link, useNavigate } from "react-router-dom";
import { Scrollbars } from "react-custom-scrollbars";
import {
  Box,
  Paper,
  Button,
  CircularProgress,
  TablePagination,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Checkbox,
} from "@mui/material";
import { apiGetClients } from "store/api";
import TableAction from "./TableAction";
import SearchBox from "./SearchBox";
import _ from "lodash";

const filterByString = (arr, str) => {
  return arr.filter(
    (d) =>
      d.company_name.toLowerCase().includes(str.toLowerCase()) ||
      (d.primary_first_name + " " + d.primary_last_name).toLowerCase().includes(str.toLowerCase()) ||
      d.email.toLowerCase().includes(str.toLowerCase()) ||
      d.phone.toLowerCase().includes(str.toLowerCase())
  );
};

const ClientListPage = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState(true);
  const mounted = React.useRef(true);
  const [clientsOrigin, setClientsOrigin] = React.useState([]);
  const [clients, setClients] = React.useState([]);
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [searchKey, setSearchKey] = React.useState("");

  React.useEffect(() => {
    const getClients = async () => {
      const res = await apiGetClients();
      if (res.status === 200 && mounted.current) {
        setClientsOrigin(res.data);

        setClients(
          filterByString(
            _.orderBy(
              res.data,
              [
                (o) => {
                  return o["company_name"];
                },
              ],
              ["asc"]
            ),
            searchKey
          )
        );

        setLoading(false);
      }
    };
    getClients();
    return () => {
      mounted.current = false;
    };
    // eslint-disable-next-line
  }, []);

  React.useEffect(() => {
    setClients(filterByString(clientsOrigin, searchKey));
    // eslint-disable-next-line
  }, [searchKey]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setSelected([]);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    setSelected([]);
  };

  const handleSelectAll = (event) => {
    if (event.target.checked) {
      setSelected(clients.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((d) => d.id));
    } else {
      setSelected([]);
    }
  };

  const handleSelectSingle = (event, client) => {
    event.stopPropagation();
    if (selected.includes(client.id)) {
      setSelected(selected.filter((d) => d !== client.id));
    } else {
      setSelected([...selected, client.id]);
    }
  };

  const handleRemove = async () => {};

  const showEditor = (client) => {
    navigate(`/settings/clients/${client.id}`);
  };

  return (
    <Box px={4} sx={{ height: 1 }}>
      <Box sx={{ height: 136, display: "flex", alignItems: "center" }}>
        <Box sx={{ fontSize: 24, color: "primary.main", flexGrow: 1 }}>Clients</Box>
        <SearchBox onChange={(e) => setSearchKey(e.target.value)} />
        <Button component={Link} variant="contained" to="/settings/clients/new" sx={{ marginLeft: 1 }}>
          Add New Client
        </Button>
      </Box>

      <Paper
        sx={{
          height: "calc(100% - 136px)",
          borderTopLeftRadius: 16,
          borderTopRightRadius: 16,
          borderBottomLeftRadius: 0,
          borderBottomRightRadius: 0,
          overflow: "hidden",
        }}
      >
        {loading ? (
          <Box pt={4} sx={{ textAlign: "center" }}>
            <CircularProgress size={20} />
          </Box>
        ) : (
          <Box sx={{ height: 1 }}>
            <Box sx={{ height: "calc(100% - 50px)" }}>
              <Scrollbars>
                <Table stickyHeader sx={{ "& th": { whiteSpace: "nowrap" } }}>
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <Checkbox
                          checked={selected.length === rowsPerPage || selected.length === clients.length}
                          indeterminate={
                            selected.length > 0 && selected.length < rowsPerPage && selected.length < clients.length
                          }
                          onClick={(e) => handleSelectAll(e)}
                        />
                      </TableCell>
                      <TableCell>{selected.length > 0 ? <TableAction onRemove={handleRemove} /> : "Client"}</TableCell>
                      <TableCell>Primary</TableCell>
                      <TableCell>Email</TableCell>
                      <TableCell>Phone</TableCell>
                      <TableCell>Level</TableCell>
                      <TableCell>Date Joined</TableCell>
                      <TableCell>Status</TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {filterByString(clients, searchKey)
                      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      .map((d) => (
                        <TableRow
                          key={d.id}
                          sx={{ "&:hover": { backgroundColor: "#eee", cursor: "pointer" } }}
                          onClick={() => showEditor(d)}
                        >
                          <TableCell>
                            <Checkbox checked={selected.includes(d.id)} onClick={(e) => handleSelectSingle(e, d)} />
                          </TableCell>
                          <TableCell>{d.company_name}</TableCell>
                          <TableCell>
                            {d.primary_first_name} {d.primary_last_name}
                          </TableCell>
                          <TableCell>{d.email}</TableCell>
                          <TableCell>{d.phone}</TableCell>
                          <TableCell sx={{ textTransform: "capitalize" }}>{d.level}</TableCell>
                          <TableCell>{moment(d.created_at).format("MMMM D, YYYY")}</TableCell>
                          <TableCell sx={{ textTransform: "capitalize" }}>
                            {d.status ? "Active" : "Suspended"}
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </Scrollbars>
            </Box>
            <TablePagination
              rowsPerPageOptions={[10, 25, 50, 100]}
              component="div"
              count={clients.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              sx={{
                height: 50,
                borderTop: "1px solid #ccc",
                "& .MuiToolbar-root": { minHeight: 0 },
              }}
            />
          </Box>
        )}
      </Paper>
    </Box>
  );
};

export default ClientListPage;

import React from "react";
import { Box, TextField } from "@mui/material";
import useConfigurator from "hooks/useConfigurator";

const UrlParams = () => {
  const hConfigurator = useConfigurator();
  const [value, setValue] = React.useState("");

  React.useEffect(() => {
    setValue(hConfigurator.client.banner_url_parameters ?? "");
    // eslint-disable-next-line
  }, []);

  const handleChange = (e) => {
    setValue(e.target.value);
    hConfigurator.setTouched(true);
  };

  const handleSave = () => {
    hConfigurator.updateSingle("banner_url_parameters", value);
  };

  return (
    <Box>
      <Box mb={2} sx={{ fontSize: 20 }}>
        Add URL Parameters
      </Box>
      <TextField fullWidth label="URL Parameters" value={value} onChange={handleChange} onBlur={handleSave} />
    </Box>
  );
};

export default UrlParams;

import React from "react";
import { useForm } from "react-hook-form";
import { Stack } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import useAlert from "hooks/useAlert";
import { apiSgEmailCheck, apiUpdateClient, apiValidateClientDns } from "store/api";
import FormText from "shared/FormText";
import { makeStyles } from "@mui/styles";
import { clientAtom } from "store/client";
import { useRecoilState } from "recoil";

const useStyles = (state) =>
  makeStyles((theme) => ({
    root: {
      "& .Mui-error": {
        color: getStateColor(state, theme),
      },

      "& .MuiFormHelperText-root": {
        color: getStateColor(state, theme),
      },

      "& .MuiOutlinedInput-root": {
        "& .MuiOutlinedInput-notchedOutline": {
          borderColor: getStateColor(state, theme),
        },
      },
    },
  }));

const getStateColor = (state, theme) => {
  switch (state) {
    case "warning":
      return theme.palette.warning.main;
    case "error":
      return theme.palette.error.main;
    default:
      return "none";
  }
};

const InfoTab = ({ client, updateClient }) => {
  const hAlert = useAlert();

  const [clientState, setClientState] = useRecoilState(clientAtom);

  const {
    control,
    trigger,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      company_name: client.company_name,
      company_slug: client.company_slug,
      primary_first_name: client.primary_first_name,
      primary_last_name: client.primary_last_name,
      phone: client.phone,
      email: client.email,
      address: client.address ?? "",
      city: client.city ?? "",
      state: client.state ?? "",
      zip_code: client.zip_code ?? "",
    },
  });

  const mounted = React.useRef(true);
  const [loading, setLoading] = React.useState(false);
  const [emailState, setEmailState] = React.useState(null);
  const [emailMessage, setEmailMessage] = React.useState("");

  const classes = useStyles(emailState)();

  React.useEffect(() => {
    return () => {
      mounted.current = false;
    };
    // eslint-disable-next-line
  }, []);

  React.useEffect(() => {
    if (errors.email?.type === "required") {
      setEmailState("error");
      setEmailMessage("required");
    }
  }, [errors.email?.type]);

  const onSubmit = async (d) => {
    setLoading(true);

    const {
      company_name,
      company_slug,
      primary_first_name,
      primary_last_name,
      phone,
      email,
      address,
      city,
      state,
      zip_code,
    } = d;

    const formData = new FormData();
    formData.append("company_name", company_name);
    formData.append("company_slug", company_slug);
    formData.append("primary_first_name", primary_first_name);
    formData.append("primary_last_name", primary_last_name);
    formData.append("phone", phone);
    formData.append("email", email);
    formData.append("address", address);
    formData.append("city", city);
    formData.append("state", state);
    formData.append("zip_code", zip_code);

    const res = await apiUpdateClient(client.id)(formData);
    if (res.status === 200) {
      let customDomainValidated = false;
      const res2 = await apiValidateClientDns(client.id)();
      if (res2.status === 200) customDomainValidated = res2.data?.custom_domain?.valid;

      setClientState({
        ...clientState,
        all: [...clientState.all.filter((d) => d.id !== client.id), res.data],
        customDomainValidated,
      });
      updateClient(res.data);
      hAlert.show("Client saved successfully!");
    }

    if (mounted.current) {
      setLoading(false);
    }
  };

  const checkEmail = async (email) => {
    const res = await apiSgEmailCheck({ email });

    if (mounted.current) {
      if (res.status === 200) {
        if (res.data.status === "Valid") {
          setEmailState("success");
          setEmailMessage("");
        } else {
          setEmailState("warning");
          setEmailMessage(res.data?.message || "Risky");
        }
        return true;
      } else {
        setEmailState("error");
        setEmailMessage(res.data?.message || "Failed");
        return false;
      }
    }
    return false;
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack p={4} spacing={4}>
        <FormText required disabled={loading} control={control} name="company_name" label="Company Name *" />
        <FormText required disabled={loading} control={control} name="company_slug" label="Company Slug *" />
        <FormText
          required
          disabled={loading}
          control={control}
          name="primary_first_name"
          label="Primary First Name *"
        />
        <FormText required disabled={loading} control={control} name="primary_last_name" label="Primary Last Name *" />
        <FormText required disabled={loading} control={control} name="phone" label="Phone Number *" />
        <FormText
          required
          disabled={loading}
          control={control}
          name="email"
          label="Work Email *"
          helperText={emailMessage}
          rules={{ validate: { checkEmail } }}
          onBlur={() => trigger(["email"])}
          className={classes.root}
        />
        <FormText disabled={loading} control={control} name="address" label="Address" />
        <FormText disabled={loading} control={control} name="city" label="City" />
        <FormText disabled={loading} control={control} name="state" label="State" />
        <FormText disabled={loading} control={control} name="zip_code" label="Zipcode" />
        <LoadingButton loading={loading} variant="contained" type="submit">
          SAVE CLIENT
        </LoadingButton>
      </Stack>
    </form>
  );
};

export default InfoTab;

import { Box } from "@mui/material";
import SingleText from "../shared/SingleText";

const Instapage = () => {
  return (
    <Box p={4}>
      <Box mb={4} sx={{ fontSize: 20, fontWeight: "bold" }}>
        Instapage
      </Box>

      <SingleText field="instapage_id" label="Instapage ID" />
    </Box>
  );
};

export default Instapage;

import React from "react";
import { Box } from "@mui/material";
import prismatic from "@prismatic-io/marketplace";
import { useRecoilState, useResetRecoilState } from "recoil";
import { clientAtom } from "store/client";
import useAlert from "hooks/useAlert";
import { apiGetPrismaticToken } from "store/api";

const IntegrationsPage = () => {
  // const jwt_prismatic = window.localStorage.getItem("jwt_prismatic");

  const [clientState, setClientState] = useRecoilState(clientAtom);
  const hAlert = useAlert();

  console.log(clientState.current.prismatic_signing_key);

  const displayPrismatic = async (data) => {
    const res = await apiGetPrismaticToken(data);
    if (res.status === 200) {
      const jwt_prismatic = res.data;
      console.log(jwt_prismatic);

      prismatic.init();

      try {
        prismatic.authenticate({ token: jwt_prismatic });
      } catch (error) {
        console.error(`Authentication failed with error ${error}`);
      }

      prismatic.showMarketplace({
        selector: `#integration-marketplace-placeholder`,
        usePopover: false,
      });
    } else {
      hAlert.show("Authenticaion info is not provided", false);
    }
  };

  React.useEffect(() => {
    displayPrismatic(clientState.current);
  }, [clientState.current]);

  return (
    <Box sx={{ height: 1 }}>
      <div id="integration-marketplace-placeholder" style={{ height: "100%" }}>
        Loading...
      </div>
    </Box>
  );
};

export default IntegrationsPage;

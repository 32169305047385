import React from "react";
import { Pie } from "react-chartjs-2";
import { Box, CircularProgress } from "@mui/material";
import useReporting from "hooks/useReporting";

const LeadViewEstimate = () => {
  const hReporting = useReporting();

  return (
    <Box p={2} sx={{ border: "1px solid #ccc", borderRadius: 2 }}>
      <Box sx={{ color: "primary.dark", fontSize: 20 }}>How often are leads viewing the estimate?</Box>
      <Box mt={2}>
        {!hReporting.conversion.data.leadViewEstimate ? (
          <Box sx={{ width: 1, minHeight: 200, display: "flex", alignItems: "center", justifyContent: "center" }}>
            <CircularProgress size={20} />
          </Box>
        ) : (
          <Pie
            data={{
              labels: ["Estimates Viewed", "Estimates Not Viewed"],
              datasets: [
                {
                  data: [
                    hReporting.conversion.data.leadViewEstimate.lead,
                    hReporting.conversion.data.leadViewEstimate.visitor,
                  ],
                  backgroundColor: ["#2196f3", "#ccc"],
                  hoverBackgroundColor: ["#2196f3", "#ccc"],
                },
              ],
            }}
          />
        )}
      </Box>
    </Box>
  );
};

export default LeadViewEstimate;

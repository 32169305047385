import React from "react";
import { Box, Divider, Stack } from "@mui/material";
import Filter from "./components/filter/Filter";
import EmailNotification from "./components/email-notification/EmailNotification";
import ExportLead from "./components/export-lead/ExportLead";
import LeadStatus from "./components/lead-status/LeadStatus";
import LeadFailEstimate from "./components/lead-fail-estimate/LeadFailEstimate";
import LeadViewEstimate from "./components/lead-view-estimate/LeadViewEstimate";

const MainReport = () => {
  return (
    <Box>
      <Stack direction="row" justifyContent="space-between">
        <Filter />
        <Stack p={4} direction="row" spacing={2} sx={{ justifyContent: "flex-end" }}>
          <EmailNotification />
          <ExportLead />
        </Stack>
      </Stack>
      <Divider />
      <Stack p={4} spacing={4}>
        <LeadStatus />
        <Box width={1} sx={{ display: "flex", flexDirection: { xs: "column", md: "row" }, gap: 2 }}>
          <Box width={{ xs: 1, md: 0.5 }}>
            <LeadFailEstimate />
          </Box>
          <Box width={{ xs: 1, md: 0.5 }}>
            <LeadViewEstimate />
          </Box>
        </Box>
      </Stack>
    </Box>
  );
};

export default MainReport;

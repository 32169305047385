import React from "react";
import { Box, TextField } from "@mui/material";
import useQuickSetupWizard from "hooks/useQuickSetupWizard";

const MeetingDescription = () => {
  const hWizard = useQuickSetupWizard();
  const [meetingDescription, setMeetingDescription] = React.useState("");

  React.useEffect(() => {
    setMeetingDescription(hWizard.client.calendar_meeting_description);
    // eslint-disable-next-line
  }, []);

  const handleChange = (e) => {
    setMeetingDescription(e.target.value);
    hWizard.update("calendar_meeting_description", e.target.value);
  };

  return (
    <Box my={2}>
      <Box sx={{ mb: 2, fontSize: 12 }}>Please customize the meeting description.</Box>
      <TextField value={meetingDescription} onChange={handleChange} sx={{ width: 280 }} />
    </Box>
  );
};

export default MeetingDescription;

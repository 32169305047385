import React from "react";
import { Box } from "@mui/material";
import useClientCreateWizard from "hooks/useClientCreateWizard";

const WizardForm = ({ label, required = [], needVerified = [], children }) => {
  const wizard = useClientCreateWizard();

  React.useEffect(() => {
    wizard.setRestrictions(required, needVerified);
    // eslint-disable-next-line
  }, []);

  return (
    <Box>
      {label && (
        <Box my={2} sx={{ color: "#0008", textTransform: "uppercase" }}>
          {label}
        </Box>
      )}
      {children}
    </Box>
  );
};

export default WizardForm;

import React from "react";
import { Box, IconButton, Stack } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { DeleteIcon, EditIcon } from "utils/icons";

const useStyles = makeStyles({
  container: {
    minWidth: "17.5rem",
    width: "17.5rem",
    minHeight: "18.125rem",
    border: "1px solid #dcdcdc",
    boxSizing: "border-box",
    borderRadius: "10px",
    margin: "1.875rem 1rem",
    boxShadow: "0px 10px 24px rgba(0, 0, 0, 0.1)",
    position: "relative",
  },

  content: {
    alignItems: "center",
    padding: "36px 20px 0 20px",
    flex: 1,
  },

  header: {
    fontSize: "1rem",
    textAlign: "center",
    textTransform: "uppercase",
    color: "rgba(0, 0, 0, 0.87)",
    margin: "1rem 0px",
    fontWeight: "500",
    letterSpacing: "0.07rem",
  },

  description: {
    fontSize: "0.875rem",
    textAlign: "center",
    color: "rgba(0, 0, 0, 0.87)",
    flexGrow: 1,
    lineHeight: "1.25rem",
  },

  system: {
    position: "absolute",
    fontWeight: "bold",
    fontSize: "0.75rem",
    left: "20px",
    top: "17px",
  },

  rate: {
    fontWeight: "bold",
    fontSize: "0.75rem",
    textAlign: "center",
    color: "rgba(0, 0, 0, 0.87)",
    margin: "1rem 0rem",

    "& span": {
      fontSize: "0.875rem",
    },
  },

  action: {
    padding: "4px 8px",
    width: "100%",
    borderTop: "1px solid #dcdcdc",
    fontWeight: "bold",
    justifyContent: "space-between",
  },
});

const Battery = ({
  title,
  description,
  cost,
  system_size_min,
  system_size_max,
  isDefault,
  showDelete,
  onEdit,
  onDelete,
}) => {
  const classes = useStyles();

  return (
    <Stack className={classes.container}>
      <Stack className={classes.content}>
        {!(
          system_size_min === null ||
          system_size_min === undefined ||
          system_size_min === "" ||
          system_size_max === null ||
          system_size_max === undefined ||
          system_size_max === ""
        ) && (
          <Box className={classes.system}>
            {system_size_min} ~ {system_size_max}kW
          </Box>
        )}
        <img src="/images/battery-2-charge-line.svg" alt="battery" />
        <Box className={classes.header}>{title}</Box>
        <Box className={classes.description}>{description}</Box>
        <Box className={classes.rate}>
          <span>+${cost}/mo</span>
        </Box>
      </Stack>
      <Stack className={classes.action} direction="row">
        {isDefault ? (
          <Box sx={{ display: "flex", alignItems: "center", fontSize: "13px", padding: "8px", color: "primary.main" }}>
            Default
          </Box>
        ) : (
          <Box />
        )}
        <Box>
          <IconButton onClick={onEdit}>
            <EditIcon />
          </IconButton>
          {showDelete && (
            <IconButton onClick={onDelete}>
              <DeleteIcon />
            </IconButton>
          )}
        </Box>
      </Stack>
    </Stack>
  );
};

export default Battery;

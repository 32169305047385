import React from "react";
import { Box, CircularProgress, Stack } from "@mui/material";
import ColorScheme from "./banner/ColorScheme";
import Position from "./banner/Position";
import BannerButtonShape from "./banner/BannerButtonShape";
import HeadlineText from "./banner/HeadlineText";
import ButtonText from "./banner/ButtonText";
import UrlParams from "./banner/UrlParams";
import BannerPreview from "./BannerPreview";
import useExperimentsCreator from "hooks/useExperimentsCreator";
import useExperiments from "hooks/useExperiments";

const BannerForm = () => {
  const hCreator = useExperimentsCreator();
  const hExperiments = useExperiments();

  React.useEffect(() => {
    if (!hCreator.bannerInitialized) {
      hCreator.setBannerValues({
        banner_bg_color: hExperiments.client.banner_bg_color,
        banner_text_color: hExperiments.client.banner_text_color,
        banner_button_bg_color: hExperiments.client.banner_button_bg_color,
        banner_button_text_color: hExperiments.client.banner_button_text_color,
        banner_button_hover_bg_color: hExperiments.client.banner_button_hover_bg_color,
        banner_button_hover_text_color: hExperiments.client.banner_button_hover_text_color,
        banner_type: hExperiments.client.banner_type,
        banner_position: hExperiments.client.banner_position,
        banner_button_shape: hExperiments.client.banner_button_shape ?? "square",
        banner_text: hExperiments.client.banner_text,
        banner_button_text: hExperiments.client.banner_button_text,
        banner_url_parameters: hExperiments.client.banner_url_parameters,
      });
    }
  }, []);

  return (
    <Box sx={{ display: "flex", flexDirection: { xs: "column", lg: "row" }, gap: 2 }}>
      {!hCreator.bannerInitialized ? (
        <Box my={4} sx={{ width: 1, display: "flex", justifyContent: "center" }}>
          <CircularProgress size={20} />
        </Box>
      ) : (
        <>
          <Box sx={{ flexGrow: 1 }}>
            <Stack spacing={4}>
              <Box fontSize={20}>Banner Widget</Box>

              <ColorScheme />
              <Position />
              <BannerButtonShape />
              <HeadlineText />
              <ButtonText />
              <UrlParams />
            </Stack>
          </Box>

          <Box sx={{ width: 434, alignSelf: { xs: "center", md: "auto" } }}>
            <BannerPreview />
          </Box>
        </>
      )}
    </Box>
  );
};

export default BannerForm;

export const leadStatusList = ["lead", "disqualified_lead", "qualified_lead", "appointment", "appointment_with_bill"];

export const leadStatusLabels = {
  visitor: "Visitor",
  contact: "Contact",
  lead: "Estimate Viewers",
  qualified_lead: "Qualified Lead",
  disqualified_lead: "Disqualified Lead",
  appointment: "Appointment",
  appointment_with_bill: "Utility Bills",
};

export const leadStatusLevels = {
  visitor: 0,
  disqualified_lead: 1,
  contact: 2,
  lead: 3,
  qualified_lead: 4,
  appointment: 5,
  appointment_with_bill: 6,
};

export const emailNotificationStatus = [
  {
    value: "lead",
    label: "Lead",
  },
  {
    value: "qualified_lead",
    label: "Qualified Lead",
  },
  {
    value: "appointment",
    label: "Appointment",
  },
  {
    value: "appointment_with_bill",
    label: "Appointment with Bill",
  },
];

export const exportLeadStatus = [
  {
    value: "lead",
    label: "Estimate Viewers",
  },
  {
    value: "qualified_lead",
    label: "Qualified Lead",
  },
  {
    value: "appointment",
    label: "Appointment",
  },
  {
    value: "appointment_with_bill",
    label: "Appointment with Bill",
  },
];

export const exportLeadStatusDefault = ["lead", "qualified_lead", "appointment", "appointment_with_bill"];

export const barChartOptions = {
  legend: {
    display: false,
  },
  maintainAspectRatio: false,
  devicePixelRatio: 1,
  layout: {
    padding: {
      top: 70,
      left: 32,
      right: 32,
    },
  },
  scales: {
    xAxes: [
      {
        gridLines: {
          display: false,
          drawBorder: false,
          tickMarkLength: 18,
        },
        ticks: {
          fontColor: "#2196f3",
        },
        display: false,
      },
    ],
    yAxes: [
      {
        display: false,
        ticks: {
          min: 0,
        },
      },
    ],
  },
  plugins: {
    xLabelsOnTop: {
      active: true,
    },
  },
  tooltips: {
    enabled: false,
  },
  events: ["click", "mousemove"],
};

export const tableColumns = [
  {
    header: "Visitor ID",
    key: "proposal_id",
  },
  {
    header: "Client",
    key: "solar_company",
  },
  {
    header: "Date Created",
    key: "first_session",
  },
  {
    header: "Date Last Updated",
    key: "last_session",
  },
  {
    header: "Lead Status",
    key: "lead_status",
  },
  {
    header: "First Name",
    key: "first_name",
  },
  {
    header: "Last Name",
    key: "last_name",
  },
  {
    header: "Email",
    key: "email",
  },
  {
    header: "Phone",
    key: "phone",
  },
  {
    header: "Address",
    key: "address",
  },
  {
    header: "Unit",
    key: "unit",
  },
  {
    header: "City",
    key: "city",
  },
  {
    header: "State",
    key: "state",
  },
  {
    header: "Zip Code",
    key: "zip_code",
  },
  {
    header: "Average Bill",
    key: "avg_bill",
  },
  {
    header: "Customer Type",
    key: "customer_type",
  },
  {
    header: "Property Type",
    key: "property_type",
  },
  {
    header: "Property Ownership",
    key: "property_ownership",
  },
  {
    header: "Credit Score",
    key: "credit_score",
  },
  {
    header: "Property Living Period",
    key: "property_living_period",
  },
  {
    header: "Property Roof Needed",
    key: "property_roof_need",
  },
  {
    header: "Household Taxable Income",
    key: "household_texable_income",
  },
  {
    header: "Timeframe",
    key: "timeframe",
  },
  {
    header: "Meeting Datetime",
    key: "meeting_datetime",
  },
  {
    header: "Meeting Timezone",
    key: "meeting_timezone",
  },
  {
    header: "Utility Bills URL",
    key: "utility_bills",
  },
  {
    header: "Estimate URL",
    key: "estimate_url",
  },
  {
    header: "Jornaya Lead ID",
    key: "jornaya_lead_id",
  },
  {
    header: "Project Detail Referral",
    key: "project_detail_referral",
  },
  {
    header: "Variation Name",
    key: "variation_name",
  },
  {
    header: "Referring URL",
    key: "referring_url",
  },
  {
    header: "Original Source",
    key: "original_source",
  },
  {
    header: "UTM Campaign Source",
    key: "utm_source",
  },
  {
    header: "UTM Campaign Medium",
    key: "utm_medium",
  },
  {
    header: "UTM Campaign Name",
    key: "utm_campaign",
  },
  {
    header: "UTM Campaign Term",
    key: "utm_term",
  },
  {
    header: "UTM Campaign Content",
    key: "utm_content",
  },
  {
    header: "Battery Name",
    key: "battery_name",
  },
  {
    header: "Battery Description",
    key: "battery_description",
  },
  {
    header: "Battery Price",
    key: "battery_price",
  },
  {
    header: "Charger Description",
    key: "charger_description",
  },
  {
    header: "Charger Price",
    key: "charger_price",
  },
];

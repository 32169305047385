import React from "react";
import { useRecoilState } from "recoil";
import { clientAtom } from "store/client";
import { Box, Button, InputAdornment, Stack, Tooltip, useMediaQuery } from "@mui/material";
import Logo from "../../shared/Logo";
import RoundedButton from "../../shared/StellaRoundedButton";
import TextField from "../../shared/StellaTextField";
import { URLLinkIcon, CheckCircleIcon, RestoreIcon } from "utils/icons";

const Success = ({ url, onRestart }) => {
  const matches = useMediaQuery((theme) => theme.breakpoints.up("sm"));
  const [clientState] = useRecoilState(clientAtom);

  const [tooltip, setTooltip] = React.useState(false);

  const copyToClipboard = () => {
    navigator.clipboard.writeText(url);
    setTooltip(true);
  };

  const handleTooltipClose = () => {
    setTooltip(false);
  };

  const {
    color_scheme_font,
    color_scheme_button,
    color_scheme_button_text,
    color_scheme_button_hover,
    color_scheme_button_hover_text,
  } = clientState.current;

  return (
    <Stack
      sx={{
        width: 1,
        minHeight: "calc(100vh - 64px)",
        color: color_scheme_font,
        backgroundColor: "white",
        px: { xs: 2, sm: 4 },
        py: { xs: 2 },
      }}
    >
      <Logo />
      <Stack
        sx={{
          width: 1,
          flex: 1,
          alignItems: "center",
          py: { xs: 2 },
        }}
      >
        <Stack
          sx={{ maxWidth: 600, flex: 1, mb: { sm: 12 }, alignItems: "center", justifyContent: "center" }}
          spacing={{ xs: 4, md: 6 }}
        >
          <CheckCircleIcon style={{ fontSize: matches ? 72 : 54 }} />
          <Box sx={{ fontSize: { xs: 20, sm: 28, md: 32 }, textAlign: "center" }}>
            Success! The instant estimate has been sent. The URL is also available below.
          </Box>
          <RoundedButton component="a" href={url} target="_blank" width="fit-content">
            View Estimate
          </RoundedButton>
          <TextField
            fullWidth
            readOnly
            value={url}
            variant="outlined"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <URLLinkIcon style={{ fontSize: 21, color: color_scheme_font }} />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <Tooltip
                    arrow
                    title="Copied!"
                    placement="top"
                    PopperProps={{ disablePortal: true }}
                    onClose={handleTooltipClose}
                    open={tooltip}
                    disableFocusListener
                    disableHoverListener
                    disableTouchListener
                  >
                    <Button
                      variant="contained"
                      color="secondary"
                      size="small"
                      sx={{
                        color: color_scheme_button,
                        backgroundColor: color_scheme_button_text,
                        "&:hover": {
                          color: color_scheme_button_hover,
                          backgroundColor: color_scheme_button_hover_text,
                        },
                      }}
                      onClick={copyToClipboard}
                    >
                      Copy Link
                    </Button>
                  </Tooltip>
                </InputAdornment>
              ),
            }}
          />
          <Button
            width="fit-content"
            startIcon={<RestoreIcon />}
            onClick={() => onRestart()}
            sx={{
              color: color_scheme_button,
            }}
          >
            Restart
          </Button>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default Success;

import { useRecoilState } from "recoil";
import { loadingAtom } from "store/common";

const useCommon = () => {
  const [, setLoading] = useRecoilState(loadingAtom);

  return {
    loading: setLoading,
  };
};

export default useCommon;

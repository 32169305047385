import React from "react";
import { Box } from "@mui/material";

const FormHead = ({ text }) => {
  return (
    <Box
      sx={{
        mt: 2,
        color: "#0008",
        textTransform: "uppercase",
      }}
    >
      {text}
    </Box>
  );
};

export default FormHead;

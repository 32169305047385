import React from "react";
import Banner from "./Banner";
import { Box } from "@mui/material";
import Scrollbars from "react-custom-scrollbars";
import useExperimentsEditor from "hooks/useExperimentsEditor";

const DYNAMIC_BANNER_SHOW_THRESHOLD_HEIGHT = 200;

const BannerPreview = () => {
  const hEditor = useExperimentsEditor();

  const [banner, setBanner] = React.useState(hEditor.bannerValues);
  const [showDynamicBottom, setShowDynamicBottom] = React.useState(false);

  React.useEffect(() => {
    setBanner(hEditor.bannerValues);
  }, [hEditor.bannerValues]);

  const handleScrollFrame = (e) => {
    if (banner.banner_type === "dynamic" && e.scrollTop > DYNAMIC_BANNER_SHOW_THRESHOLD_HEIGHT) {
      setShowDynamicBottom(true);
    } else {
      setShowDynamicBottom(false);
    }
  };

  return (
    <Box
      sx={{
        backgroundImage: "url(/images/iphone.png)",
        backgroundSize: "434px 888px",
        backgroundRepeat: "no-repeat",
        padding: "109px 29px 112px 30px",
        width: 434,
        height: 888,
      }}
    >
      <Box
        sx={{
          position: "relative",
          height: "100%",
        }}
      >
        <Box
          sx={{
            position: "relative",
            overflowY: "auto",
            display: "flex",
            flexDirection: "column",
            height: "100%",
            backgroundColor: "#fff",
            fontSize: "16px",
            fontFamily: "Roboto, arial",
          }}
        >
          <Scrollbars onScrollFrame={handleScrollFrame}>
            {banner.banner_type !== "dynamic" && banner.banner_position === "top" && (
              <Banner position="top" theme={banner} />
            )}

            {banner.banner_type === "dynamic" && <Banner position="top" theme={banner} />}

            <div>
              <div style={{ height: 400, backgroundColor: "#1b262c" }}></div>
              <div style={{ height: 300, backgroundColor: "#0f4c75" }}></div>
              <div style={{ height: 200, backgroundColor: "#3282b8" }}></div>
              <div style={{ height: 100, backgroundColor: "#bbe1fa" }}></div>
            </div>

            {banner.banner_type !== "dynamic" && banner.banner_position === "bottom" && (
              <Banner position="bottom" theme={banner} />
            )}

            {banner.banner_type === "dynamic" && showDynamicBottom && <Banner position="bottom" theme={banner} />}
          </Scrollbars>
        </Box>
      </Box>
    </Box>
  );
};

export default BannerPreview;

import React from "react";
import { Box, TextField } from "@mui/material";
import useQuickSetupWizard from "hooks/useQuickSetupWizard";

const LinkEditForm = ({ field, label = null }) => {
  const setupWizard = useQuickSetupWizard();
  const [error, setError] = React.useState("");
  const inputRef = React.useRef();

  React.useEffect(() => {
    setError(setupWizard.errors[field]);
    if (setupWizard.errors[field]) {
      inputRef.current.focus();
    }
    // eslint-disable-next-line
  }, [setupWizard.errors]);

  const handleLinkChange = async (e) => {
    if (e.target.value === "") {
      setError("required");
      setupWizard.update(field, "required");
    } else {
      setError("");
      setupWizard.update(field, e.target.value);
    }
  };

  return (
    <Box>
      {error && (
        <Box sx={{ color: "error.main" }}>
          {error === "required" && "Required"}
          {error !== "required" && error}
        </Box>
      )}
      <TextField
        inputRef={inputRef}
        label={label ?? ""}
        defaultValue={setupWizard.client[field] || ""}
        inputProps={{
          onKeyUp: handleLinkChange,
        }}
        sx={{ minWidth: 400 }}
      />
    </Box>
  );
};

export default LinkEditForm;

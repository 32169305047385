import React from "react";
import { Box } from "@mui/material";
import useSpanishSetupWizard from "hooks/useSpanishSetupWizard";
import FileUpload from "../shared/FileUpload";

const UploadAiAvatar = () => {
  const spanishWizard = useSpanishSetupWizard();
  const [avatar, setAvatar] = React.useState(null);

  React.useEffect(() => {
    setAvatar(spanishWizard.client.spanish_ai_avatar);
    // eslint-disable-next-line
  }, []);

  const handleChange = (file, url) => {
    setAvatar(url);
    spanishWizard.update("spanish_ai_avatar", file);
  };

  return (
    <Box>
      <FileUpload label="Upload an Avatar" onChange={handleChange} />
      {avatar && (
        <Box mt={4} sx={{ maxWidth: 360 }}>
          <img src={avatar} alt="avatar" />
        </Box>
      )}
    </Box>
  );
};

export default UploadAiAvatar;
